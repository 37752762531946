import { useRef, useEffect } from 'react';
import { EditorState } from 'prosemirror-state';
import { getPlugins } from './plugins/index.ts'
import { DOMSerializer, DOMParser } from 'prosemirror-model';
import { schema } from './schema.ts';


export function isMarkActive(state, type) {
  let {from, $from, to, empty} = state.selection;
  if (empty) return !!type.isInSet(state.storedMarks || $from.marks());
  else return state.doc.rangeHasMark(from, to, type);
}

export function isNodeActive(nodeType, state, attrs) {
  let {$from, to, node} = state.selection;
  node = node || (to <= $from.end() && $from.parent);
  if (!node) return false;
  var r = node.type === nodeType;
  for (var k in attrs) {
    r = r && attrs[k] === node.attrs[k];
  }
  return r;
}

export function canInsert(state, nodeType) {
  let $from = state.selection.$from
  for (let d = $from.depth; d >= 0; d--) {
    let index = $from.index(d)
    if ($from.node(d).canReplaceWith(index, index, nodeType)) return true
  }
  return false
}


export function stateToHtml(state) {
  const scratch = document.createElement('div');
  scratch.appendChild(DOMSerializer.fromSchema(schema).serializeFragment(state.doc.content))
  return scratch.innerHTML
}


export function htmlToState(htmlString, extraPlugins=[]) {
  const scratch = document.createElement('div')
  scratch.innerHTML = htmlString;
  return EditorState.create({
    doc: DOMParser.fromSchema(schema).parse(scratch),
    plugins: extraPlugins.concat(getPlugins())
  });
}


export function HTMLElement({ html }) {
  const ref = useRef();
  useEffect(() => {
    const el = ref.current;
    if (!el)
      return;
    el.innerHTML = html;
  }, [html]);

  return (<div html={undefined} ref={ref} />)
}