import { TextAreaInput } from './inputs';
import './Cell.css';
import { stringToNumber } from '../Controller/Data';
import Icon from 'lib/Icon';
import { Button } from 'react-aria-components';
import clsx from 'clsx';
import { faSort, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons';


export default function Cell({ controller, i, j, tableIsActive }) {
  const
    { selection, data } = controller,
    cell = data.getCell(i, j),
    column = data.getCell(-1, j),
    selected = selection.isSelected(i, j),
    active = selection.isActive(i, j),
    isEditing = controller.state.get('isEditingCell') && active,
    textAlign = i === -1 ? controller.getSettingsValue('titleAlignment') : column.get('alignment');

  return (
    <div
      className={clsx(
        'cell relative py-0.5 px-2',
        selected && tableIsActive && 'selected',
        active && tableIsActive && 'active'
      )}
      onMouseDown={ e => {
        if (isEditing)
          return;
        e.preventDefault();
        selection.startSelection(i, j);
        controller.focus();
      }}
      onClick={e => {
        if (e.detail === 2 && !isEditing) {
          controller.startEditingCell();
        }
      }}
      onMouseMove={ e => { selection.updateSelection(i, j) }}
      onMouseUp={e => { selection.endSelection(i, j) }}
      style={{
        width: column.get('width') + 'px',
        textAlign: textAlign
      }} >
        <CellDisplay cell={cell} column={column} isEditing={isEditing} i={i} j={j} controller={controller} />
        { isEditing && (<CellInput cell={cell} controller={controller} i={i} j={j} textAlign={textAlign} />) }
    </div>
  )
}

function CellDisplay({ cell, column, isEditing, controller, i, j }) {

  return (
    <div style={{ opacity: isEditing ? 0 : 1 }}>
      {(
        cell === column ?
          <TitleDisplay controller={controller} column={column} j={j} /> :
          column.get('type') === 'number' ?
            <NumberDisplay cell={cell} column={column} /> :
            column.get('type') === 'text' ?
              <TextDisplay cell={cell} column={column} /> :
              (cell.get('value') || <>&nbsp;</>)
      )}
    </div>
  )
}

function CellInput({ i, j, cell, controller, textAlign }) {
  return (
    <div className="cell-input-container">
      <TextAreaInput
        textAlign={textAlign}
        value={cell.get('value')}
        onChange={value => {
          controller.data.setCellValue(i, j, value);
        }}
        onFinish={(next) => {
          controller.endEditingCell();
          controller.focus();
          if (next)
            controller.selection.moveSelection(1, 0);
        }} />
    </div>
  )
}

function TextDisplay({ cell }) {
  return ( cell.get('value') || <>&nbsp;</>);
}

function NumberDisplay({ cell, column }) {
  const
    value = cell.get('value'),
    isString = typeof value === 'string',
    number = isString ? stringToNumber(value) : value,
    formatting = column.get('numberFormatting'),
    decimalPlaces = column.get('decimalPlaces'),
    
    displayNumber = !isNaN(number) && (
      formatting !== false ?
        number.toLocaleString(formatting || undefined, decimalPlaces ? ({
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        }) : undefined) :
        (decimalPlaces ? number.toFixed(decimalPlaces) : number)
    );

  return (
    isNaN(number) ?
       (<span className="nan text-red-700">{value}</span>) :
       (<>
         <span className="prefix mr-1">{column.get('prefix')}</span>
         <span className="nuber">{displayNumber}</span>
         <span className="suffix ml-1">{column.get('suffix')}</span>
       </>)
  );
}

function TitleDisplay({ column, controller, j }) {
  const
    current = controller.state.get('currentSorted') || {},
    self = (controller.data.rows === current.rows && j === current.j);

  return (
    <div className="title-display">
      <div className="title">{ column.get('value') }</div>
      { column.get('isSortable') && (
        <Button
          className="sort-button outline-none text-xs px-1"
          preventFocusOnPress={true}
          onPress={(e) => {
            const asc = !self ? true : !current.asc;
            controller.data.sort(j, !asc);
            controller.setState({
              currentSorted: { rows: controller.data.rows, j: j, asc: asc } });
          }}>
          <Icon faIcon={!self ? faSort : current.asc ? faSortAsc : faSortDesc} />
        </Button>
      ) }
    </div>
  )
}