import { Link } from 'react-router-dom';
import StorageImage from 'lib/StorageImage';
import { CubeIcon } from '@radix-ui/react-icons';

export default function DomainLink({ slug, title, imagePath, placeholder, Icon, loading }) {
  return (
    <Link to={`/workspaces/${slug}`} className="flex gap-2 py-2">
      <div className="thumbnail w-12 h-12 rounded-sm overflow-hidden border-rim border bg-wax text-pencil">
        {
          loading ? '' :
          imagePath || placeholder ?
          (<div className="p-1">
            <StorageImage path={imagePath} placeholder={placeholder} />
          </div>) :
          (<div>
            {
              Icon ?
                (<Icon className="m-auto my-3 w-6 h-6 text-pencil2" />) : 
                (<CubeIcon className="m-auto my-3 w-5 h-5" />)
            }
          </div>)
        }
      </div>
      <div className="info text-pencil">
        <div className="font-medium">{title}</div>
        <span className="slug text-xs font-mono">/{slug}</span>
      </div>
    </Link>
  );
}