export default class ImageBlockView {
  constructor(node, view, getPos, onClick, getURL) {
    this.node = node;
    this.view = view;
    this.getPos = getPos;
    this.getURL = getURL;

    this.dom = document.createElement('figure');
    this.dom.setAttribute('data-type', 'imageBlock');
    this.dom.className = 'relative border-rim';
    this.imgCtr = document.createElement('div');
    this.imgCtr.className = 'border-rim';
    this.dom.appendChild(this.imgCtr);

    this.img = document.createElement('img');
    this.imgCtr.appendChild(this.img);

    this.caption = document.createElement('figcaption');
    this.caption.className = 'text-sm py-2';
    this.dom.appendChild(this.caption);

    this.updateFigureAttributes(node.attrs);
    this.updateImageAttributes(node.attrs);


    this.resizeHandle = document.createElement('div');
    this.resizeHandle.className = 'resize-handle w-1 h-full bg-marker absolute top-0 -right-1 opacity-10 hover:opacity-20 hidden';
    this.resizeHandle.style.cursor = 'col-resize';
    this.dom.appendChild(this.resizeHandle);

    this.initializeEventListeners();
    this.loadImageSource();
  }

  updateFigureAttributes(attrs) {
    const { alignment, maxWidth, hasBorder, description } = attrs;    
    this.dom.setAttribute('data-alignment', alignment);
    this.dom.style.margin = (
      alignment === 'left' ? '0 auto 1rem 0' :
        alignment === 'right' ? '0 0 1rem auto' :
          alignment === 'center' ? '0 auto 1rem auto' :
            alignment === 'float-left' ? '0 1rem 1rem 0' :
              alignment === 'float-right' ? '0 0 1rem 1rem' :
                ''
    )
    // if (alignment === 'float-right') {
    //   this.dom.style.marginRight = `-${maxWidth}px`;
    // }

    this.dom.style.float =  (
      alignment === 'float-left' ? 'left' :
        alignment === 'float-right' ? 'right' :
          'none'
    );

    if (maxWidth) {
      this.dom.style.maxWidth = maxWidth;
    } else {
      this.dom.style.maxWidth = '';
    }

    this.dom.setAttribute('data-hasborder', hasBorder);
    if (hasBorder) {
      this.imgCtr.classList.add('border');
      this.imgCtr.classList.add('p-2');
    } else {
    }

    this.caption.innerHTML = description;
    if (!description) {
      this.caption.classList.add('hidden');
    } else {
      this.caption.classList.remove('hidden');
    }
  }

  updateImageAttributes(attrs) {
    this.img.setAttribute('data-location', attrs.location);
    this.img.alt = attrs.alt;
    this.img.title = attrs.title;
    // Remove maxWidth from img style
    this.img.style.maxWidth = '';
    // Set img to full width of figure
    this.img.style.width = '100%';
  }

  initializeEventListeners() {
    this.img.addEventListener('click', this.onImageClick.bind(this));
    // this.caption.addEventListener('input', this.onCaptionInput.bind(this));
    this.resizeHandle.addEventListener('mousedown', this.onResizeStart.bind(this));
  }

  loadImageSource() {
    this.getURL(this.node.attrs.location).then(src => {
      this.img.src = src;
    });
  }

  onImageClick() {
    // Implement image editing logic here
    // For example, open a dialog to change src, alt, title
    console.log('Image clicked');
  }

  onCaptionInput() {
    // const transaction = this.view.state.tr.setNodeMarkup(
    //   this.getPos(),
    //   null,
    //   { ...this.node.attrs, description: this.caption.textContent }
    // );
    // this.view.dispatch(transaction);
  }

  onResizeStart(e) {
    e.preventDefault();
    const startX = e.pageX;
    const startWidth = this.dom.offsetWidth;

    const onMouseMove = (e) => {
      const currentX = e.pageX;
      let newWidth = startWidth + (currentX - startX);
      newWidth = Math.floor(newWidth / 32) * 32;
      this.dom.style.maxWidth = `${newWidth}px`;
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      const transaction = this.view.state.tr.setNodeMarkup(
        this.getPos(),
        null,
        { ...this.node.attrs, maxWidth: this.dom.style.maxWidth }
      );
      this.view.dispatch(transaction);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  update(node) {
    if (node.type.name !== 'imageBlock') return false;
    if (node.attrs.location !== this.node.attrs.location) {
      this.node = node;
      this.updateImageAttributes(node.attrs);
      this.loadImageSource();
    } else if (node !== this.node) {
      this.updateImageAttributes(node.attrs);
    }
    this.updateFigureAttributes(node.attrs);
    this.node = node;
    return true;
  }

  selectNode() {
    this.resizeHandle.classList.remove('hidden');
  }

  deselectNode() {
    this.resizeHandle.classList.add('hidden');
  }

  destroy() {
    this.img.removeEventListener('click', this.onImageClick);
    // this.caption.removeEventListener('input', this.onCaptionInput);
    this.resizeHandle.removeEventListener('mousedown', this.onResizeStart);
  }

  stopEvent() {
    return false;
  }

  ignoreMutation() {
    return true;
  }
}