import React from 'react';
import Editor from '../Editor';

function Viewer({ state, setState, controller }) {
  return (<Editor {...{
    state, setState, controller,
    readOnly: true
  }} />);
}

export default Viewer;