export const breakPath = (path) => {
  var
    split = path.split('/'),
    filename = split.pop(),
    docPath = split.join('/'),
    [articlePath, versionSlug] = docPath.split('@');
  return { filename, articlePath, versionSlug, docPath };
}

export const assemblePath = ({ articlePath, versionSlug, filename }) => {
  return `${articlePath}${versionSlug ? `@${versionSlug}` : ''}/${filename}`;
}

export const combinePath = (basePath, relativePath) => (
  new URL(relativePath, 'http://example.com/' + basePath + '/').pathname
);

export const isSameDomain = (src) => (
  src.startsWith('/') || src.startsWith('./') || src.startsWith('../')
);