import { ui as p } from 'lib/popup';
import { Group, TextInput } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons'
import { useControllerState } from 'lib/BaseController';

import './style.css';


function Popup({ popup }) {
  let
    state = useControllerState(popup),
    domain = state.get('domain');

  return (
    <p.Popup popup={popup} className="">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>Workspace Preferences</p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          {!domain && '...'}
          {domain && <Body domain={domain} popup={popup} />}
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

function Body({ domain, popup }) {
  return (
    <form onSubmit={e => { e.preventDefault(); }}>
      <Group>
        <TextInput
          label="Slug"
          value={domain.get('slug')}
          isDisabled={true} />
      </Group>

      <p.Alert />

      <Buttons className="text-right">
        <Button onPress={_ => popup.openRenameSlugPopup()}>
          Rename Slug
        </Button>
      </Buttons>
    </form>    
  );
}

export default Popup;