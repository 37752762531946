import { ui } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';
import PathSelector from 'lib/inputs/PathSelector';
import { Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';


function Popup({ popup }) {
  let
    state = useControllerState(popup),
    loading = state.get('loading'),
    { item, folder } = popup,
    itemType = folder ? 'Folder' : 'Article';
  
  return (
    <ui.Popup popup={popup} className="move-to-folder-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Move Folder
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.move(); }}>
            <ui.Warning title="Warning!">
              You're about to move this {itemType.toLowerCase()}.
              This might break imports. Please understand the risks.
            </ui.Warning>
            <Group>

              <div className="text-sm font-medium mb-1">{itemType}</div>
              <ui.Item
                itemType={itemType}
                title={item.get('title') || item.get('label')}
                slug={item.get('slug')} />

              <PathSelector
                label="From Folder"
                value={state.get('oldPath')}
                isDisabled={true} />

              <PathSelector
                label="To Folder"
                value={state.get('newPath')}
                omitIds={popup.folder && [popup.folder.get('id')]}
                fetchPath={popup.provider.fetchPath}
                fetchChildrenFolders={popup.provider.fetchChildrenFolders}
                isDisabled={loading}
                onChange={v => { popup.setState({ newPath: v }) }} />
            </Group>

            <ui.Alert />

            <Buttons className="text-right">
              <Button isDisabled={loading} type="submit">
                Move
              </Button>
              <Button onPress={e => { popup.close() }}>Cancel</Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;