export function Logo({ className }) {
  return (
    <svg
      width="15" height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
    <path
      d="M 6.289 0.047 C 6.423 -0.016 6.577 -0.016 6.711 0.047 L 12.711 2.847 C 12.888 2.929 13 3.106 13 3.3 L 13 10.2 C 13 10.394 12.888 10.571 12.711 10.653 L 6.711 13.453 C 6.577 13.516 6.423 13.516 6.289 13.453 L 0.289 10.653 C 0.113 10.571 0 10.394 0 10.2 L 0 3.3 C 0 3.106 0.113 2.929 0.289 2.847 L 6.289 0.047 Z M 1 4.056 L 6 6.181 L 6 12.215 L 1 9.882 L 1 4.056 Z M 7 12.215 L 12 9.882 L 12 4.056 L 7 6.181 L 7 12.215 Z M 6.5 5.307 L 11.272 3.279 L 6.5 1.052 L 1.728 3.279 L 6.5 5.307 Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"/>
      <path stroke="currentColor" style={{strokeWidth: '0.9px'}} d="M 8.0 6.8 L 11.179 9.383"/>
      <path stroke="currentColor" style={{strokeWidth: '0.9px'}} d="M 10.911 5.787 L 8.161 10.631"/>
    </svg>
  )  
}


export function Logo2({ className }) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M12.5555 15.0833V29.1667L27 21.9444V7.5L12.5555 15.0833Z" stroke="currentColor" strokeLinejoin="round"/>
      <path d="M9.66666 27V12.9167L24.1111 5.33333" stroke="currentColor" strokeLinejoin="round"/>
      <path d="M6.77777 25.5556V11.4722L21.2222 3.88889" stroke="currentColor" strokeLinejoin="round"/>
      <path d="M3.88889 24.1111V10.0278L18.3333 2.44444" stroke="currentColor" strokeLinejoin="round"/>
      <path d="M1 22.6667V8.58333L15.4444 1" stroke="currentColor" strokeLinejoin="round"/>
      <path d="M23.75 20.5L17.9722 15.8055M23.0278 13.2778L17.9722 23.3889" stroke="currentColor" strokeLinejoin="round"/>
    </svg>
  )
}

export default function Logo3({ className }) {
  return (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M1.52447 12.2773L12.5427 18.6386L25 10.7633M1.34964 15.1899L12.3678 21.5512L24.8252 13.6759M1.17482 18.1025L12.193 24.4638L24.6504 16.5885M1 21.015L12.0182 27.3764L24.4755 19.501M12.9711 4.19511L13.538 7.73683M13.538 7.73683L14.0341 10.8358M13.538 7.73683L18.2953 8.02237M13.538 7.73683L8.10121 7.4105M1.13425 9.03847L12.1524 15.3998L24.6098 7.52446L13.3091 1L1.13425 9.03847Z"
      stroke="currentColor"
      strokeLinejoin="round"/>
    </svg>    
  );
}