import { useControllerState } from 'lib/BaseController';
import { ui } from 'lib/popup';
import { Group, TextInput, TextAreaInput, Checkbox } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';

function Popup({ popup }) {
  let
    state = useControllerState(popup),
    loading = state.get('loading');

  return (
    <ui.Popup popup={popup} className="edit-version-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Edit Version Info
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <Group>
            <form onSubmit={e => { e.preventDefault(); popup.save(); }}>
              <TextInput
                label="Version Label"
                value={state.get('label')}
                autoFocus={true}
                isDisabled={loading}
                onChange={v => popup.setState({ label: v })} />

              <TextAreaInput
                label="Version Notes"
                value={state.get('notes')}
                autoFocus={true}
                isDisabled={loading}
                onChange={v => popup.setState({ notes: v })} />

              <Checkbox
                label="Public Access"
                disabled={loading || popup.args.isPublished}
                value={state.get('isPublic')}
                onChange={c => popup.setState({ isPublic: c })} />
              
              <ui.Alert />

              <Buttons className="text-right">
                <Button isDisabled={loading} type="submit">
                  Save
                </Button>
                <Button onPress={e => { popup.close() }}>
                  Cancel
                </Button>
              </Buttons>
            </form>
          </Group>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;