import Editor from './Editor';
import Policies from './Policies';
import Inspector from './Inspector';
import Viewer from './Viewer';
import Controller from './Controller';
import Overview from './Overview';
import { faCode } from '@fortawesome/free-solid-svg-icons';

const index = {
  type: 'code',
  caption: 'Code',
  faIcon: faCode,
  description: 'Add a code block',
  
  Editor: Editor,
  Inspector: Inspector,
  Policies: Policies,
  Viewer: Viewer,
  Controller: Controller,
  Overview: Overview
}

export default index;