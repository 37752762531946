import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });

  constructor(args, manager) {
    super(args, manager);

    this.state = this.state.merge(args);
    if (args.inputs)
      args.inputs.forEach(
        (input, i) => 
          this.state = this.state.set('inputValue' + i, input.defaultValue)
      )

    this.onMount = args.onMount;
    this.onSubmit = args.onSubmit;

    if (args.question)
      this.state = this.state.set('question', args.question);
    if (args.height)
      this.position = this.position.set('height', args.height);
    if (args.width)
      this.position = this.position.set('width', args.width);
    
    this.unloadFns.push(_ => {
      if (args.onUnload)
        args.onUnload(this);
      args.onUnload = null;
      this.onSubmit = null;
      this.onMount = null;
    });
  }

  get inputs() { return this.state.get('inputs') || [];}

  get inputValues() {
    return this.inputs.map(
      (input, i) => this.state.get('inputValue' + i)
    );
  }
  
  async handleSubmit() {
    this.setState({ loading: true });
    try {
      if (this.onSubmit)
        (await this.onSubmit(this.inputValues));
      this.setState({ loading: false });
      this.close();
    } catch (e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }
}

export default Controller;