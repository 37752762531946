function Overview({ controller }) {
  return (
    <div className="">
      { controller.entries.map((item, i) => (
        <div key={i} className="text-xs font-mono mb-1">
          {item.get('filename')}
        </div>
      )) }
    </div>
  )
}

export default Overview;