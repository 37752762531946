import BaseController from 'lib/BaseController';
import { List, Set } from 'immutable';


class Controller extends BaseController {
  constructor({ vani, saveEditorData, getEditorData, openPopup, getRuntimeFile }) {
    super();
    this.vani = vani;
    this.saveEditorData = saveEditorData;
    this.getEditorData = getEditorData;
    this.getRuntimeFile = getRuntimeFile;
    this.openPopup = openPopup;

    this.state = this.state.merge({
      build: null,
      buildVersion: 0,
      errored: false,
      errorTitle: '',
      errorMessage: '',
      loading: false,
      logInputValue: '',
      logs: List([]),
      
      showConsole: false,
      consoleShownOnBuild: null,
      hasNewLogs: false,
      hasNewErrors: false,
      clientHeight: 100,
      loadingFiles: Set()
    });

    if (vani) {
      this.stopVaniListener = vani.on('for webframe', this.handleVaniMessage);
    }
  }

  setPostMessageFn(fn) {
    this._postMessageFn = fn;
    this.postMessage('ready');
  }

  postMessage(subject, payload) {
    if (this._postMessageFn) {
      this._postMessageFn({ from: 'doc', subject, payload });
    }
  }

  isReady() {
    return !!this._postMessageFn;
  }

  handleMessage(message) {
    // console.log('incoming doc message', message);
    const { subject, payload } = message;

    if (subject === 'get build') {
      this.postMessage('build', this.state.get('build'));

    } else if (subject === 'resize') {
      const { height } = payload;
      if (Math.abs(this.state.get('clientHeight') - height) > 2)
        this.setState({ clientHeight: Math.min(Math.max(height, 10), 800) });
    } else if (
      subject === 'eval-out' || subject === 'eval-out-error' ||
      subject === 'log' || subject === 'warn' || subject === 'error'
    ) {
      this.pushLog(subject, payload);

    } else if (subject === 'vani') {
      this.vani.handleWebframeMessage(payload);

    } else if (subject === 'request') {
      this.handleRequest(payload);
    }
  }

  handleRequest = ({ subject, data, requestId }) => {
    // Get Data
    if (subject === 'get editor data') {
      this.postMessage('response', {
        requestId: requestId,
        data: this.getEditorData(),
        success: true
      });
    }

    // Save Data
    else if (subject === 'save editor data') {
      const
        errorReason = (
          typeof data !== 'object' ? 'The data should be an object' :
            JSON.stringify(data).length > 200000 ? 'Data too large.' : null
        );
      if (!errorReason) {
        this.saveEditorData(data);
      }
      this.postMessage('response', {
        requestId: requestId,
        errorReason: errorReason,
        status: errorReason ? 'error' : 'success'
      });
    }

    // Popup
    else if (subject === 'open popup prompt') {
      const { question, height, width, title, inputs, submitLabel } = data;
      this.openPopup('prompt', {
        question, height, width, title, inputs, submitLabel,
        onSubmit: (values) => this.postMessage('response', {
          requestId, success: true, values
        }),
        onUnload: () => this.postMessage('response', {
          requestId, success: true, values: null
        })
      });
    }

    else if (subject === 'open popup uploads') {
      const
        { contentTypeFilterRegex, title } = data,
        regex = new RegExp(contentTypeFilterRegex, 'i');

      this.openPopup('upload-files', {
        title,
        contentTypeFilter: contentType => regex.test(contentType),
        allowPreview: true,
        onSelect: filename => this.postMessage('response', {
          requestId, success: true, filename: filename
        }),
        onUnload: () => this.postMessage('response', {
          requestId, success: true, filename: null
        })
      });
    }

    else if (subject === 'get file') {
      this.setState({ loadingFiles: this.state.get('loadingFiles').add(data.pathname) })

      this.getRuntimeFile(data.pathname, data.destination)
        .then(file => {
          this.postMessage('response', { requestId, success: true, file });
        }).catch(error => {
          console.error(error);
          this.postMessage('response', { requestId, success: false, errorReason: error.message });
        })
        .finally(() => {
          this.setState({ loadingFiles: this.state.get('loadingFiles').delete(data.pathname) })
        })
    }
  }

  handleVaniMessage = (payload) => {
    this.postMessage('vani', payload);
  }

  clear() {
    this.setState({
      errored: false,
      errorTitle: '',
      errorMessage: '',
      hasNewLogs: false,
      hasNewErrors: false,
      logs: List([])
    });
  }

  setBuild(build) {
    this.setState({
      build: build,
      buildVersion: this.state.get('buildVersion') + 1,
      loadingFiles: new Set()
    });
  }

  setError({ title, message }) {
    this.setState({
      errored: true,
      errorTitle: title,
      errorMessage: message
    });
  }
  
  pushLog(type, args) {
    const
      { state } = this,
      logs = state.get('logs').push({ type, args });

    if (!state.get('showConsole')) {
      if (state.get('consoleShownOnBuild') !== state.get('buildVersion')) {
          this.setState({ showConsole: true, consoleShownOnBuild: state.get('buildVersion') });
      } else {
        if (!state.get('hasNewLogs') && type === 'log')
          this.setState({ hasNewLogs: true });
        if (!state.get('hasNewErrors') && (type === 'error' || type === 'eval-out-error'))
          this.setState({ hasNewErrors: true });
      }
    }
    
    this.setState({ logs });
  }

  clearLogs() {
    this.setState({ logs: List([]) });    
  }

  toggleConsole() {
    this.setState({
      showConsole: !this.state.get('showConsole'),
      hasNewErrors: false,
      hasNewLogs: false
    })
  }
  
  submitCommand() {
    const v = this.state.get('logInputValue');
    if (!v)
      return;

    this.setState({ logInputValue: '' });
    
    if (!this.isReady()) {
      this.pushLog('no-eval', ['Web instance is not running']);
      return;
    }
    this.pushLog('eval-in', [v]);
    this.postMessage('eval', v);
  }

  destroy() {
    console.log('destryoing webframe');
    if (this.stopVaniListener) {
      this.stopVaniListener();
    }
    super.destroy();
  }
}

export default Controller;