import { TooltipTrigger, Tooltip } from 'react-aria-components';
import clsx from 'clsx';


export default function Tooltipped({ children, tooltip, delay=10, isInverted=true }) {
  return (
    <TooltipTrigger delay={delay}>
      {children}
      
      { tooltip && (
        <Tooltip
          placement="bottom"
          className={clsx(
            'text-sm p-1 px-3',
            isInverted ? 'bg-pencil2 text-paper' : 'border border-rim bg-paper text-pencil'
          )}>{tooltip}</Tooltip>
      )}
    </TooltipTrigger>    
  )
}