import { Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useControllerState } from 'lib/BaseController';
import { AddUnderlay, AddHoverBar } from './addOverlays';
import './style.css';

import clsx from 'clsx';


function Layout({ titleElement, emptyElement, renderedElements, layout, selection }) {
  const
    { activeElementId, isPageFocused, selectedElements } = selection,
    { scroll } = layout,
    state = useControllerState(scroll),
    adding = state.get('adding');

  const handleMouseDownOnPage = e => {
    if (e.target === e.currentTarget) {
      selection.toggleSelect();
      e.preventDefault();
    }
  }

  const onDragEnd = ({ source, destination }) => {
    if (destination && source.index !== destination.index) {
      layout.moveElementIndex(source.index, destination.index);
    }
  }

  const
    { pointSize, themeData } = layout,
    pageMargin = themeData.getValue('pageMargin'),
    // outPadding = 0, //themeData.getValue('pagePadding') * pointSize,
    pagePaddingStyle = {
      paddingLeft: pageMargin.left * pointSize,
      paddingRight: pageMargin.right * pointSize,
      paddingTop: pageMargin.top * pointSize,
      paddingBottom: pageMargin.bottom * pointSize
    };

  return (
    <div
      className="h-full w-full overflow-auto slim-sidebar scroll-doc-padder"
      onMouseDown={handleMouseDownOnPage}>
      <div className="relative miin-h-full w-full" onMouseDown={handleMouseDownOnPage}>
        <div
          className={clsx(
            'scroll-page min-h-full relative page-max-width mx-auto bg-paper2',
            'border',
            // 'border-r border-l',
            isPageFocused ? 'border-rim' : 'border-wax2'
          )}
          style={pagePaddingStyle}
          onMouseDown={handleMouseDownOnPage}>
          <div ref={ref => layout.setElementContainerRef('titlebar', ref)}>
            <TitleElement
              isActive={activeElementId === 'titlebar'}
              isSelected={isPageFocused && selectedElements.has('titlebar')}
              titleElement={titleElement} />
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  { layout.order.map((id, index) => renderedElements[id] && (
                    <Fragment key={id}>
                      <DraggableElement
                        id={id}
                        index={index}
                        isLast={index === layout.order.size - 1}
                        containerRef={ref => layout.setElementContainerRef(id, ref)}
                        isActive={(activeElementId === id)}
                        isSelected={isPageFocused && selectedElements.has(id)}
                        selectMe={() => selection.setActiveElementId(id)}
                        renderedElement={renderedElements[id]}
                        layout={layout} />
                    </Fragment>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <AddHoverBar layout={layout} />
          { adding && (<AddUnderlay scroll={scroll} index={layout.order.length} />) }
        </div>
      </div>
    </div>
  )
}

function TitleElement({ titleElement, isActive, isSelected }) {
  return (
    <div className="relative mb-6 pt-1">
      <Highlighter
        isActive={isActive}
        isSelected={isSelected}
        className="pointer-events-none" />
      {titleElement}
    </div>
  );
}

function DraggableElement({ id, index, renderedElement, isActive, isSelected, isLast, containerRef, selectMe, layout }) {
  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <div {...provided.draggableProps} style={provided.draggableProps.style}>
          <div
            className={clsx(index === 0 ? 'mb-8' : isLast ? 'mt-8' : 'my-8', 'relative')}
            ref={provided.innerRef}
            onMouseDown={selectMe}>
            <Highlighter
              isActive={isActive}
              isSelected={isSelected}
              dragHandleProps={provided.dragHandleProps}
              tabIndex={-1} />

            <div ref={containerRef} className="scroll-layout-element-container">
              { renderedElement }
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

function Highlighter({ dragHandleProps={}, isActive, isSelected, className }) {
  const style = {
    width: 'calc(100% + 2rem)',
    height: 'calc(100% + 1rem)',
    left: '-1rem',
    top: '-0.5rem'
  }
  if (isActive) {
    style.border = '1px dashed rgb(var(--color-marker))';
    style.borderLeft = '1px solid rgb(var(--color-marker))';
  }
  else if (isSelected) {
    style.border = '1px dashed rgb(var(--color-rim3))';
    style.borderLeft = '1px solid rgb(var(--color-rim3))';
  }

  return (
    <div
      style={style}
      className={clsx('nb-element-highlighter absolute block', className)}
      {...dragHandleProps}></div>)
}

export default Layout;