import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 480 });

  constructor(args, manager) {
    super(args, manager);
    this.machine = args.machine;
    this.state = this.state.merge(getAuthArgs() || this.defaultAuthArgs);
  }

  defaultAuthArgs = {
    url: 'http://localhost:1823',
    protocol: 'http',
    domain: 'localhost',
    port: 3456,
    username: '',
    password: '',
    rememberAuth: true,
    fromSavedAuth: false
  }

  getURL() {
    const s = this.state;
    return `${s.get('protocol')}://${s.get('domain')}:${s.get('port')}`;
  }

  connect() {
    const { protocol, domain, port, username, password, rememberAuth } = this.state.toJS();
    if (rememberAuth && password) {
      saveAuthArgs({ protocol, domain, port, username, password });
      this.setState({ fromSavedAuth: true });
    }
    const url = this.getURL();
    this.machine.tryAuthAndConnect({ url, username, password });
  }

  closeConnection() {
    this.machine.close();
  }

  fort() {
    forgetAuthArgs();
    this.setState({ fromSavedAuth: false })
  }

  clear() {
    this.setState(this.defaultAuthArgs);
  }
}


function forgetAuthArgs() {
  window.localStorage.removeItem('machine-auth-args');
}

function saveAuthArgs({ protocol, domain, port, username, password }) {
  window.localStorage.setItem('machine-auth-args', JSON.stringify(
    { protocol, domain, port, username, password }
  ));
}

function getAuthArgs() {
  try {
    var args = window.localStorage.getItem('machine-auth-args');
    if (!args)
      return null;
    const { protocol, domain, port, username, password } = JSON.parse(args);
    return ({ protocol, domain, port, username, password, rememberAuth: true, fromSavedAuth: true });
  } catch (e) {
    console.log('invalid saved args');
    return null;
  }
}


export default Controller;