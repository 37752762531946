import Adder from './Adder';
import Selector from './Selector';
import Tiles from './Tiles';
import Margins from './Margins';
import Resizer from './Resizer';


export default function Outlines({ grid }) {
  return (
    <>
      <Tiles grid={grid} type="grid" />
      <Selector grid={grid} />
      <Adder grid={grid} />
      <Margins grid={grid} />
      <Resizer grid={grid} />
    </>
  )
}