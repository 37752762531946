import {
  HamburgerMenuIcon,
  LaptopIcon,
  // BoxModelIcon,
  ChatBubbleIcon,
  CheckIcon
} from '@radix-ui/react-icons';
import { useState } from 'react';

import { EnterFullScreenIcon, ExitFullScreenIcon } from '@radix-ui/react-icons';

import MenuBuilder from 'lib/components/MenuBuilder';
import {useFullScreen, toggleFullScreen} from './fullscreen';
import clsx from 'clsx';

export default function Menu({ viewer, onOpen }) {
  const
    isFullScreen = useFullScreen(),
    [isOpen, setIsOpen] = useState(false),
    autoHideMenu = viewer.state.get('autoHideMenu'),

    { workspace, currentArticle } = viewer,
    { domain } = workspace,
    allowComments = domain.allowComments || {},
    articleId = currentArticle && currentArticle.article.id,
    isCommentsDisabled = (
      (articleId &&  !allowComments.articles) ||
      (!articleId && !allowComments.workspace)
    );

  return (
    <MenuBuilder
      options={[
        [
          'Comments',
          ChatBubbleIcon,
          () => viewer.setState({ showComments: !viewer.state.get('showComments') }),
          { isDisabled: isCommentsDisabled }
        ],
        // ['Show Minimap', BoxModelIcon, () => {}, { isDisabled: true }],
        ['Machine', LaptopIcon, () => {},],
        [],
        [
          isFullScreen ? 'Exit full screen' : 'Full screen',
          isFullScreen ? ExitFullScreenIcon : EnterFullScreenIcon,
          toggleFullScreen
        ],
        ['Menubar on Hover', autoHideMenu ? CheckIcon : '', () => viewer.setAutoHide(!autoHideMenu)],
      ]}
      onOpenChange={c => { setIsOpen(c); onOpen(c); } }>
      <div className={clsx('p-2 hover:bg-wax2 bg-paper', isOpen && 'bg-wax2')}>
        <HamburgerMenuIcon />      
      </div>
    </MenuBuilder>
  )  
}
