import MenuBuilder from 'lib/components/MenuBuilder';
import { OpenInNewWindowIcon, CommitIcon, PersonIcon, LaptopIcon, Half2Icon } from '@radix-ui/react-icons';

export default function MenuButton({ editor, onOpenChange }) {
  return (
    <MenuBuilder
      options={getOptions(editor)}
      className="w-8 py-1"
      onOpenChange={onOpenChange}/>);
}

function getOptions(editor) {
  const { articleController } = editor;

  if (!articleController)
    return ([]);

  const
    { state, popups } = articleController,
    { isOffline } = articleController.provider;

  const options = [
    [
      'View Published',
      OpenInNewWindowIcon,
      () => {},
      {
        href: `/${state.getIn(['article', 'path'])}`,
        target: '_blank',
        isDisabled: !state.getIn(['article', 'publishedVersionId'])
      }
    ], /* [
      'Save & Publish',
      'Save',
      articleController.popups.expressVersionPopup,
      { isDisabled: isOffline }
    ], */ [
      'Manage Versions',
      CommitIcon,
      popups.manageVersionsPopup,
      { isDisabled: isOffline }
    ], [
      'User Access',
      PersonIcon,
      popups.openUserAccessPopup,
      { isDisabled: isOffline }
    ] , /*[
      `Autosave: ${autosave ? 'ON ' : 'OFF'}`,
      'Check",
      () => articleController.setState({ autosave: !autosave })
    ],*/ [
      'Machine',
      LaptopIcon,
      articleController.app.machine.showMachinePopup
    ],
    []
    , [
      'Editor UI Preferences',
      Half2Icon,
      (v) => editor.openPreferencePopup()
    ]
  ]
  return options;
}