import BaseController from 'lib/BaseController';
import { throttle } from 'lodash';
import startDragging from 'lib/startDragging';


export default class Selector extends BaseController {
  constructor(grid) {
    super();
    this.grid = grid;
    this.state = this.state.merge({ x0: 0, y0: 0, dx: 0, dy: 0, dragging: false });
  }

  defaultDrag(e) { this.startDrag(e, 'default'); }
  addElementDrag(e) { this.startDrag(e, 'add'); }

  getCellAt(x, y) {
    const { cellGap, cellSize } = this.grid;

    return {
      x: Math.floor((x + cellGap.x / 2) / (cellSize.width + cellGap.x)),
      y: Math.floor((y + cellGap.y / 2) / (cellSize.height + cellGap.y)),
    }
  }

  startDrag(e, x, y) {
    const
      { camera, layout } = this.grid,
      a = this.getCellAt(x, y);

    this.setState({
      dragging: true,
      x: x, y: y, dx: 0, dy: 0,
      ax: a.x, ay: a.y, aw: 1, ah: 1,
      // range: null
    });
    // e.stopPropagation();
    layout.selection.setActiveElementId(null);
    e.preventDefault();
    layout.selection.focusPage();

    startDragging({
      event: e,
      camera: camera,
      onMove: (e, dx, dy) => {
        this.setState({ dx, dy });
        this.updateDrag_();
      },
      onEnd: () => this.addElement(),
    });
  }

  updateDrag = () => {
    const
      { state } = this,
      x = state.get('x'),
      y = state.get('y'),
      dx = state.get('dx'),
      dy = state.get('dy'),
      x0 = Math.min(x, x + dx),
      y0 = Math.min(y, y + dy),
      x1 = Math.max(x, x + dx),
      y1 = Math.max(y, y + dy);

    const
      a0 = this.getCellAt(x0, y0),
      a1 = this.getCellAt(x1, y1);

    this.setState({
      ax: a0.x,
      ay: a0.y,
      aw: a1.x - a0.x + 1,
      ah: a1.y - a0.y + 1
    });
  }
  updateDrag_ = throttle(this.updateDrag, 80);

  addElement() {
    this.setState({ adding: true });
    this.grid.layout.newElementPopup((id) => {
      const { state } = this;
      this.grid.saveGridElement({
        id: id,
        x: state.get('ax'), y: state.get('ay'),
        w: state.get('aw'), h: state.get('ah'),
      });
    }, () => {
      this.setState({ dragging: false, type: null });      
    });
    this.grid.setState({ 'cursor': 'default' });
  }
}