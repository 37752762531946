import { useEffect, useState } from 'react';
import HeightResizer from 'modules/elements/lib/components/HeightResizer';
import { DARK_THEME_KEYS } from 'modules/theme/defaults/code';
import { FileBar, FloatingFileBar, VerticalFileBar, VerticalFileBarMenus } from './bars';
import CodeMirrorInstance from './CodeMirrorInstance';
import clsx from 'clsx';

import './style.css';


function Editor({ controller, isActive, isGrid, colorScheme }) {
  const
    { getSettingsValue } = controller,
    fileControllers = controller.getOrderedFileControllers(),
    [, setChildren] = useState(controller.getChildren());

  useEffect(() => controller.onChildren(() => setChildren(controller.getChildren())), [controller]);

  const
    themeKey = getSettingsValue('theme') || (
      colorScheme === 'dark' ?
        controller.themeData.getValue('codeThemeDark') :
        controller.themeData.getValue('codeThemeLight')
    ),
    isDark = DARK_THEME_KEYS.has(themeKey),
    props = { isGrid, isDark, controller, isActive, fileControllers };  

  return (
    <Container {...props}>
      <BarWrapper {...props}>
        { fileControllers.map((fileController, index) => (
          <CodeMirrorInstance
            isGrid={isGrid}
            key={fileController.elementId}
            fileController={fileController}
            controller={controller}
            isHidden={controller.currentIndex !== index}
            themeKey={themeKey} />
        )) }
      </BarWrapper>
    </Container>
  );
}

function Container({ isGrid, isDark, controller, children }) {
  const { getSettingsValue, setSettings } = controller;

  return (
    <div
      id={'code' + controller.elementId}
      className={clsx(
        'nb-code font-code relative border rounded-sm border-rim',
        isDark ? 'color-scheme-dark' : 'color-scheme-light',
        isGrid && 'h-full overflow-hidden is-grid',
      )}
      key={getSettingsValue('autoHeight')}>
      {children}

      { !isGrid && !getSettingsValue('autoHeight') && (
        <HeightResizer
          onChange={(dy) => {
            let
              height = getSettingsValue('height') + dy,
              range = controller.settingsFields.height.range;

            height = Math.max(Math.min(height, range[1]), range[0])
            setSettings('height', height);
          }} />
       )}
    </div>
  )
}


function BarWrapper({ isGrid, isDark, controller, children, isActive, fileControllers }) {
  const
    { getSettingsValue } = controller,
    size = fileControllers.size,
    filebarLayout = getSettingsValue('filebarLayout') || (size === 1 ? 'float' : size <= 3 ? 'hbar' : 'vbar'),
    props = { controller, isGrid, isActive, isDark, fileControllers };

  return (
    <div
      className={clsx(
        'relative',
        isGrid && 'h-full',
        filebarLayout === 'vbar' ? 'flex' : isGrid ? 'flex flex-col' : ''
      )}>
      { filebarLayout === 'hbar' && <FileBar {...props} />}
      { filebarLayout === 'vbar' && <VerticalFileBar {...props} />}
      <div className="flex-grow overflow-auto">
        { children }        
      </div>
      { filebarLayout === 'float' && (<FloatingFileBar {...props} />)}
      { filebarLayout === 'vbar' && (<VerticalFileBarMenus {...props} />)}
    </div>
  )
}


export default Editor;