import { ASPECT_RATIOS } from 'modules/theme/defaults/image';

export const fields = {
  galleryType: {
    caption: 'Gallery', input: 'options', init: null, valueOnNull: 'standard',
    options: [
      { key: null, caption: 'Standard' },
      { key: 'classic', caption: 'Classic' }
    ],
  },

  maxWidth: {
    caption: 'Width',
    input: 'number',
    init: 500,
    unit: 'px',
    range: [100, 2000],
  },

  maxHeight: {
    caption: 'Height',
    input: 'number',
    init: 300,
    unit: 'px',
    range: [100, 2000],
  },

  percentWidth: {
    caption: 'Percent Width', input: 'number', init: 100,
    unit: '%',
    range: [20, 100],
  },

  aspectRatio: {
    caption: 'Aspect Ratio',
    input: 'options',
    init: '1.6000',
    options: ASPECT_RATIOS.map(([key, caption]) => ({ key, caption }))
  },

  fitting: {
    caption: 'Image Fitting',
    input: 'options',
    init: 'cover',
    options: [
      { key: 'cover', caption: 'Stretch and Fill' },
      { key: 'contain', caption: 'Letterbox' }
    ]
  },

  fillerColor: {
    caption: 'Filler Color',
    input: 'color',
    init: '#ffffff',
    nullable: true,
    // isHiddenFn: getValue => getValue('fitting') !== 'contain'
  },

  hasBorder: {
    caption: 'Has Border', input: 'checkbox', init: false
  },

  // borderColor: {
  //   caption: 'Border Color', input: 'color', init: null, nullable: true, themeKey: 'borderColor',
  //   isHiddenFn: getValue => getValue('hasBorder') === false },
}

export const initialSettings = {};
for (let k in fields) { initialSettings[k] = fields[k].init; }