import { useControllerState } from 'lib/BaseController';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Icon from 'lib/Icon';
import clsx from 'clsx';


function MiniMap({ editor }) {
  const
    articleController = editor.currentArticleController,
    { selection } = articleController,
    { activeElementId } = selection;

  useControllerState(articleController);
  useControllerState(selection);
  
  return (
    <div
      className="nb-article-minimap slim-sidebar h-full w-full overflow-auto p-3"
      onClick={e => {
        if (e.target === e.currentTarget) {
          articleController.selection.focusPage();
        }
      }}>
      <div
        className={clsx(
          'p-1 text-center cursor-pointer',
          activeElementId === 'titlebar' && 'outline outline-blue-600 outline-1'
        )}
        onClick={(e) => {
          articleController.titleElement.focus();
          articleController.titleElement.scrollIntoView();
        }}>
        {articleController.state.getIn(['article', 'title'])}
      </div>

      <Items articleController={articleController} />
    </div>
  )
}


function Items({ articleController }) {
  const
    { elements, layout, selection } = articleController,
    { order } = layout,
    { activeElementId } = selection;

  const onDragEnd = ({ source, destination }) => {
    if (destination && source.index !== destination.index) {
      layout.moveElementIndex(source.index, destination.index);
      setTimeout(() => { selection.scrollIntoSelectedElement(); }, 10);
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="minimap-droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            { order && order.map((id, index) => elements[id] && (
            <Draggable draggableId={id} index={index} key={id}>
              {provided => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={provided.draggableProps.style}
                  ref={provided.innerRef}
                  className="py-1">
                  <MinimapItem
                    key={id}
                    element={elements[id]}
                    isActive={activeElementId === id} />
                </div>
              )}
            </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function MinimapItem({ element, isActive }) {
  useControllerState(element);

  return (
    <div
      className={clsx(
        'py-1 px-2 border border-rim text-sm',
        isActive && 'outline outline-marker outline-1'
      )}
      onMouseDown={e => {
        element.focus();
        element.scrollIntoView();
      }} >
      <div className="flex overflow-hidden gap-4">
        <div className="w-3 flex-shrink-0">
          <Icon faIcon={element.lib.faIcon} />
        </div>
        <div className="flex-grow overflow-hidden">
          <element.lib.Overview controller={element.controller} />
        </div>
      </div>
    </div>
  );
}

export default MiniMap;