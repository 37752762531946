import StorageImage from 'lib/StorageImage';
import FolderContent from './FolderContent';
import BookmarkButton from './BookmarkButton';


export default function Workspace2({ viewer }) {
  const
    { workspace } = viewer,
    { folder, domain } = workspace;

  if (!domain)
    return '';

  return (
    <div className="pt-12 pb-16 px-16 max-w-[50rem] mx-auto border border-rim relative rounded-sm">
      <div className="absolute top-4 right-4">
        <BookmarkButton viewer={viewer} />
      </div>

      {domain.cover640 && (
        <div className="max-w-[20rem] p-1 border mx-auto">
          <StorageImage path={domain.cover640} className="w-full" getDownloadURL={viewer.provider.getDownloadURL} />
        </div>
      ) }

      <div className="text-center text-5xl font-header pt-6 pb-2">
        {domain.title}
      </div>

      <div className="text-lg font-medium pb-2 mt-8 px-6">
        {folder.title}
      </div>

      <div className="folder relative overflow-auto md:columns-3 border-t border-b border-rim py-4 px-4">
        <FolderContent viewer={viewer} />
      </div>

      { domain.description && (
        <div className="mt-8 px-6">
          <div ref={el => { if (el) el.innerHTML = domain.description; }} />
        </div>
      ) }
    </div>
  )
}
