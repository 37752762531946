import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  async publish() {
    const
      { version, provider, isPublished } = this.args,
      versionId = version.get('id'),
      articleId = version.get('articleId'),
      domainId = version.get('domainId');
    
    this.setState({ loading: true, alert: '' });

    try {
      if (isPublished)
        await provider.unpublishArticle({ domainId, articleId });
      else
        await provider.publishVersion({ domainId, versionId, articleId });
      this.close();
    } catch(e) {
      console.error(e);
      this.setState({ alert: 'Error! ' + e.message });
    }
    this.setState({ loading: false });
  }
}

export default Controller;