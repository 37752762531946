import { useControllerState } from 'lib/BaseController';
import { ui } from 'lib/popup';
import SlugCheckInput from 'lib/inputs/SlugCheckInput';
import { Group, Checkbox } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import { OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { Link } from 'react-aria-components';

import VersionChanger from './VersionChanger';
import VersionDetail from './VersionDetail';


function CreateVersionView({ popup }) {
  let state = useControllerState(popup);

  return (
    <ui.Popup popup={popup} className="create-new-version-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Save Version &amp; Publish
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          {
            !state.get('created') ?
              <CreateVersion popup={popup} /> :
              <VersionCreated popup={popup} />
          }
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

function VersionCreated({ popup }) {
  const
    { state } = popup,
    href = popup.articleController.state.get('article').get('path').replaceAll('\\', '/');

  return (
    <div>
      <div className="text-center py-4 mt-2">
        <div className="text-lg">
          Article has been saved to version!
        </div>

        { state.get('publishVersion') && state.get('isPublic') && (
          <div>
            and is published at &nbsp;
            <Link href={'/' + href} target="_blank" className="font-mono text-xs font-bold">
              {href} 
            </Link>.
          </div>
        )}
      </div>
      <div className="text-right flex my-2">
        <div className="flex-grow"></div>
        <Button
          className="flex gap-2 items-center"
          onPress={() => {
            window.open(`/${state.get('parentPath')}@${state.get('slug')}`, '_blank');
            popup.close();
          }}
          autoFocus={true}>
          <OpenInNewWindowIcon /> Open Version
        </Button>

        <Button onPress={() => popup.close()}>
          Close
        </Button>
      </div>
    </div>
  )
}

function CreateVersion({ popup }) {
  const
    { state } = popup,
    slugReady = state.get('isSlugValid'),
    loading = state.get('loading'),
    dvMap = state.get('dvMap');

  return (
    <Group>
      <form onSubmit={e => { e.preventDefault(); popup.createAndPublish(); }}>
        <SlugCheckInput
          autoFocus={true}
          label="Create Version"
          value={state.get('slug')}
          onChange={v => popup.setState({ slug: v })}
          onValidChange={e => popup.setState({ isSlugValid: e })}
          checkFn={(val) => popup.provider.checkAvailable(state.get('parentPath') + '@' + val)} />

        <VersionChanger popup={popup} />

        <Checkbox
          label="Make it public:"
          isDisabled={loading}
          value={state.get('isPublic')}
          onChange={c => popup.setState({ isPublic: c })}
          isYesNo={true} />
        {/*<div>{popup.articleController.state.getIn(['article', 'path'])}\{state.get('slug')}</div>*/}

        <Checkbox
          label="And publish:"
          isDisabled={loading || !state.get('isPublic')}
          value={state.get('publishVersion') && state.get('isPublic')}
          onChange={c => popup.setState({ publishVersion: c })}
          isYesNo={true} />

        {/*<div>{popup.articleController.state.getIn(['article', 'path'])}</div>*/}
        <VersionDetail popup={popup} />

        <ui.Alert />

        { state.get('dvMapError') && (
          <div className="text-xs my-2 p-2 border">
            JavaScript Version Map could not be created. {state.get('dvMapError')}
          </div>
        )}

        <Buttons className="text-right">
          <Button isDisabled={loading || !slugReady || dvMap === null} type="submit">
            Continue
          </Button>
        </Buttons>
      </form>
    </Group>
  )
}


export default CreateVersionView;