import { useEffect } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { useControllerState } from 'lib/BaseController';
import { ErrorBlock, LoadingBlock } from 'lib/staticPages';
import { ThickArrowLeftIcon } from '@radix-ui/react-icons';

import Sidebar from './Sidebar';
import Article from './Article';
import Navbar from './Navbar';

import './style.css'


export default function Loader({ app }) {
  const
    path = useMatch({ path: '/theme/*' }).params['*'],
    { theme } = app;

  useEffect(() => {
    theme.loadPath(path);
    return theme.unload;
  }, [path, theme]);

  return (<Page theme={theme} />);
}

function Page({ theme }) {
  const
    state = useControllerState(theme),
    domainState = useControllerState(theme.domainController),
    error = state.get('error') || domainState.get('error');

  useControllerState(theme.themeData);
  useControllerState(theme.navData);

  if (error)
    return (<ErrorBlock title="Loading Error" error={error} />);

  if (!domainState.get('domain'))
    return (<LoadingBlock />);

  return (
    <div className="nb-theme md:pt-6 pb-10 h-full">
      <div className="max-w-[60rem] md:border border-rim m-auto h-full flex flex-col bg-paper">
       <div className="border-b border-rim flex items-center">
          <Link to={`/preferences/${domainState.getIn(['domain', 'slug'])}`} className="p-2 ml-2">
            <ThickArrowLeftIcon className="inline-block w-5 h-5" />
          </Link>

          <div className="font-medium flex-grow p-2 ">
            Theme - {domainState.getIn(['domain', 'title'])}
          </div>
       </div>
       <div className="flex-grow flex h-full overflow-hidden">
         <div className="flex-grow flex flex-col border-r border-rim h-full overflow-hidden">
           <Navbar themeController={theme} />
           <Article themeController={theme} />
         </div>
         <div className="w-[20em] flex-shrink-0">
           <Sidebar themeController={theme} />
         </div>
       </div>
      </div>
    </div>
  );
}