import { validateFilename } from 'lib/tools.ts';

export const fields = {
  filename: {
    caption: 'External Filename', input: 'text', init: '',
    validateFn: validateFilename
  },

  styling: {
    caption: 'Style',
    input: 'options',
    init: 'striped',
    valueOnNull: 'striped',
    options: [
      { key: 'plain', caption: 'Plain' },
      { key: 'striped', caption: 'Striped' },
    ]
  }
}

export const fieldsLayout = [
  [
    ['filename'],
  ],
  [
    ['styling']
  ]
]

export const initialSettings = {};
for (let k in fields) { initialSettings[k] = fields[k].init; }