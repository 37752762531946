import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 540 });

  constructor(args, manager) {
    super(args, manager);
    this.state = this.state.merge({ code: args.value });
  }
  
  async handleSubmit() {
    this.setState({ loading: true });
    try {
      if (this.args.onSave) {
        await this.args.onSave(this.state.get('code'));
      }
      this.setState({ loading: false });
      this.close();
    } catch (e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }

  close() {
  }
}

export default Controller;