import { useState, useEffect } from 'react';

import p from 'lib/popup/components/ui';
import { TextInput, Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';


function Popup({ popup }) {
  let
    [state, setState] = useState(popup.state),
    loading = state.get('loading');

  useEffect(_ => popup.emitter.on('change', _ => setState(popup.state)), [popup]);

  return (
    <p.Popup popup={popup} className="change-email-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>
            Change Email
          </p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          { state.get('done') ? (
            <>
              <div className="done">Your email has been changed!</div>
              <Buttons className="text-center">
                <Button onClick={_ => popup.close()}>Done</Button>
              </Buttons>
            </>
          ) : (
            <form onSubmit={e => { e.preventDefault(); popup.changeEmail(); }}>
              <p.Warning title="Warning!">
                You're about to change your primary email.
              </p.Warning>

              <Group>
                <TextInput
                  label="Current Primary Email"
                  value={state.get('oldEmail')}
                  isDisabled={true} />

                <TextInput
                  label="Password"
                  type="password"
                  value={state.get('password')}
                  onChange={v => popup.setState({ password: v })}
                  isDisabled={loading} />

                <TextInput
                  label="New Primary Email"
                  value={state.get('email')}
                  onChange={v => popup.setState({ email: v })}
                  isDisabled={loading} />
              </Group>

              <p.Alert />

              <Buttons className="text-right">
                <Button
                  type="submit"
                  isDisabled={loading}>
                  Change Email
                </Button>

                <Button
                  onPress={_ => popup.close()}
                  isDisabled={loading}>
                  Cancel
                </Button>
              </Buttons>
            </form>
          ) }
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

export default Popup;