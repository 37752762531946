// import BaseController from 'lib/BaseController';
import ThemeData from 'modules/theme/controllers/ThemeData';
import { sectionize } from './helpers';

export default class Workspace {
  constructor() {
    // super();
    this.themeData = new ThemeData();
  }

  async load({ folderId, domainId, provider }) {
    console.log('workspace loading', { folderId, domainId })
    if (folderId === this.folderId) {
      console.log('workspace already currently loaded with', folderId);
      return;
    }

    // const { provider } = this;
    let [domain, folder, childrenFolders, childrenArticles] = await Promise.all([
      domainId ? provider.fetchDomain({ domainId }) : false,
      provider.fetchFolder({ domainId, folderId }),
      provider.fetchChildrenFolders({ domainId, folderId, isPublic: true }),
      provider.fetchChildrenArticles({ domainId, folderId, isPublic: true })
    ]);
    domain = domain || await provider.fetchDomain({ domainId: folder.domainId });

    this.domain = domain;
    this.folder = folder;
    this.sections = sectionize({ folder, childrenFolders, childrenArticles })
    this.folderId = folderId;
    this.themeData.load(domain.theme);
  }

  unload() {
    console.log('workspace unload')
    this.domain = null;
    this.folder = null;
    this.sections = null;
    this.folderId = null;
  }

  get isRoot() {
    return this.folder && (this.folder.id === this.folder.domainId);
  }
}