import { useEffect, useState } from 'react'
import GlobalStyle from 'lib/GlobalStyle';
import clsx from 'clsx';
import BarFiles from './BarFiles';
import MenuButton from './MenuButton';
import ModeSelection from './ModeSelection';
import { ResizeBar } from './resizers';


const getSpacerCSS = (elementId, content) => (`
  #code${elementId} .cm-line:first-child:before {
    content: '${content}';
    padding: 2px 5px;
    display: block;
    background: tomato;
    height: 1em;
    float: right;
    opacity: 0;
  }`);


export function FileBar({ controller, isGrid, isActive, isDark, fileControllers }) {
  const fileController = fileControllers.get(controller.currentIndex);
  
  return (
    <div
      className={clsx(
        'flex items-center border-b border-rim bg-wax',
        isDark && 'bg-wax'
      )}>
      <div className="px-2">
        <ModeSelection controller={controller} fileController={fileController} />
      </div>
      <div className="flex-grow overflow-auto no-scrollbar">
        <BarFiles
          controller={controller}
          fileControllers={fileControllers}
          isGrid={isGrid}
          isActive={isActive}
          isDark={isDark}
          type="normal" />
      </div>
      <MenuButton controller={controller} isGrid={isGrid} />
    </div>
  )
}

export function FloatingFileBar({ controller, isGrid, isActive, isDark, fileControllers }) {
  const
    fileController = fileControllers.get(controller.currentIndex),
    [hasFilename, setHasFilename] = useState(!!fileController.getSettingsValue('filename')),
    hide = !isActive && fileControllers.size === 1 && !hasFilename,
    filenamesString = fileControllers.map(fc => fc.getSettingsValue('filename') || 'untitled').join('   '),
    content = filenamesString === 'untitled' ? '----' : filenamesString + '------';

  useEffect(() => {
    const getHasFilename = () => !!fileController.getSettingsValue('filename');
    setHasFilename(getHasFilename());
    return fileController.onStateChange(() => setHasFilename(getHasFilename()));
  }, [fileController])
  
  return (
    <div className="absolute top-2 right-2 flex items-center">
      <BarFiles
        controller={controller}
        fileControllers={fileControllers}
        isGrid={isGrid}
        isActive={isActive}
        isDark={isDark}
        type="floating" />

      { !hide && (<MenuButton controller={controller} isGrid={isGrid} />)}

      <GlobalStyle css={getSpacerCSS(controller.elementId, content)} />
    </div>
  )
}


export function VerticalFileBar({ controller, isGrid, isActive, isDark, fileControllers }) {
  const
    width = controller.getSettingsValue('verticalFileBarWidth') || 200,
    setWidth = (w) => controller.setSettings('verticalFileBarWidth', w);

  return (
    <div
      className={clsx('border-r border-rim flex-shrink-0 py-1 overflow-auto bg-wax', isDark && 'border-pencil')}
      style={{ width: width }}>
      <BarFiles
        controller={controller}
        fileControllers={fileControllers}
        isGrid={isGrid}
        isActive={isActive}
        isDark={isDark}
        type="vertical" />

      <ResizeBar
        width={width}
        onChange={dx => { setWidth(Math.min(Math.max(width + dx, 100), 300)) }} />
    </div>
  );
}


export function VerticalFileBarMenus({ controller, isGrid, isDark, fileControllers }) {
  const fileController = fileControllers.get(controller.currentIndex);
  return (
    <>
      <GlobalStyle css={getSpacerCSS(controller.elementId, 'xx xx xx xx')} />

      <div className="flex absolute top-2 right-1 items-center gap-0">
        <ModeSelection controller={controller} fileController={fileController} />
        <MenuButton controller={controller} isGrid={isGrid} isVertical={true} />
      </div>
    </>
  )
}