import {
  CheckIcon, TrashIcon, CursorTextIcon,
  ArrowLeftIcon, ArrowRightIcon,
  ArrowUpIcon, ArrowDownIcon, PlusIcon
} from '@radix-ui/react-icons';
import MenuBuilder from 'lib/components/MenuBuilder';


export default function DDMenu({ controller, isGrid, isVertical }) {
  const
    // id = order.get(index),
    // filename = state.getIn(['filenames', id]),
    { getSettingsValue, setSettings, isReadOnly } = controller,
    index = controller.currentIndex,
    fileControllers = controller.getOrderedFileControllers(),
    fileController = fileControllers.get(index),
    gsv = getSettingsValue,
    ss = setSettings;
  
  if (isReadOnly)
    return '';

  const options = [
    [`Rename File`, CursorTextIcon, () => { fileController.editFilenamePopup(index) }],

    [
      !isVertical ? `Move File Left` : `Move File Up`,
      !isVertical ?  ArrowLeftIcon : ArrowUpIcon, () => {
        const currentIndex = controller.currentIndex;
        controller.moveOrderIndex(currentIndex, currentIndex - 1);
      },
      { isDisabled: index === 0 }
    ],

    [
      !isVertical ? `Move File Right` : `Move File Down`,
      !isVertical ? ArrowRightIcon : ArrowDownIcon,
      () => {
        const currentIndex = controller.currentIndex;
        controller.moveOrderIndex(currentIndex, currentIndex + 1);      
      },
      { isDisabled: index === fileControllers.size - 1 }],

    [
      `Delete File`, TrashIcon,
      () => controller.deleteCurrentFilePopup(),
      { isDisabled: fileControllers.size === 1 }],

    [],

    ['Add New File', PlusIcon, () => { controller.addNewFilePopup('') }],
    [],

    ['Line Numbers', gsv('hasLineNumbers') ? CheckIcon : '', () => ss('hasLineNumbers', !gsv('hasLineNumbers'))],
    ['Word Wrap', gsv('lineWrapping') ? CheckIcon : '', () => ss('lineWrapping', !gsv('lineWrapping'))],
  ];
  
  if (!isGrid)
    options.push(
      ['Auto Height', gsv('autoHeight') ? CheckIcon : '', () => ss('autoHeight', !gsv('autoHeight'))]
    );

  return (<MenuBuilder className="px-1 mx-2 text-pencil" options={options} tight={true} />);
}