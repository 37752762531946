import { PlusIcon, MinusIcon, ResetIcon } from '@radix-ui/react-icons';
import { Button as AriaButton } from 'react-aria-components';
import clsx from 'clsx';

export default function VersionChanger({ popup }) {
  const breakdown = popup.extractVersion(popup.state.get('slug'));

  return (
    <div className="flex text-xs gap-1">
      <div className="flex-grow"></div>
      { breakdown && (<NumberButtons popup={popup} breakdown={breakdown} />) }
      {!breakdown && (<div className="text-sm">Version is not in the standard format.</div>) }

      <NumberButton
        onPress={() => popup.setState({ slug: popup.state.get('initialSlug') })}
        isDisabled={popup.state.get('slug') === popup.state.get('initialSlug')}>
        <ResetIcon />
      </NumberButton>      
    </div>
  );
}

function NumberButtons({ popup, breakdown }) {
  const setValue = (i, val) => {
    let breakdown = popup.extractVersion(popup.state.get('slug'));
    if (!breakdown)
      return popup.setState({ slug: '0.0.0-d.0' });
    let [m1, m2, p, dt, d] =  breakdown;
    if (i === 0) { m1 = val; m2 = 0; p = 0; dt = ''; }
    if (i === 1) { m2 = val; p = 0; dt = ''; }
    if (i === 2) { p = val; dt = ''; }
    if (i === 4) {
      d = val;
      if (d === -1) dt = '';
      else if (!dt) dt = 'd';
    }
    let slug = `${m1}.${m2}.${p}` + (dt ? `-${dt}.${d}` : '');
    popup.setState({ slug });
  };

  const [major, minor, patch, , draft] =  breakdown;

  return (
    <>
      <NumberChanger label="MAJOR" value={major} setValue={val => setValue(0, val)} />.
      <NumberChanger label="MINOR" value={minor} setValue={val => setValue(1, val)} />.
      <NumberChanger label="PATCH" value={patch} setValue={val => setValue(2, val)} />-
      <NumberChanger label="DRAFT" value={draft} setValue={val => setValue(4, val)} min={-1} />
    </>
  );
}

function NumberChanger({ label, value, setValue, min=0 }) {
  return (
    <div className="flex flex-col">
      <div className="text-center">
        {label}
      </div>

      <div className="flex"> 
        <NumberButton
          isDisabled={value <= min}
          onPress={() => setValue(value - 1)}>
          <MinusIcon className="w-2.5" />
        </NumberButton>

        <NumberButton
          onPress={() => setValue(value + 1)}>
          <PlusIcon className="w-2.5" />
        </NumberButton>
      </div>
    </div>
  )
}

function NumberButton(props) {
  return (
    <AriaButton
      className={({isDisabled}) => clsx(
        isDisabled && 'opacity-30',
        'px-2 outline-none'
      )}
      {...props} />
  );
}