import { Plugin } from 'prosemirror-state';
import { schema } from '../schema.ts';
import { getPathInfo } from 'api/public';


class SelectionSizeTooltip {
  constructor(view) {
    this.tooltip = document.createElement('a');
    this.tooltip.className = 'prosemirror-link-tooltip bg-white';
    view.dom.parentNode.appendChild(this.tooltip);
    this.update(view, null);
  }

  update(view, lastState) {
    let
      state = view.state,
      { from, $from, empty} = state.selection,
      type = schema.marks.link,
      mark = null;

    if (empty) {
      mark = type.isInSet(state.storedMarks || $from.marks());
    }
    if (!mark) {
      this.tooltip.style.display = 'none';
      return;
    }
    const href = mark && mark.attrs.href;
    this.tooltip.style.display = '';

    let start = view.coordsAtPos(from - $from.textOffset)
    // The box in which the tooltip is positioned, to use as base
    let box = this.tooltip.offsetParent.getBoundingClientRect()
    // Find a center-ish x position from the selection endpoints (when
    // crossing lines, end may be more to the left)
    let left = start.left;
    this.tooltip.style.left = (left - box.left - 5) + 'px';
    this.tooltip.style.top = (start.top - box.top + 30) + 'px';

    if (href.startsWith('file:')) {
      this.tooltip.href = '#';
    } else {
      this.tooltip.href = href;
    }
    this.tooltip.target = '_blank';
    this.addHrefText(href);
  }

  addHrefText = async (href) => {
    if (href === this.href)
      return;
    this.href = href;
    this.tooltip.textContent = '';

    var text = '';
    if (href.startsWith('/')) {
      const info = await getPathInfo(href.substr(1));
      this.tooltip.textContent = '...';
      if (this.href !== href)
        return;
      text = info && (`${info.title} [${href.substr(1)}]`);
    } else if (href.startsWith('file:')) {
      text = 'Download File: ' + href.substr(5);
    } else {
      text = href;
    }
    this.tooltip.textContent = text;
  }

  destroy() {
    this.tooltip.remove();
  }
}

export const getLinkTooltipPlugin = () => new Plugin({
  view: (editorView) => new SelectionSizeTooltip(editorView)
});