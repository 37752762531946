import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';
import { toHuman } from 'lib/firebaseError';

class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });

  constructor(args, manager) {
    super(args, manager);
    this.domain = args.domain;
    this.provider = args.provider;
    this.domainId = this.domain.get('id');
    this.fetch();
  }

  fetch = async () => {
    this.setState({ loading: true });
    try {
      const [plans, tally] = await Promise.all([
        this.provider.fetchDomainPlans({ domainId: this.domainId }),
        this.provider.fetchTally({ domainId: this.domainId })
      ]);
      this.setState({ tally, plans, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }

  upgrade = () => {
    this.args.upgradePopup();
  }
}

export default PopupController;