import { useState, useEffect } from 'react';
import { BookmarkIcon, BookmarkFilledIcon, StarIcon, StarFilledIcon } from '@radix-ui/react-icons';
import { getBookmark, addBookmark, deleteBookmark } from 'api/remote/calls/user';

function getProps(viewer) {
  const
    { app, currentArticle } = viewer,
    user = app.auth.user,
    userId = user && user.uid,
    type = viewer.state.get('type'),
    item = (
      type === 'article' ?
        currentArticle.article :
      type === 'version' ?
        currentArticle.version :
        viewer.workspace.folder
    );

  var { id, title, path } = item;
  
  if (type === 'folder') {
    title = `${viewer.workspace.domain.title} - ${title}`;
  } else if (type === 'version') {
    title = `${currentArticle.article.title} [${currentArticle.version.slug}]`;
  }
  return { userId, itemId: id, title, path, type };
}

export function useBookmark(viewer) {
  const
    [isBookmarked, setIsBookmarked] = useState(null),
    { userId, itemId, title, path, type } = getProps(viewer);

  useEffect(() => {
    if (itemId && userId) {
      getBookmark({ itemId, userId })
        .then(b => setIsBookmarked(!!b));
    }
  }, [itemId, userId]);

  function toggleBookmark() {
    if (!userId || !itemId)
      return;

    if (isBookmarked) {
      deleteBookmark({ userId, itemId });
      setIsBookmarked(false);
    } else {
      setIsBookmarked(true);
      addBookmark({ userId, itemId, title, path, type });
    }
  }
  var Icon = (
    type === 'article' || type === 'version'  ?
      (isBookmarked ? BookmarkFilledIcon : BookmarkIcon) :
    type === 'folder' ?
      (isBookmarked ? StarFilledIcon : StarIcon) :
    null
  );
  return { isBookmarked, toggleBookmark, type, Icon };
}