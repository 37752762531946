import { useRef, useEffect } from 'react';
import MenuBar from './MenuBar';
import './style.css';

function Editor({ controller, isMarkdown }) {
  const ref = useRef();
  useEffect(() => controller.setEl(ref.current), [controller]);
  return (
    <div className="editor-wrapper normal">
      <MenuBar controller={controller} isMarkdown={isMarkdown} />
      <div
        className="nb-porsemirror-container px-3 py-2 border border-rim mt-2 whitespace-pre-wrap outline-none bg-paper2"
        ref={ref} />
    </div>
  )
}

export default Editor;