import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';


export default function placeholderPlugin(text) {
  return new Plugin({
    props: {
      decorations(state) {
        let doc = state.doc

        if (doc.childCount === 1 && doc.firstChild.isTextblock && doc.firstChild.content.size === 0) {
          const placeHolder = document.createElement('span')
          placeHolder.classList.add('opacity-40', 'pointer-events-none', 'user-select-none')
          placeHolder.textContent = text
          
          return DecorationSet.create(doc, [Decoration.widget(1, placeHolder)])
        }
      }
    }
  })
}