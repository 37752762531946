import { FONT_PRESETS } from '../../defaults/presets';
import { Block } from 'modules/elements/lib/sidebar/inputs/components';
import Selection from 'modules/elements/lib/sidebar/inputs/Selection';


const getPresetOptions = presets => {
  return Object.keys(presets).map(key => ({
    key: key, caption: presets[key].caption
  })).concat([{ key: 'custom', caption: 'Custom', isDisabled: true }]);
}

const isSubset = (theme, themeData) => {
  for (var k in theme) {
    if (theme[k] && theme[k] !== themeData.getValue(k))
      return false;
  }
  return true;
}

const getPresetValue = (presets, theme) => {
  for (var p in presets) {
    const preset = presets[p];
    if (isSubset(preset.theme, theme)) {
      return p;
    }
  }
  return 'custom';
}

export default function Presets({ themeController }) {
  const { themeData } = themeController;


  return (
    <>
      <Block>
        <Selection
          label="Font Preset"
          value={getPresetValue(FONT_PRESETS, themeData)}
          options={getPresetOptions(FONT_PRESETS)}
          onChange={v => themeData.setData(FONT_PRESETS[v].theme)} />
      </Block>

{/*
        <Block>
          <Selection
            label="Color Preset"
            value={'custom'}
            options={[
              { key: 'custom', caption: 'Custom' },
              { key: 'dark', caption: 'Dark' },
              { key: 'standard', caption: 'Standard' },
              { key: 'pink', caption: 'Pink' },
            ]}
            onChange={v => {}} />
        </Block>

        <Block>
          <Selection
            label="Size Preset"
            value={'custom'}
            options={[
              { key: 'custom', caption: 'Custom' },
              { key: 'small', caption: 'Small' },
              { key: 'medium', caption: 'Medium' },
              { key: 'large', caption: 'Large' },
              { key: 'x-large', caption: 'Extra Large' }
            ]}
            onChange={v => {}} />
        </Block>*/}
    </>
  )
}