import BaseElementController from 'modules/elements/lib/BaseElementController';
import { List, Map, fromJS } from 'immutable';
import settingsFields from '../Policies/settingsFields';


class Controller extends BaseElementController {
  settingsFields = settingsFields;
  
  get elementFilenames() {
    if (this.getSettingsValue('filename'))
      return new Set([this.getSettingsValue('filename')]);
    return null;
  }

  getElementFile(filename) {
    if (filename !== this.getSettingsValue(filename))
      return;

    return ({
      filename: filename,
      getContent: () => this.getOutput().map(e => e.get('data')).join(''),
      contentType: 'text/plain',
      isBase64: false,
      isPulled: true
    });
  }

  constructor(args) {
    super(args);
    this.machineInterface = this.getMachineInterface();
    this.unloadFns = [
      this.machineInterface.subscribeDataStream(this.handleStream),
      this.machineInterface.subscribeProcessEvent(this.handleProcess),
      this.machineInterface.subscribeProcessIndex(this.handleProcessIndex)
    ];
    this.checkProcessIsActive();
  }

  setFocusFn(fn) { this._focus = fn; }
  focus() { if (this._focus) this._focus(); }

  getOutput = () => this.state.get('output') || List();

  setOutputRef = (el) => { this.outputEl = el; }
  
  handleStream = ({ type, data }) => {
    const { outputEl } = this;
    if (outputEl && outputEl.scrollHeight - outputEl.scrollTop <= outputEl.clientHeight + 1) {
      setTimeout(() => { outputEl.scrollTop = outputEl.scrollHeight }, 100);
    }

    const output = this.getOutput().push(new Map({ type, data }));

    this.setState({ output });
  }

  handleProcess = ({ process, event, error }) => {
    if (event === 'start') {
      this.clearFetchStatus();
      this.setState({ output: List() });
    } else if (event === 'error') {
      let output = this.getOutput().push({ type: 'error', data: error });
      this.setState({ output });
    } else if (event === 'close') {
      this.fetchFiles();
    }
    this.checkProcessIsActive();
  }

  handleProcessIndex = () => { this.checkProcessIsActive(); }

  removeOutput = () => this.setState({ output: null });
  clearOutput = () => this.setState({ output: List() });
  exceedsSizeLimit = () => this.getOutputSize() > 204800;
  getOutputSize= () => this.getOutput().reduce((t, i) => (t + 24 + i.get('data').length), 0) * 2;

  run = () => this.machineInterface.syncAndRun({
    command: this.state.get('settings').get('command'),
    isCommon: this.state.get('settings').get('isCommon')
  });
  kill = () => this.machineInterface.kill();
  
  checkProcessIsActive = () => {
    const processIsActive = this.machineInterface.processIsActive();
    if (processIsActive !== this.getState().get('processIsActive'))
      this.setState({ processIsActive });
  }

  destroy = () => {
    this.unloadFns.forEach(fn => fn());
    this.unloadFns = [];
  }

  saveOutput = () => {
    this.setState({ savedOutput: this.state.get('output') });
    this.save();
  }
  

  fetchFiles = async () => {
    for (let child of this.getChildren()) {
      const { elementId } = child;
      this.setFetchStatus(elementId, 'loading');
      try {
        await this.fetchFile(child);
        this.setFetchStatus(elementId, 'loaded');
      } catch (e) {
        console.error(e);
        this.setFetchStatus(elementId, 'error');
        this.setFetchStatus('error' + elementId, e.message);
      }
    }
  }

  fetchFile = async (child) => {
    const
      filename = child.getSettingsValue('filename'),
      response = await this.machineInterface.fetchFile(child.getSettingsValue('filename'));

    // await new Promise(r => setTimeout(r, 2000));
    if (response.status === 404) {
      throw new Error('404. Not found.');
    } else if (response.status !== 200) {
      throw new Error('Load error.');
    }
    let
      reader = new FileReader(),
      blob = await response.blob(),
      dataURL = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(blob);
      }),
      [header, content] = dataURL.split(','),
      contentTypeMatch = dataURL.match(/^data:(.*?);/),
      contentType = contentTypeMatch ? contentTypeMatch[1] : null,
      isBase64 = header.includes(';base64'),
      isPulled = true,
      settings = child.state.get('settings').merge({ contentType, isBase64, isPulled });

    if (!isBase64) {
      content = decodeURIComponent(content);
    }
    // console.log(contentType, isBase64, isPulled);
    // check for limit exceeded
    child.setState({ content, settings });
    child.save();
    this.commonEmitter.emit(`file update ${filename}`);
  }

  clearFetchStatus = () => {
    let m = {};
    for (let child of this.getChildren()) { m[child.elementId] = 'init'; }
    this.setState({ fetchStatus: fromJS(m) })
  }

  setFetchStatus = (elementId, value) => this.setState({
    fetchStatus: this.state.get('fetchStatus').set(elementId, value)
  });

  sendStdin = (message) => {
    this.machineInterface.sendStdin(message);
  }
}

export default Controller;