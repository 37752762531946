import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Cross1Icon } from '@radix-ui/react-icons';


export function CloseButton({ to }) {
  return (
    <Link className="top-button p-2 inline-block" to={to}>
      <Cross1Icon />
    </Link>
  );
}

export function TopLeft({ children }) {
  return (
    <div className="absolute top-2 right-4 max-md:hidden">{children}</div>
  )
}

export function Frame({ children, className, fullHeight=true }) {
  return (
    <div className={clsx('md:p-8 md:pb-8 overflow-hidden w-full', fullHeight && 'h-full')}>
      <div
        className={clsx(
          'md:border border-rim mx-auto relative bg-paper overflow-auto h-full',
          className
        )}>
        {children}
      </div>      
    </div>
  )
}