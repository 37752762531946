import { BuildInputs } from 'modules/elements/lib/sidebar/builder';
import { fields, fieldsLayout } from '../Policies/settings';

import { Buttons, Button } from 'modules/elements/lib/sidebar/inputs/buttons';
import { Break, Block } from 'modules/elements/lib/sidebar/inputs/components';


export default function TableInspector({ controller }) {
  const
    { getSettingsData, getSettingsValue, setSettings, getSettingsValueOnNull, data } = controller;

  return (
    <>
      <BuildInputs
        fields={fields}
        layout={fieldsLayout}
        getData={getSettingsData}
        getValue={getSettingsValue}
        setData={setSettings}
        getValueOnNull={getSettingsValueOnNull} />

      <Break />
      <Block>
        <Buttons>
          <Button
            preventFocusOnPress={true}
            isDisabled={data.history.index <= 0}
            onPress={e => data.undo()}>
            Undo
          </Button>
          <Button
            preventFocusOnPress={true}
            isDisabled={data.history.index >= data.history.history.size - 1}
            onPress={e => data.redo()}>
            Redo
          </Button>
        </Buttons>
      </Block>
    </>
  );
}