import BaseController from 'lib/BaseController';
import Prompt from 'lib/popup/popups/Prompt/Controller';
import DomainController from 'modules/domain/controllers/Domain';


class ThemeController extends BaseController {
  constructor({ app }) {
    super();
    this.app = app;
    this.popupManager = app.popupManager;
    this.state = this.state.merge({ tab: 'page' });
    this.domainController = new DomainController({ app });
    this.themeData = this.domainController.themeData;
    this.navData = this.domainController.navData;
  }

  loadPath = async (path) => {
    const
      slug = path.split('/')[0],
      provider = path === 'local' ? this.app.localProvider : this.app.remoteProvider,
      pathData = await provider.fetchPath(slug),
      { domainId } = pathData;
    
    await this.domainController.load({ provider, domainId, slug });
  }

  unload = () => {
    this.domainController.unload();
    console.log('unloading theme page', this.path);
  }

  confirmSave = () => this.popupManager.open(Prompt, {
    title: 'Save your changes?',
    submitLabel: 'Save',
    question: (
      <>
        These changes will take effect immediately
        throughout your entire workspace.
      </>
    ),
    inputs: [],
    onSubmit: this.save
  });


  confirmRevert = () => this.popupManager.open(Prompt, {
    title: 'Discard your changes?',
    submitLabel: 'Yes',
    question: (
      <>
        This will discard all of your changes,
        and reload the saved theme.
      </>
    ),
    inputs: [],
    onSubmit: this.revert
  });

  save = async () => {
    await this.domainController.updateDomain({
      theme: this.themeData.toJS(),
      navLinks: this.navData.toJS()
    });
  }

  revert = () => {
    this.navData.revert();
    this.themeData.revert();
  }

  hasChanged = () => this.navData.hasChanged() || this.themeData.hasChanged();
}

export default ThemeController;