import { useState, useRef, useEffect } from 'react';
import { Form, Button } from 'react-aria-components';
import EditorController from 'lib/prosemirror/Controller';
import placeholderPlugin from 'lib/prosemirror/plugins/placeholderPlugin'
import { htmlToState, stateToHtml } from 'lib/prosemirror/helpers';
import Loader from 'lib/staticPages/Loader';

import clsx from 'clsx';


function newViewState(value) {
  return htmlToState(value, [placeholderPlugin('Add a comment...')]);
}

export default function NewComment({ controller, comment='', commentId=null, onCancel, autoFocus=false }) {
  const
    ref = useRef(),
    [editing, setEditing] = useState(false),
    [posting, setPosting] = useState(false),
    [error, setError] = useState(null),
    clear = () => {
      setEditing(false);
      editorController.setViewState(newViewState(comment));
      setError(false);
    };

  const [editorController, ] = useState(() => new EditorController({ viewState: newViewState(comment) }));
  useEffect(() => editorController.setEl(ref.current), [editorController]);
  useEffect(() => { if (autoFocus) editorController.view.focus(); });

  return (
    <Form
      className="py-4"
      onSubmit={e => {
        e.preventDefault();
        let
          doc = editorController.viewState.doc,
          comment = stateToHtml(editorController.viewState);

        if (doc.childCount === 1 && doc.firstChild.isTextblock && doc.firstChild.content.size === 0) {
          setError('Hey, you\'ve gotta write something.');
          return;
        }
        setPosting(true);
        controller.postComment({ commentId, comment })
          .then(() => { clear(); controller.fetchComments(); })
          .then(() => { setPosting(false); setError('There was an error posting the comment.'); });
      }}>
      <div
        className={clsx(
          'px-4 py-2 w-full border-b outline-none mb-3',
          editing ? 'border-rim3' : 'border-rim'
        )}
        ref={ref}
        onFocus={() => setEditing(true)} />

      { editing && (
        <div className="flex gap-2">
          <div className="flex-grow pl-4">
            { posting ?
              (<Loader />) :
              error && (
                <span className="text-error text-sm">
                  { error }
                </span>
              ) }
          </div>

          <CommentButton type="submit" isDisabled={posting}>
            { commentId ? 'Save' : 'Comment' }
          </CommentButton>

          <CommentButton
            onPress={e => { clear(); if (onCancel) onCancel(); }}
            isDisabled={posting}>
            Cancel
          </CommentButton>
        </div>
      )}
    </Form>
  )
}


const CommentButton = (props) => (
  <Button
    className={({ isDisabled }) => clsx(
      'py-1 px-2 border-rim3 border font-medium text-sm',
      isDisabled && 'opacity-50'
    )}
    {...props} />
);