import { Map } from 'immutable';
import { initialSettings } from './settingsFields';
import { htmlToState, stateToHtml } from 'lib/prosemirror/helpers';
// import {  }

class Policies {
  // EXAMPLE_DATA = {
  //   content: { //... }
  // }

  // EXAMPLE_STATE = {
  //   editorState: <EditorState({ <ContentState> <SelectionState> })>
  // }

  static getInitialData() {
    return {
      version: 0,
      type: 'text',
      settings: initialSettings,
      content: ''
    }
  }
  
  static dataToState(data, currentState) {
    const
      editorViewState = htmlToState(data.content),
      settings = data.settings || {};

    if (currentState) {
      return (
        currentState
          .set('editorViewState', editorViewState)
          .set('settings', Map(settings))
      );
    }

    return Map({
      editorViewState: editorViewState,
      settings: Map(settings)
    });    
  }
  
  static stateToData(state) {
    const
      content = stateToHtml(state.get('editorViewState')),
      settings = state.get('settings').toObject();

    return { content, settings }
  }
  static autoSave = true;

  static contentHasChanged(oldState, newState) {
    const changed = (
      (oldState.get('editorViewState').doc.content !== newState.get('editorViewState').doc.content) ||
      (oldState.get('settings') !== newState.get('settings'))
    );
    return changed;
  }
}

export default Policies;