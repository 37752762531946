import { Block, Label, Break } from 'modules/elements/lib/sidebar/inputs/components';
import { Button, Buttons } from 'modules/elements/lib/sidebar/inputs/buttons';
import TextInput from 'modules/elements/lib/sidebar/inputs/TextInput';
import ColorInput from 'modules/elements/lib/sidebar/inputs/ColorInput';
import { faPlus, faArrowUp, faArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons';

function Navigation({ themeController }) {
  const
    { themeData, navData } = themeController,
    { getData, getDefault, setDataDebounced } = themeData;

  return (
    <>
      <Block span="half">
        <ColorInput
          label="Link Color"
          nullable={true}
          valueOnNull={getDefault('navColor')}
          value={getData('navColor')}
          onChange={v => setDataDebounced({ navColor: v })} />
      </Block>

      <Block span="half">
        <ColorInput
          label="Background Color"
          nullable={true}
          valueOnNull={getDefault('navBackgroundColor')}
          value={getData('navBackgroundColor')}
          onChange={v => setDataDebounced({ navBackgroundColor: v })} />
      </Block>
      
      <Break />

      <Block>
        <Label>NAVIGATION LINKS</Label>

        {navData.getData().size === 0 && (
          <div className="text-sm py-4 italic">Empty</div>
        )}
        { navData.getData().map((nav, i) => (
          <NavLinkInput
            key={i}
            index={i}
            nav={nav}
            update={o => navData.setLink(i, o)}
            remove={_ => navData.deleteLink(i)}
            move={(i, d) => navData.moveLink(i, d)}
            lastIndex={navData.getData().size - 1} />
        )) }
        <Break />
      </Block>


      <Buttons className="text-right">
        <Button
          faIcon={faPlus}
          onPress={() => navData.addLink()}> 
          Add
        </Button>
      </Buttons>
    </>
  )
}

function NavLinkInput({ nav, update, remove, index, lastIndex, move }) {
  return (
    <>
      <div className="px-3 py-1 my-2 border rounded-sm">
        <TextInput
          label="Caption"
          value={nav.get('caption')}
          onChange={v => update({ caption: v })} />

        <TextInput
          label="Link"
          value={nav.get('link')}
          onChange={v => update({ link: v })} />
      </div>
      <Buttons className="text-right">
        <Button
          faIcon={faArrowUp}
          tooltip="Move Up"
          preventFocusOnPress={false}
          onPress={_ => move(index, -1)} 
          isDisabled={index === 0} />
        <Button
          faIcon={faArrowDown}
          tooltip="Move Down"
          preventFocusOnPress={false}
          onPress={_ => move(index, 1)}
          isDisabled={index === lastIndex } />
        <Button
          preventFocusOnPress={false}
          faIcon={faTrash}
          tooltip="Delete"
          onPress={remove} />
      </Buttons>
    </>
  )
}

export default Navigation;