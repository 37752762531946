import BaseController from 'lib/BaseController';

const SCHEME_LIST = ['dark', 'light', 'orange']

export default class ColorScheme extends BaseController {
  constructor() {
    super();
    this.state = this.state.merge({ scheme: window.localStorage.getItem('scheme') });
    this.applyScheme();
  }

  getSchemeValue() {
    return (this.state.get('scheme') || this.systemScheme());
  }

  setSchemeData(scheme) {
    window.localStorage.setItem('scheme', scheme);
    this.setState({ scheme });
    this.applyScheme();
  }

  applyScheme() {
    const
      scheme = this.getSchemeValue(),
      classList = window.document.documentElement.classList;

    for (let s of SCHEME_LIST) {
      if (scheme === s) {
        classList.add(s);
      } else {
        classList.remove(s);
      }
    }
  }

  systemScheme() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }
}