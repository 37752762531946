import { useControllerState } from 'lib/BaseController';
import elementLibs from 'modules/elements/all';
import Margins from 'modules/layout/View/Grid/Outlines/Margins';
// import Tiles from 'modules/layout/View/Grid/Outlines/Tiles';

export default function Grid({ article }) {
  const
    { layout } = article,
    { grid } = layout;

  return (
    <GridPage grid={grid}>
      { layout.order.map((id, index) => article.elementControllers[id] && (
        <GridElement
          key={id}
          id={id}
          element={article.elementControllers[id]}
          grid={grid} />
      ))}
      <Margins grid={grid} isResizable={false} />
    </GridPage>
  );
}

function GridPage({ grid, children }) {
  const
    { pageRect } = grid,
    { themeData, pointSize } = grid.layout,
    pageMargin = themeData.getValue('pageMargin'),
    // padding = themeData.getValue('pagePadding') * pointSize,
    paddingStyle = {
      paddingLeft: pageMargin.left * pointSize,
      paddingRight: pageMargin.right * pointSize,
      paddingTop: pageMargin.top * pointSize,
      paddingBottom: pageMargin.bottom * pointSize
    },
    { width, height } = pageRect;

  return (
    <div className="text-center min-w-full">
      <div className="inline-block text-left px-12 pt-24 pb-24">
        <div style={paddingStyle} className="m-auto border-rim border">
          <div
            className="relative"
            style={{ width, height }}>
            {/*<Tiles grid={grid} />*/}
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}

function GridElement({ id, element, grid }) {
  const
    { x, y, w, h } = grid.getGridElement(id).toJS(),
    { cellSize, cellGap } = grid,
    width = (w || 1) * (cellSize.width + cellGap.x) - cellGap.x,
    height = (h || 1) * (cellSize.height + cellGap.y) - cellGap.y,
    left = (x || 0) * (cellSize.width + cellGap.x),
    top = (y || 0) * (cellSize.height + cellGap.y);

  return (
    <div className="absolute" style={{ width, height, left, top }}>
      <Element element={element} />
    </div>
  );
}

function Element({ element }) {
  useControllerState(element);
  const lib = elementLibs[element.type];
  return (<lib.Viewer controller={element.controller} isGrid={true} />)
}