import { fromJS } from 'immutable';
import { initialSettings } from './settings';

export default class Policies {
  static autoSave = true;

  static getInitialData() {
    return {
      version: 0,
      type: 'table',
      settings: initialSettings,

      columns: [
        {
          value: 'Fruit',
          width: 150,
          type: 'text',
          alignment: 'left',
          numberFormatting: null,
          decimalPlaces: null },
        {
          value: 'Price ($ per kg)',
          width: 120,
          type: 'number',
          alignment: 'left',
          numberFormatting: null,
          decimalPlaces: null,
          prefix: '$'
        },
        {
          value: 'Amount (kg)',
          width: 120,
          type: 'number',
          alignment: 'left',
          numberFormatting: null,
          decimalPlaces: null,
          suffix: 'kg'
        }
      ],

      rows: {
        0: [{ value: 'Apple' }, { value: 60 }, { value: 2 }],
        1: [{ value: 'Orange' }, { value: 50 }, { value: 4 }],
        2: [{ value: 'Strawberry' }, { value: 120 }, { value: 3 }],
        3: [{ value: 'Blueberries' }, { value: 245 }, { value: 5 }],
        4: [{ value: 'Banana' }, { value: 25 }, { value: 10 }],
      },
    }
  }

  static dataToState(data, currentState) {
    const newData = {
      columns: data.columns,
      rows: Object.values(data.rows)
    }

    if (!currentState) {
      currentState = fromJS({
        selection: { startCell: {i: -1, j: 0}, endCell: null },
        history: [newData],
        historyIndex: 0,
        tabAt: 'cell'
      });
    } else {
      currentState = currentState.set(
        'history',
        currentState.get('history').push(fromJS(newData))
      );
    }

    return (
      currentState
        .set('data', fromJS(newData))
        .set('settings', fromJS(data.settings))
    );
  }

  static stateToData(state) {
    const
      dataState = state.get('data'),
      rows = {};
    dataState.get('rows').map((row, i) => rows[i] = row.toJS());
    
    return {
      settings: state.get('settings').toJS(),
      columns: dataState.get('columns').toJS(),
      rows: rows
    }
  }

  static contentHasChanged(newState, oldState) {
    return (
      newState.get('data') !== oldState.get('data') ||
      newState.get('rows') !== oldState.get('rows') ||
      newState.get('settings') !== oldState.get('settings')
    )
  }
}