export const LIGHT_THEMES = [
  ['bbedit', 'BB Edit'],
  ['duotoneLight', 'Duotone Light'],
  ['eclipse', 'Eclipse'],
  ['githubLight', 'GithubLight'],
  ['gruvboxLight', 'GruvBox Light'],
  ['light', 'Plain'],
  ['materialLight', 'Material Light'],
  ['noctisLilac', 'Noctis Lilac'],
  ['quietlight', 'Quietlight'],
  ['solarizedLight', 'SolarizedLight'],
  ['tokyoNightDay', 'Tokyo Night Day'],
  ['xcodeLight', 'Xcode Light'],
]

export const DARK_THEMES = [
  ['abcdef', 'ABCDEF'],
  ['abyss', 'Abyss'],
  ['androidstudio', 'Android Studio'],
  ['atomone', 'Atomone'],
  ['aura', 'Aura'],
  ['bespin', 'Bespin'],
  ['darcula', 'Darcula'],
  ['duotoneDark', 'Duotone Dark'],
  ['githubDark', 'GitHub Dark'],
  ['gruvboxDark', 'GruvBox Dark'],
  ['kimbie', 'Kimbie'],
  ['material', 'Material'],
  ['materialDark', 'Material Dark',],
  ['monokai', 'Monokai'],
  ['monokaiDimmed', 'Monokai Dimmed'],
  ['nord', 'Nord'],
  ['okaidia', 'Okaidia'],
  ['red', 'Red'],
  ['solarizedDark', 'Solarized Dark'],
  ['sublime', 'Sublime'],
  ['tokyoNight', 'Tokyo Night'],
  ['tokyoNightStorm', 'Tokyo Night Storm'],
  ['tomorrowNightBlue', 'Tomorrow Night Blue'],
  ['vscodeDark', 'VSCode Dark'],
  ['xcodeDark', 'Xcode Dark'],
]

export const DARK_THEME_KEYS = new Set();
DARK_THEMES.forEach(([key]) => DARK_THEME_KEYS.add(key))

export const LIGHT_THEME_OPTIONS = LIGHT_THEMES.map(([key, caption], i) => ({key, caption }));
export const DARK_THEME_OPTIONS = DARK_THEMES.map(([key, caption], i) => ({key, caption }));

export const ALL_THEME_OPTIONS = (
  [
    { type: 'heading', caption: 'Light Themes' },
    ...LIGHT_THEME_OPTIONS,
    { type: 'break' },
    { type: 'heading', caption: 'Dark Themes' },
    ...DARK_THEME_OPTIONS
  ]
);


export const CODE_FONT_SIZES = {
  12: '12',
  13: '13',
  14: '14 Small',
  15: '15',
  16: '16 Normal',
  17: '17',
  18: '18 Large',
  19: '19',
  20: '20 XL'
}

export const CODE_FONT_SIZE_OPTIONS = Object.keys(CODE_FONT_SIZES).map(
  key => ({ key: key, caption: CODE_FONT_SIZES[key] })
);

export const CODE_BORDER_OPTIONS = [
  { key: 'full', caption: 'Full' },
  { key: 'none', caption: 'None' },
  { key: 'sandwich', caption: 'Sandwich' },
];