import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';
import sendFeedback from 'lib/sendFeedback';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });

  constructor(args, manager) {
    super(args, manager);
    this.auth = args.auth;
    this.isPrimary = args.isPrimary;
    this.checkAvailable = args.checkAvailable;

    this.state = this.state.merge({
      title: this.isPrimary ? `${this.auth.user.displayName}'s Workspace` : '',
      slug: this.isPrimary ? this.auth.user.email.split('@')[0] : '',
      autoSlug: true,
      emailVerified: args.auth.user.emailVerified
    });
  }

  async createDomain() {
    const
      title = this.state.get('title'),
      slug = this.state.get('slug');

    this.setState({ loading: true, alert: '' });
    try {
      await this.auth.createDomain({ slug, title, isPrimary: this.isPrimary });
      await this.auth.loadUserDomains();
      this.setState({ created: true, loading: false });
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }

  requestMore = () => {
    const { auth } = this.args;
    sendFeedback({
      popupManager: this.manager,
      email: (auth.user && auth.user.email) || '',
      message: `Please increase my workspace limit.`
    });
  }
}

export default PopupController;