import { useRef } from 'react';
import { getSizeString } from 'lib/tools.ts';

import { useControllerState } from 'lib/BaseController';
import ui from 'lib/popup/components/ui';
import { Group, TextInput } from 'lib/inputs/basic';
import { Button, Buttons } from 'lib/inputs/buttons';

import './style.css';


function Popup({ popup }) {
  const
    state = useControllerState(popup),
    fileInputRef = useRef(),
    at = state.get('at');
  
  return (
    <ui.Popup popup={popup}>
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            { at === 'select' ? (popup.args.title || 'Upload A File') : 'Confirm Upload' }
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <div className="upload-popup">
            <input
              id="file-input"
              type="file"
              accept={popup.args.accept}
              ref={fileInputRef}
              style={{ 'display': 'none' }}
              onChange={e => {
                if (fileInputRef.current.value) {
                  const file = fileInputRef.current.files[0];
                  popup.setState({ at: 'confirm', file: file, filename: file.name });
                } else {
                  popup.setState({ at: 'select', file: null });
                }
              }} />
            {
              at === 'select' ?
                (<Selection popup={popup} fileInputRef={fileInputRef} />) :
                (<Confirm popup={popup} fileInputRef={fileInputRef} />)
            }
          </div>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}


function Selection({ popup, fileInputRef }) {
  const
    dragging = popup.state.get('dragging'),
    handleDrop = e => {
      e.preventDefault()
      e.stopPropagation()
      popup.setState({ dragging: false })
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        popup.setState({ file: file, filename: file.name, at: 'confirm'  });
        e.dataTransfer.clearData();
      }
    },
    handleDragOver = e => {
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragEnter = e => {
      e.stopPropagation()
      popup.setState({ dragging: true })
    },
    handleDragLeave = e => {
      e.stopPropagation()
      popup.setState({ dragging: false });
    }

  return (
    <div className="selection">
      <div
        className={'dnd-box' + (dragging ? ' dragging' : '')}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave} >
        { dragging ? 'Drop your file!' : 'Drag and Drop a File Here'}
      </div>
      <div className="dnd-or">or</div>
      <Buttons className="text-center">
        <Button onPress={_ => fileInputRef.current.click() } autoFocus={true}>
          Select a File
        </Button>
      </Buttons>
    </div>
  )
}


function Confirm({ popup, fileInputRef }) {
  const
    file = popup.state.get('file'),
    tally = popup.state.get('tally'),
    domainPlan = popup.state.get('domainPlan'),
    loading = !domainPlan || !tally || !file,
    limitMessage = 
      loading ? '' :
        file.size > domainPlan.maxUploadSize * 1028 * 1028 ?
          `The file is larger than ${domainPlan.maxUploadSize}mb.` :
          file.size + tally.storageUse > domainPlan.maxStorage * 1028 * 1028 ?
            'You don\'t have enough storage space for this file.' :
            null;

  return (
    <form
      className="confirm"
      onSubmit={e => {
        e.preventDefault();
        if (!popup.state.get('uploading'))
          popup.beginUpload();

        if (popup.state.get('uploaded')) {
          // popup.setState({ at: 'select', uploading: false, uploaded: false });
          // fileInputRef.current.value = '';
          popup.close();
        }
      }}>
      <Group>
        <TextInput
          label="Filename"
          value={popup.state.get('filename')}
          onChange={v => popup.setState({ filename: v })}
          isDisabled={popup.state.get('uploading')} />

        <TextInput
          label="File Type"
          value={file.type || 'Unknown'}
          isDisabled={true} />

        <TextInput
          label="File Size"
          value={file.size ? getSizeString(file.size) : 'Unknown'}
          isDisabled={true} />


        { popup.state.get('uploading') && (
          <div className="progress text-right">
            {popup.state.get('uploaded') ? 'Upload Complete' : 'Uploading'}
            <div className="progressbar1"></div>
            <div
              className="progressbar2"
              style={{ width: popup.state.get('uploadPercent') + '%' }}>
            </div>
          </div>
        ) }

        { (!loading && limitMessage) && (
          <div className="alert text-right font-sm">
            {limitMessage} <br/> Please upgrade your workspace.
          </div>
        )}

        <ui.Alert />
        
        <Buttons className="text-right">
          {!popup.state.get('uploading') && (
            <Button
              type="submit"
              isDisabled={popup.state.get('uploading') || limitMessage || loading}
              autoFocus={true}>
              Upload
            </Button>
          )}

          { popup.state.get('uploaded') && (
            <Button type="submit" autoFocus={true}>
              Done
            </Button>
          ) }

          { !popup.state.get('uploaded') && (
            <Button
              onPress={_ => {
                if (popup.state.get('uploading')) {
                  popup.cancelUpload();
                } else {                  
                  fileInputRef.current.value = '';
                  popup.discard();
                }
              }}>
              Cancel
            </Button>
          )} 
        </Buttons>
      </Group>
    </form>    
  )
}

export default Popup;