import { db, functions, storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL as getDownloadURL_ } from 'firebase/storage';
import { collection, getDocs, query } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getCurrentUser } from './auth';


export async function getDownloadURL(path, forMachine) {
  if (forMachine) {
    const data = await getMachineDownloadURL(path);
    return data.url;
  }
  
  const time = new Date();
  const
    result = await getDownloadURL_(ref(storage, path)),
    filename = path.split('/').pop();
  console.log(`[ download URL for "${filename}", ${new Date() - time}ms ]`);
  return result;
}

export function uploadArticleFile(
  { domainId, articleId, userId, filename, file }, progress, error, done
) {
  const
    hash = new Date().getTime().toString(36),
    path = `domain/${domainId}/article/${articleId}/user/${userId}/upload/${hash}/${filename}`,
    uploadTask = uploadBytesResumable(ref(storage, path), file);

  console.log('upload file', path, file);

  uploadTask.on(
    'state_changed',
    snapshot => progress(100 * snapshot.bytesTransferred / snapshot.totalBytes),
    e => {
      // TODO
      if (e.code === 'storage/unknown')
        return done();
      if (e.code === 'storage/canceled')
        return error(new Error('The upload was cancelled.'));
      return error(e)
    },
    done
  );
  return _ => uploadTask.cancel();
}


// {article|folder|domain} /id/ {cover|logo} / upload / filename
export async function uploadImage({ basePath, filename, file }, progress, error, done) {
  const
    userId = getCurrentUser().uid,
    path = `${basePath}/${userId}/upload/${filename}`,
    uploadTask = uploadBytesResumable(ref(storage, path), file);
  
  console.log('upload image', path, file);
  return uploadTask.on(
    'state_changed',
    snapshot => progress(100 * snapshot.bytesTransferred / snapshot.totalBytes),
    error, done
  );
}


export async function getUploadUsedBy({ domainId, articleId, uploadId }) {
  const qss = await getDocs(query(
    collection(db, 'domains', domainId, 'articles', articleId, 'uploads', uploadId, 'usedBy')
  ));
  return qss.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}


export async function deleteUpload({ domainId, articleId, uploadId, hardDelete }) {
  return await httpsCallable(functions, 'deleteArticleUploadCall')({ domainId, articleId, uploadId, hardDelete });
}

export async function getMachineDownloadURL(name) {
  const { data } = await httpsCallable(functions, 'getMachineDownloadURLCall')({ name });
  return data;
}