import { ui } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';
import { Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';


function View({ popup }) {
  let
    state = useControllerState(popup),
    loading = state.get('loading'),
    folder = popup.folder;

  return (
    <ui.Popup popup={popup} className="delete-folder-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Delete Folder
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.deleteFolder(); }}>
            <Group>
              <ui.Question>
                Are you sure you want to delete this folder?
              </ui.Question>
              <ui.Item
                itemType="Folder"
                title={folder.get('title')}
                slug={folder.get('slug')} />
            </Group>

            <ui.Alert />

            <Buttons className="text-right">
              <Button type="submit" isDisabled={loading}>Delete</Button>
              <Button onPress={e => { popup.close() }}>Cancel</Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  );
}

export default View;