export default function Navbar({ themeController }) {
  const
    { navData, themeData } = themeController,
    { getValue } = themeData,
    navs = navData.getData();

  return navs.size > 0 && (
    <div
      className="text-right py-3 px-6 border-b"
      style={{ color: getValue('navColor'), background: getValue('navBackgroundColor') }}>
      { navs.map((nav, i) => (
        <div className="mx-2 inline font-medium" key={i}>
          {nav.get('caption')}
        </div>
      )) }
    </div>
  );
}