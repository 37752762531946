import katex from 'katex';


export default class MathNodeView {
  constructor(node, view, getPos, onClick) {
    this.node = node;
    this.view = view;
    this.textContent = this.node.textContent;
    this.dom = document.createElement('span');
    this.katexEl = document.createElement('span');
    this.dom.appendChild(this.katexEl);
    this.renderKatex();
    this.katexEl.addEventListener('click', evt => onClick(this.node, getPos));
  }

  update(node) {
    if (!node.sameMarkup(this.node))
      return false;
    this.node = node;
    this.textContent = node.textContent;
    this.renderKatex();
  }

  renderKatex() {
    try {
      katex.__parse(this.textContent);
      katex.render(this.textContent, this.katexEl, { displayMode: false });
      this.katexEl.className = '';
    } catch (e) {
      this.katexEl.textContent = this.textContent;
      this.dom.className = 'text-error-red';
    }
  }
}