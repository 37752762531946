import { useControllerState } from 'lib/BaseController';
import ui from 'lib/popup/components/ui';
import { TextInput, Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import clsx from 'clsx';


function Popup({ popup }) {
  const
    state = useControllerState(popup),
    src = state.get('src'),
    status = (
      state.get('checking') ? 'spinner' :
        (state.get('checkedLink') !== state.get('link') || state.get('link') === '') ? null :
          state.get('isValid') === false ? 'cross' :
            state.get('isValid') === true ? 'check' :
              null
    );

  return (
    <ui.Popup popup={popup} className="link-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>Add Image</ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.submit(); }}>
            {/*<div></div>*/}
            <div className={
              clsx(
                'border border-rim p-2 min-h-[12rem] w-[12rem] m-auto',
                (!src || !state.get('isValid')) && 'bg-striped'
              )}>
              {src && (
                <img
                  key={src}
                  src={src}
                  alt="Preview"
                  className={clsx(!state.get('isValid') && 'hidden')}
                  onLoad={popup.onImageLoad}
                  onError={popup.onImageError} /> )}
            </div>
            <div className="text-center font-medium text-sm">Preview</div>

            <Group>
              <TextInput
                label="Enter Image Location"
                value={state.get('location')}
                onChange={location => popup.updateLocation(location)}
                placeholder="A relative location(./a.png), or an absolute URL(http:)"
                autoFocus={true} 
                status={status} />

              Or 
              <select
                className="outline-none"
                value=""
                onChange={e => popup.updateLocation(`./${e.currentTarget.value}`)}>
                <option value="">select a local file</option>
                {popup.args.localFilenames.map((filename, i) => (
                  <option key={i} value={filename}>{filename}</option>
                ))}
              </select>

              { popup.args.allowUploads && (
                <div className="pull-right">
                  <label className="mr-2">
                    Or
                  </label>
                  <Button onClick={() => popup.openUploadFilesPopup()}>
                    A File Download Link
                  </Button>
                </div>
              ) }

            </Group>
            
            <ui.Alert />

            <Buttons className="text-right">
              <Button
                type="submit"
                isDisabled={
                  state.get('loading') || state.get('checking') ||
                  !state.get('isValid') || state.get('loadedSrc') !== src
                }>
                Save
              </Button>

              <Button onPress={_ => popup.close()}>
                Cancel
              </Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;