import Emitter from 'lib/Emitter';

class Vani {
  constructor() {
    const emitter = new Emitter();
    this.emit = function() { emitter.emit.apply(emitter, arguments); }
    this.on = function() { return emitter.on.apply(emitter, arguments); }
    this.boardcast = new BroadcastChannel('vani');
    this.boardcast.onmessage = this.handleBoardcastMessage();
  }

  handleMachineMessage = (payload) => {
    // -> webframes
    this.emit('for webframe', payload);
  }

  handleBoardcastMessage = (payload) => {
    // console.log('vani boardcast', payload);
    // -> webframes
    this.emit('for webframe', payload);
  }

  handleWebframeMessage = (payload) => {
    // -> machine
    this.emit('for machine', payload);
    // -> other tabs
    this.boardcast.postMessage(payload);
    // -> webframes
    this.emit('for webframe', payload);
  }
}

export default Vani;