import { upgradeToVersion1 } from './initialize';

export const
  DB_NAME = 'nbx-local',
  BOARDCAST_CHANNEL = 'nbx-local-db-write';

export function getUniqueId() {
  return Math.random().toString(36).substr(2);
}

export function getDB() {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB_NAME, 1);
    request.onupgradeneeded = (event) => upgradeToVersion1(event.target.result);
    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
}

export async function getItem({ storeName, id }) {
  console.log('storeName', storeName, 'id', id)
  const
    db = await getDB(),
    transaction = db.transaction([storeName], 'readonly'),
    objectStore = transaction.objectStore(storeName),
    request = objectStore.get(id);

  return await new Promise((resolve, reject) => {
    request.onsuccess = event => resolve(event.target.result);
    request.onerror = event => reject(event.target.error);
  });
}


export async function setItem({ storeName, value, update=false }) {
  if (update) {
    const currentValue = await getItem({ storeName, id: value.id });
    value = Object.assign({}, currentValue, value);
  }
  value.id = value.id || `${storeName}-${getUniqueId()}`;

  const
    db = await getDB(),
    writeTransaction = db.transaction([storeName], 'readwrite'),
    writeObjectStore = writeTransaction.objectStore(storeName),
    writeRequest = writeObjectStore.put(value);

  return await new Promise((resolve, reject) => {
    writeRequest.onsuccess = (event) => {
      resolve(value);
      dispatchMessage({
        storeName,
        value,
        changeType: update ? 'modified' : 'added'
      });
    };
    writeRequest.onerror = (event) => reject(event.target.error);
  });
}


export async function getItems({ storeName, filter }) {
  const
    db = await getDB(),
    transaction = db.transaction([storeName], 'readonly'),
    objectStore = transaction.objectStore(storeName),
    result = [];

  return await new Promise((resolve, reject) => {
    objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (!cursor)
          return resolve(result);
        if (!filter || filter(cursor.value))
          result.push(cursor.value);
        cursor.continue();
      };
    transaction.onerror = (event) => reject(event.target.error);
  });
};

export async function deleteItem({ storeName, id }) {
  const
    value = await getItem({ storeName, id }),
    db = await getDB(),
    transaction = db.transaction([storeName], 'readwrite'),
    objectStore = transaction.objectStore(storeName),
    request = objectStore.delete(id);

  return await new Promise((resolve, reject) => {
    request.onsuccess = event => {
      dispatchMessage({ storeName, value, changeType: 'removed' });
      resolve(event.target.result)
    };
    request.onerror = event => reject(event.target.error);
  });
}

export async function deleteItems({ storeName, filter }) {
  const items = await getItems({ storeName, filter });
  for (let item of items) {
    await deleteItem({ storeName, id: item.id });
  }
}


// Dispatchers,for inter tab.
export const writeChannel = new BroadcastChannel(BOARDCAST_CHANNEL);
const listeners_ = new Set();
const listenToMessage = (fn) => {
  listeners_.add(fn);
  const cb = evt => fn(evt.data);
  writeChannel.addEventListener('message', cb);
  return _ => {
    listeners_.delete(fn)
    writeChannel.removeEventListener('message', cb);
  }
}
const dispatchMessage = (data) => {
  writeChannel.postMessage(data);
  listeners_.forEach(fn => fn(data));
}


export const onItem = ({ storeName, id }, cb, ecb) => {
  getItem({ storeName, id }).then(cb).catch(ecb);
  return listenToMessage((data) => {
    if (storeName === data.storeName && id === data.value.id)
      cb(data.value);
  });
}

export const onItems = ({ storeName, filter }, cb, ecb) => {
  getItems({ storeName, filter }).then(items => {
    cb(items);
  }).catch(ecb);
  return listenToMessage((data) => {
    if (storeName === data.storeName && (!filter || filter(data.value))) {
      getItems({ storeName, filter }).then(cb);
    }
  });
}

export const onItemChange = ({ storeName, filter }, cb, ecb) => {
  getItems({ storeName, filter }).then(items => {
    for (let item of items) {
      cb(item, 'added');
    }
  }).catch(ecb);
  return listenToMessage((data) => {
    if (storeName === data.storeName && (!filter || filter(data.value))) {
      cb(data.value, data.changeType);
    }
  })
}