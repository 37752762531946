import { fromJS } from 'immutable';
import DataHistory from './DataHistory';

const MINIMUM_COL_WIDTH = 50;


class Data {
  constructor(editor) {
    this.editor = editor;
    this.history = new DataHistory(this);
  }

  get state() { return this.editor.state.get('data'); }
  get rows() { return this.state.get('rows'); }
  get columns() { return this.state.get('columns'); }

  setDataState = (diff) => {
    const
      state = this.state,
      newState = state.merge(diff);
    this.history.push(newState, state);
    this.editor.setState({ data: newState });
  }

  getCell = (i, j) => {
    return (
      i === -1 ?
        this.columns.get(j) :
        this.rows.get(i).get(j)
    );
  }

  setCell = (i, j, key, value) => {
    if (i === -1) {
      const columns = this.columns.setIn([j, key], value);
      this.setDataState({ columns });
    } else {
      const rows = this.rows.setIn([i, j, key], value);
      this.setDataState({ rows });
    }
  }
  setCellValue = (i, j, v) => this.setCell(i, j, 'value', v);

  insertCol(d) {
    const { sj, startCell} = this.editor.selection;
    if (!startCell)
      return;
    const
      columns = this.columns.insert(sj + d, fromJS({ value: '', width: 100, type: 'text' })),
      rows = this.rows.map(r => r.insert(sj + d, fromJS({ value: '' })));

    this.editor.selection.moveSelection(0, d);
    this.setDataState({ columns, rows })
  }

  insertRow(d) {
    const { si, startCell } = this.editor.selection;
    if (!startCell || si + d < 0)
      return;
    const rows = this.rows.insert(si + d, this.columns.map(col => fromJS({ value: '' })));
    this.editor.selection.moveSelection(d, 0);
    this.setDataState({ rows });
  }

  deleteRow() {
    const { si, startCell } = this.editor.selection;
    if (!startCell)
      return;
    this.editor.selection.moveSelection(-1, 0);
    const rows = this.rows.delete(si);
    this.setDataState({ rows });
  }

  deleteCol() {
    const { sj, startCell } = this.editor.selection;
    if (this.columns.size <= 1 || !startCell)
      return;

    const
      columns = this.columns.delete(sj),
      rows = this.rows.map(r => r.delete(sj));
    this.editor.selection.moveSelection(0, -1);
    this.setDataState({ columns, rows })
  }

  moveRowUp() {
    const { si, startCell } = this.editor.selection;
    if (!startCell || si === 0) return;
    const rows = this.rows.splice()
    this.setDataState({ rows })
  }

  deleteRowPrompt() {
    this.editor.openPopup('prompt', {
      question: 'Are you sure you want to delete the row?',
      title: 'Delete Row',
      inputs: [],
      submitLabel: 'Yes',
      onSubmit: () => { this.deleteRow() },
      onUnload: () => {}
    });
  }

  deleteColPrompt() {
    this.editor.openPopup('prompt', {
      question: 'Are you sure you want to delete the column?',
      title: 'Delete  Column',
      inputs: [],
      submitLabel: 'Yes',
      onSubmit: () => { this.deleteCol() },
      onUnload: () => {}
    });
  }


  handleColWidthChange = (i, dx) => {
    const columns = this.columns.setIn([i, 'width'], Math.max(this.columns.getIn([i, 'width']) + dx, MINIMUM_COL_WIDTH))
    this.setDataState({ columns });
  }

  moveList = (list, i, d) => {
    if (i + d < 0 || i + d > list.size)
      return list;
    const
      row = list.get(i),
      rows = list.splice(i, 1).splice(i + d, 0, row);
    return rows;    
  }

  moveRow = (i, d) => {
    this.setDataState({ rows: this.moveList(this.rows, i, d) });
  }

  moveCol = (i, d) => {
    const
      columns = this.moveList(this.columns, i, d),
      rows = this.rows.map(r => this.moveList(r, i, d));
    this.setDataState({ columns, rows });
  }


  sort = (j, desc=false) => {
    const
      column = this.columns.get(j),
      t = (
        column.get('type') === 'number' ?
          x => stringToNumber(x) :
          x => x
      );

    this.rows.forEach(r => console.log(r.get(j)))
    const
      rows = this.rows.sort((a, b) => (
        t(a.get(j).get('value')) < t(b.get(j).get('value')) ? -1 :
        t(a.get(j).get('value')) === t(b.get(j).get('value')) ? 0 : 1
      ));
    this.setDataState({ rows: desc ? rows.reverse() : rows });
  }

  undo = () => {
    const data = this.history.undo();
    if (data)
      this.editor.setState({ data });
  }

  redo = () => {
    const data = this.history.redo();
    if (data)
      this.editor.setState({ data });
  }

  copyToClipboard = (event) => {
    let
      { rows, columns } = this,
      { si, sj, ei, ej } = this.editor.selection;

    if (typeof ei === 'undefined') {
      ei = si; ej = sj;
    }

    const
      i1 = Math.min(si, ei),
      i2 = Math.max(si, ei),
      j1 = Math.min(sj, ej),
      j2 = Math.max(sj, ej),
      text = rows.unshift(columns).slice(i1 + 1, i2 + 2).map(
        row => row.slice(j1, j2 + 1).map(cell => cell.get('value')).join('\t')
      ).join('\n');

    if (window.navigator.clipboard.writeText) {
      window.navigator.clipboard.writeText(text)
    }
  }

  pasteFromClipboard = async (event) => {
    const text = await navigator.clipboard.readText()
    if (!text)
      return;
    var
      { rows, columns } = this,
      sheet = rows.unshift(columns);

    const
      { si, sj } = this.editor.selection,
      height = rows.size,
      length = columns.size;

    text.split('\n').forEach((r, i) => r.split('\t').forEach((d, j) => {
      if (si + i + 1 < height && sj + j < length) {
        sheet = sheet.setIn([si + i + 1, sj + j, 'value'], d);
      }
    }));

    this.setDataState({
      columns: sheet.get(0),
      rows: sheet.shift(1)
    });
  }

  cutToClipboard = (e) => {
    this.copyToClipboard(e);
    this.deleteSelected(e);
  }

  deleteSelected = (e) => {
    let
      { rows, columns } = this,
      sheet = rows.unshift(columns),
      { si, sj, ei, ej } = this.editor.selection;

    if (typeof ei === 'undefined') { ei = si; ej = sj; }
    
    for (var i = Math.min(si, ei) + 1; i <= Math.max(si, ei) + 1; i++) {
      for (var j = Math.min(sj, ej); j <= Math.max(sj, ej); j++) {
        sheet = sheet.setIn([i, j, 'value'], '');
      }
    }
    this.setDataState({
      columns: sheet.get(0),
      rows: sheet.shift(1)
    });
  }
}

export const stringToNumber = (value) => {
  return typeof value === 'string' ? Number(value.replaceAll(',', '')) : value;
}

export default Data;