import { Pencil1Icon, TrashIcon, EyeNoneIcon } from '@radix-ui/react-icons';

export function getSectionOptions({ controller, item }) {
  let isHidden = item.isHidden;

  return ([
    ['Edit', Pencil1Icon, () => controller.popups.editSection(item)],
    ['Delete', TrashIcon, () => controller.layout.deleteSection(item.id)],
    [
      !isHidden ? 'Hide this section' : 'Show this section',
      !isHidden ? EyeNoneIcon : '',
      () => controller.layout.editSection(item.id, { isHidden: !isHidden })]
  ]);
}