import { Button, Buttons } from 'lib/inputs/buttons';
import { Button as AriaButton } from 'react-aria-components';
import {
  FileIcon, CardStackIcon,
  CounterClockwiseClockIcon, CheckIcon,
  ArrowLeftIcon, ArrowRightIcon, DotIcon, DotFilledIcon,
  ExclamationTriangleIcon
} from '@radix-ui/react-icons';
import Loader from 'lib/staticPages/Loader';
import { TooltipTrigger, Tooltip } from 'react-aria-components';

export default function List({ popup }) {
  const
    { state } = popup,
    files = Object.values(state.get('files')),
    remoteIndex = state.get('remoteIndex'),
    isEmpty = files.length === 0;

  return (
    <div className="">
      <div className="my-4 flex items-center">
        <div className="font-mono text-sm flex-grow">{remoteIndex.docPath}</div>
      </div>
      <div className="py-2 px-4 border h-[18rem] overflow-auto">
        <div className="">
          { isEmpty && (
            <div className="text-sm text-center pt-2 text-pencil3">
              There are no local files in this article.
            </div>
          ) }

          <div className="flex text-sm font-medium text-center text-pencil">
            <div className="flex-grow"></div>
            <div className="w-14">
              Article
            </div>
            <div className="w-14">
              Machine
            </div>
            <div className="w-6"></div>
          </div>
          <>
            {[
              ['Document Files', files.filter(file => !file.location && !file.isPulled)],
              ['Upload Files', files.filter(file => file.location)],
              ['Files to pull from machine', files.filter(file => !file.location && file.isPulled)]
            ].map(([title, list], i ) => (
              <div key={i}>
                { list.length !== 0 && <Title>{title}</Title> }
                { list.map((file, i) => (
                  <File
                    key={i}
                    name={file.filename}
                    left={file.content !== null}
                    right={(remoteIndex.files[file.filename] || {}).exists}
                    direction={file.isPulled ? -1 : 1}
                    popup={popup}
                    status={state.getIn(['fileStatus', file.filename])}
                    errorMessage={state.getIn(['fileErrors', file.filename])} />
                )) }
              </div>
            ))}
          </>
        </div>
      </div>

      <Buttons className="text-right">
        <Button onPress={popup.browseFiles}>
          Browse Machine Files
        </Button>

        <Button onPress={popup.tryLoad}>
          Refresh
        </Button>

        { state.get('isSynced') ? (
          <Button onPress={popup.close}>
            Done
          </Button>
        ) : (
          <Button onPress={popup.syncAll} isDisabled={state.get('isSyncing')}>
            { state.get('isSyncing') ? 'Syncing' : 'Sync' }
          </Button>        
        ) }
      </Buttons>
    </div>
  )
}

const Title = (props) => <div className="text-sm font-medium mt-3 mb-1" {...props} />

const File = ({ isFolder, name, children, left, right, direction, status, popup, errorMessage }) => (
  <div className="font-mono text-sm flex my-1 py-0.5 gap-2">
    <div className="flex-grow flex gap-2 items-center">
      {isFolder ?
        <CardStackIcon className="inline" /> :
        <FileIcon className="inline" />
      }
      {name}
    </div>

    <div className="flex text-center items-center">
      <div className="w-10">
        {
          left === true ? <DotFilledIcon className="inline" /> :
          left === false ? <DotIcon className="inline" /> :
          ''
        }
      </div>

      <div className="w-8">
        { direction === 1 && <ArrowRightIcon className="inline" /> }
        { direction === -1 && <ArrowLeftIcon className="inline" /> }
      </div>

      <div className="w-10">
        { status === 'syncing' ? (
          <Loader />
        ) : right === true ? (
          <DotFilledIcon className="inline group-hover:hidden" />
        ) : direction === 1 ? (
          <DotIcon className="inline" />
        ) : ''}
      </div>

      <div className="w-6 flex">
        {
          status === 'not-synced' ? (<CounterClockwiseClockIcon />) :
            status === 'synced' ? (<CheckIcon />) :
              status === 'error' ? (<Error message={errorMessage} />):
                ''
        }
      </div>
    </div>
  </div>
);


function Error({ message }) {
  return (
    <TooltipTrigger delay={0} >
      <AriaButton className="cursor-default inline outline-none">
        <ExclamationTriangleIcon className="text-red-500" />
      </AriaButton>
      <Tooltip placement="bottom" className="text-xs bg-pencil text-paper p-1">{message}</Tooltip>
    </TooltipTrigger>
  )
}