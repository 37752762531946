import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';
import { markdownToState, stateToMarkdown } from 'lib/prosemirror/markdown/helpers';
import { htmlToState, stateToHtml } from 'lib/prosemirror/helpers';

import EditorController from 'lib/prosemirror/Controller';
import LinkSelect from 'lib/popup/popups/LinkSelect/Controller';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 580 });

  constructor(args, manager) {
    super(args, manager);
    this.editorController = new EditorController({
      openLinkPopup: this.openLinkPopup,
      viewState: (args.format === 'html' ? htmlToState : markdownToState)(args.text),
    });
  }
  
  openLinkPopup = (args) => {
    this.manager.open(LinkSelect, args, this.sessionId);
  }
  
  async handleSubmit() {
    this.setState({ loading: true });
    try {
      let text = (this.args.format === 'html' ? stateToHtml : stateToMarkdown)(this.editorController.viewState);

      if (this.args.format === 'html' && text === '<p class="text-left"></p>')
        text = '';
      
      if (this.args.onSave) {
        await this.args.onSave(text);
      }
      this.setState({ loading: false, text: text });
      this.close();
    } catch (e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }

  close() {
    // if (this.state.get('text') !== this.editorController.getValue()) {
    //   if (!window.confirm('Are you sure you want to close this popup? The changes aren\'t saved')) {
    //     return;
    //   }
    // }
    BasePopupController.prototype.close.call(this, null);
  }

  handleValueFn(fn) {
    this.valueFn = fn;
  }
}

export default Controller;