export function SidebarIcon({ className }) {
  return (
    <svg
      width="15" height="15"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M 10 1 L 13.5 1 C 13.776 1 14 1.223 14 1.5 L 14 11.5 C 14 11.776 13.776 12 13.5 12 L 10 12 L 10 1 Z M 7 1 L 1.5 1 C 1.224 1 1 1.223 1 1.5 L 1 11.5 C 1 11.776 1.224 12 1.5 12 L 9 12 L 9 1 L 7 1 Z M 0 1.5 C 0 0.6709999999999996 0.6719999999999996 0 1.5 0 L 13.5 0 C 14.329 0 15 0.6709999999999996 15 1.5 L 15 11.5 C 15 12.328 14.329 13 13.5 13 L 1.5 13 C 0.6719999999999996 13 0 12.328 0 11.5 L 0 1.5 Z"
        fill="currentColor" 
        fillRule="evenodd"
        clipRule="evenodd"
        transform="matrix(1, 0, 0, 1, 0, -1.7763568394002505e-15)"/>
    </svg>
  )  
}


export function DiskIcon({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path d="M3.83333 2V4.75C3.83333 5.25626 4.24374 5.66667 4.75 5.66667H9.33333C9.83959 5.66667 10.25 5.25626 10.25 4.75V2M2 2.91667V12.0833C2 12.5896 2.41041 13 2.91667 13H12.0833C12.5896 13 13 12.5896 13 12.0833V4.02969C13 3.78658 12.9034 3.55342 12.7315 3.38151L11.6185 2.26849C11.4466 2.09658 11.2134 2 10.9703 2H2.91667C2.4104 2 2 2.41041 2 2.91667ZM9.33333 9.33333C9.33333 10.3459 8.51252 11.1667 7.5 11.1667C6.48748 11.1667 5.66667 10.3459 5.66667 9.33333C5.66667 8.32081 6.48748 7.5 7.5 7.5C8.51252 7.5 9.33333 8.32081 9.33333 9.33333Z" stroke="black" strokeLinejoin="round"/>
    </svg>    
  )
}

export function Disk2Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      className={className}>
      <path d="M2 12.0833V2.91667C2 2.41041 2.4104 2 2.91667 2H10.9703C11.2134 2 11.4466 2.09658 11.6185 2.26849L12.7315 3.38151C12.9034 3.55342 13 3.78658 13 4.02969V12.0833C13 12.5896 12.5896 13 12.0833 13H2.91667C2.41041 13 2 12.5896 2 12.0833Z" stroke="black" strokeLinejoin="round"/>
      <path d="M5 13V9H10V11V13" stroke="black" strokeLinejoin="round"/>
    </svg>
  )
}


export function Laptop2Icon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 4.25C2 4.11193 2.11193 4 2.25 4H12.75C12.8881 4 13 4.11193 13 4.25V11.5H2V4.25ZM2.25 3C1.55964 3 1 3.55964 1 4.25V12H0V12.5C0 12.7761 0.223858 13 0.5 13H14.5C14.7761 13 15 12.7761 15 12.5V12H14V4.25C14 3.55964 13.4404 3 12.75 3H2.25Z" fill="black"/>
      <path d="M6 6.5L4 8L6 9.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 6.5L11 8L9 9.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}