import { FONT_SIZE_OPTIONS, LINE_SPACING_OPTIONS } from 'modules/theme/defaults/fonts';
import { getGetOptionsFnWithDefaults } from 'modules/elements/lib/helpers';

const settingsFields = {
  alignment: {
    caption: 'Alignment', input: 'text', init: null
  },

  spellCheck: {
    caption: 'Spell Check',
    input: 'checkbox',
    init: true,
  },

  fontSize: {
    caption: 'Font Size',
    input: 'options',
    init: null,
    getOptions: getGetOptionsFnWithDefaults(FONT_SIZE_OPTIONS),
    themeKey: 'fontSize'
  },

  lineSpacing: {
    caption: 'Line Spacing',
    input: 'options',
    init: null,
    getOptions: getGetOptionsFnWithDefaults(LINE_SPACING_OPTIONS),
    themeKey: 'lineSpacing'
  },

  css: {
    caption: 'CSS Style',
    input: 'options',
    init: '',
    options: [
      { caption: 'None', key: '' },
      { caption: 'Gray', key: 'background: #f0f0f0;\npadding: 1rem;\n border-left: 0.5rem solid #666;\ncolor:black;' },
    ]
  },

  columns: {
    caption: 'Columns',
    input: 'options',
    init: null,
    options: [
      { caption: 'One', key: null },
      { caption: 'Two', key: 2 },
      { caption: 'Three', key: 3 },
    ]
  }  
}


export const initialSettings = {};
for (let k in settingsFields) {
  initialSettings[k] = settingsFields[k].init;
}

export default settingsFields;