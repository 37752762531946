import { useEffect } from 'react';
import Body from './Body';
import { useControllerState } from 'lib/BaseController';
import Loader from 'lib/staticPages/Loader';

import { Cross1Icon } from '@radix-ui/react-icons';
import { Button } from 'react-aria-components';

export default function Comments({ viewer, close }) {
  const
    { workspace, currentArticle, comments } = viewer,
    { domain }= workspace,
    articleId = viewer.articleId,
    domainId = domain.id,
    provider = viewer.provider,
    state = useControllerState(comments),

    allowComments = domain.allowComments || {},
    isDisabled = (articleId &&  !allowComments.articles) || (!articleId && !allowComments.workspace),
    sortBy = comments.state.get('sortBy');

  useEffect(
    () => comments.load({ articleId, domainId, provider }),
    [articleId, domainId, provider, comments, sortBy]
  );

  return domain && (
    <div className="p-8 h-full flex flex-col overflow-auto">
      <div className="text-lg text-center mb-2 font-medium border-b border-rim pb-8 relative">
        Comments for <span>
          {domain.title} {currentArticle ? (<span className="text-pencil3"> - {currentArticle.article.title}</span>) : ''}
        </span>

        <div className="absolute top-0 right-6">
          <Button onPress={close}>
            <Cross1Icon />
          </Button>
        </div>
      </div>

      {
        isDisabled ?
          <CommentsDisabled articleId={articleId} /> :
          state.get('error') ?
            <ErrorScreen error={state.get('error')} /> :
            (state.get('loading') || state.get('list') === null) ?
              <LoadingScreen /> :
              <Body controller={comments} viewer={viewer} />
      }
    </div>
  )
}

function CommentsDisabled({ articleId }) {
  return (
    <div className="text-center pt-8 text-pencil3">
      Comments are disabled for this {articleId ? 'article' : 'workspace'}.
    </div>
  );
}


function LoadingScreen() {
  return (<div className="p-4 text-center"><Loader /></div>)
}

function ErrorScreen({ error }) {
  return (
    <div className="p-4 text-center text-error text-sm font-medium">
      Error loading comments
    </div>
  )
}