import BaseElementController from 'modules/elements/lib/BaseElementController';
import { getMode, getMimeType } from 'lib/codemirror/languages';
import { validateFilename } from 'lib/tools.ts';


class Controller extends BaseElementController {
  editFilenamePopup = ({ allowEmpty }) => {
    const filename = this.getSettingsValue('filename');

    this.openPopup('prompt', {
      title: 'Rename File',
      inputs: [{ label: 'Filename', type: 'text', defaultValue: filename, autoFocus: true }],
      submitLabel: 'Save',
      onSubmit: ([name]) => {
        if (name === filename)
          return;
        if (!name && !allowEmpty) {
          throw new Error('The name cannot be empty.');
        }
        const invalid = this.validateFilename(name);
        if (invalid)
          throw new Error(invalid);
        this.setSettings('filename', name);
      }
    });
  }

  deletePrompt = () => {
    this.openPopup('prompt', {
      title: 'Delete this file?',
      question: (
        <>
          Are you sure you want to delete
          &nbsp;<span className="font-medium">{this.state.getIn(['settings', 'filename'])}</span>?
        </>
      ),
      onSubmit: this.deleteSelf,
      submitLabel: 'Yes'
    });
  }

  getSuggestedMode() {
    return getMode(this.getSettingsValue('filename'));
  }

  getSuggestedTabMode() {
    const filename = this.getSettingsValue('filename');
    return (filename && filename.endsWith('py')) ? '4s' : '2s';
  }

  validateFilename = (filename) => {
    const invalid = validateFilename(filename);
    if (invalid)
      return invalid;
    if (this.getLocalFiles().getFilenames({ ignoreId: this.elementId }).has(filename))
      return 'Filename already exists.';
    return false;
  }

  handleFilenameChange = () => {

  }

  get elementFilenames() {
    return this.getSettingsValue('filename') ? new Set([this.getSettingsValue('filename')]) : null;
  }

  getElementFile(filename) {
    if (filename !== this.getSettingsValue('filename'))
      return;
    
    return ({
      filename: filename,
      version: this.getVersion(),
      getContent: () => this.state.get('content'),
      isBase64: this.getSettingsValue('isBase64'),
      isPulled: this.getSettingsValue('isPulled'),
      contentType: this.getSettingsValue('contentType') || getMimeType(filename)
    });
  }
}

export default Controller;