import LogoButton from './LogoButton';
import Titles from './Titles';

import PageButtons from './PageButtons';
import ArticleButtons from './ArticleButtons';
import MachineButton from './MachineButton';
import PanelButtons from './PanelButtons';
import MenuButton from './MenuButton';
import { Break } from './res/components';


export default function BarHorizontal({ editor }) {
  return (
    <div className="flex w-full border-b border-rim bg-paper py-3">
      <div className="flex-grow flex overflow-hidden pl-6 gap-2">
        <LeftGroup editor={editor} />
      </div>

      <div className="flex items-center pr-6 gap-2 px-4">
        <RightGroup editor={editor} />
      </div>
    </div>
  )
}

export function LeftGroup({ editor }) {
  return (
    <>
      <div className="flex-shrink-0">
        <LogoButton editor={editor} />
      </div>
      <Titles editor={editor} />    
    </>
  )
}

export function RightGroup({ editor, verticle }) {
  return (
    <>
      <PageButtons editor={editor} />
      <Break verticle={verticle} />
      <PanelButtons panels={editor.panels} />
      <Break verticle={verticle} />
      <ArticleButtons editor={editor} />
      <MachineButton machine={editor.app.machine} editor={editor} withBreak={true} verticle={verticle} />
      <Break verticle={verticle} />
      <MenuButton editor={editor} />
    </>
  )
}