import { Button, Buttons } from 'modules/elements/lib/sidebar/inputs/buttons';
import { Break, Block } from 'modules/elements/lib/sidebar/inputs/components';
import RichTextInput from 'modules/elements/lib/sidebar/inputs/RichTextInput';
import Selection from 'modules/elements/lib/sidebar/inputs/Selection';
import Menu from 'modules/elements/lib/sidebar/inputs/Menu';
import { faTrash, faBackwardStep, faForwardStep } from '@fortawesome/free-solid-svg-icons';

import clsx from 'clsx';


export default function Entries({ controller }) {
  const
    { state, updateEntry, setState, deleteEntry, addEntry, moveEntry, getLocalFiles } = controller,
    entries = state.get('entries'),
    at = state.get('at'),
    entry = entries.get(at);

  return (
    <>
      {entries.size > 1 && (
        <div className="py-3 border-b border-rim flex">
          <div className="flex-grow">
            <EntrySelector
              size={entries.size}
              selectedIndex={at}
              onSelectIndex={at => setState({ at })} />          
          </div>

          <Menu
            tooltip="Entry Options"
            options={[
              [faTrash, 'Delete Entry', () => deleteEntry(at)],
              [faBackwardStep, 'Move Left', () => moveEntry(at, -1), at === 0],
              [faForwardStep, 'Move Right', () => moveEntry(at, 1), at === entries.size - 1],
            ]} />

        </div>
      ) }

      { entry && (
        <>
          <Block>
            <Selection
              label="Filename"
              defaultValue={''}
              value={entry.get('filename')}
              options={
                Object.values(getLocalFiles().getAllFiles())
                  // .getFilenames({ startsWith: 'image' })
                  .filter(file => file.contentType && file.contentType.startsWith('image'))
                  .map(e => ({ key: e.filename, caption: e.filename }))
                  .concat([
                    { type: 'break' },
                    { key: '', caption: 'Not Selected' }
                  ])
              }
              onChange={v => updateEntry(at, 'filename', v)} />
          </Block>

          <RichTextInput
            caption="Caption"
            value={entry.get('caption')}
            onClick={_ => controller.editCaption(at)} />
        </>
      ) }

      { !entry && (
        <div className="text-center">
          <Block>
            <i>Image Not Added</i>
          </Block>
        </div>
      ) }

      <Break />

      <Block>
        <Buttons className="text-center">
          <Button onPress={() => addEntry() } icon="Plus">
            &nbsp;Add an Image
          </Button>        
        </Buttons>
      </Block>
    </>
  );
}

function EntrySelector({ size, selectedIndex, onSelectIndex }) {  
  return (Array(size).fill(0).map((z, i) => (
    <button
      key={i}
      className={clsx(
        'inline px-1 ml-2 py-0 text-sm font-bold',
        selectedIndex === i ? 'text-marker3 border-b-2 border-marker3' : 'text-pencil3 hover:text-pencil'
      )}
      onMouseDown={e => {
        onSelectIndex(i)
      }}>
      {i + 1}
    </button>
  )));
}