import MenuBuilder from 'lib/components/MenuBuilder';
import { useState } from 'react';
import { UploadIcon, PlusIcon, TrashIcon, ImageIcon, Pencil2Icon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';


function Menu({ controller }) {
  const
    at = controller.state.get('at'),
    [open, setOpen] = useState(false);

  return !controller.isReadOnly && (
    <MenuBuilder
      className={clsx(
        'absolute top-1 right-1',
        open ? '' : 'hidden group-hover:block'
      )}
      options={[
        ['Upload Image', UploadIcon, _ => controller.uploadMediaFilePopup()],
        ['Add New Image', PlusIcon, _ => controller.addEntry()],
        ['Delete This Image', TrashIcon, _ => controller.deleteFrameConfirm(at)],
        ['Select Image', ImageIcon, _ => controller.selectImagePopup()],
        ['Edit Caption', Pencil2Icon, _ => controller.editCaption(at)]      
      ]}
      isOpen={open}
      onOpenChange={setOpen}>
      <div className="bg-wax2 rounded-sm p-1">
        <HamburgerMenuIcon />
      </div>
    </MenuBuilder>
  )
}

export default Menu;