import React, { useState, useEffect } from 'react';

import p from 'lib/popup/components/ui';
import { TextInput, TextAreaInput, Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';

function Popup({ popup }) {
  let
    [state, setState] = useState(popup.state),
    loading = state.get('loading');

  useEffect(_ => popup.emitter.on('change', _ => setState(popup.state)), [popup]);

  return (
    <p.Popup popup={popup} className="change-info-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>
            User Info
          </p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          <form onSubmit={e => { e.preventDefault(); popup.save(); }}>
            <Group>
              <TextInput
                autoFocus={true}
                label="Display Name"
                value={state.get('name')}
                onChange={v => popup.setState({ name: v})}
                isDisabled={loading}
                spellCheck={false} />

              <TextInput
                label="URL"
                value={state.get('url')}
                onChange={v => popup.setState({ url: v})}
                isDisabled={loading} />

              <TextAreaInput
                label="Bio"
                value={state.get('bio')}
                onChange={v => popup.setState({ bio: v})}
                isDisabled={loading} />
            </Group>

            <p.Alert />

            <Buttons className="text-right">
              <Button type="submit" isDisabled={loading}>
                Save
              </Button>
              <Button isDisabled={loading} onPress={_ => popup.close()}>
                Cancel
              </Button>
            </Buttons>
          </form>
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

export default Popup;