import {
  Pencil1Icon, PlayIcon, ChevronRightIcon, CheckIcon
} from '@radix-ui/react-icons';


export function getOptions(controller) {
  const
    { getSettingsValue, toggleSettings, setSettings, webFrame, isReadOnly } = controller,
    frameState = webFrame.state,
    gsv = getSettingsValue,
    menuBg = gsv('menuBg'),
    invertedColor = gsv('invertedColor'),
    hasNewLogs = frameState.get('hasNewLogs'),
    hasNewErrors = frameState.get('hasNewErrors'),
    showConsole = frameState.get('showConsole');

  const result = [
    ['Run ', PlayIcon, () => controller.run(), { isDisabled: !gsv('target') }],
    [ 
      showConsole ? 'Hide Console' : 'Show Console',
      ChevronRightIcon,
      () => controller.webFrame.toggleConsole(),
      { notification: hasNewErrors ? 'alert': hasNewLogs ? 'normal' : '' }],
  ];

  if (isReadOnly)
    return result;

  result.push(
    [],
    ['Edit Target and Title', Pencil1Icon, () => controller.editTarget()],
    ['Display Target', gsv('showTarget') ? CheckIcon : '', () => toggleSettings('showTarget')],
    ['Display Border', gsv('hasBorder') ? CheckIcon : '', () => toggleSettings('hasBorder')],
    ['Display Run Button', gsv('showRun') ? CheckIcon : '', () => toggleSettings('showRun')],
    [],
    [
      'Bar Menu Layout',
      gsv('menuPosition') === 'topbar' ? CheckIcon : '',
      () => setSettings('menuPosition', 'topbar')
    ],
    [
      'Floating Menu Layout',
      gsv('menuPosition') === 'floating' ? CheckIcon : '',
      () => setSettings('menuPosition', 'floating')
    ],
  );

  if (gsv('layout') === 'dynamic') {
    result.push(
      [],
      ['Auto Height', gsv('autoHeight') ? CheckIcon : '', () => toggleSettings('autoHeight')],
    )
  }

  if (gsv('menuPosition') === 'floating')
    result.push(
      [],
      ['Opaque Menu Background', menuBg ? CheckIcon : '', () => { setSettings('menuBg', !menuBg) } ],
      ['Invert Menu Color', '', () => { setSettings('invertedColor', !invertedColor) } ]
    )

  return result;
}