import p from 'lib/popup/components/ui';
import './style.css'

function Popup({ popup }) {
  return (
    <p.Popup popup={popup} className="coming-soon-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>
            Coming Soon
          </p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          <div className="message">
            This feature is coming soon!          
          </div>
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

export default Popup;