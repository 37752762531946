export const FONTS = {
  'Alegreya': `'Alegreya', serif`,
  'Alegreya+Sans': `'Alegreya Sans', sans-serif`,
  'Archivo+Narrow': `'Archivo Narrow', sans-serif`,
  'BioRhyme': `'BioRhyme', serif`,
  'Cardo': `'Cardo', serif`,
  'Chivo': `'Chivo', sans-serif`,
  'Cormorant': `'Cormorant', serif`,
  'DM+Sans': `'DM Sans', sans-serif`,
  'Eczar': `'Eczar', serif`,
  'EB Garamond': `EB+Garamond, serif`,
  'Fira+Sans': `'Fira Sans', sans-serif`,
  'IBM+Plex+Sans': `'IBM Plex Sans', sans-serif`,
  'Inconsolata': `'Inconsolata', monospace`,
  'Inknut+Antiqua': `'Inknut Antiqua', serif`,
  'Inter': `'Inter', sans-serif`,
  'Karla': `'Karla', sans-serif`,
  'Lato': `'Lato', sans-serif`,
  'Libre+Baskerville': `'Libre Baskerville', serif`,
  'Libre+Franklin': `'Libre Franklin', sans-serif`,
  'Lora': `'Lora', serif`,
  'Manrope': `'Manrope', sans-serif`,
  'Merriweather': `'Merriweather', serif`,
  'Montserrat': `'Montserrat', sans-serif`,
  'Neuton': `'Neuton', serif`,
  'Open+Sans': `'Open Sans', sans-serif`,
  'PT+Sans': `'Playfair Display', serif`,
  'PT+Serif': `'Poppins', sans-serif`,
  'Playfair+Display': `'Proza Libre', sans-serif`,
  'Poppins': `'PT Sans', sans-serif`,
  'Quicksand': `'Quicksand', sans-serif`,
  'Proza+Libre': `'PT Serif', serif`,
  'Raleway': `'Raleway', sans-serif`,
  'Roboto': `'Roboto', sans-serif`,
  'Roboto+Slab': `'Roboto Slab', serif`,
  'Rubik': `'Rubik', sans-serif`,
  'Sofia+Sans': `'Sofia+Sans', sans-serif`,
  'Source+Sans+3': `'Source Sans 3', sans-serif`,
  'Source+Sans+Pro': `'Source Sans Pro', sans-serif`,
  'Source+Serif+Pro': `'Source Serif Pro', serif`,
  'Space+Grotesk': `'Space Grotesk', sans-serif`,
  'Space+Mono': `'Space Mono', monospace`,
  'Spectral': `'Spectral', serif`,
  'Syne': `'Syne', sans-serif`,
  'Work+Sans': `'Work Sans', sans-serif`
}

export const MONOSPACE_FONTS = {
  'System': `monospace`,
  'Fira+Code': `'Fira Code', monospace`,
  'IBM+Plex+Mono': `'IBM Plex Mono', monospace`,
  'Inconsolata': `'Inconsolata', monospace`,
  'Oxygen+Mono': `'Oxygen Mono', monospace`,
  'PT+Mono': `'PT Mono', monospace`,
  'Roboto+Mono': `'Roboto Mono', monospace`,
  'Source+Code+Pro': `'Source Code Pro', monospace`,
  'Space+Mono': `'Space Mono', monospace`,
  'Ubuntu+Mono': `'Ubuntu Mono', monospace`
}

export const CURSIVE_FONTS = {
  'Arizonia': `'Arizonia', cursive`,
  'Lobster': `'Lobster', cursive`,
  'Rouge+Script': `'Rouge Script', cursive`,
  'Pacifico': `'Pacifico', cursive`,
  'Alex+Brush': `'Alex Brush', cursive`,
  'Kaushan+Script': `'Kaushan Script', cursive`,
  'Sail': `'Sail', cursive`,
  'Sacramento': `'Sacramento', cursive`,
  'Marck+Script': `'Marck Script', cursive`,
  'Sonsie+One': `'Sonsie One', cursive`,
  'Monsieur+La+Doulaise': `'Monsieur La Doulaise', cursive`,
  'Rock+Salt': `'Rock Salt', cursive`,
  'Rochester': `'Rochester', cursive`,
  'Rancho': `'Rancho', cursive`,
  'Croissant+One': `'Croissant One', cursive`,
  'Yesteryear': `'Yesteryear', cursive`,
  'Courgette': `'Courgette', cursive`,
  'Sofia': `'Sofia', cursive`,
  'Allura': `'Allura', cursive`,
  'Redressed': `'Redressed', cursive`,
  'Cookie': `'Cookie', cursive`,
  'Amita': `'Amita', cursive`,
  'Meie+Script': `'Meie Script', cursive`,
  'Euphoria+Script': `'Euphoria Script', cursive`,
  'Tangerine': `'Tangerine', cursive`,
  'Sevillana': `'Sevillana', cursive`,
  'Cedarville+Cursive': `'Cedarville Cursive', cursive`
}



export const FONT_OPTIONS = Object.keys(FONTS).map(
  key => ({ key: key, caption: key.replaceAll('+' , ' ') })
);

export const CURSIVE_FONT_OPTIONS = Object.keys(CURSIVE_FONTS).map(
  key => ({ key: key, caption: key.replaceAll('+' , ' ') })
);

export const FONT_SIZES = {
  12: '12 Tiny',
  13: '13',
  14: '14 Small',
  15: '15',
  16: '16 Normal',
  17: '17',
  18: '18 Large',
  19: '19',
  20: '20 XL',
}

export const FONT_SIZE_OPTIONS =  Object.keys(FONT_SIZES).map(
  key => ({ key: key, caption: FONT_SIZES[key] })
);

export const LINE_SPACINGS = {
  '1.2': '1.2 Tight',
  '1.3': '1.3',
  '1.4': '1.4',
  '1.5': '1.5 Normal',
  '1.6': '1.6',
  '1.7': '1.7',
  '1.8': '1.8 Large', 
}

export const FONT_WEIGHTS = {
  '300': 'Light',
  '400': 'Normal',
  '500': 'Medium',
  '600': 'Bold'
}

export const FONT_WEIGHT_OPTIONS = Object.keys(FONT_WEIGHTS).map(
  key => ({ key: key, caption: FONT_WEIGHTS[key] })
);

export const LINE_SPACING_OPTIONS = Object.keys(LINE_SPACINGS).map(
  key => ({ key: key, caption: LINE_SPACINGS[key] })
);

export const MONOSPACE_FONT_OPTIONS = Object.keys(MONOSPACE_FONTS).map(
  key => ({ key: key, caption: key.replaceAll('+' , ' ') })
);