import { useEffect, useState } from 'react';
import { onBookmarks, deleteBookmark, moveToTopBookmark } from 'api/remote/calls/user';
import { Link } from 'react-aria-components';
import { BookmarkIcon, StarIcon, OpenInNewWindowIcon, TrashIcon, ArrowUpIcon, ClipboardCopyIcon } from '@radix-ui/react-icons';
import MenuBuilder from 'lib/components/MenuBuilder';


export default function Bookmarks({ app }) {
  const
    user = app.auth.user,
    [bookmarks, setBookmarks] = useState(null);

  useEffect(() => {
    if (!user) return;
    return onBookmarks({ userId: user.uid }, setBookmarks);
  }, [user])

  return (
    <div className="p-8">
      <div className="font-medium mb-4 text-center text-2xl">My Bookmarks</div>

      <hr className="mb-4" />
      { bookmarks === null && '...' }
      { bookmarks !== null && (
        <div className="flex gap-2 flex-col">
          { bookmarks.map((bookmark, i) => (
            <Bookmark
              index={i}
              key={bookmark.id}
              bookmark={bookmark}
              userId={user.uid} />)
          )}
        </div>
      )}
      { bookmarks !== null &&  bookmarks.length === 0 && (
        <div className="text-center text-sm">You have no saved bookmarks</div>
      ) }
    </div>
  )
}

function Bookmark({ bookmark, refresh, userId, index }) {
  const itemId = bookmark.id,
    path = '/' + bookmark.path.replaceAll('\\', '/');

  return (
    <div className="flex items-center gap-3 border py-2 px-4 ">
      { (bookmark.type === 'article' || bookmark.type === 'version') && <BookmarkIcon className="inline" /> }
      { bookmark.type === 'folder' && <StarIcon className="inline" /> }
      <div className="flex-grow">
        <Link href={path} target="_blank">{bookmark.title}</Link>
      </div>

      <div>
        <MenuBuilder options={[
          ['Open', OpenInNewWindowIcon, () => {}, { href:path, target: '_blank' }],
          ['Move to Top', ArrowUpIcon, () => moveToTopBookmark({ itemId, userId }), { isDisabled: index === 0 }],
          ['Copy URL',ClipboardCopyIcon , () => window.navigator.clipboard.writeText(`${window.origin}${path}`)],
          [],
          ['Delete', TrashIcon, () => deleteBookmark({ userId, itemId })]
        ]} />
      </div>
    </div>
  )
}