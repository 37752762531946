import { Block, Label } from 'modules/elements/lib/sidebar/inputs/components';
import { Button, Buttons } from 'modules/elements/lib/sidebar/inputs/buttons';

import { schema } from 'lib/prosemirror/schema.ts';
import { isNodeActive } from 'lib/prosemirror/helpers';
import { wrapInList } from 'prosemirror-schema-list';
import { setBlockType, lift } from 'prosemirror-commands';

import {
  faBold, faItalic, faUnderline, faStrikethrough, faHighlighter,
  faCode, faSignature, faSuperscript, faSubscript, faAlignLeft,
  faAlignCenter, faAlignRight, faAlignJustify,
  faListOl, faListUl, faLeftLong
} from '@fortawesome/free-solid-svg-icons';

function Toolbar({ controller }) {
  const props = { controller };

  return (
    <>
      <Block>
        <Label>Style</Label>
        <Buttons>
          <InlineStyleButton {...props} tooltip="Bold" faIcon={faBold} mark={schema.marks.strong} />
          <InlineStyleButton {...props} tooltip="Italic" faIcon={faItalic} mark={schema.marks.em} />
          <InlineStyleButton {...props} tooltip="Underline" faIcon={faUnderline} mark={schema.marks.underline} />
          <InlineStyleButton {...props} tooltip="Strikethrough" faIcon={faStrikethrough} mark={schema.marks.strikethrough} />
          <InlineStyleButton {...props} tooltip="Highlight" faIcon={faHighlighter} mark={schema.marks.highlight} />
          <InlineStyleButton {...props} tooltip="Code" faIcon={faCode} mark={schema.marks.code} />
          <InlineStyleButton {...props} tooltip="Cursive" faIcon={faSignature} mark={schema.marks.cursive} />
          <InlineStyleButton {...props} tooltip="Superscript" faIcon={faSuperscript} mark={schema.marks.sup} />
          <InlineStyleButton {...props} tooltip="Subscript" faIcon={faSubscript} mark={schema.marks.sub} />
        </Buttons>
      </Block>

      <Block>
        <Label>Alignment</Label>
        <Buttons>
          <BlockAttrButton {...props} tooltip="Left" faIcon={faAlignLeft} attrKey="align" attrValue="left" />
          <BlockAttrButton {...props} tooltip="Center" faIcon={faAlignCenter} attrKey="align" attrValue="center" />
          <BlockAttrButton {...props} tooltip="Right" faIcon={faAlignRight} attrKey="align" attrValue="right" />
          <BlockAttrButton {...props} tooltip="Justify" faIcon={faAlignJustify} attrKey="align" attrValue="justify"
            className="text-justify" /> {/* adding className so tailwind picks it up. */}
        </Buttons>
      </Block>

      <Block>
        <Label>Block Format</Label>
        <Buttons>
          <BlockButton {...props} tooltip="Normal" text="Normal" nodeType={schema.nodes.paragraph} />
          <BlockButton {...props} tooltip="Header 1" text="H1" nodeType={schema.nodes.heading} attrs={{ level: 1 }} />
          <BlockButton {...props} tooltip="Header 2" text="H2" nodeType={schema.nodes.heading} attrs={{ level: 2 }} />
          <BlockButton {...props} tooltip="Header 3" text="H3" nodeType={schema.nodes.heading} attrs={{ level: 3 }} />
          <BlockButton {...props} tooltip="Header 4" text="H4" nodeType={schema.nodes.heading} attrs={{ level: 4 }} />
          <BlockButton {...props} tooltip="Header 5" text="H5" nodeType={schema.nodes.heading} attrs={{ level: 5 }} />
          <BlockButton {...props} tooltip="Code" faIcon={faCode} nodeType={schema.nodes.code_block} />
        </Buttons>
      </Block>

      <Block>
        <Label>List Format</Label>
        <Buttons>
          <BlockButton
            controller={controller}
            tooltip="Ordered List"
            faIcon={faListOl}
            command={wrapInList(schema.nodes.ordered_list, null)}
            nodeType={schema.nodes.ordered_list} />

          <BlockButton
            controller={controller}
            tooltip="Unordered List"
            faIcon={faListUl}
            type="unordered-list-item"
            command={wrapInList(schema.nodes.bullet_list, null)}
            nodeType={schema.nodes.bullet_list} />

          <BlockButton
            controller={controller}
            tooltip="Lift"
            faIcon={faLeftLong}
            command={lift} />
        </Buttons>
      </Block>
    </>
  );
}

export default Toolbar;


function InlineStyleButton({ faIcon, tooltip, mark, controller }) {
  const active = controller.editorController.isMarkActive(mark);

  return (
    <Button
      faIcon={faIcon}
      isActive={active}
      tooltip={tooltip}
      onPress={_ => controller.editorController.toggleMark(mark)} />
  )
}


export const getBlockButtonProps = ({ controller, nodeType, attrs, command }) => {
  const { view, viewState } = controller.editorController;
  let { $from, to, node } = viewState.selection;
  node = node || (to <= $from.end() && $from.parent);
  command = command || setBlockType(nodeType, Object.assign({}, node.attrs, attrs))
  const isActive = nodeType && isNodeActive(nodeType, viewState, attrs);

  return {
    isActive: isActive,
    isDisabled: !command(viewState),
    fn: () => {if (!isActive) command(viewState, view.dispatch); }
  }
}


function BlockButton({ faIcon, text, command, controller, nodeType, attrs, tooltip }) {
  const { isActive, isDisabled, fn } = getBlockButtonProps({ controller, nodeType, attrs, command });

  return (
    <Button
      isActive={isActive}
      isDisabled={isDisabled}
      faIcon={faIcon}
      tooltip={tooltip}
      onPress={fn}>
      {text}
    </Button>
  )
}

export const getBlockAttrButtonProps = ({ controller, attrKey, attrValue }) => {
  const { view, viewState } = controller.editorController;
  let { $from, to, node } = viewState.selection;
  node = node || (to <= $from.end() && $from.parent);

  const
    isEnabled = node && node.attrs && node.attrs[attrKey],
    isActive = isEnabled && (node.attrs[attrKey] === attrValue),
    command = node && setBlockType(node.type, Object.assign({}, node.attrs, { [attrKey]: attrValue }));

  return ({ isActive, isDisabled: !isEnabled, fn: () => command(viewState, view.dispatch) });
}

function BlockAttrButton({ faIcon, tooltip, controller, attrKey, attrValue, children }) {
  const { isDisabled, isActive, fn } = getBlockAttrButtonProps({ controller, attrKey, attrValue });

  return (
    <Button
      faIcon={faIcon}
      isActive={isActive}
      tooltip={tooltip}
      isDisabled={isDisabled}
      children={children}
      onPress={fn} />
  )
}