export function Floating({ className }) {
  return (
    <svg className={className} width="120" height="98" viewBox="0 0 120 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="118.5" height="96.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
      <rect x="4.75" y="4.75" width="23.5" height="9.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
      <rect x="85.75" y="18.75" width="29.5" height="73.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
      <rect x="80.75" y="4.75" width="34.5" height="9.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
  )
}

export function Normal({ className }) {
  return (
    <svg className={className} width="122" height="99" viewBox="0 0 122 99" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M121 12.75H1M88.75 12V98M1 1H121V98H1V1Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
  )
}

export function Sidebar({ className }) {
  return (
    <svg className={className} width="122" height="100" viewBox="0 0 122 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M88.75 1L88.75 99M10.75 1L10.75 99M1 1H121V99H1V1Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
  )
}
