import Editor from './Editor';
import Policies from './Policies';
import Inspector from './Inspector';
import Viewer from './Viewer';
import Controller from './Controller';
import Overview from './Overview';
import { faImages } from '@fortawesome/free-solid-svg-icons';

const index = {
  type: 'images',
  caption: 'Image(s)',
  faIcon: faImages,

  Editor: Editor,
  Inspector: Inspector,
  Policies: Policies,
  Viewer: Viewer,
  Controller: Controller,
  Overview: Overview,
  description: 'Create a simple images or items media',
}

export default index;