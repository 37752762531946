import { useRef, useEffect } from 'react';
import { useControllerState } from 'lib/BaseController';
import Console from './Console';
import { webFrameUrl } from 'config';
import { Button } from 'react-aria-components';
import clsx from 'clsx';

import './style.css'


export function WebFrame({ controller, height, autoHeight, fullHeight }) {
  const
    state = useControllerState(controller),
    clientHeight = state.get('clientHeight'),
    style = {};

  if (fullHeight) {
    style['height'] = '100%';
  } else if (controller.state.get('errored')) {
    style['height'] = (height && height > 250) ? height + 'px' : '300px';
  } else if (!autoHeight || !clientHeight || !state.get('buildVersion')) {
    style['height'] = height + 'px';
  } else if (clientHeight > 20) {
    style['height'] = clientHeight + 'px';
  }

  // if (state.get('buildVersion')) {
  //   style['background'] = '#ffffff';
  // }

  return (
    <div
      className={clsx(
        "nb-web-runner flex",
        !state.get('buildVersion') ? 'bg-striped' : 'bg-paper2'
      )}
      style={fullHeight ? { height: '100%' } : {}}>
      <div className="display-frame w-full" style={style}>
        {state.get('build') && (
          <IFrame controller={controller} key={state.get('buildVersion')} />
        )}
        { controller.state.get('errored') && (
          <ErrorScreen controller={controller} />
        )}
      </div>

      { controller.state.get('showConsole') && (
        <Console controller={controller} />
      )}

      
      { state.get('loadingFiles').size > 0 && (
        <div className="absolute bottom-0 left-0 bg-wax px-2 text-xs opacity-60 max-w-full overflow-hidden">
          <span className="pr-1">loading:</span>
          <span className="inline truncate">
            { state.get('loadingFiles').join(', ') }
            {/*{state.get('loadingFiles').map((file, i) => (
              <span key={i} className="px-1">{file}</span>
            ))}*/}
          </span>
        </div>
      )}
    </div>
  )
}

function IFrame({ controller }) {
  const ifRef = useRef();
  useEffect(_ => {
    if (!ifRef.current)
      return;
    const
      ifWindow = ifRef.current.contentWindow,
      handleMessage = event => {
        if (event.source === ifWindow)
          controller.handleMessage(event.data);
      };

    controller.setPostMessageFn(message => ifWindow.postMessage(message, '*'));
    window.addEventListener('message', handleMessage);

    return _ => window.removeEventListener('message', handleMessage);
  }, [controller]);

  return (
    <iframe
      title="webframe"
      style={{ width: '100%', 'height': '100%', border: 'none' }}
      allowtransparency="true"
      src={webFrameUrl}
      ref={ifRef} />
  )
}

// .nb-web-runner-error {
//   background: #fffafa;
// }

// .nb-web-runner-error .error-title {
//   font-size: 1.2em;
//   font-weight: bold;
// }

// .nb-web-runner-error .error-message {
//   font-family: 'Courier New', Courier, monospace;
//   font-size: 12px;
//   white-space: pre-wrap;
//   margin-top: 20px;
// }

function ErrorScreen({ controller }) {
  return (
    <div className="absolute top-0 left-0 w-full h-full p-6 overflow-auto bg-wax">
      <div className="text-2xl font-medium">
        {controller.state.get('errorTitle')}
      </div>

      <div className="font-mono whitespace-pre-wrap break-words text-xs mt-4 text-orange-800 border-l-4 border-orange-200 bg-orange-50 p-4">
        { controller.state.get('errorMessage') }
      </div>

      <div className="mt-4 flex gap-2">
        <div className="flex-grow"></div>

        <Button
          className="close-button border bg-paper p-1 px-4 float-right text-sm"
          onPress={_ => controller.openPopup('cache-manager')}>
          Open Cache Manager
        </Button>

        <Button
          className="close-button border bg-paper p-1 px-4 float-right text-sm"
          onPress={_ => controller.setState({ errored: false, errorText: '' })}>
          Clear
        </Button>
      </div>

    </div>
  )
}

export default WebFrame;