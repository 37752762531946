import { ui as p } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';
// import { getSizeString } from 'lib/tools.ts';
import Loader from 'lib/staticPages/Loader';

import './style.css';


function Popup({ popup }) {
  let
    state = useControllerState(popup),
    plans = state.get('plans');

  return (
    <p.Popup popup={popup} className="domain-usage-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>Upgrade</p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          { !plans && (<Loader />) }
          { plans && (<Plans popup={popup} plans={plans} />) }
          <p.Alert />
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

function Plans({ plans }) {
  return (
    <div>
      {Object.keys(plans).map((key) => (
        <div>
          { plans[key].caption }
        </div>
      ))}
    </div>
  );
}



export default Popup;