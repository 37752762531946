import * as Babel from '@babel/standalone';
import { gatherDependenciesPlugin, modifyImportsPlugin } from './plugins';
import { getLocalDVMap, getRemoteDVMap } from './dvMap';


const processCode = async ({ file, docPath, localPath, fetchPath }) => {
  let 
    content = await file.getContent(),
    { filename } = file;

  // Step 1. Transpile JSX/TSX/TS -> JS, get dependencies
  let { code, dependencies } = transpileToJS({ filename, content, docPath });

  // Step two get version map
  let dvMap = (
    docPath === localPath ?
      await getLocalDVMap({ dependencies, docPath, localPath, fetchPath }) :
      await getRemoteDVMap({ docPath, fetchPath })
  );

  // Step three modify imports
  code = modifyImports({ code, docPath, dvMap });

  return ({
    filename: file.filename,
    contentType: 'application/javascript',
    content: code,
    isBase64: false
  });
};


export const transpileToJS = ({ filename, content, docPath }) => {
  let dependencies = new Set();

  let { code } = Babel.transform(content, {
    filename,
    sourceType: 'module',
    presets: [
      'react',
      'typescript',
      ['env', { modules: false }]
    ],
    plugins: [[gatherDependenciesPlugin, { dependencies, docPath }]],
  });

  return { code, dependencies };
}


const modifyImports = ({ code, docPath, dvMap }) => Babel.transform(code, {
  plugins: [[modifyImportsPlugin, { docPath, dvMap }]],
}).code;

export default processCode;