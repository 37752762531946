import  { useState, useEffect } from 'react';
import { StatusBar, DeleteButton } from 'modules/elements/lib/sidebar/statusbar';


function ElementInspector({ articleController, element, isGrid }) {
  const
    [, setState] = useState(element.state),
    [controlState, setControlState] = useState(element.controlState);
  
  useEffect(_ => element.on('change', _ => setState(element.state)), [element, element.id]);
  useEffect(_ => element.on('control-change', _ => setControlState(element.controlState)));

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex-grow overflow-auto px-2 py-2">
        <element.lib.Inspector
          key={element.id}
          controller={element.controller}
          state={element.state}
          controlState={element.controlState}
          onActive={ element.onActive }
          getState={ _ => element.state }
          setState={ o => element.setState(o) }
          isGrid={isGrid} />
      </div>
       
      <StatusBar
        saving={controlState.get('saving')}
        error={controlState.get('saveError')} >
        <DeleteButton onPress={_ => articleController.popups.deleteElementPopup(element.id)} />
      </StatusBar>
    </div>
  )
}

export default ElementInspector;