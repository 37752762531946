import Editor from './Editor';
import Policies from './Policies';
import Inspector from './Inspector';
import Viewer from './Viewer';
import Controller from './Controller';
import Overview from './Overview';
import { faFont } from '@fortawesome/free-solid-svg-icons';


const index = {
  type: 'text',
  caption: 'Text',
  faIcon: faFont,
  description: 'Add text',

  Editor: Editor,
  Inspector: Inspector,
  Policies: Policies,
  Viewer: Viewer,
  Controller: Controller,
  Overview: Overview
}

export default index;