import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });

  constructor(args, manager) {
    super(args, manager);
    this.caching = args.caching;
  }

  clearCache() {
    this.caching.remoteCache.clearAll();
    this.setState({ cleared: new Date() });
  }
}

export default PopupController;