import settingsFields from '../Policies/settingsFields';
import { BuildInputs } from 'modules/elements/lib/sidebar/builder';
import { Button } from 'modules/elements/lib/sidebar/inputs/buttons';
import { Block } from 'modules/elements/lib/sidebar/inputs/components';
import Collapsible from 'modules/elements/lib/sidebar/inputs/Collapsible';

import './style.css'

function Inspector({ controller, isGrid }) {
  const
    { setSettings, getSettingsData, getSettingsValue, getSettingsValueOnNull } = controller,
    props = {
      fields: settingsFields,
      getData: getSettingsData,
      getValue: getSettingsValue,
      setData: setSettings,
      getValueOnNull: getSettingsValueOnNull
    };

  return (
    <>
      <Collapsible title="Runtime Settings">
        <BuildInputs
          {... props }
          layout={[
            [
              ['runtime'],
              ['target'],
              ['renderOption'],
              ['classToRender'],
            ]
          ]} />

        <Block>
          <Button onPress={controller.openImportMapPopup}>
            Edit Import Map
          </Button>
        </Block>
      </Collapsible>        

      <Collapsible title="Display Settings">
        <BuildInputs
          {... props }
          layout={[
            [
              ['layout'],
              ...(isGrid ? [] : (
                getSettingsValue('layout') === 'fixed' ? 
                  [
                    ['fixedWidth', 'fixedHeight'],
                    ['alignment'], // ['scaling'],
                  ] :
                  getSettingsValue('layout') === 'dynamic' ? [
                    ['autoHeight', 'height']
                  ] : []
              ))
            ],
            [
              ['menuPosition'],
              ['showTarget', 'hasBorder'],
              ['showRun'],
            ],
          ]} />
      </Collapsible>

      <Collapsible title="Published Page Settings">
        <BuildInputs
          {... props }
          layout={[
            [
              ['autorun'],
            ]
          ]} />
      </Collapsible>
    </>
  );
}

export default Inspector;