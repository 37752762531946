import React from 'react';
import { CheckCircledIcon, CircleBackslashIcon, CircleIcon, TrashIcon } from '@radix-ui/react-icons';
import { Button } from 'react-aria-components';


export function StatusBar({ children, status, saving, error }) {
  return (
    <div className="flex border-t border-rim">
      <div className="flex-grow">
        {children}
      </div>
      <div className="p-2">
        <StatusIcon saving={saving} error={error} />
      </div>
    </div>
  )
}

export function StatusIcon({ saving, error }) {
  return (
    error ?
      <span className="text-red-500"><CircleBackslashIcon className="w-5 h-5" /></span> :
      saving ?
        <span><CircleIcon className="w-5 h-5" /></span> :
          <span><CheckCircledIcon className="w-5 h-5" /></span>
  );
}

export function DeleteButton({ onPress }) {
  return (
    <Button onPress={onPress} className="p-2">
      <TrashIcon className="w-5 h-5" />
    </Button>
  );
}