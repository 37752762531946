import { Button } from 'modules/elements/lib/sidebar/inputs/buttons';
import { Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import Icon from 'lib/Icon';
import clsx from 'clsx';
import { faBars } from '@fortawesome/free-solid-svg-icons';


export default function SidebarMenu({ options, tooltip }) {
  const disabledKeys = options.reduce((r, option, i) => {
    if (option[3]) { r.push(i); } 
    return r;
  }, []);
  
  return (
    <MenuTrigger>
      <Button
        tooltip={tooltip || 'Menu'}
        marginClass="ml-2"
        paddingClass="px-2"
        faIcon={faBars} />

      <Popover className="min-w-32 text-sm p-1 bg-paper border border-rim">
        <Menu
          disabledKeys={disabledKeys}
          onAction={i => options[i][2]()}
          className="outline-none">
          {options.map(([faIcon, caption, fn, isDisabled], i) => (
            <MenuItem
              id={i}
              key={i}
              isDisabled={isDisabled}
              className={({ isDisabled, isFocused }) => clsx(
                'py-1 px-3 cursor-pointer outline-none',
                isFocused && 'text-white bg-marker3',
                isDisabled && 'opacity-50 pointer-events-none'
              )}>
              <span className="w-5 inline-block"><Icon faIcon={faIcon} /></span>
              {caption}
            </MenuItem>
          ) )}
        </Menu>
      </Popover>
    </MenuTrigger>
  )
}