import BaseController from 'lib/BaseController';

import { EditorView } from 'prosemirror-view';
import { schema } from './schema.ts';
import { toggleMark } from 'prosemirror-commands';
import { isMarkActive } from './helpers';
import MathNodeView from './MathNodeView';
import LinkNodeView from './LinkNodeView';
import ImageNodeView from './ImageNodeView';

import 'prosemirror-view/style/prosemirror.css';

import './style.css';


class Controller extends BaseController {
  content = false;

  constructor({
    viewState, autoFocus,
    openKatexPopup, openPromptPopup,
    openLinkPopup, openImagePopup,
    getURL,
    isReadOnly
  }) {
    super();
    this.state = this.state.merge({ viewState });
    this.autoFocus = autoFocus;
    this.openKatexPopup = openKatexPopup;
    this.openPromptPopup = openPromptPopup;
    this.openLinkPopup = openLinkPopup;
    this.openImagePopup = openImagePopup;
    this.getURL = getURL;
    this.isReadOnly = isReadOnly;
  }

  get viewState() { return this.state.get('viewState'); }

  setViewState(viewState) {
    if (this.view)
      this.view.updateState(viewState);
    this.state = this.state.merge({ viewState });
  }

  setEl(el) {
    this.el = el;
    this.view = new EditorView(el, {
      state: this.state.get('viewState'),

      dispatchTransaction: (transaction) => {
        const state = this.view.state.apply(transaction);
        this.view.updateState(state);
        this.handleViewStateChange(state);
        if (transaction.before.content !== transaction.doc.content)
          this.handleContentChange()
      },

      nodeViews: {
        math : (node, view, getPos) => new MathNodeView(node, view, getPos, this.handleMathClick),
        link: (node, view, getPos) => new LinkNodeView(node, view, getPos, this.handleLinkClick),
        image: (node, view, getPos) => new ImageNodeView(node, view, getPos, this.handleImageClick, this.getURL),
      },

      editable: () => !this.isReadOnly
    });

    this.unload = _ => {
      this.view.destroy();
    }
    if (this.autoFocus)
      this.view.focus();

    return this.unload;
  }

  handleContentChange() {
    this.emit('content change');
  }

  handleViewStateChange(state) {
    this.setState({ viewState: state });
  }

  addLinkPrompt = () => {
    this.openLinkPopup({
      onSave: (href) => {
        console.log(href);
        this.toggleMark(schema.marks.link, { href })
      }
    });
  }

  handleImageClick = (node, getPos) => {
    // this.addImage({ node, getPos });
  }

  addImage = ({ node, getPos }) => {
    const attrs = node ? node.attrs : {};

    this.openImagePopup({
      location: attrs.location || '',
      getURL : this.getURL,
      
      onSave: (location) => {
        if (!location) //  || (!getPos && !this.canReplaceSelectionWith(schema.nodes.image))
          return;
        
        const
          { view, viewState } = this,
          tr = viewState.tr,
          newNode = schema.nodes.image.create({ ...attrs, location });

        // if (getPos) {
        //   const
        //     begin = getPos(),
        //     $begin = viewState.doc.resolve(begin),
        //     end = begin + $begin.nodeAfter.nodeSize;

        //   tr.replaceWith(begin, end, newNode);
        // } else {
        tr.replaceSelectionWith(newNode);
        // }
        view.dispatch(tr);        
      }
    });
  }

  handleLinkClick = (event, dom) => {
    if (this.view.editable) {
      event.preventDefault();
      return;
    }
    if (dom.href.startsWith('file:')) {
      event.preventDefault();
      var filename = dom.href.substr('5');
      
      if (filename.startsWith('/'))
        filename = filename.substr('3');

      this.getURL(filename).then(url => {
        if (url) {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.target = '_blank';
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
    }
  }

  addRule() {
    this.view.dispatch(
      this.viewState.tr.replaceSelectionWith(
        schema.nodes.horizontal_rule.create()));
  }

  toggleMark = (markType, opts) => toggleMark(markType, opts)(this.viewState, this.view.dispatch);

  isMarkActive = (markType) => isMarkActive(this.viewState, markType);

  handleMathClick = (node, getPos) => {
    this.addMath(node.textContent, getPos);
  }

  addMath = (currentText, getPos) => {
    this.openKatexPopup({
      value: currentText ? currentText : '\\KaTeX',

      onSave: value => {
        if (!value || (!getPos && !this.canReplaceSelectionWith(schema.nodes.math)))
          return;

        const
          { view, viewState } = this,
          tr = viewState.tr,
          newNode = schema.nodes.math.create({}, viewState.schema.text(value));

        if (getPos) {
          const
            begin = getPos(),
            $begin = viewState.doc.resolve(begin),
            end = begin + $begin.nodeAfter.nodeSize;

          tr.replaceWith(begin, end, newNode);
        } else {
          tr.replaceSelectionWith(newNode);
        }
        view.dispatch(tr);
      },

      onClose: () => {
        this.view.focus();
      }
    })
  }

  canReplaceSelectionWith = (nodeType) => {
    const
      { viewState } = this,
      { $from } = viewState.selection,
      index = $from.index();

    return $from.parent.canReplaceWith(index, index, nodeType)
  }

}


export default Controller;