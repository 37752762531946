import { useEffect } from 'react';
import { useControllerState } from 'lib/BaseController';

import { Frame } from 'modules/app/views/Home/misc/components';
import Cover from './Cover';
import Folder from './Folder';
import Menu from '../common/Menu';

import { ErrorBlock } from 'lib/staticPages';
import Loader from 'lib/staticPages/Loader';


export default function PageLoader({ path, app }) {
  const { workspace } = app;
  useEffect(() => { workspace.loadPath(path) }, [workspace, path]);
  useEffect(() => workspace.unload, [workspace]);
  return (<Page controller={workspace} />);
}

function Page({ controller }) {
  const
    { domainController, folderController } = controller,
    state = useControllerState(controller),
    domainState = useControllerState(domainController),
    domain = domainState.get('domain');

  useControllerState(folderController);

  return (
    <Frame className="max-w-[60rem] h-full flex flex-col">
    {
      state.get('error') ? (
        <ErrorBlock
          className="m-10"
          title="Loading Error!"
          error={state.get('error')} />
        ) : (!domain) ? (
          <div className="text-center text-2xl p-8"><Loading /></div>
          ) : (<>
            <Menu controller={controller} />
            <div className="flex-grow flex flex-row overflow-hidden">
              <div className="w-80 border-r border-rim max-md:hidden">
                <Cover controller={domainController} />
              </div>
              <div className="flex-grow overflow-hidden h-full">
                <Folder folderController={folderController} />
              </div>
            </div>
          </>) }
    </Frame>
  )
}

function Loading() {
  return (<div><Loader /></div>);
}