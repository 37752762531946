import { GridList, GridListItem } from 'react-aria-components';
import VersionItem from './VersionItem';


export default function List({ versions,selectedId, setSelectedId,  publishedId }) {
  return (
    <GridList
      aria-label="Version list"
      selectionMode="single"
      items={versions}
      selectedKeys={selectedId ? [selectedId] : []}
      onSelectionChange={keys => setSelectedId(keys.currentKey)}
      renderEmptyState={() => (
        <div className="p-4 text-center text-sm text-pencil3">No version created yet</div>
      )}>
      { versions.map((version) => (
        <Item
          id={version.get('id')}
          key={version.get('id')}
          version={version}
          isSelected={selectedId === version.get('id')}
          isPublished={publishedId === version.get('id')} />
      )) }
    </GridList>
  );
}


function Item({ id, version, isPublished, isSelected }) {
  return (
    <GridListItem
      id={id}
      textValue={'Version ' + version.get('slug')}
      className="cursor-pointer outline-none">
      <VersionItem
        version={version}
        isPublished={isPublished}
        isSelected={isSelected}
        isExpanded={isSelected}
        isExpandable={true} />
    </GridListItem>
  );
}
