import { useRef, useEffect } from 'react';
import katex from 'katex';
import { useControllerState } from 'lib/BaseController';
import { Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import ui from 'lib/popup/components/ui';

import './style.css';


function Popup({ popup }) {
  const
    state = useControllerState(popup),
    textareaRef = useRef();

  useEffect(() => {
    textareaRef.current.select();
  }, [])

  return (
    <ui.Popup popup={popup} className="katex-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>{popup.args.title || 'KaTeX'}</ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.handleSubmit(); }}>
            <Group>
              { !state.get('invalid') && (
                <KatexOutput value={state.get('value')} />
              ) }
              { state.get('invalid') && (
                <div className="invalid">Invalid Format</div>
              ) }
              <textarea
                className="outline-none w-full border border-rim p-2"
                ref={textareaRef}
                value={state.get('value')}
                onChange={e => popup.updateValue(e.currentTarget.value)}
                autoFocus={true}
                rows={5} />
            </Group>

            <ui.Alert />
            <Buttons className="text-right">
              <Button type="submit" isDisabled={state.get('loading') || state.get('invalid')}>
                Save
              </Button>

              <Button onPress={e => { popup.close() }}>
                Cancel
              </Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

function KatexOutput({ value }) {
  const ref = useRef();
  useEffect(_ => { katex.render(value, ref.current, { displayMode: true }); }, [value]);
  return <div className="bg-wax2 px-2 py-6 mb-4" ref={ref} />;
}

export default Popup;