import BaseSelection from 'lib/inputs/BaseSelection';


export default function Topbar({ controller, viewer }) {
  return (
    <div className="flex py-2 pl-4">
      <Count controller={controller} viewer={viewer} />
      <div className="flex-grow"></div>
      <SortInput controller={controller} />
    </div>
  )
}

function Count({ controller, viewer }) {
  const { domain } = viewer.workspace;

  let count, plus;
  if (viewer.state.get('type') !== 'article') {
    count = domain.commentsCount + controller.state.get('countDiff');
    plus = '';
  } else {
    count = (controller.state.get('list') || []).length;
    plus = (controller.state.get('nextCursor') ? '+' : '');    
  }

  return (
    <div className="font-medium inline flex-grow text-pencil text-sm">
      {count}{plus} Comment{ count > 1 && 's'}
    </div>
  )
}

function SortInput({ controller }) {
  const
    sortBy = controller.state.get('sortBy');

  return (
    <div className="inline-flex items-center text-sm gap-2">
      <div className="font-bold">Sort by:</div>
      <BaseSelection
        value={sortBy}
        label="Sort By"
        autoFocus={false}
        options={[
          { key: 'createdAt', caption: 'Newest' },
          { key: 'likesCount', caption: 'Likes' },
        ]}
        buttonClassName="gap-2"
        onChange={v => controller.setState({ sortBy: v }) } />
    </div>
  )
}