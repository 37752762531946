import RenameSlug from 'modules/folder/popups/RenameSlug/Controller';
import MoveToFolder from 'modules/folder/popups/MoveToFolder/Controller';
import DeleteFolder from 'modules/folder/popups/DeleteFolder/Controller';

import {
  LockOpen2Icon, LockClosedIcon, CursorTextIcon,
  Pencil2Icon, ImageIcon, MoveIcon, TrashIcon, OpenInNewWindowIcon
} from '@radix-ui/react-icons';

export function getFolderOptions({ controller, folder }) {
  if (!folder)
    return [];
  const
    { popups, popupManager, provider } = controller,
    isPublic = folder.get('isPublic'),
    root = !folder.get('parentId');

  return ([
    ...(provider.isOffline ? [] : isPublic ? [
      ['View Public', OpenInNewWindowIcon, () => {}, { href: '/' + folder.get('path'), target: '_blank' }],
      ['Make Private', LockClosedIcon, () => popups.changeFolderIsPublic({ folder, isPublic: false })],
    ] : [
      ['Make Public', LockOpen2Icon, () => popups.changeFolderIsPublic({ folder, isPublic: true })],
    ]),
    [],
    ['Change Title', CursorTextIcon, () => { popups.editTitle({ folder }) }],
    ...(root ? [] : [
      ['Change Description', Pencil2Icon, () => popups.editDescription({ folder })],
      ['Change Thumbnail', ImageIcon, () => popups.editThumbnail({ folder })],
      ['Rename Slug', CursorTextIcon, () => popupManager.open(RenameSlug, { folder, provider })],
      [],
      ['Move to Folder', MoveIcon, () => popupManager.open(MoveToFolder, { folder, provider })],
      ['Delete', TrashIcon, () => popupManager.open(DeleteFolder, { folder, provider })],
    ])
  ])
}