// import { ASPECT_RATIOS } from 'modules/theme/defaults/image';

export const fields = {
  maxWidth: {
    caption: 'Width',
    input: 'number',
    init: 500,
    unit: 'px',
    range: [100, 900],
  },

  percentWidth: {
    caption: 'Percent Width',
    input: 'number',
    init: 100,
    unit: '%',
    range: [20, 100],
  },

  caption: {
    caption: 'Caption',
    init: '',
    type: 'text'
  },

  // alignment: {
  //   caption: 'Alignment',
  //   input: 'options',
  //   init: 'center',
  //   options: [
  //     { key: 'left', caption: 'Left' },
  //     { key: 'center', caption: 'Center' },
  //     { key: 'right', caption: 'Right' },
  //   ]
  // },

  hasBorder: {
    caption: 'Has Border', input: 'checkbox', init: true
  },

  backgroundColor: {
    caption: 'Background Color', input: 'color', init: null, nullable: true, themeKey: 'backgroundColor',
  },
}

export const initialSettings = {};
for (let k in fields) { initialSettings[k] = fields[k].init; }