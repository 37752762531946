import { FloatingMenu, BarMenu } from './menus'
import { Button } from 'react-aria-components';

export function TopBar({ controller }) {
  const gsv = controller.getSettingsValue;

  if (controller.isFloating) {
    if (gsv('title')) {
      return (
        <div className="text-center pb-2 text-2xl font-medium">
          {gsv('title')}
        </div>
      );
    }
    return '';
  }

  return (<BarMenu controller={controller} />);
}


export function Floater({ controller }) {
  if (!controller.isFloating)
    return '';
  
  return (
    <>
      <NoTargetButton controller={controller} />
      <FloatingMenu controller={controller} />
    </>);  
}


export function NoTargetButton({ controller }) {
  return !controller.getSettingsValue('target') && (
    <Button
      className="font-mono text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 text-pencil3"
      onPress={controller.editTarget}>
      Missing target file.<br />
      Click to add.
    </Button>
  )
}
