export const TITLE_ALIGNMENT_OPTIONS = [
  { key: 'left', caption: 'Left' },
  { key: 'center', caption: 'Center' },
  { key: 'right', caption: 'Right' },
];

export const PAGE_WIDTH_OPTIONS = [
  { key: 'tight', caption: 'Tight' }, // 920 px
  { key: 'normal', caption: 'Normal' }, // 720 px
  { key: 'wide', caption: 'Wide' }, // 540 px ?
];