import { Label, Block } from 'modules/elements/lib/sidebar/inputs/components';
import { Markdown } from 'lib/prosemirror/markdown/helpers';
import { HTMLElement } from 'lib/prosemirror/helpers';
import clsx from 'clsx';


export default function RichTextInput({ caption, value, isDisabled, onClick, format }) {
  return (
    <Block>
      <Label>{caption}</Label>
      <div
        className={clsx(
         'relative text-sm bg-wax px-2 py-1 cursor-pointer hover:bg-wax2 border border-rim mt-1 min-h-20',
          (!value) && 'bg-wax2',
          isDisabled && 'text-pencil3 pointer-events-none'
        )}
        onClick={onClick}>
        <div style={{ pointerEvents: 'none' }}>
          { format === 'markdown' ? (<Markdown text={value} />) : (<HTMLElement html={value} />) }
        </div>
      </div>
    </Block>
  );
}

// .nb-sb-markdown-input {
//   background: #eee;
//   font-weight: normal;
//   padding: 5px 10px;
//   min-height: 100px;
//   max-height: 150px;
//   overflow: auto;
//   cursor: pointer;
// }