import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { useControllerState } from 'lib/BaseController';
import { ErrorBlock, LoadingBlock } from 'lib/staticPages';
import clsx from 'clsx';

import Doc from 'modules/article/views/Doc';
import Casing from './panels/Casing';
import Styler from 'modules/theme/views/Styler';


export default function Container({ app }) {
  const
    match = useMatch({ path: '/editor/*' }),
    path = match.params['*'],
    { editor } = app,
    editorState = useControllerState(editor),
    domainState = useControllerState(editor.domainController);
  
  useEffect(() => editor.loadPath(path), [path, editor]);
  useEffect(() => editor.unload, [editor]);

  const error = editorState.get('error') || domainState.get('error');
  if (error)
    return (<ErrorBlock title="Loading Error" error={error} />)

  if (!domainState.get('domain'))
    return (<LoadingBlock />);

  return <Page editor={editor} themeData={editor.domainController.themeData} />
}

function Page({ editor, themeData }) {
  return (
    <div className="h-full w-full overflow-hidden">
      <Casing editor={editor}>
        <ArticleDocs editor={editor} themeData={themeData} />
      </Casing>
   </div>
  )
}

function ArticleDocs({ editor, themeData }) {
  const
    { state, articleControllers } = editor,
    articleIds = state.get('articleIds'),
    currentId = state.get('currentId');

  return (
    <>
      { articleIds.map((id, index) => (
        <div
          className={clsx('w-full h-full', id !== currentId && 'hidden', 'nb-editor-theme')}
          key={id}>
          <ArticleDoc articleController={articleControllers[id]}/>
        </div>
      )) }

      <Styler themeData={themeData} baseClass="nb-editor-theme" />
    </>
  );
}

function ArticleDoc({ articleController, hidden }) {
  const state = useControllerState(articleController);
  useControllerState(articleController.selection);

  if (state.get('error'))
    return <ErrorBlock className="pt-4" title="Loading Error!" error={state.get('error')} />

  if (!state.get('article'))
    return <LoadingBlock />

  return (!state.get('error') && state.get('article') && (
    <Doc articleController={articleController} />
  ));
}