import Gallery from './Gallery';
import Menu from './Menu';
import clsx from 'clsx';


function Editor({ controller, isGrid }) {
  return (
    <div
      className={clsx('nb-gallery-editor relative group', isGrid && 'h-full')}
      onClick={controller.focus}>
      <HiddenInput controller={controller} />
      <Gallery controller={controller} isGrid={isGrid}>
        <Menu controller={controller} />
      </Gallery>
    </div>
  );
}

function HiddenInput({ controller }) {
  return (
    <input
      className="w-0 h-0 outline-none pointer-events-none absolute opacity-0 top-1/2 left-1/2"
      ref={ref => controller.setHiddenInputRef(ref)}
      onBlur={_ => controller.handleBlur()}
      onFocus={e => controller.handleFocus()}
      onKeyDown={evt => controller.onKeyDown(evt)} />    
  )
}

export default Editor;