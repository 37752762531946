import { useState, useEffect } from 'react';

import p from 'lib/popup/components/ui';
import { TextInput, Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';

function Popup({ popup }) {
  let
    [state, setState] = useState(popup.state),
    oldPassword = state.get('oldPassword'),
    newPassword = state.get('newPassword'),
    confirmPassword = state.get('confirmPassword'),
    loading = state.get('loading');

  useEffect(_ => popup.emitter.on('change', _ => setState(popup.state)), [popup]);

  return (
    <p.Popup popup={popup} className="change-password-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>
            Change Password
          </p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          <form onSubmit={e => { e.preventDefault(); popup.changePassword(); }}>
            {state.get('done') ? (
              <>
                <div className="done">
                  Your password has been changed!
                </div>
                <Buttons className="text-center">
                  <Button onClick={_ => popup.close()}>Close</Button>
                </Buttons>
              </>
            ) : (
            <>
              <Group>
                <TextInput
                  label="Old Password"
                  type="password"
                  value={oldPassword}
                  onChange={v => popup.setState({oldPassword: v})}
                  isDisabled={loading} />

                <TextInput
                  label="New Password"
                  type="password"
                  value={newPassword}
                  onChange={v => popup.setState({newPassword: v})}
                  isDisabled={loading} />

                <TextInput
                  label="Confirm New Password"
                  type="password"
                  value={confirmPassword}
                  onChange={v => popup.setState({confirmPassword: v})}
                  isDisabled={loading} />
              </Group>

              <p.Alert />

              <Buttons className="text-right">
                <Button
                  type="submit"
                  isDisabled={
                    loading || !newPassword ||
                    (newPassword !== confirmPassword) || !oldPassword
                  }>
                  Change Password
              </Button>
                <Button isDisabled={loading} onPress={_ => popup.close()}>
                  Cancel
                </Button>
              </Buttons>
            </>
            )}
          </form>
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

export default Popup;