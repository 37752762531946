import { MONOSPACE_FONT_OPTIONS } from '../../defaults/fonts';
import { LIGHT_THEME_OPTIONS, DARK_THEME_OPTIONS, CODE_FONT_SIZE_OPTIONS } from '../../defaults/code';

import { getGetOptionsFnWithDefaults } from 'modules/elements/lib/helpers';
import { Block, Break } from 'modules/elements/lib/sidebar/inputs/components';
import Checkbox from 'modules/elements/lib/sidebar/inputs/Checkbox';
import Selection from 'modules/elements/lib/sidebar/inputs/Selection';
// import ColorInput from 'modules/elements/lib/sidebar/inputs/ColorInput';


export default function Code({ themeController }) {
  const
    { themeData } = themeController,
    { getData, getValue, getDefault, setData } = themeData;

  return (
    <>
      <Block>
        <Selection
          label="Light Theme"
          options={getGetOptionsFnWithDefaults(LIGHT_THEME_OPTIONS)({ valueOnNull: getDefault('codeThemeLight') })}
          value={getData('codeThemeLight')}
          onChange={v => setData({ codeThemeLight: v })} />
      </Block>

      <Block>
        <Selection
          label="Dark Theme"
          options={getGetOptionsFnWithDefaults(DARK_THEME_OPTIONS)({ valueOnNull: getDefault('codeThemeDark') })}
          value={getData('codeThemeDark')}
          onChange={v => setData({ codeThemeDark: v })} />
      </Block>

      <Block>
        <Checkbox
          label="Line Number"
          value={getValue('codeHasLineNumbers')}
          onChange={v => setData({ codeHasLineNumbers: v })} />
      </Block>

      <Break />

      <Block>
        <Selection
          label="Font"
          options={getGetOptionsFnWithDefaults(MONOSPACE_FONT_OPTIONS)({ valueOnNull: getDefault('codeFont') })}
          value={getData('codeFont')}
          onChange={v => setData({ codeFont: v })} />
      </Block>

      <Block>
        <Selection
          label="Font Size"
          value={getData('codeFontSize')}
          options={getGetOptionsFnWithDefaults(CODE_FONT_SIZE_OPTIONS)({ valueOnNull: getDefault('codeFontSize') })}
          onChange={v => setData({ codeFontSize: v })} />
      </Block>


      {/*<Break />*/}

{/*      <Block span="half">
        <Selection
          label="Code Border"
          value={getData('codeBorder')}
          options={getGetOptionsFnWithDefaults(CODE_BORDER_OPTIONS)({ valueOnNull: getDefault('codeBorder') })}
          onChange={v => setData({ codeBorder: v })} />
      </Block>

      { getValue('codeBorder') !== 'none' && (
        <Block span="half">
          <ColorInput
            label="Border Color"
            nullable={true}
            valueOnNull={getDefault('codeBorderColor')}
            value={getData('codeBorderColor')}
            onChange={v => setDataDebounced({ codeBorderColor: v })} />
        </Block>
      ) }*/}
    </>
  );
}