import settingsFields from '../Policies/settingsFields';
import { BuildInputs } from 'modules/elements/lib/sidebar/builder';


export default function DisplayInputs({ controller, isGrid }) {
  const
    { setSettings, getSettingsData, getSettingsValue, getSettingsValueOnNull } = controller,
    props = {
      fields: settingsFields,
      getData: getSettingsData,
      getValue: getSettingsValue,
      setData: setSettings,
      getValueOnNull: getSettingsValueOnNull
    };

  return (
    <>
      <BuildInputs
        {...props}
        layout={[
          [
            ['theme'],
            ['hasLineNumbers'], ['lineWrapping'],
          ],
        ]} />

      <BuildInputs
          {...props}
          layout={
            isGrid ? ([
              [],
              [['filebarLayout']],
              [['isReadOnly'], ['isHiddenOnPublish']]
            ]) : ([
              [],
              [['autoHeight', 'height']],
              [['filebarLayout']],
              [['isReadOnly'], ['isHiddenOnPublish']]
            ])} />
    </>
  );
}