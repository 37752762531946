import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as _signOut,
  updateProfile as _updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  EmailAuthProvider,
  updatePassword,
  updateEmail,
  reauthenticateWithCredential,
  sendEmailVerification as _sendEmailVerification
} from 'firebase/auth';

import { auth } from '../firebase';

export const
  signIn = (email, password) => signInWithEmailAndPassword(auth, email, password),
  signOut = () => _signOut(auth),
  createUser = (email, password) => createUserWithEmailAndPassword(auth, email, password),
  signInWithGoogle = ()  => signInWithPopup(auth, new GoogleAuthProvider()),
  sendForgotPasswordEmail = (email) => sendPasswordResetEmail(auth, email),
  updateProfile = (profile) => _updateProfile(auth.currentUser, profile),
  onAuthStateChanged = fn => auth.onAuthStateChanged(fn),
  changePassword = ({ oldPassword, newPassword }) => {
    const
      auth = getAuth(),
      user = auth.currentUser,
      credential = EmailAuthProvider.credential(user.email, oldPassword);
    return reauthenticateWithCredential(user, credential).then(_ => updatePassword(user, newPassword));
  },
  findProvider = (providerId) => {
    const auth = getAuth();
    if (!auth.currentUser)
      return null;
    return auth.currentUser.providerData.find(i => i.providerId === providerId);
  },
  sendEmailVerification = () => {
    const auth = getAuth();    
    return _sendEmailVerification(auth.currentUser);
  },
  changeEmail = ({ password, newEmail }) => {
    const
      auth = getAuth(),
      user = auth.currentUser,
      credential = EmailAuthProvider.credential(user.email, password);
    return (reauthenticateWithCredential(user, credential).then(_ => updateEmail(user, newEmail)));
  },
  getCurrentUser = () => getAuth().currentUser,
  getUserId = () => getCurrentUser() && getCurrentUser().uid,
  getUserName = () => getCurrentUser() && getCurrentUser().displayName,
  checkIsAdmin = async () => {
    const
      user = getAuth().currentUser,
      claims = user && (await user.getIdTokenResult()).claims;
    return claims && claims.role === 'admin'
  };