import BaseElementController from 'modules/elements/lib/BaseElementController';
import { fields } from '../Policies/settings';

import KeyManager from './KeyManager';
import Data from './Data';
import Selection from './Selection';


export default class Controller extends BaseElementController {
  settingsFields = fields;
  data = new Data(this);
  selection = new Selection(this);
  keyManager = new KeyManager(this);

  focus() {
    if (this._inputRef) {
      this._inputRef.focus();
    }
  }

  setInputRef(ref) { this._inputRef = ref; }
  setTableRef(ref) { this._tableRef = ref; }
  handleFocus() { this.setState({ isFocused: true }); this.activate(); }
  handleBlur() { this.setState({ isFocused: false }); this.keyManager.handleBlur(); }

  startEditingCell() { this.setState({ isEditingCell: true }) }
  endEditingCell() { this.setState({ isEditingCell: false }) }
}