import { fromJS } from 'immutable';

const
  getTime = () => (new Date()).getTime(),
  MAX_STACK = 1000,
  ACTION_GROUP_INTERVAL = 500;

const empty = fromJS([]);

class History {
  constructor(state) {
    this.stack = empty;
    this.redoStack = empty;
    this.prevTime = getTime() - 2000;
  }

  initialize() {
    this.stack = empty;
    this.redoStack = empty;
  }

  push = (state) => { // prev state
    var { stack } = this;

    if (this.redoStack.size > 0) {
      this.redoStack = empty;      
    }
    if (stack.size >= MAX_STACK) {
      stack = stack.unshift();
    }
    if ((getTime() - this.prevTime) < ACTION_GROUP_INTERVAL) {
      stack = stack.pop();
    } else {
      this.prevTime = getTime();
    }
    this.stack = stack.push(state);
  }


  undo(current) {
    const popped = this.stack.last();
    if (!popped)
      return null;
    this.stack = this.stack.pop();
    this.redoStack = this.redoStack.push(current);
    return this.syncElements(popped, current);
  }

  redo(current) {
    const popped = this.redoStack.last();
    if (!popped)
      return null;
    this.redoStack = this.redoStack.pop();
    this.stack = this.stack.push(current);
    return this.syncElements(popped, current);
  }

  syncElements(newState, oldState) {
    var
      state = newState,
      order = newState.get('order'),
      oldOrder = oldState.get('order'),

      elements = newState.getIn(['grid', 'elements']),
      oldElements = oldState.getIn(['grid', 'elements']);

    // if element in newState and not oldState, remove
    // if element in oldState and not newState, add

    order = order.filter(id => oldOrder.indexOf(id) !== -1);
    oldOrder.forEach((id, i) => { 
      if (order.indexOf(id) === -1) {
        order = order.splice(i, 0, id);
      }
    });
    state = state.set('order', order)

    if (elements) {
      elements = elements.filter((v, id) => oldElements.get(id));
      oldElements.forEach((v, id) => {

        if (!elements.get(id)) {
          console.log('gg', id)
          elements = elements.set(id, v);
        }
      });
      state = state.setIn(['grid', 'elements'], elements)
    }

    return state;
  }
}

export default History;