import { useBookmark } from '../wrappers/components/bookmarks';
import { Button } from 'react-aria-components';


export default function BookmarkButton({ viewer }) {
  const { toggleBookmark, Icon } = useBookmark(viewer);
  return (
    <Button onPress={toggleBookmark} className="outline-none">
      <Icon />
    </Button>
  )
}