import Tabs from 'modules/elements/lib/sidebar/inputs/Tabs';
import FileInputs from './FileInputs';
import DisplayInputs from './DisplayInputs';


function Inspector({ controller, state, isGrid }) {
  return (
    <>
    <Tabs
      selectedKey={controller.state.get('inspectorTab')}
      onSelectionChange={key => controller.setState({ inspectorTab: key })}
      tabs={[
        { key: 'files', caption: 'Files', el: (<FileInputs controller={controller} />) },
        { key: 'display', caption: 'Display', el: (<DisplayInputs controller={controller} isGrid={isGrid} />) }
      ]} />
    </>
  )
}

export default Inspector;