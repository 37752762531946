import { Link } from 'react-router-dom';
import PageHome from './PageHome';
import PageAbout from './PageAbout';
// import PageCognitive from './PageCognitive';
// import PageVersatile from './PageVersatile';
// import PageRuntime from './PageRuntime';
// import PageVersionable from './PageVersionable';
// import PageSharable from './PageSharable';
// import PageLast from './PageLast';
import clsx from 'clsx';
import './style.css'


export default function Landing() {
  return (
    <div className="landing-page relative w-full h-full overflow-hidden">      
      <Cells />
      <Pages />
      <Footer />
    </div>
  );
}

function Cells() {
  return (
    <div className="w-[59rem] h-full relative m-auto mt-6">
      <div className="absolute top-0 left-0 w-[60rem] h-full">
        <div className="grid grid-cols-12">
          { Array(12 * 8).fill(0).map((r, i) => {
            const mod = (i + Math.floor(i / 12)) % 3;
            return (
              <div
                key={i}
                className={clsx(
                  mod === 2 ? 'border-opacity-25' : mod === 1 ? 'border-opacity-40' : 'border-opacity-60',
                  'w-16 h-16 border border-rim mb-4 bg-paper2 opacity-80'
                )}>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}


function Pages() {
  return (
    <div
      className="absolute top-0 left-0 w-full h-full overflow-auto scroll-snap no-scrollbar">
      <PageHome />
      <PageAbout />
{/*      <PageCognitive />
      <PageVersatile />
      <PageRuntime />*/}
    </div>
  )
}

function Footer() {
  return (
    <section className="footer absolute bottom-2 right-4 text-sm max-md:hidden">
      <Link to={'/about'}>About</Link>.&nbsp;&nbsp;
      <Link to={'/terms'}>Terms</Link>.&nbsp;&nbsp;
      <span className="copyright">
        © 2024 Koshi, Inc
      </span>
    </section>
  )
}