import { memo, useState, useEffect, useRef } from 'react';
import { useControllerState } from 'lib/BaseController';
import SaveIndicators from './SaveIndicators';
import clsx from 'clsx';

export function getRenderedElements({ articleController, colorScheme }) {
  const
    { elements, layout, selection } = articleController,
    { activeElementId } = selection;

  const renderedElements = {};
  layout.order.forEach((id, index) => {
    if (!elements[id])
      return;
    
    renderedElements[id] = (
      <ElementWrapper
        key={id}
        index={index}
        element={elements[id]}
        articleController={articleController}
        colorScheme={colorScheme}
        isActive={activeElementId === id} />
      );
  });

  return renderedElements;
}

export function ElementWrapper({ element, isActive, articleController, colorScheme }) {
  const
    [controlState, setControlState] = useState(element.controlState),
    containerRef = useRef(),
    isGrid = articleController.layout.type === 'grid';
  useEffect(() => element.on('control-change', () => setControlState(element.controlState)), [element]);
  useEffect(() => { element.setWrapperEl(containerRef.current); }, [element]);

  const
    modified = controlState.get('modified'),
    saving = controlState.get('saving'),
    className = clsx(
      isGrid && 'h-full flex flex-col',
      (isActive ? ' active' : '') +
      (modified ? ' modified' : '') +
      (saving ? ' saving' : '')
    );

  return (
    <div className={className} ref={containerRef}>
      <MemoizedElement {...{ element, isActive, articleController, isGrid, colorScheme }} />
      <Indicators element={element} articleController={articleController} />
    </div>
  )
}

function Indicators({ element, articleController }) {
  const [children, setChildren] = useState(articleController.getElementChildren(element.id));
  useEffect(() => element.on('children', () => { setChildren(articleController.getElementChildren(element.id)) }));

  return (
    <>
      <SaveIndicators element={element}  />
      {children.map((element) => (
        <SaveIndicators key={element.id} element={element} />
      ))}
    </>
  )  
}

export function Element({ element, articleController, isActive, isGrid, colorScheme }) {
  useControllerState(element);
  return (
    <element.lib.Editor
      controller={element.controller}
      isActive={isActive}
      isGrid={isGrid}
      colorScheme={colorScheme} />)
}
const MemoizedElement = memo(Element);