import { useState, useContext } from 'react';
import { Link, useNavigate, useMatch, Navigate } from 'react-router-dom';

import AppContext from '../../Context';
import { Frame } from '../Home/misc/components';

import Icon from 'lib/Icon';
import { Group, TextInput } from 'lib/inputs/basic';
import { Button } from 'lib/inputs/buttons';
import { useControllerState } from 'lib/BaseController';
import { toHuman } from 'lib/firebaseError';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Auth() {
  const
    navigate = useNavigate(),
    app = useContext(AppContext),
    { auth } = app,
    [name, setName] = useState(''),
    [email, setEmail] = useState(''),
    [password, setPassword] = useState(''),
    [alert, setAlert] = useState(''),
    [emailSent, setEmailSent] = useState(false),
    [busy, setBusy] = useState(false),
    authState = useControllerState(app.auth),
    user = authState.get('user');

  
  const
    match = useMatch('/auth/:at'),
    at = match && match.params.at,
    setAt = at => navigate('/auth/' + at);

  const
    goHome = _ => navigate('/workspaces'),
    login = _ => {
      setBusy(true); setAlert(false);
      auth.signIn(email, password)
        .then(goHome)
        .catch(e => setAlert(toHuman(e)))
        .then(_ => setBusy(false))
    },
    createUser = _ => {
      setBusy(true); setAlert(false);
      auth.createUser(name, email, password)
        .then(goHome)
        .catch(e => setAlert(toHuman(e)))
        .then(_ => setBusy(false))
    },
    forgotPassword = _ => {
      setBusy(true); setAlert(false);
      auth.sendForgotPasswordEmail(email)
        .then(goHome)
        .then(_ => setEmailSent(true))
        .catch(e => setAlert(toHuman(e)))
        .then(_ => setBusy(false))
    },
    signInWithGoogle = () => auth.signInWithGoogle().catch(e => {
      setAlert('Couldn\'t log in. Please contact support if this persists.');
    });
  
  const self = {
    at, setAt,
    name, setName,
    password, setPassword,
    email, setEmail,
    alert, setAlert,
    busy, setBusy,
    emailSent, setEmailSent,
    login,
    createUser,
    forgotPassword,
    signInWithGoogle
  };

  if (user)
    return (<Navigate replace to="/workspaces" />);

  return (
    <Frame className="max-w-[30rem] p-8 pb-16" fullHeight={false}>
      {
        at === 'signup' ? <SignUp self={self} /> :
          at === 'forgot-password' ? <ForgotPassword self={self} /> :
            <Login self={self} />
      }
    </Frame>
  )
}


function Login({ self }) {
  return (
    <form onSubmit={e => { e.preventDefault(); self.login(); }}>
      <h2 className="text-3xl text-center mb-6 font-medium">Login</h2>
      <hr className="mb-6" />

      <Group>
        <TextInput
          label="Email"
          type="email"
          autoFocus={true}
          value={self.email}
          onChange={val => self.setEmail(val)} />

        <TextInput
          label="Password"
          type="password"
          value={self.password}
          onChange={val => self.setPassword(val)} />
      </Group>

      <Alert self={self} />

      <div className="text-center">
        <Button type="submit" isDisabled={self.busy}>
          Log In
        </Button>
        <div className="text-sm">Or</div>
        <Button type="button" onPress={e => self.signInWithGoogle()}>
          Sign In with Google
          &nbsp;<Icon faIcon={faGoogle} />
        </Button>
      </div>

      <div className="text-center mt-5 text-sm">
        If you don't have an account you could &nbsp;
        <a
          href="#signup"
          className="link-button"
          onClick={e => { e.preventDefault(); self.setAt('signup') }}>
          Sign Up
        </a><br />
        or&nbsp;
        <a
          href="#forgot-password"
          className="link-button"
          onClick={e => { e.preventDefault(); self.setAt('forgot-password') }}>
          Recover Your Password
        </a> if you've forgotten.
      </div>
      
    </form>
  )
}


function SignUp({ self }) {
  return (
    <form onSubmit={e => { e.preventDefault(); self.createUser(); }}>
      <h2 className="text-3xl text-center mb-4 font-medium">Sign Up</h2>
      <hr />

      <Group>
        <TextInput
          label="Full Name"
          type="text"
          autoFocus={true}
          value={self.name}
          onChange={val => self.setName(val)} />

        <TextInput
          label="Email"
          type="email"
          value={self.email}
          onChange={val => self.setEmail(val)} />

        <TextInput
          label="Password"
          type="password"
          value={self.password}
          onChange={val => self.setPassword(val)} />

        <div className="text-center m-5 text-sm">
          By signing up you're agreeing to 
          our <Link className="link-button" to="/terms">terms of use</Link>.
        </div>
      </Group>

      <Alert self={self} />

      <div className="text-center">
        <Button type="submit" isDisabled={self.busy}>
          Sign Up
        </Button>
        <div className="text-sm text-center">Or</div>
        <Button type="button" onPress={e => self.signInWithGoogle()}>
          Sign Up with Google
          &nbsp;<Icon faIcon={faGoogle} />
        </Button>
      </div>
      
      <div className="text-center m-5 text-sm">
        Already have account?&nbsp;
        <a
          href="#login"
          className="link-button"
          onClick={e => { e.preventDefault(); self.setAt('login'); }}>
          Log In
        </a>
      </div>
    </form>
  )
}


function ForgotPassword({ self }) {
  if (self.emailSent)
    return <EmailSent self={self} />;

  return (
    <form onSubmit={e => { e.preventDefault(); self.forgotPassword(); }}>
      <h2 className="text-3xl text-center mb-4 font-medium">Recover Your Password</h2>
      <hr />

      <div className="mt-5">
        Please enter your email address you've signed up with,
        and we will email you a link to reset your password.
      </div>

      <Group>
        <TextInput
          label="Email"
          type="email"
          value={self.email}
          autoFocus={true}
          onChange={val => self.setEmail(val)} />
      </Group>

      <Alert self={self} />

      <div align="center">
        <Button type="submit" isDisabled={self.busy}>
          Send
        </Button>
      </div>

      <div className="text-center m-5 text-sm">
        Already have account?&nbsp;
        <a
          href="#login"
          className="link-button"
          onClick={e => { e.preventDefault(); self.setAt('login'); }}>
          Log In
          </a>
      </div>
    </form>
  )
}


function EmailSent({ self }) {
  return (
    <div>
      <h2 className="text-center">Email Sent</h2>
      <hr />

      <div className="title-description text-center">
        Thank you! We have sent you an email with instrution
        on how to reset your password.
      </div>
      <div className="foot text-center">
        Go back to <a
          href="#login"
          className="link-button"
          onClick={e => {
            e.preventDefault();
            self.setEmailSent(false)
            self.setAt('login');
          }}>
          Log In
        </a>
      </div>      
    </div>
  )
}

export function Alert({ self }) {
  return (self.alert && (
    <div className="text-error-red text-center text-sm my-3">
      {self.alert} &nbsp;&nbsp;
      <span onClick={_ => self.setAlert('')} className="link-button">
        <Icon faIcon={faTimes} />
      </span>
    </div>
  ))
}