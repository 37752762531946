import Navigation from './Navigation';
import Page from './Page';
import Code from './Code';
import Presets from './Presets';
import Icon from 'lib/Icon';
import { faSave, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import Tabs from 'modules/elements/lib/sidebar/inputs/Tabs';
import { Button, Buttons } from 'modules/elements/lib/sidebar/inputs/buttons';


function Sidebar({ themeController }) {
  const
    state = themeController.state,
    tab = state.get('tab'),
    tabs = [
      { key: 'page', caption: 'Page', el: (<Page themeController={themeController} />) },
      { key: 'code', caption: 'Code', el: (<Code themeController={themeController} />) },
      { key: 'nav', caption: 'Navigation', el: (<Navigation themeController={themeController} />) },
      { key: 'presets', caption: 'Presets', el: (<Presets themeController={themeController} />) }
    ];
  
  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex-grow px-3 py-2 overflow-auto">
        <Tabs
          tabs={tabs}
          selectedKey={tab}
          onSelectionChange={tab => themeController.setState({ tab })} />        
      </div>
      
      <div className="p-3 border-t border-rim text-right">
        <Buttons>
          <Button
            tooltip="Save changes"
            marginClass="mr-3"
            isDisabled={!themeController.hasChanged()}
            onPress={() => themeController.confirmSave()}>
            <Icon faIcon={faSave} /> &nbsp;Save
          </Button>

          <Button
            tooltip="Restore the current version"
            isDisabled={!themeController.hasChanged()}
            onPress={() => themeController.confirmRevert()}>
            <Icon faIcon={faRotateLeft} /> &nbsp;Restore
          </Button>
        </Buttons>
      </div>
    </div>
  );
}

export default Sidebar;