import EditButton from '../common/EditButton';
import ImageInput from '../common/ImageInput';
import clsx from 'clsx';


export function Cover({ controller }) {
  const
    { state } = controller,
    domain = state.get('domain'),
    title = domain.get('title'),
    description = domain.get('description'),
    hasImage = !!(domain.getIn(['thumbnail', '640x640']) || state.get('uploadedThumbnailImageData'));
  
  return (
    <div className="nb-domain-cover flex flex-col h-full gap-6">
      {hasImage && (
        <div className="px-4 pt-4">
          <ImageInput controller={controller} />
        </div>
      )}
      <Title {...{title, hasImage, controller}} />
      <Description {...{ description, controller }} />
    </div>
  )
}

function Title({ title, hasImage, controller }) {
  return (
    <div className={
      clsx(
        'title text-3xl text-center font-medium relative group text-pencil',
        !hasImage ? 'pt-12 pb-8' : 'pt-0'
      )}>
      <h2>{title}</h2>
      <EditButton onPress={() => controller.popups.editTitlePopup()} />
    </div>
  )
}

function Description({ description, controller }) {
 return (
    <div className="description relative bg-wax px-4 py-2 flex-grow normal border-t border-rim2 group overflow-auto text-pencil2">
      {description ? (
        <div ref={el => { if (el) el.innerHTML = description}} />) : (
          <div className="mt-5 text-pencil3 text-sm text-center">Add workspace description</div>
        )}
      <EditButton onPress={() => controller.popups.editDescriptionPopup()} />
    </div>
 );
}

export default Cover;