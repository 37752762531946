import { fromJS } from 'immutable';
import BaseController from 'lib/BaseController';


class NavData extends BaseController {
  load = (navLinks) => {
    navLinks = fromJS(navLinks || []);
    this.setState({ data: navLinks, original: navLinks })
  };
  hasChanged = () => this.state.get('data') !== this.state.get('original');
  revert = () => this.setState({ data: this.state.get('original') });

  getData = () => this.state.get('data');
  toJS = () => this.state.get('data').toJS();

  addLink = () => {
    const i = this.state.get('data').size + 1;
    this.setState({ data: this.state.get('data').push(fromJS({
      caption: 'Link ' + i,
      link: '/link-' + i
    }))});
  }

  setLink = (i, o) => {
    this.state = this.state.mergeIn(['data', i], o);
    this.emit('change');
  }

  deleteLink = (i) => this.setState({
    data: this.state.get('data').delete(i)
  });

  moveLink = (i, dir) => {
    var data, entry;
    data = this.state.get('data');
    entry = data.get(i);
    data = data.delete(i).insert(i + dir, entry);
    this.setState({ data });
  };  
}

export default NavData;