import { TooltipTrigger, Tooltip, Button as AriaButton } from 'react-aria-components';
import clsx from 'clsx';
import Icon from 'lib/Icon';


export function Buttons({ children, className }) {
  return (<div className={clsx('select-none', className)}>{children}</div>);
}

export function Button({ isActive, isDisabled, onPress, iconType, faIcon, children, tooltip, paddingClass, marginClass, preventFocusOnPress }) {
  const render = (
    <AriaButton
      className={clsx(
        'inline-block text-sm font-medium',
        isDisabled && 'opacity-50 pointer-events-none',
        isActive && 'text-marker3',
        paddingClass || 'p-1 px-2',
        marginClass || 'mr-0'
      )}
      onPress={onPress}
      preventFocusOnPress={!(preventFocusOnPress === false)}>
      {(iconType || faIcon) && <span className="text-xs"><Icon type={iconType} faIcon={faIcon} /></span>}
      {(iconType || faIcon) && children && (<>&nbsp;&nbsp;</>)}
      {children}
    </AriaButton>
  )
  return !tooltip ? render : (
    <TooltipTrigger>
      { render }
      <Tooltip className="text-sm bg-paper p-1 border border-rim">{tooltip}</Tooltip>
    </TooltipTrigger>      
  )
}