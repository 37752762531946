import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 420 });
}

export default PopupController;