import { useRef } from 'react';
import { useControllerState } from 'lib/BaseController';

import ImageInput from 'lib/inputs/ImageInput';

import { Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import ui from 'lib/popup/components/ui';
import { Button as AriaButton } from 'react-aria-components';
import { TrashIcon } from '@radix-ui/react-icons';

import './style.css';



function Popup({ popup }) {
  const
    state = useControllerState(popup),
    fileInputRef = useRef(),
    at = state.get('at');
  
  return (
    <ui.Popup popup={popup} className="upload-image-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            { at === 'select' ? 'Upload An Image' : 'Confirm Image Upload' }
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <ImageSection popup={popup} />

          <input
            id="file-input"
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ 'display': 'none' }}
            onChange={evt => popup.setFile(evt.currentTarget.files[0]) } />
          
          <ActionSection popup={popup} fileInputRef={fileInputRef} />
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

function ImageSection({ popup }) {
  const
    state = popup.state,
    at = state.get('at'),
    {
      imageWidth, imageHeight, 
      placeholderText, placeholderUrl,
    } = popup.args,
    displayUrl = state.get('imageUrl') || placeholderUrl;

  const
    dragging = popup.state.get('dragCount') > 0,
    handleDrop = e => {
      e.preventDefault();
      e.stopPropagation();
      popup.setState({ dragCount: 0 });
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        popup.setFile(file);
        e.dataTransfer.clearData();
      }
    },
    handleDragOver = e => {
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragEnter = e => {
      e.stopPropagation();
      popup.setState({ dragCount: popup.state.get('dragCount') + 1 });
    },
    handleDragLeave = e => {
      e.stopPropagation();
      popup.setState({ dragCount: popup.state.get('dragCount') - 1 });
    }

  return (
    <div
      className={'image-section' + (dragging ? ' dragging' : '')}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}>
      
      <ImageInput
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        imageData={(at === 'confirm' && state.get('uploadData')) || state.get('imageData')}
        imageUrl={displayUrl}
        placeholderText={placeholderText}>
        
        { popup.args.onRemoveClick && state.get('imageUrl') && (
          <AriaButton
            className="p-1 absolute bottom-2 right-2 bg-paper border border-rim rounded-full"
            onPress={_ => {
              popup.args.onRemoveClick();
              popup.close();
            } }>
            <TrashIcon className="w-5 h-5" />
          </AriaButton>
        ) }
      </ImageInput>

      { dragging && <div className="dnd-text">Drop the file here</div>}
    </div>
  )
}


function ActionSection({ popup, fileInputRef }) {
  const
    state = popup.state,
    at = state.get('at');

  return (
    <div className="action-section">
      <Group>
        { (popup.state.get('uploading') || popup.state.get('uploaded')) && (
          <div className="progress text-right text-sm mt-1">
            {popup.state.get('uploaded') ? 'Upload Complete' : 'Uploading'}
            <div className="progressbar1"></div>
            <div
              className="progressbar2"
              style={{ width: popup.state.get('uploadPercent') + '%' }}>
            </div>
          </div>
        ) }
        <ui.Alert />
        { at === 'select' && (
          <>
            <Buttons className="text-center">
              <span className="text-sm"><strong>Drag &amp; Drop</strong> or </span>&nbsp;
              <Button onPress={() => fileInputRef.current.click() }>
                Select a File
              </Button>
            </Buttons>
          </>
        )}
        
        { at === 'confirm' && (
          <Buttons className="text-center">
            { !popup.state.get('uploading') && !popup.state.get('uploaded') && (
              <Button
                onPress={popup.beginUpload}
                isDisabled={popup.state.get('uploading')}>
                Upload
              </Button>
            )}

            { popup.state.get('uploaded') && (
              <Button
                onPress={_ => popup.close()}>
                Done
              </Button>
            ) }

            { !popup.state.get('uploaded') && (
              <Button 
                onPress={_ => {
                  if (popup.state.get('uploading')) {
                    popup.cancelUpload();
                  } else {
                    popup.discard();
                    fileInputRef.current.value = '';
                  }
                }}>
                Cancel
              </Button>
            ) }
          </Buttons>
        )}
      </Group>
    </div>
  )
}




export default Popup;