import { useRef, useEffect, useState } from 'react';
import WebFrameView from 'lib/webframe/View';
import { Floater, TopBar } from './bars';
import clsx from 'clsx';
import HeightResizer from 'modules/elements/lib/components/HeightResizer';


function Editor({ controller, isActive, isGrid }) {
  const
    ref = useRef(),
    [dragging, setDragging] = useState(),
    { getSettingsValue, setSettings, isReadOnly } = controller,
    isFixed = getSettingsValue('layout') === 'fixed' && !isGrid;

  // Autorun
  useEffect(() => controller.checkAutorun(), [controller]);

  return (
    <div
      className={clsx(isGrid && 'flex flex-col h-full', 'relative')}
      ref={ref}>

      { isFixed ?
        (<FixedFrame controller={controller} />) : 
        (<DynamicFrame controller={controller} fullHeight={isGrid} />)
      }

      { !isReadOnly && (!isActive || dragging) && ( // Overlay
        <div
          className="absolute top-0 left-0 w-full h-full bg-transparent"
          onClick={_ => controller.activate()} />
      )}

      { !getSettingsValue('autoHeight') && !isGrid && (
        <HeightResizer
          onDrag={setDragging}
          onChange={(dy) => {
            let
              height = getSettingsValue(isFixed ? 'fixedHeight' : 'height') + dy,
              range = controller.settingsFields.height.range;
            height = Math.max(Math.min(height, range[1]), range[0]);
            setSettings(isFixed ? 'fixedHeight' : 'height', height);
          }} />
      ) }
    </div>
  );
}

function FixedFrame({ controller, menu }) {
  const
    { getSettingsValue } = controller,
    fixedWidth = Number(getSettingsValue('fixedWidth')),
    fixedHeight = Number(getSettingsValue('fixedHeight')),
    alignment = getSettingsValue('alignment'),
    hasBorder = getSettingsValue('hasBorder'),
    topBar = <TopBar controller={controller} />,
    floater = <Floater controller={controller} />;

  return (
    <>
      {topBar && (
        <div
          style={{ width: (fixedWidth + (hasBorder && 10)) + 'px' }}
          className={clsx(alignment === 'center' ? 'mx-auto' : alignment === 'right' ? 'ml-auto' : '')}>
          {topBar}
        </div>
      )}
      <div style={{ textAlign: alignment }}>
        <div
          className={clsx(
            'webframe-container relative overflow-auto inline-block',
            hasBorder && 'border border-rim p-[2px]'
          )}>
          <div style={{ width: fixedWidth + 'px' }} className="text-left">
            <WebFrameView
              controller={controller.webFrame}
              height={fixedHeight}
              autoHeight={false} />
            { floater }
          </div>
        </div>
      </div>
    </>
  )
}

function DynamicFrame({ controller, fullHeight, menu }) {
  const { getSettingsValue } = controller;

  return (
    <>
      <TopBar controller={controller} />
      <div
        className={clsx(
          'relative',
          fullHeight && 'flex-grow overflow-hidden',
          getSettingsValue('hasBorder') && 'border border-rim p-[4px]'
        )}>
        <WebFrameView
          controller={controller.webFrame}
          fullHeight={fullHeight}
          height={getSettingsValue('height')}
          autoHeight={getSettingsValue('autoHeight')} />

        <Floater controller={controller} />
      </div>
    </>
  )
}

export default Editor;