import { Map } from 'immutable';
import BasePopup from 'lib/popup/BasePopupController';
import View from './View';


class Popup extends BasePopup {
  static View = View;
  position = new Map({  });
  created = false;
  placement = 'bottom-right';

  constructor(args, manager) {
    super(args, manager);
    this.element = args.element;
    if (args.onClose)
      this.unloadFns.push(_ => args.onClose(this.created));
  }
}

export default Popup;