import { useState } from 'react';
import { Button } from 'react-aria-components';
import {
  HeartIcon, HeartFilledIcon, DotsVerticalIcon,
  ExclamationTriangleIcon, TrashIcon, Pencil1Icon, QuoteIcon
} from '@radix-ui/react-icons';
import MenuBuilder from 'lib/components/MenuBuilder';
import CommentInput from './CommentInput';
import { formatDistanceToNow } from 'date-fns';
import Loader from 'lib/staticPages/Loader';
import clsx from 'clsx';


export default function Entries({ controller }) {
  const
    { state } = controller,
    list = state.get('list'),
    commentLikes = state.get('commentLikes'),
    likedIds = new Set(commentLikes.map(l => l.commentId));

  return (
    <div className="flex-grow overflow-auto py-4">
      {
        list.length === 0 ?
          (<Empty />) :
          list.map(entry => (
            <Entry
              key={entry.id}
              entry={entry}
              liked={likedIds.has(entry.id)}
              controller={controller} />
          ))
      }

      <NextButton controller={controller} />
    </div>
  )
}

function NextButton({ controller }) {
  const loadingMore = controller.state.get('loadingMore');

  return controller.state.get('nextCursor') && (
    <div className="text-center">
      {loadingMore && (<Loader />)}
      {!loadingMore && (
        <Button className="text-marker outline-none" onPress={() => controller.fetchNext()}>
          more...
        </Button>
      )}
    </div>
  )
}

function Empty() {
  return (
    <div className="text-center text-pencil3">
      There are no comments yet.
    </div>
  )
}

function Entry({ entry, controller, liked }) {
  const [editing, setEditing] = useState(false);

   if (editing)
     return (
       <div>
          <CommentInput
            controller={controller}
            comment={entry.comment}
            commentId={entry.id}
            onCancel={() => setEditing(false)}
            autoFocus={true} />
        </div>
      );

  return (
    <div className="flex mb-12 pl-4 group gap-2">
{/*      <div className="pr-1 pt-1">
        <div className="rounded-full border border-pencil3 text-pencil3 p-1">
          <PersonIcon className="w-4 h-4 block" />
        </div>
      </div>
*/}      <div className="flex flex-col gap-2 flex-grow">
        <div className="flex gap-2">
          <div className="flex-grow">
            <div ref={el => { if (el) el.innerHTML = entry.comment }} />
          </div>

          <div className="flex flex-col">
            <div className="">
              <QuoteIcon className="w-6 h-6 text-rim" />
            </div>
          </div>
        </div>

        <div className="flex gap-2 items-center">
          <Likes entry={entry} controller={controller} liked={liked} />

          <div className="flex-grow"></div>
          <div className="text-sm">
            <span className="font-medium">
              @{entry.email.substr(0, 3)}
            </span>,&nbsp;
            {formatDistanceToNow(entry.createdAt.seconds * 1000)} ago
            { entry.original && (<span className="italic">, edited</span>) }
          </div>
        </div>
      </div>

      <div className={clsx('pt-1')}>
        <Menu {...{ controller, entry, setEditing }} />
      </div>
    </div>
  )
}

function Menu({ controller, entry, setEditing }) {
  const
    isOwner = controller.getUserId() === entry.userId,
    isAdmin = controller.state.get('isAdmin');

  return (
    <MenuBuilder
      options={
        isOwner ? [
          [ 'Delete', TrashIcon, () => controller.openDeletePopup(entry.id)],
          [ 'Edit', Pencil1Icon, () => setEditing(true)],
        ] :
        isAdmin ? [
          [ 'Delete', TrashIcon, () => controller.openDeletePopup(entry.id)],
        ] : [
          [ 'Report', ExclamationTriangleIcon, () => controller.reportComment(entry.id) ],
        ]}
      onOpenChange={e => { controller.checkIsAdmin(); }}>
      <DotsVerticalIcon />
    </MenuBuilder>

  )
}


function Likes({ entry, controller, liked }) {
  const
    [countDiff, setCountDiff] = useState(0),
    [override, setOverride] = useState(null),
    value = override == null ? liked : override,
    likesCount = entry.likesCount + countDiff;

  return (
    <div className="flex gap-2 text-pencil3 text-sm">
      <Button
        className="outline-none"
        onPress={() => {
          controller.likeComment(entry.id, !value);
          setOverride(!value);
          setCountDiff(countDiff + (value ? -1 : 1));
        }}>
        { value ? <HeartFilledIcon /> : <HeartIcon /> }
      </Button>

      {likesCount > 0 && likesCount}
    </div>
  )
}