import { MODE_OPTIONS, MODE_CAPTION_MAP } from 'lib/codemirror/languages';
import { useState, useEffect } from 'react';

import TextInput from 'modules/elements/lib/sidebar/inputs/TextInput';
import Selection from 'modules/elements/lib/sidebar/inputs/Selection';

import { Buttons, Button } from 'modules/elements/lib/sidebar/inputs/buttons';
import { Break, Block } from 'modules/elements/lib/sidebar/inputs/components';

import { faCaretLeft, faCaretRight, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

import settingsFields from  'modules/elements/all/File/Policies/settingsFields';

export default function FileInputs({ controller }) {
  const
    { setState } = controller,
    at = controller.currentIndex,
    fileControllers = controller.getOrderedFileControllers(),
    fileController = fileControllers.get(at),
    size = fileControllers.length;
  
  return (
    <>
      {size > 1 && (
        <>
          <Buttons className="text-right">
            <Button
              onPress={() => setState({ currentIndex: at - 1 })}
              faIcon={faCaretLeft}
              isDisabled={at === 0} />

            {at + 1} / {size}

            <Button
              onPress={() => setState({ currentIndex: at + 1 })}
              faIcon={faCaretRight}
              isDisabled={at=== size - 1 } />
          </Buttons>
        </>
      )}

      <CurrentFileInputs fileController={fileController} />

      {size > 1 && (
        <Block>
          <Buttons className="text-right">
            <Button onPress={fileController.deletePrompt} faIcon={faTrash}></Button>
          </Buttons>
        </Block>
      ) }

      <Break />

      <Block>
        <Buttons className="text-right">
          <Button onPress={controller.addNewFilePopup} faIcon={faPlus}>
            Add File
          </Button>
        </Buttons>
      </Block>
    </>
  );
}


function CurrentFileInputs({ fileController }) {
  const [, setState] = useState(fileController.state);
  useEffect(() => fileController.onStateChange(() => setState(fileController.state)));

  let suggestedModeCaption = MODE_CAPTION_MAP[fileController.getSuggestedMode()];

  return (
    <>
      <Block>
        <TextInput
          label="Filename"
          key={fileController.elementId}
          type="text"
          value={fileController.getSettingsValue('filename')}
          validateFn={fileController.validateFilename}
          onChange={filename => {
            fileController.setSettings('filename', filename);
            fileController.handleFilenameChange(filename);
          }} />
      </Block>

      <Block>
        <Selection
          key={fileController.elementId}
          label="File Type"
          options={[...MODE_OPTIONS, { type: 'break' }, { key: '', caption:  suggestedModeCaption + '' }]}
          value={fileController.getSettingsValue('mode')}
          onChange={mode => fileController.setSettings('mode', mode) } />
      </Block>

      <Block>
        <Selection
          key={fileController.elementId}
          label="Tab Spacing"
          options={settingsFields.tabMode.options}
          value={fileController.getSettingsValue('tabMode')}
          onChange={tabMode => fileController.setSettings('tabMode', tabMode) } />      
      </Block>
    </>
  )
}