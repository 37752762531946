import Editor from './Editor';
import Controller from './Controller';
import Policies from './Policies';
import Inspector from './Inspector';
import Viewer from './Viewer';
import Overview from './Overview';
import { faTable } from '@fortawesome/free-solid-svg-icons';

const index = {
  caption: 'Table',
  faIcon: faTable,
  description: 'Table',
  type: 'table',

  Editor,
  Inspector,
  Controller,
  Policies,
  Viewer,
  Overview
}

export default index;