import { useControllerState } from 'lib/BaseController';
import { Block, Break } from 'modules/elements/lib/sidebar/inputs/components';
import TextInput from 'modules/elements/lib/sidebar/inputs/TextInput';
import Textarea from 'modules/elements/lib/sidebar/inputs/Textarea';
import Selection from 'modules/elements/lib/sidebar/inputs/Selection';
import { StatusBar } from 'modules/elements/lib/sidebar/statusbar';


export default function PageInspector({ articleController }) {
  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex-grow overflow-auto px-2 py-2">
        <Block>
          <TextInput
            label="Article Slug"
            type="text"
            value={'/' + articleController.getInState(['article', 'slug'])}
            onChange={v => {}}
            isDisabled={true} />
        </Block>

        <Block>
          <Textarea
            label="Article Title"
            value={articleController.getInState(['article', 'title'])}
            onChange={v => articleController.setInState(['article', 'title'], v)}
            onBlur={_ => articleController.saveArticleStateKey('title') } />
        </Block>

        <Break />

        <Block>
          <Selection
            label="Layout"
            value={articleController.getInState(['article', 'layout', 'type']) || 'scroll'}
            options={[
              { key: 'scroll', caption: 'Scroll' },
              { key: 'grid', caption: 'Grid' },
            ]}
            onChange={v => {
              articleController.layout.switchType(v);
            }} />
        </Block>

        { articleController.layout.type === 'grid' && (
          <GridInputs articleController={articleController} />
        ) }
      </div>

      <StatusBar
        saving={articleController.state.get('saving')}
        error={articleController.state.get('saveError')} />
    </div>
  )
}


function GridInputs({ articleController }) {
  const
    { grid } = articleController.layout,
    cellSize = grid.getConfig('cellSize'),
    cellGap = grid.getConfig('cellGap'),
    dim = grid.getConfig('dim');

  useControllerState(grid);
  return (
    <>
      <Block span="half">
        <TextInput
          label="Rows"
          type="number"
          value={dim.rows}
          range={[0, 40]}
          onChange={v => {
            grid.saveIn(['config', 'dim'], {rows: v, cols: dim.cols})}
          } />
      </Block>

      <Block span="half">
        <TextInput
          label="Columns"
          type="number"
          value={dim.cols}
          range={[0, 40]}
          onChange={v => {
            grid.saveIn(['config', 'dim'], {rows: dim.rows, cols: v})}
          } />
      </Block>

      <Block span="half">
        <TextInput
          label="Cell Width"
          type="number"
          value={cellSize.width}
          unit={'pts'}
          range={[5, 50]}
          onChange={v => grid.saveIn(['config', 'cellSize'], {width: v, height: cellSize.height})} />
      </Block>

      <Block span="half">
        <TextInput
          label="Cell Height"
          type="number"
          value={cellSize.height}
          unit={'pts'}
          range={[5, 50]}
          onChange={v => grid.saveIn(['config', 'cellSize'], {width: cellSize.width, height: v})} />
      </Block>

      <Block span="half">
        <TextInput
          label="Cell Gap"
          type="number"
          value={cellGap.x}
          unit={'pts'}
          range={[0, 10]}
          onChange={v => grid.saveIn(['config', 'cellGap'], {x: v, y: v})} />
      </Block>
    </>
  )
}