import CommentInput from './CommentInput';
import Entries from './Entries';
import Topbar from './Topbar';


export default function Body({ controller, viewer }) {
  return (
    <div className="flex flex-col flex-grow gap-2">
      <Topbar controller={controller} viewer={viewer} />
      <CommentInput controller={controller} commentId={null} comment={''} />
      <Entries controller={controller} />
    </div>
  )
}