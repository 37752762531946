import { ui } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';
import { Buttons, Button } from 'lib/inputs/buttons';
import VersionItem from '../ManageVersions/View/VersionItem';

import './style.css';


function View({ popup }) {
  let
    state = useControllerState(popup),
    loading = state.get('loading');

  return (
    <ui.Popup popup={popup} className="delete-version-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Delete Version
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.deleteVersion(); }}>
            <ui.Warning title="Warning!">
              Are you sure you want to delete this version?
              { popup.args.isPublished && (
                <div>
                  <br />
                  This version is currently <u>published</u>.<br/>
                  Deleting it will unpublish your article.
                </div>
              ) }
            </ui.Warning>
            <VersionItem version={popup.args.version} />
            
            <ui.Alert />

            <Buttons className="text-right">
              <Button type="submit"disabled={loading}>Delete</Button>
              <Button onPress={e => { popup.close() }}>Cancel</Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  );
}

export default View;