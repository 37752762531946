import BaseController from 'lib/BaseController';
import { toHuman } from 'lib/firebaseError';
import { fromJS } from 'immutable';
import Popups from './Popups';
import Layout from './Layout';


export default class Controller extends BaseController {
  constructor({ app }) {
    super();
    this.app = app;
    this.popupManager = app.popupManager;
    this.popups = new Popups(this);
    this.unloadFns = [];
    this.state = this.state.merge({
      self: null, childrenFolders: null, childrenArticles: null,
      connect1: false, connect2: false, connect3: false
    });
  }

  load = async ({ folderId, domainId, provider }) => {
    console.log('loading folder', domainId, folderId);
    if (this.id === folderId) {
      console.log('folder already loaded', folderId);
      return;
    } else if (this.id) {
      this.unload();
    }
    this.id = folderId;
    this.domainId = domainId;
    this.provider = provider;
    this.layout = new Layout(this);
    
    const onError = e => {
      console.error(e);
      this.setState({ error: toHuman(e) });
    }
    this.unloadFns.push(
      provider.onFolder(domainId, folderId,
        data => this.setState({ self: fromJS(data), connect1: true }), onError),
      provider.onChildrenFolders(domainId, folderId,
        data => this.setState({ childrenFolders: fromJS(data), connect2: true }), onError),
      provider.onChildrenArticles(domainId, folderId,
        data => this.setState({ childrenArticles: fromJS(data), connect3: true }), onError),
    );
  }

  get isLoaded() {
    const g = v => this.state.get(v);
    return g('self') && g('childrenFolders') && g('childrenArticles') && this.isConnected;
  }

  get isConnected() {
    const g = v => this.state.get(v);
    return g('connect1') && g('connect2') && g('connect3');
  }

  unload = () => {
    if (!this.id)
      return;
    console.log('unloading folder', this.id);
    this.unloadFns.forEach(fn => fn());
    this.unloadFns = [];
    this.id = null;
    this.setState({ connect1: false, connect2: false, connect3: false, error: false });
  }
  
  setSelfState = (obj) => {
    this.setState({ self: this.state.get('self').merge(obj) });
  }

  saveSelfKey = (key) => {
    var value = this.state.get('self').get(key);
    if (value.toJS)
        value = value.toJS(); // hacky
    return this.provider.updateFolder({
      domainId: this.domainId,
      folderId: this.id,
      data: { [key]: value }
    });
  }

  getItem = (id) => {
    return (
      this.state.get('childrenArticles').find(d => d.get('id') === id) ||
      this.state.get('childrenFolders').find(d => d.get('id') === id)
    )
  }
}