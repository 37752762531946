import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { fetchDomain } from 'api/remote/calls/domain';
import Loader from 'lib/staticPages/Loader';
import DomainLink from './DomainLink';


export default function UserDomains({ app }) {
  const
    { state } = app.auth,
    domainOrder = app.auth.getDomainOrder(),
    domainsLoading = state.get('domainsLoading'),
    domainsLoadingError = state.get('domainsLoadingError');
  
  useEffect(() => { app.auth.loadUserDomains(); }, [app.auth]);

  if (domainsLoadingError)
    return (<div className="error">There was an error loading your workspaces.</div>);

  if (domainsLoading || !domainOrder)
    return (<div className="text-center"><Loader /></div>)

  const isEmpty = domainOrder.length === 0;

  return (
    <>
      { !isEmpty && (
        <SortableIndex
          domainIds={domainOrder}
          setDomainIds={(domainOrder) =>{ app.auth.saveDomainOrder(domainOrder); }} />
      ) }
      { isEmpty && (
        <div className="p-4 bg-wax text-center">
          No cloud workspace found
        </div>
      ) }
    </>
  );
}

function SortableIndex({ domainIds, setDomainIds }) {
  const
    half = Math.ceil(domainIds.length / 2),
    onDragEnd = ({ source, destination }) => {
      if (!source || !destination)
        return;

      const
        sourceIndex = source.index,
        destinationIndex = destination.index;

      if (sourceIndex !== destinationIndex) {
        domainIds = domainIds.slice();
        var item = domainIds[sourceIndex];
        domainIds.splice(sourceIndex, 1)
        domainIds.splice(destinationIndex, 0, item);
        setDomainIds(domainIds);
      }
    }

  return (
    <div className="flex max-md:flex-col">
      <DragDropContext onDragEnd={onDragEnd}>
        <DomainDropContainer droppableId="domains-1" list={domainIds.slice(0, half)} offset={0} />
        <DomainDropContainer droppableId="domains-2" list={domainIds.slice(half)} offset={half} />
      </DragDropContext>
      <div className="clearfix"></div>
    </div>
  )
}

function DomainDropContainer({ droppableId, list, offset }) {
  return (
    <Droppable droppableId={droppableId}>
      {provided => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="droppable flex-grow basis-1">
          { list.map((domainId, i) => (
            <Draggable key={domainId}  draggableId={domainId} index={i + offset}>
              {provided => (
                <div {...provided.draggableProps} style={provided.draggableProps.style}>
                  <div ref={provided.innerRef} {...provided.dragHandleProps}>
                    <DomainLinkLoader domainId={domainId} />
                  </div>
                </div>
              )}
            </Draggable>
          )) }
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

function DomainLinkLoader({ domainId }) {
  const
    [domain, setDomain] = useState(null);
    // emptySVG = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E`;

  useEffect(_ => { fetchDomain({ domainId }).then(setDomain); }, [domainId]);
  return (
    domain ?
      (<DomainLink slug={domain.slug} title={domain.title} imagePath={null /*domain.cover160*/}/>) :
      (<DomainLink loading={true} />)
  );
}