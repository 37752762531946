import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class DeleteFolderController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });
  constructor(args, manager) {
    super(args, manager);
    this.provider = args.provider;
    this.folder = args.folder;
  }

  deleteFolder = async () => {
    this.setState({ loading: true, alert: '' });
    try {
      await this.provider.deleteFolder({
        folderId: this.folder.get('id'),
        domainId: this.folder.get('domainId')
      });
      this.close();
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }

}

export default DeleteFolderController;