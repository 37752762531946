import ui from 'lib/popup/components/ui';
import { Buttons, Button } from 'lib/inputs/buttons';
import { useControllerState } from 'lib/BaseController';

export default function Popup({ popup }) {
  useControllerState(popup);
  const
    keys = popup.caching.remoteCache.getAllKeys();

  return (
    <ui.Popup popup={popup}>
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Cache Manager
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
        <div className="pt-2">
          <div className="text-sm font-medium">Cached keys</div>

          <div className="p-2 border my-2 min-h-16 max-h-20 text-xs leading-relaxed overflow-auto">
            {keys.map((k, i) => <span className="break-words mr-2 bg-wax2" key={i}>{k}</span>)}
            {keys.length === 0 && <div className="opacity-50">All clear!</div>}
          </div>

          <Buttons className="text-right">
            <Button
              isDisabled={keys.length === 0}
              onClick={() => popup.clearCache()}>
              Clear All
            </Button>
          </Buttons>
        </div>

        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}