import Domains from './Domains';
import Domain from './Domain';
import { useMatch } from 'react-router-dom';

export default function Workspaces({ app }) {
  const
    match = useMatch({ path: '/workspaces/*' }),
    path = match.params['*'];

  return (path ? <Domain path={path} app={app} /> : <Domains app={app} />);
}

/*
<Route path="upgrade/*" element={<Upgrade app={app} />} />
<Route path="theme/*" element={<Theme app={app} />} />*/