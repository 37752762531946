import BaseController from 'lib/BaseController';
import startDragging from 'lib/startDragging';
import { fromJS } from 'immutable';


export default class ElementTransformer extends BaseController {
  constructor(grid) {
    super();
    this.grid = grid;
    this.state = this.state.merge({
      id: null,
      moving: false,
      resizing: false,
    });
  }

  // Grid
  onMoverMouseDown = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { selection } = this.grid.layout;
    if (!selection.selectedElements.has(id)) {
      selection.setSelectedElementId(id);
      selection.focusPage();
    }

    this.setState({ id: id, moving: true, dx: 0, dy: 0 });
    this.startDrag(e);
  }

  onResizerMouseDown = (e, id) => {
    e.preventDefault();
    this.setState({ id: id, resizing: true, dx: 0, dy: 0 });
    this.startDrag(e);
  }

  startDrag = (event) => {
    startDragging({
      event: event,
      onMove: (e, dx, dy) => this.setState({ dx, dy }),
      onEnd: () => this.commit(),
      camera: this.grid.camera
    });
  }

  commit = () => {
    var
      { dx, dy, moving, resizing, id } = this.state.toJS(),
      rect = this.grid.getGridElement(id),
      { x, y, w, h } = rect.toJS(),
      { cellSize, cellGap } = this.grid,
      ddx = Math.floor(0.5 + dx / (cellSize.width + cellGap.x)),
      ddy = Math.floor(0.5 + dy / (cellSize.height + cellGap.y));

    if (resizing) {
      w = Math.max(1, w + ddx);
      h = Math.max(1, h + ddy);
      this.grid.saveGridElement({ id, w, h, x, y });
    } else if (moving) {
      this.moveSelectedElements(ddx, ddy);
    }

    this.setState({ moving: false, resizing: false, id: null });
  }

  moveSelectedElements(dx, dy) {
    const { grid } = this;
    var elements = grid.grid.getIn(['elements']);
    grid.layout.selection.selectedElements.forEach(id => {
      const {x, y, w, h } = grid.getGridElement(id).toJS();
      elements = elements.set(id, fromJS({ id, x: x + dx, y: y + dy, w, h }));
    })
    grid.setIn(['elements'], elements);
    grid.save();
  }
}