import { useState } from 'react';
import { ui } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';

import SlugCheckInput from 'lib/inputs/SlugCheckInput';
import { Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';

// import FolderBar from 'lib/misc/FolderBar';
// import ArticleBar from 'lib/misc/ArticleBar';
// import VersionBar from 'lib/misc/VersionBar';
// import DomainBar from 'lib/misc/DomainBar';

function Popup({ popup }) {
  let
    state = useControllerState(popup),
    loading = state.get('loading'),
    { version, article, folder, domain } = popup,
    item = version || article || folder || domain,
    itemType = (
      version ? 'Version' : 
        article ? 'Article' :
          folder ? 'Folder' :
            domain ? 'Domain' : ''
    ),
    path = item.get('path') || '',
    original = item.get('slug'),
    splits = path.split('\\'),
    basePath = splits.slice(0, splits.length - 1).join('\\'),
    [slug, setSlug] = useState(original),
    [valid, setValid] = useState(false);


  return (
    <ui.Popup popup={popup} className="rename-slug-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Rename Slug
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.renameSlug(slug); }}>
            <ui.Warning title="Warning!">
              You are about to rename the slug. <br />
              This might lead to unintended behaviors.
            </ui.Warning>

            <Group>
              <div className="text-sm font-medium mb-1">{itemType}</div>
              <ui.Item
                itemType={itemType}
                title={item.get('title') || item.get('label')}
                slug={item.get('slug')} />
            
              <SlugCheckInput
                label="Slug"
                value={slug}
                onChange={setSlug}
                onValidChange={setValid}
                original={original}
                autoFocus={true}
                checkFn={(val) => popup.checkAvailable(basePath + '\\' + val)} />             
            </Group>

            <ui.Alert />

            <Buttons className="text-right">
              <Button isDisabled={loading || !slug || !valid} type="submit">
                Rename Slug
              </Button>
              <Button onPress={e => { popup.close() }}>Cancel</Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;