import Inspector from 'modules/article/views/Inspector';
import Overview from 'modules/article/views/Overview';
import FolderPanel from '../FolderPanel';


const map = {
  folder: { Render: FolderPanel, title: 'Folder' },
  inspector: { Render: Inspector, title: 'Inspector' },
  overview: { Render: Overview, title: 'Contents' },
}

export default map;
