import { Frame } from 'modules/app/views/Home/misc/components';
import { Button } from 'lib/inputs/buttons';
// import dino from 'api/local/media/dino.jpeg';
import { ReactComponent as Dino } from 'api/local/media/dino.svg';

import { Link } from 'react-aria-components';
import UserDomains from './UserDomains';
import DomainLink from './DomainLink';
import CreateDomain from 'modules/app/popups/CreateDomain/Controller';

import { PlusIcon } from '@radix-ui/react-icons';


export default function DomainList({ app }) {
  const user = app.auth.user;

  return (
    <Frame className="max-w-[50rem] px-8 py-8 flex flex-col">
      <div className="text-2xl font-medium mb-5 ml-4">My Workspaces</div>
      <div className="flex-grow w-full mb-3 gap-2 border border-rim2 bg-paper2 py-2 px-4 overflow-auto pb-6 rounded-sm">
        { user && <UserDomains app={app} /> }
        { !user && (
          <div className="p-4 bg-wax text-center">
            <Link href="/auth" className="font-bold">Login</Link> to view your cloud workspaces.
          </div>
        ) }
      </div>

      <AddDomain app={app} />

      <p className="">
        Or open your brower's&nbsp;
        <Link href='/workspaces/local' className="font-medium text-pen">
          offline workspace
        </Link>.
      </p>

      <div className="offline-domain mt-3 max-w-[20rem] border-rim border px-4 py-2 bg-paper2">
        <DomainLink slug="local" title="Offline Workspace" Icon={Dino} />
      </div>
    </Frame>
  )
}


function AddDomain({ app }) {
  const
    domainOrder = app.auth.getDomainOrder(),
    isEmpty = domainOrder && domainOrder.length === 0;

  return (
    <div className="text-right">
      <Button
        isDisabled={!app.auth.user}
        onPress={() => app.popupManager.open(CreateDomain, {
          auth: app.auth,
          isPrimary: isEmpty,
          checkAvailable: app.auth.checkPathAvailable
        })}>
        <div className="flex gap-2 items-center">
          <PlusIcon className="inline-block" />
          <div>Create a New Workspace</div>          
        </div>
      </Button>      
    </div>
  );
}