import {
  wrapIn, setBlockType, chainCommands, toggleMark, exitCode,
  joinUp, joinDown, lift,
  // selectParentNode
} from 'prosemirror-commands'
import {
  wrapInList, splitListItem, liftListItem, sinkListItem
} from 'prosemirror-schema-list'
import { undo, redo } from 'prosemirror-history'
import { undoInputRule } from 'prosemirror-inputrules'
import { Command } from 'prosemirror-state'
import { Schema } from 'prosemirror-model'

const isMac = typeof navigator != 'undefined' && /Mac|iP(hone|[oa]d)/.test(navigator.platform)

export function buildKeymap(schema: Schema, mapKeys?: {[key: string]: false | string}) {
  let keys: {[key: string]: Command} = {}
  function bind(key: string, cmd: Command) {
    if (mapKeys) {
      let mapped = mapKeys[key]
      if (mapped === false) return
      if (mapped) key = mapped
    }
    keys[key] = cmd
  }

  bind('Mod-z', undo)
  bind('Shift-Mod-z', redo)
  bind('Backspace', undoInputRule)
  if (!isMac) bind('Mod-y', redo)

  bind('Alt-ArrowUp', joinUp)
  bind('Alt-ArrowDown', joinDown)
  bind('Mod-BracketLeft', lift)
  // bind('Escape', selectParentNode)
  bind('Mod-b', toggleMark(schema.marks.strong))
  bind('Mod-B', toggleMark(schema.marks.strong))
  bind('Mod-i', toggleMark(schema.marks.em))
  bind('Mod-I', toggleMark(schema.marks.em))
  bind('Mod-u', toggleMark(schema.marks.underline))
  bind('Mod-U', toggleMark(schema.marks.underline))
  bind('Mod-`', toggleMark(schema.marks.code))
  bind('Shift-Ctrl-8', wrapInList(schema.nodes.bullet_list))
  bind('Shift-Ctrl-9', wrapInList(schema.nodes.ordered_list))
  bind('Ctrl->', wrapIn(schema.nodes.blockquote))

  let
    br = schema.nodes.hard_break,
    cmd = chainCommands(exitCode, (state, dispatch) => {
      if (dispatch)
        dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView())
      return true
    })
  bind('Mod-Enter', cmd)
  bind('Shift-Enter', cmd)
  if (isMac)
    bind('Ctrl-Enter', cmd)


  bind('Enter', splitListItem(schema.nodes.list_item))
  bind('Mod-[', liftListItem(schema.nodes.list_item))
  bind('Mod-]', sinkListItem(schema.nodes.list_item))
  bind('Shift-Ctrl-0', setBlockType(schema.nodes.paragraph))
  bind('Shift-Ctrl-\\', setBlockType(schema.nodes.code_block))

  for (let i = 1; i <= 6; i++)
    bind('Shift-Ctrl-' + i, setBlockType(schema.nodes.heading, {level: i}))

  let hr = schema.nodes.horizontal_rule
  bind('Mod-_', (state, dispatch) => {
    if (dispatch) dispatch(state.tr.replaceSelectionWith(hr.create()).scrollIntoView())
    return true
  })

  return keys
}