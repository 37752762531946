export const excalidrawUrl = (
  process.env.REACT_APP_ENV === 'emulator' ?
    'http://localhost:3003':
    'https://xenote-excalidraw.web.app'
);

export const webFrameUrl = (
  process.env.REACT_APP_ENV === 'emulator' ?
    '/external/webframe/index.html' :
    'https://xenote-webframe.web.app'
);

export const getBabel = async () => {
  // moved to index for now
  // await import('http://localhost:3000/babel.min.js');
  // await import('https://unpkg.com/@babel/standalone@^7.0.0/babel.min.js');  
}

export const firebaseConfig = window.firebaseConfig = {
  apiKey: 'AIzaSyBevvhXRHfpUxou_3A0TpPVw-611qbb3LE',
  authDomain: 'xenote-app.firebaseapp.com',
  projectId: 'xenote-app',
  storageBucket: 'xenote-app.appspot.com',
  messagingSenderId: '929485212007',
  appId: '1:929485212007:web:f0a184c284180a07b4c7c2',
  measurementId: 'G-N7KX7HC5E5'
};


export const firebaseEmuPorts = {
  firestore: 5001,
  functions: 5002,
  auth: 5003,
  storage: 5004,
  hosting: 5005
}