import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { BellIcon, MagnifyingGlassIcon, RocketIcon, Component1Icon, BookmarkIcon } from '@radix-ui/react-icons';
import Logo from 'lib/Logo';
import clsx from 'clsx';


export default function Container({ app, children }) {
  const marked = app.auth.state.getIn(['userInfo', 'unreadNotes']);

  return (
    <div className="home flex max-md:flex-col-reverse h-full">
      <div className="home-nav bg-paper">
        <div className="home-buttons flex h-full md:pb-6 border-rim md:flex-col max-md:justify-center md:border-r max-md:border-t">
          <div className="md:flex-grow hidden md:block">
            <div className="pt-6 text-center">
              <Link to="/">
                <Logo className="w-8 h-8 text-pen inline-block" />
              </Link>
            </div>
          </div>

          <LeftButton caption="Workspaces" Ico={Component1Icon} to="/workspaces" />
          <LeftButton caption="Explore" Ico={MagnifyingGlassIcon} to="/explore" />
          <LeftButton caption="Demos" Ico={RocketIcon} to="/demos" />
          <LeftButton caption="Bookmarks" Ico={BookmarkIcon} to="/user/bookmarks" />
          <LeftButton caption="Notifications" Ico={BellIcon} to="/user/notifications" marked={marked} />
        </div>       
      </div>

      <div className="flex-grow overflow-hidden">
        {children}
      </div>
    </div>
  )  
}

function LeftButton({ caption, iconType, to, marked, Ico }) {
  const
    { pathname } = useLocation(),
    active = pathname.startsWith(to);
  return (
    <Link
      to={to}
      className={clsx(
        'home-button text-center mx-2 py-2 text-sm relative',
        'min-w-20',
        // 'bg-wax pt-2 pb-1 px-1 border-r-4',
        // active ? 'border-rim' : 'border-gray-100',
        active ? 'bg-wax font-bold text-pencil' : 'font-medium text-pencil2'
      )}>
      { marked && <div className="absolute w-1.5 h-1.5 bg-pencil2 rounded border-pencil2 top-2 right-6"></div> }
      <div className="inline-block">
        {Ico && <Ico className="h-6 w-6" />}
      </div>
      <div>
        {caption}
      </div>
    </Link>
  )
}