import MenuBuilder from 'lib/components/MenuBuilder';
import { Buttons, Button } from 'lib/inputs/buttons';
import {
  HamburgerMenuIcon, PlusIcon, GlobeIcon, TrashIcon,
  Pencil1Icon, Pencil2Icon, CounterClockwiseClockIcon, OpenInNewWindowIcon } from '@radix-ui/react-icons';


export default function VersionButtons({ popup, publishedId }) {
  const
    selectedId = popup.state.get('selectedId'),
    versions = popup.state.get('versions'),
    version = versions && versions.find(v => v.get('id') === selectedId),
    published = version && version.get('id') === publishedId;

  return (
    <Buttons className="text-right p-2 pt-3 border-t border-rim">
      {!version ? (
        <>
          <span>
            Select a Version <strong>or</strong>&nbsp;&nbsp;&nbsp;
          </span>
          <Button onPress={_ => popup.articleController.popups.createVersionPopup()}>
             <PlusIcon className="inline" />&nbsp;Create New
          </Button>
        </>
      ) : (
        <>
          <Button onPress={_ => { popup.openPublishPopup({ version }); }}>
            <GlobeIcon className="inline" />&nbsp; {published ? 'Unpublish' : 'Publish'}
          </Button>

          <Button onPress={_ => { popup.openDeletePopup({ version }); }}>
            <TrashIcon className="inline" />&nbsp; Delete
          </Button>

          
          <VersionMenu version={version} popup={popup} />
        </>
      )}
       
    </Buttons>
  );
}

function VersionMenu({ version, popup }) {
  const options = [
    [
      'View this Version',
      OpenInNewWindowIcon,
      () => {},
      { href: `/${version.get('path')}`, target: '_blank', isDisabled: !version.get('isPublic') }
    ],
    [
      'Restore to this Version',
      CounterClockwiseClockIcon,
      () => popup.openRevertPopup({ version })
    ],
    [
      'Edit Info and Privacy',
      Pencil2Icon,
      () => popup.openEditPopup({ version })
    ],
    [
      'Rename Slug',
      Pencil1Icon,
      () => popup.openRenameSlugPopup({ version })
    ],
  ];
  return (
    <MenuBuilder options={options} className="py-2 px-2 border border-rim3 rounded-sm inline-block text-sm">
      <HamburgerMenuIcon className="inline" />&nbsp; More
    </MenuBuilder>
  )
}