
const startDragging = ({ event, onMove, onEnd, camera }) => {
  event.preventDefault();

  var origin = {
    x: event.pageX,
    y: event.pageY,
    cx: camera && camera.getX(),
    cy: camera && camera.getY()
  };
  
  const onMouseMove = (e) => {
    const
      dx = e.pageX - origin.x - (camera ? (origin.cx - camera.getX()) : 0),
      dy = e.pageY - origin.y - (camera ? (origin.cy - camera.getY()) : 0);
    onMove(e, dx, dy);
  }
  
  const stopDrag = (e) => {
    const
      dx = e.pageX - origin.x - (camera ? (origin.cx - camera.getX()) : 0),
      dy = e.pageY - origin.y - (camera ? (origin.cy - camera.getY()) : 0);

    onEnd(e, dx, dy);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', stopDrag);
  }
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', stopDrag);
}

export default startDragging;