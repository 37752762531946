import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';

class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  constructor(args, manager) {
    super(args, manager);
    this.domainId = args.domain.get('id');
    this.provider = args.provider;
    this.state = this.state.merge({
      email: '',
      role: 'read',
      list: null
    });
  }

  async loadAccessList() {
    this.setState({ loadingList: true, loading: true, alert: '' });
    try {
      const result = (await this.provider.getDomainAccessList(this.domainId));
      this.setState({ list: (result && result.data) });
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loadingList: false, loading: false });
  }
  
  async addDomainAccess() {
    this.setState({ loading: true });
    const
      email = this.state.get('email'),
      role = this.state.get('role'),
      domainId = this.domainId;
    
    this.tryThis(async _ => {
      await this.provider.addDomainAccess({ domainId, email, role });
      await this.loadAccessList();
      this.setState({ email: '', role: 'read' });
    });
  }

  async deleteUserAccess({ userId }) {
    this.tryThis(async _ => {
      await this.provider.deleteDomainUserAccess({ domainId: this.domainId, userId })
      await this.loadAccessList();
    });
  }

  async updateDomainUserRole({ userId, role }) {
    console.log(userId, role);
    this.tryThis(async _ => {
      await this.provider.updateDomainUserRole({ domainId: this.domainId, userId, role });
    });
  }

  async tryThis(fn) {
    this.setState({ loading: true, alert: '' });
    try {
      await fn();
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }
}

export default PopupController;