import { Map } from 'immutable';
import BasePopup from 'lib/popup/BasePopupController';
import View from './View';
import { list } from 'modules/elements/all';


class Popup extends BasePopup {
  static View = View;
  position = new Map({ width: 380 });
  created = false;

  constructor(args, manager) {
    super(args, manager);
    this.createNewElement = args.createNewElement;
    
    this.state = this.state.merge({ selected: 0 });
    if (args.onClose)
      this.unloadFns.push(_ => args.onClose(this.created));
  }

  handleInputKeyDown = (event) => {
    const key = event.key;

    if (this.state.get('selected') === null)
      return this.select(0);
    else if (key === 'ArrowUp')
      this.moveSelection(-3);
    else if (key === 'ArrowDown')
      this.moveSelection(3);
    else if (key === 'ArrowLeft')
      this.moveSelection(-1);
    else if (key === 'ArrowRight')
      this.moveSelection(1);
    else if (key === 'Enter')
      this.addSelection();
  }

  moveSelection(i) {
    const
      s = this.state.get('selected') || 0,
      r = s + i;
    
    if (
      r < 0 ||
      r >= list.length ||
      (Math.floor(r/3) !== Math.floor(s/3) && ((r - s) % 3 !== 0))
    )
      return;
    
    this.select(r);
  }

  select(i) {
    this.setState({ selected: i });
  }

  addSelection() {
    const i = this.state.get('selected');
    if (i !== null)
      this.addElement(list[i].type);
  }
  
  addElement(type) {
    this.setState({ loading: true, alert: '' });

    this.createNewElement({ type })
      .then(_ => this.created = true)
      .then(_ => this.close())
      .catch(e => {
        console.error(e);
        this.setState({ alert: 'Error! ' + e.message, loading: false });
      });
  }
}

export default Popup;