import {
  collection, query, doc, onSnapshot, where, updateDoc, getDoc, getDocs
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../firebase';


export function onFolder(domainId, folderId, cb, ecb) {
  const ref = doc(db, 'domains', domainId, 'folders', folderId)
  return onSnapshot(ref, snap => cb({ id: snap.id, ...snap.data() }), ecb);
}

const toObject = (qss) => qss.docs.map(doc => ({ id: doc.id, ...doc.data() }));

export function onChildrenFolders(domainId, folderId, cb, ecb) {
  const q = query(collection(db, 'domains', domainId, 'folders'), where('parentId', '==', folderId))
  return onSnapshot(q, qss => cb(toObject(qss)), ecb);
}

export function onChildrenArticles(domainId, folderId, cb, ecb) {
  const q = query(collection(db, 'domains', domainId, 'articles'), where('parentId', '==', folderId));
  return onSnapshot(q, qss => cb(toObject(qss)), ecb)
}

export async function createFolder({ domainId, parentId, title, slug }) {
  return await httpsCallable(functions, 'createFolderCall')({ domainId, parentId, slug, title });
}

export async function createArticle({ domainId, parentId, slug, title, layoutType }) {
  return await httpsCallable(functions, 'createArticleCall')({ domainId, parentId, slug, title, layoutType });
}

export async function updateFolder({ domainId, folderId, data }) {
  return await updateDoc(doc(db, 'domains', domainId, 'folders', folderId), data);
}

export async function deleteArticle({ domainId, articleId }) {
  return await httpsCallable(functions, 'deleteArticleCall')({ domainId, articleId })
}

export async function deleteFolder({ domainId, folderId }) {
  return await await httpsCallable(functions, 'deleteFolderCall')({ domainId, folderId })
}

export async function renameSlug({ slug, versionId, articleId, folderId, domainId }) {
  return await await httpsCallable(functions, 'renameSlugCall')({
    slug, versionId, articleId, folderId, domainId
  });
}

export async function moveToFolder({ domainId, itemId, itemType, newParentId }) {
  return await httpsCallable(functions, 'moveToFolderCall')({ domainId, itemId, itemType, newParentId });
}

export async function fetchFolder({ domainId, folderId }) {
  const snap = await getDoc(doc(db, 'domains', domainId, 'folders', folderId));
  return (snap.exists() ? ({ id: snap.id, ...snap.data() }) : null);
}

export async function fetchChildrenFolders({ domainId, folderId, isPublic }) {
  let q = query(collection(db, 'domains', domainId, 'folders'), where('parentId', '==', folderId));
  if (isPublic)
    q = query(q, where('isPublic', '==', true));
  return await getDocs(q).then(qss => toObject(qss));
}

export async function fetchChildrenArticles({ domainId, folderId, isPublic }) {
  let q = query(collection(db, 'domains', domainId, 'articles'), where('parentId', '==', folderId));
  if (isPublic)
    q = query(q, where('publishedVersionId', '!=', null));
  return await getDocs(q).then(qss => toObject(qss));
}