import { useState, useEffect, Fragment } from 'react';
import { CardStackIcon, DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Button, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';

import './PathSelector.css';

function PathSelector({ label, value, isDisabled, onChange, omitIds, fetchPath, fetchChildrenFolders }) {
  return (
    <div className="my-3">
      <div className="mb-1 text-sm font-medium">{ label }</div>
      <div className="input">
        { value.map((v, i) => (
          <Fragment key={i}>

            <Button
              key={i}
              className="text-sm px-1 py-1 inline-block font-mono text-marker data-[disabled]:text-pencil2"
              onClick={() => onChange(value.slice(0, i + 1))}
              isDisabled={isDisabled || i >= value.length - 1}>
              <CardStackIcon className="inline mr-2" />
              {v}
            </Button>

            <div className="slash inline-block font-xs font-mono">/</div>
          </Fragment>
        )) }

        { !isDisabled && (
          <AddMenu {...{ value, onChange, omitIds, fetchPath, fetchChildrenFolders }} />
        ) }
      </div>
    </div>
  )
}

function AddMenu({ fetchPath, fetchChildrenFolders, omitIds, value, isDisabled, onChange }) {
  const [folders, setFolders] = useState(null);

  useEffect(() => {
    fetchPath(value.join('\\'))
      .then(s => fetchChildrenFolders({ folderId: s.folderId, domainId: s.domainId }))
      .then(docs => setFolders(docs))
      .catch(e => console.error(e));
  }, [value, fetchChildrenFolders, fetchPath, omitIds])


  return (
    <MenuTrigger>
      <Button className="ml-2 text-marker px-1 py-1 rounded align-middle">
        <DotsHorizontalIcon className="" />
      </Button>
      <Popover>
        { folders === null ? (
            <div className="bg-paper px-3">...</div>
          ) :
            folders.length === 0 ? (
              <div className="border-rim2 border bg-paper py-2 px-4 cursor-default text-sm text-pencil3">No Sub Folders</div>
            ) : (
              <Menu
                onAction={(id) => onChange(value.concat(folders.find(f => f.id === id).slug))}
                disabledKeys={[]}>
                { folders.map((folder, i) => (
                  <MenuItem
                    className="mb-1 py-1 px-3 border-rim2 border bg-paper data-[disabled]:text-pencil3 cursor-pointer"
                    id={folder.id}
                    key={folder.id}>
                    <CardStackIcon className="inline mr-2" /> { folder.title }
                  </MenuItem>
                )) }
              </Menu>
            ) }
      </Popover>
    </MenuTrigger>
  );
}

export default PathSelector;