import { useControllerState } from 'lib/BaseController';


export default function Adder({ grid }) {
  const
    { cellSize, cellGap, adder } = grid,
    state = useControllerState(adder);

  if (!state.get('dragging'))
    return '';

  return (
    <>
      <div
        className="absolute pointer-events-none opacity-20 bg-orange-600 z-10"
        style={{
          left: state.get('ax') * (cellSize.width + cellGap.x) - cellGap.x / 2,
          top: state.get('ay') * (cellSize.height + cellGap.y) - cellGap.y / 2,
          width: state.get('aw') * (cellSize.width + cellGap.x),
          height: state.get('ah') * (cellSize.height + cellGap.y)
        }}></div>
    </>
  )
}