import Prompt from 'lib/popup/popups/Prompt/Controller';
import RichText from 'lib/popup/popups/RichText/Controller';
import UploadImage from 'modules/app/popups/UploadImage/Controller';
import CreateArticle from 'modules/folder/popups/CreateArticle/Controller';
import CreateFolder from 'modules/folder/popups/CreateFolder/Controller';


export default class Popups {
  constructor(folderController) {
    this.folderController = folderController;
  }

  get popupManager() {
    return this.folderController.popupManager;
  }

  get folderId() {
    return this.folderController.id;
  }

  get provider() {
    return this.folderController.provider;
  }

  get path() {
    return this.folderController.state.getIn(['self', 'path']);
  }

  editTitle({ folder, article }) {
    const type = folder ? 'Folder' : 'Article';

    this.popupManager.open(Prompt, {
      title: `Edit ${type} Title`,
      submitLabel: 'Save',
      inputs: [{
        label: `${type} Title`,
        type: 'text',
        defaultValue: (folder || article).get('title'),
        autoFocus: true
      }],
      onSubmit: async ([title]) => {
        if (folder) {
          await this.provider.updateFolder({
            folderId: folder.get('id'),
            domainId: folder.get('domainId'),
            data: { title }
          });
        } else {
          await this.provider.updateArticle({
            articleId: article.get('id'),
            domainId: article.get('domainId'),
            data: { title }
          });
        }
      }
    }, this.domainId);
  }

  editDescription({ article, folder }) {
    const
      item = article || folder,
      description = item.get('description') || '';

    this.popupManager.open(RichText, {
      title: 'Edit Description',
      format: 'html',
      text: description,
      onSave: async (text) => {
        if (folder) {
          await this.provider.updateFolder({
            folderId: folder.get('id'),
            domainId: folder.get('domainId'),
            data: { description: text }
          });
        } else {
          await this.provider.updateArticle({
            articleId: article.get('id'),
            domainId: article.get('domainId'),
            data: { description: text }
          });
        }
      }
    }, this.domainId);
  }

  editThumbnail({ article, folder }) {
    const
      item = article || folder,
      type = folder ? 'folder' : 'article',
      domainId = item.get('domainId'),
      id = item.get('id'),
      basePath = `domain/${domainId}/${type}/${id}/thumbnail`,
      imageUrl = item.getIn(['thumbnail', '640x640']);

    this.popupManager.open(UploadImage, {
      placeholderUrl: null,
      uploadImageFn: (
        ({ filename, file }, progress, error, done) =>
          this.provider.uploadImage({ basePath, filename, file }, progress, error, done)
      ),
      getImage: async () => imageUrl && this.provider.getDownloadURL(imageUrl),
      imageWidth: 300,
      imageHeight: 300,
      placeholderText: 'Upload Item Thumbnail',
      onRemoveClick: _ => this.removeThumbnail({ articleId: article && id, folderId: folder && id }),
    }, this.domainId);
  }

  removeThumbnail({ articleId, folderId }) {
    this.popupManager.open(Prompt, {
      title: 'Remove the Cover?',
      submitLabel: 'Remove',
      question: 'Are you sure you want to remove the thumbnail?',
      inputs: [],
      width: 380,
      onSubmit: () => this.provider.removeThumbnailCall({ articleId, folderId })
    });
  }

  changeFolderIsPublic({ folder, isPublic }) {
    this.popupManager.open(Prompt, {
      title: `Make Folder ${isPublic ? 'Public' : 'Private'}`,
      submitLabel: `Make ${isPublic ? 'Public' : 'Private'}`,
      question: `Are you sure you want make the folder ${isPublic ? 'public' : 'private'}?`,
      inputs: [],
      width: 380,
      onSubmit: () => this.provider.updateFolder({
        folderId: folder.get('id'),
        domainId: folder.get('domainId'),
        data: { isPublic }
      })
    });
  }

  createFolder = () => {
    this.popupManager.open(CreateFolder, {
      parentPath: this.path,
      provider: this.provider
    });
  }

  createArticle = () => {
    this.popupManager.open(CreateArticle, {
      parentPath: this.path,
      provider: this.provider
    });
  }

  createSection = () => {
    this.popupManager.open(Prompt, {
      title: 'Add a New Section',
      inputs: getSectionInputs(),
      submitLabel: 'Create',
      onSubmit: async ([title, displayMode, hasImage]) => {
        await this.folderController.layout.addNewSection({ title, displayMode, hasImage })
      },
    });
  }

  editSection = ({ id, title, displayMode, hasImage }) => {
    this.popupManager.open(Prompt, {
      title: 'Edit Section',
      submitLabel: 'Save',
      inputs: getSectionInputs({ title, displayMode, hasImage }),
      onSubmit: async ([title, displayMode, hasImage]) => {
        await this.folderController.layout.editSection(id, ({ title, displayMode, hasImage }));
      }});
  }
}


export const getSectionInputs = (section={}) => [
  {
    label: 'Caption',
    type: 'text',
    defaultValue: section.title || '',
    autoFocus: true
  },
  {
    label: 'Display mode in public',
    type: 'select',
    defaultValue: section.displayMode || 'list',
    options: [['list', 'List'], ['tile', 'Tile'], ['featured', 'Featured']]
  },
  {
    label: 'Display thumbnails in public',
    type: 'switch',
    defaultValue: section.hasImage || false,
  },
];