function Overview({ controller, isActive }) {
  const
    t = controller.getSettingsValue('target'),
    rcc = controller.getSettingsValue('classToRender'),
    target = t  + (rcc ? ('/' + rcc) : '');

  return (
    <div>
      { t && (<div className="font-mono text-xs">{target}</div>) }
      { !t && (<div className="italic opacity-50">No Target</div>) }
    </div>
  )
}

export default Overview;