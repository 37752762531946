import { useState, useRef, useEffect } from 'react';
import { Button } from 'react-aria-components';
import clsx from 'clsx';


export function AddUnderlay({ scroll }) {
  return (
    <div
      className="absolute top-0 left-0 bg-paper w-full h-full bg-opacity-0 z-40"
      >
    </div>
  )
}

export function AddHoverBar({ layout }) {
  const
    { scroll } = layout,
    show = scroll.state.get('isHovered') || scroll.state.get('adding'),
    ref = useRef();

  return (
    <div
      ref={ref}
      // onMouseEnter={() => scroll.setState({ isHovered: true })}
      // onMouseLeave={() => scroll.setState({ isHovered: false })}
      className={clsx(
        'h-full absolute top-0 -right-20 w-20 bg-wax bg-opacity-50',
        show ? 'opacity-100' : 'opacity-0 pointer-events-none',
        'transition duration-200 ease-in-out'
      )}>

      { show && (
        <Buttons
          layout={layout}
          getBaseTop={() => ref.current && ref.current.getBoundingClientRect().top} />          
      )}
    </div>
  )  
}

function Buttons({ layout, getBaseTop }) {
  const
    [tops, setTops] = useState([]),
    { fontSize } = layout,
    buttonRefs = useRef([]),
    handleKeyDown = (e, i) => {
      if (e.key === 'ArrowUp') { i -= 1; }
      else if (e.key === 'ArrowDown') { i += 1; }
      else if (e.key === 'Escape') {
        layout.scroll.setState({ adding: false });
        layout.selection.focusPage();
      }
      else { return; }
      if (buttonRefs[i])
        buttonRefs[i].focus();
    };

  useEffect(() => {
    let
      rect,
      tops = [],
      baseTop = getBaseTop(),
      ids = ['titlebar', ...layout.order];

    for (var id of ids) {
      const el = layout.getElementContainerRef(id);
      if (el) {
        rect = el.getBoundingClientRect();
        tops.push(rect.top + rect.height - baseTop + 1.5 * Number(fontSize));
      }
    }
    setTops(tops);
  }, [fontSize, getBaseTop, layout]);

  return tops.map((y, i) => (
    <div
      key={i}
      className="absolute left-2"
      style={{ top: y - fontSize }}>
      <AddButton
        layout={layout}
        index={i}
        autoFocus={i === 0}
        buttonRef={el => buttonRefs[i] = el}
        onKeyDown={e => handleKeyDown(e, i)} />
    </div>
  ));
}


export function AddButton({ layout, index, autoFocus, buttonRef, onKeyDown }) {
  const ref = useRef();

  return (
    <div className="h-0 w-full">
      <div className="absolute w-full -top-3 -left-5 bg-pencil border-t h-[1px] z-50" />
        
      <Button
        className={
          ({ isFocused }) => clsx(
            'absolute left-0 -top-3 font-medium uppercase z-50 outline-none',
            isFocused ? 'text-marker2' : 'text-pen'
          )}
        autoFocus={autoFocus}
        ref={el => { ref.current = el; buttonRef(el) }}
        onKeyDown={onKeyDown}
        onHoverStart={e => { ref.current.focus() }}
        onBlur={() => { layout.scroll.setState({ adding: false }) }}
        onFocus={() => { layout.scroll.setState({ adding: true }) }}
        onPress={() => { layout.scroll.addElementAt(index) }}>
        <AddButtonImage />
        <div className="absolute w-8 top-1 left-2 text-sm">
          Add
        </div>
      </Button> 
    </div>
  )
}


function AddButtonImage({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="26" viewBox="0 0 47 26" fill="none">
      <path d="M1.5 12.8154L9.34646 1H46V12.8154V25H9.34646L1.5 12.8154Z" fill="fillColor" stroke="currentColor"/>
    </svg>
  );
}