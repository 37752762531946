import React from 'react';
import clsx from 'clsx';
import { CheckCircledIcon, CrossCircledIcon, DotFilledIcon } from '@radix-ui/react-icons';


function InputWrapper(props) {
  const { label, children, status, warn, description } = props;

  return (
    <div className={'nb-text-input nb-input my-2'}>
      <label>
        <div className="block mb-1 text-sm font-medium text-pencil dark:text-white">
          {label}
        </div>

        <div className="text-input-container border border-rim2 text-pencil text-sm block w-full relative">
          { children }
          { status && (
            <div className="absolute right-2 top-2.5">
              { status === 'check' && <CheckCircledIcon className="" />}
              { status === 'cross' && <CrossCircledIcon className="text-red-500" />}
              { status === 'spinner' && <DotFilledIcon className="animate-ping"  />}
            </div>
          ) }
        </div>
        { warn && (<div className="text-xs text-error-red text-right my-1">{warn}</div>) }
        {description && (<div className="text-sm py-1 px-3">{description}</div>)}
      </label>
    </div>
  );
}


export function TextInput(props) {
  props = {...props};
  let isDisabled = props.isDisabled;
  delete props.isDisabled;

  return (
    <InputWrapper {...props} >
      <input
        className={clsx(
          'outline-none px-2 p-1.5 w-full bg-wax',
          isDisabled && ' opacity-50',
          props.status && 'pr-8'
        )}
        type="text"
        autoComplete="false"
        {...props}
        onChange={e => props.onChange(e.currentTarget.value)} />
    </InputWrapper>
  )
}

export function TextAreaInput(props) {
  props = {...props};
  let isDisabled = props.isDisabled;
  delete props.isDisabled;
  
  return (
    <InputWrapper {...props} >
      <textarea
        {...props}
        className={clsx('outline-none p-2 w-full bg-wax block', isDisabled && ' opacity-50')}
        onChange={e => props.onChange(e.currentTarget.value)} />
    </InputWrapper>
  );
}