import { useState, useEffect } from 'react';
import { Link } from 'react-aria-components';
import { Frame } from '../misc/components';
import { ThickArrowLeftIcon } from '@radix-ui/react-icons';

import { fetchDemo } from 'api/public';
import WebFrameView from 'lib/webframe/View';
import WebFrameController from 'lib/webframe/Controller';
import Loader from 'lib/staticPages/Loader';


export default function ItemPage({ app, demoId, isAdmin }) {
  const [demo, setDemo] = useState(null);

  useEffect(() => {
    fetchDemo(demoId).then(demo => setDemo(demo));
  }, [demoId]);

  return (
    <Frame className="demo-item-page max-w-[55rem] flex flex-col">
      { demo && (<DemoPage demo={demo} app={app} />) }
      { !demo && (<div className="text-center"><Loader /></div>) }
    </Frame>
  )
}

function DemoPage({ demo, app }) {
  return (
    <>
      <div className="flex gap-4 items-center p-4 border-b">
        <Link className=" outline-none inline-block" href="/demos">
          <ThickArrowLeftIcon className="h-5 w-5" />
        </Link>

        <div className="text-xl text-center pr-14">
          {demo.title}
        </div>
      </div>

      <div className="flex-grow flex gap-6 max-md:flex-col p-4">
        <div className="w-[440px] h-[440px] overflow-hidden p-2 border border-rim max-md:m-auto">
          <Program demo={demo} app={app} />
        </div>

        <div className="flex-grow p-2 max-md:px-4">
          <div
            className="body"
            ref={el => { if (el) el.innerHTML = demo.longDesc; }}>
          </div>
        </div>
      </div>
    </>
  )
}


function Program({ demo, app }) {
  const
    [controller, ] = useState(_ => new WebFrameController({})),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
    setLoading(true);
    // controller.setBuild({ target, classToRender, importMap, getFiles })
  }, [demo, controller, app]);

  return error ? (
    <div className="p-8 text-center text-red-900 font-medium">
      Program Load Error!
    </div>
  ) : loading ? (
    <div className="p-8 text-center">
      <Loader />
    </div>
  ) : (
    <WebFrameView
      controller={controller}
      height={400}  
      autorun={false}
      autoHeight={true} />
  );
}