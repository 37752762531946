import { Map } from 'immutable';
import io from 'socket.io-client';
import BaseController from 'lib/BaseController';
import DiskSync from './DiskSync';
import Interface from './Interface';
import VaniInterface from './VaniInterface';
import MachinePopup from '../popups/Machine/Controller';
import DiskSyncPopup from '../popups/DiskSync/Controller';


class Machine extends BaseController {
  constructor(app) {
    super();
    this.app = app;
    this.state = this.state.merge({ status: 'initialized' });
    this.interface = new Interface(this);
    this.vaniInterface = new VaniInterface(this);
    this.diskSync = new DiskSync(this);
    
    this.checkAutoConnect();
  }

  get url() { return this.state.get('url'); }

  showMachinePopup = () => {
    this.app.popupManager.open(MachinePopup, { machine: this });
  }

  showDiskSyncPopup= ({ docId, docPath, getLocalFiles, onClose, autoSyncAndClose }) => {
    this.app.popupManager.open(DiskSyncPopup, {
      machine: this,
      docId, docPath, getLocalFiles,
      onClose, autoSyncAndClose
    });
  }

  checkAutoConnect() {
    const lastConnectedURL = window.localStorage.getItem('machine-connected-url');
    if (lastConnectedURL) {
      this.connect({ url: lastConnectedURL });
    }
  }

  async tryAuthAndConnect({ url, username, password }) {
    try {
      this.setState({ status: 'authenticating' });
      await this.authenticate({ url, username, password });
    } catch (e) {
      console.error(e);
      this.setState({ status: 'failed', errorMessage: e.message, errorName: e.name });
      return;
    }
    this.connect({ url });
  }

  async authenticate({ url, username, password }) {
    const response = await fetch(`${url}/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ username, password }),
    });
    if (response.status === 401)
      throw new Error('incorrect password');

    if (response.status !== 200)
      throw new Error('login error');

    const token = (await response.json()).token;
    window.localStorage.setItem('machine-token', token);
  }

  connect({ url }) {
    const
      token = window.localStorage.getItem('machine-token'),
      socket = this.socket = io(url, {
        withCredentials: true,
        secure: true,
        query: { token },
        transports: ['polling', 'flashsocket', 'websocket'],
        reconnect: false,
        reconnectionAttempts: 4
      });
    window.localStorage.setItem('machine-connected-url', url);

    socket.on('a machine', machineInfo => {
      this.setState({ machineInfo });
    });

    socket.on('a process index', index => {
      this.setState({ processes: Map(index) });
      this.emit('process.index', index);
    });

    socket.on('event process', ({ process, event, error }) => {
      this.setState({ processes: this.state.get('processes').set(process.id, process) });
      this.emit(`process.${process.caller.elementId}`, { event, process, error });
    });

    socket.on('remove process', processId => this.setState({
      processes: this.state.get('processes').delete(processId)
    }));

    socket.on('data process', ({ id, type, data }) => {
      const process = this.state.get('processes').get(id);
      if (process)
        this.emit(`data.${process.caller.elementId}`, { process, type, data });
    });

    socket.on('vani', data => this.emit('vani', data));

    [
      'connect', 'error', 'connect_error', 'connect_timeout',
      'reconnect_error', 'reconnecting', 'reconnect_attempt', 
      'reconnect_failed', 'disconnect'
    ].forEach(e => socket.on(e, v => this.handleConnectionEvent(e, v)));

    this.setState({
      status: 'connecting',
      errorMessage: null,
      statusMessage: '',
      url: url
    });
  }

  close = (status='closed') => {
    if (!this.socket)
      return;
    this.socket.close();
    this.socket = null;
    window.localStorage.removeItem('machine-connected-url');
    window.localStorage.removeItem('machine-token');
    this.setState({ status: status });
  }

  handleConnectionEvent = (e, v) => {
    console.log(`[${(new Date()).toLocaleTimeString()}] ${e} ${v || ''} \n`);

    if (
      e === 'connect_error' || e === 'error' ||
      e === 'reconnect_error' || e === 'reconnect_failed') {
      this.setState({ errorMessage: v.message });
      this.close('failed');
    } else if (e === 'reconnecting') {
      this.setState({ status: 'reconnecting' })
    } else if (e === 'reconnect_attempt') {
      this.setState({ reconnetAttempt: v });
    } else if (e === 'connect') {
      this.setState({ status: 'connected' });
    } else if (e === 'disconnect') {
      this.setState({ status: 'disconnected' });
    }
  }

  startProcess(opts) {
    const
      appId = this.app.uuid,
      { command, elementId, docId, docPath, isCommon} = opts;
    this.socket.emit('start process', { command, elementId, docId, docPath, isCommon, appId });
  }

  killProcess(processId) {
    this.socket.emit('kill process', processId);
  }

  sendStdin(processId, message) {
    this.socket.emit('m stdin', { processId, message });
  }
}

export default Machine;