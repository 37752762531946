import { useNavigate } from 'react-router-dom';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import MenuBuilder from 'lib/components/MenuBuilder';
import { getFolderOptions } from 'modules/folder/views/options/folder';
import { getDomainOptions } from 'modules/domain/views/options/domain';


export default function MenuBar({ controller }) {
  const
    { domainController, folderController } = controller,
    folder = folderController.state.get('self'),
    navigate = useNavigate();

  return folderController.isLoaded && (
    <div className="flex border-b border-rim max-md:pr-14 max-md:py-1">
      <div className="flex-grow">
      </div>

      <MenuBuilder options={getDomainOptions({ controller: domainController, folder, navigate })}>
        <div className="py-2 px-4 hover:bg-wax2 outline-none">
          <span className="">Workspace</span> &nbsp; &nbsp; <HamburgerMenuIcon className="inline" />
        </div>
      </MenuBuilder>

      <MenuBuilder
        options={getFolderOptions({ controller: folderController, folder, navigate })}>
        <div className="py-2 px-4 hover:bg-wax2 outline-none">
          <span className="">Folder</span> &nbsp; &nbsp; <HamburgerMenuIcon className="inline" />
        </div>
      </MenuBuilder>

{/*        <Link
          className="py-3 px-4 hover:bg-wax2"
          to={'/workspaces/' + (folder.get('path').split('/').slice(0, -1).join('/'))}>
          <Cross1Icon />
        </Link>*/}
    </div>
  )
}