import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class DeleteArticleController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });
  constructor(args, manager) {
    super(args, manager);
    this.provider = args.provider;
    this.article = args.article;
  }

  async deleteArticle() {
    this.setState({ loading: true, alert: '' });
    try {
      await this.provider.deleteArticle({
        articleId: this.article.get('id'),
        domainId: this.article.get('domainId')
      });
      this.close();
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }

}

export default DeleteArticleController;