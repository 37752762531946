import { ui } from 'lib/popup';
import { Buttons, Button } from 'lib/inputs/buttons';
import { useControllerState } from 'lib/BaseController';
import VersionItem from '../ManageVersions/View/VersionItem';

import './style.css';


function Popup({ popup }) {
  const
    state = useControllerState(popup),
    { version, isPublished } = popup.args,    
    isPublic = version.get('isPublic');

  return (
    <ui.Popup popup={popup} className="publish-version-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            { isPublished ?  'Unpublish Version' : 'Publish Version' }
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.publish(); }}>
            <ui.Question>
              { isPublished ?
                'Are you sure you want to unpublish this version?' :
                'Are you sure you want to publish this version?'
              }
            </ui.Question>

            { !isPublished && !isPublic && (
              <div className="mb-3 text-pencil3 text-sm">
                Note: This will also make this version <strong>publicly accessible</strong>.
              </div>
            )}

            <VersionItem version={popup.args.version} />

            <ui.Alert />

            <Buttons className="text-right">
              <Button isDisabled={state.get('loading')} type="submit">
                { isPublished ? 'Unpublish' : 'Publish' }
              </Button>
              <Button onPress={e => { popup.close() }}>
                Cancel
              </Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;