import { useRef, useEffect } from 'react';
import clsx from 'clsx';
import QuickSelectMenu from './QuickSelectMenu';
import { createGlobalStyle } from 'styled-components';


const ElementStyle = createGlobalStyle`#${prop => prop.elementId} {${ prop => prop.css }}`;


function TextEditor({ controller, isActive, isGrid }) {
  const
    ref = useRef(),
    { editorController, getSettingsData, getSettingsValue } = controller,
    style = { lineHeight: getSettingsValue('lineSpacing') },
    overflow = null;

  if (getSettingsData('fontSize'))
    style.fontSize = getSettingsData('fontSize') + 'px';

  if (getSettingsData('columns')) {
    style.columns = getSettingsData('columns');
  }


  useEffect(_ => editorController.setEl(ref.current), [editorController]);
  return (
    <>
      <ElementStyle elementId={controller.elementId} css={getSettingsValue('css') || ''} />
      
      <div
        id={controller.elementId}
        style={style}
        className={clsx(
          'nb-prosemirror-editor',
          isGrid && 'h-full',
          overflow === 'auto' && 'overflow-auto')}
        spellCheck={controller.getSettingsValue('spellCheck')}
        onMouseDown={(e) => {
          if (e.currentTarget === e.target) {
            e.preventDefault();
            controller.focus();
          }
        }}
       ref={ref}
       onFocus={controller.activate}>
      </div>

      {isActive && (<QuickSelectMenu controller={controller} />)}
      <div className="clear-both"></div>
    </>
  )
}


export default TextEditor;