import { useState, useEffect } from 'react';
import { getDownloadURL as getDownloadURL_ } from 'api/public';


export default function StorageImage({ path, style, className, placeholder, children, getDownloadURL, backgroundColor }) {
  const
    [url, setUrl] = useState(null),
    [error, setError] = useState(null);
  getDownloadURL = getDownloadURL || getDownloadURL_;

  useEffect(() => {
    if (path)
      getDownloadURL(path)
        .then(u => setUrl(u))
        .catch(e => { console.error(e); setError(e.message) });
  }, [path, getDownloadURL]);

  const style_ = {
    ...style,
    width: '100%',
    paddingBottom: '100%',
    backgroundImage: `url(${url || placeholder})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: backgroundColor || '#eee',
    position: 'relative'
  };

  return (
    <div className={className} style={style_}>
      {error && <div className="text-pencil text-center pt-4">Error Loading Image!</div>}
      {!error && children}
    </div>
  )
}