import { Link } from 'react-router-dom';
import { ui } from 'lib/popup';
import { slugify } from 'lib/slugify';
import { useControllerState } from 'lib/BaseController';

import { Group, TextInput, CardSelect, Selection } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import {
  OpenInNewWindowIcon, ChevronRightIcon, ChevronLeftIcon,
  GridIcon, FileIcon, MagicWandIcon } from '@radix-ui/react-icons';

import SlugCheckInput from 'lib/inputs/SlugCheckInput';
import templates from './templates';
import clsx from 'clsx';

import './style.css';


function Popup({ popup }) {
  let state = useControllerState(popup);

  return (
    <ui.Popup popup={popup} className="create-article-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Add a New Article
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          {
            state.get('at') === 0 ?
              <CreateArticle popup={popup} /> :
            state.get('at') === 1 ?
              <SelectTemplate popup={popup} /> :
            state.get('at') === 2 ?
              <ArticleCreated popup={popup} />
            : ''
          }
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

function CreateArticle({ popup }) {
  const
    { state } = popup,
    slug = state.get('slug'),
    valid = state.get('valid'),
    loading = state.get('loading');

  return (
    <form onSubmit={e => { e.preventDefault(); popup.setState({ at: 1 }); }}>
      <Group>
        <TextInput
          label="Article Title"
          value={state.get('title')}
          autoFocus={true}
          isDisabled={loading}
          onChange={v => {
            popup.setState({ title: v });
            if (state.get('autoSlug')) {
              popup.setState({ slug: slugify(v) });
            }
          }} />

        <SlugCheckInput
          label="Slug"
          value={state.get('slug')}
          onChange={v => popup.setState({ slug: v })}
          onValidChange={e => popup.setState({ valid: e })}
          checkFn={(val) => popup.checkAvailable(state.get('parentPath') + '\\' + val)} />
      </Group>

      <ui.Alert />

      <Buttons className="text-right mt-4">
        <Button
          isDisabled={loading || !slug || !valid}
          type="submit">
          Next <ChevronRightIcon className="inline" />
        </Button>
      </Buttons>
    </form>
  );
}


function SelectTemplate({ popup }) {
  const { state } = popup;

  return (
    <form onSubmit={e => { e.preventDefault(); popup.addArticle(); }}>
      <Group>
        <CardSelect
          label="Layout"
          value={state.get('layoutType')}
          itemClassName={({isSelected, isFocused, isHovered}) => clsx(
            'text-center w-20 py-4 text-center text-sm outline-none cursor-pointer rounded-sm bg-paper w-1/3',
            isSelected ? 'border border-2 border-marker3' :
              !isSelected && (isFocused || isHovered) ? 'border border-marker' :
                'border border-rim3'
          )}          
          onChange={(layoutType) => popup.setState({ layoutType })}
          options={[
            { Card: () => <Card caption="Grid" Icon={GridIcon} />, value: 'grid' },
            { Card: () => <Card caption="Scroll" Icon={FileIcon} />, value: 'scroll' },
            { Card: () => <Card caption="Template" Icon={MagicWandIcon} />, value: 'template' },
          ]}/>
      </Group>


      { state.get('layoutType') === 'template' && (
        <div className="mt-4">
          <Selection
            label="Select a template"
            value={state.get('templateIndex')}
            onChange={templateIndex => popup.setState({ templateIndex })}
            options={templates.map((t, i) => ({
              key: i,
              caption: (
                <div className="">
                  <div className="">{t.caption}</div>
                  <div className="text-sm">{t.description}</div>
                </div>
              )
            }))} />

          <div className="text-right">
            <Link
              className="text-sm underline"
              target="_blank"
              to={'/' + templates[state.get('templateIndex')].path}>
              {/*'{templates[state.get('templateIndex')][1]}'*/}
                preview  <OpenInNewWindowIcon className="inline-block" />
              </Link>
          </div>
        </div>
      )}


      <ui.Alert />

      <Buttons className="text-right mt-4">
        <Button
          onPress={() => popup.setState({ at: 0 })}>
          <ChevronLeftIcon className="inline" /> Previous
        </Button>

        <Button type="submit" autoFocus={true}>
          Create
        </Button>
      </Buttons>
    </form>
  );
}

function Card({ caption, Icon }){
  return (
    <div className="">
      <div className="mb-1">{caption}</div>
      <Icon className="m-auto" />
    </div>
  )
}




function ArticleCreated({ popup }) {
  const { state } = popup;

  return (
    <div>
      <div className="text-lg text-center py-4">Article "{state.get('title')}" created!</div>
      <div className="text-right my-4">
        <Button href={`/editor/${state.get('parentPath')}/${state.get('slug')}`} autoFocus={true}>
          <OpenInNewWindowIcon className="inline-block" /> Open
        </Button>
      </div>
    </div>
  )
}

export default Popup;