export const FONT_PRESETS = {
  'Space': {
    caption: 'Space',
    theme: { headerFont: 'Rubik', font: 'Space+Mono' }
  },
  'eloquent': {
    caption: 'Eloquent',
    theme: { headerFont: 'Cinzel', font: 'Georgia' }
  },
  'default': {
    caption: 'Default',
    theme: { headerFont: null, font: null }
  },
}