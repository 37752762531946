export const ASPECT_RATIOS = [
  ['0.4615', '6:13 - Smartphones'],
  ['0.5625', '9:16 - 2010s Smartphones'],
  ['1.0000', '1:1 - Square'],
  ['1.2500', '5:4 - Early TV'],
  ['1.3333', '4:3 - Traditional TV'],
  ['1.3750', '11:8 - Film Academy'],
  ['1.4300', '1.43:1 - IMAX'],
  ['1.5000', '3:2 - 35mm Photo Film'],
  ['1.5556', '14:9 - Mid'],
  ['1.6000', '16:10 - Computer Screen'],
  ['1.6180', '1.6180:1 - Golden Ratio'],
  ['1.6667', '5:3 - European Widescreen'],
  ['1.7778', '16:9 - HD Video'],
  ['1.8500', '1.85:1 - US Widescreen'],
  ['1.9000', '1.9:1 - Digital IMAX'],
  ['2.0000', '2:1 - Univisium'],
  ['2.2000', '2.2:1 - 70mm Film'],
  ['2.3700', '2.370:1 - Cinematic Widescreen'],
  ['2.3500', '2.35:1 - Widescreen Cinema'],
  ['2.4140', '2.414:1 - The Silver Ratio'],
  ['3.5556', '32:9 - Super Ultrawide'],
  ['4.0000', '4:1 - Polyvision']
]