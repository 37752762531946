import { useState, useEffect } from 'react';
import { Button, ListBox, ListBoxItem, Popover, Select } from 'react-aria-components';
import { MODE_CAPTION_MAP, MODE_OPTIONS } from 'lib/codemirror/languages';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';


export default function ModeSelection({ controller, fileController }) {
  let
    [isOpen, setOpen] = useState(false),
    [mode, setMode] = useState(fileController.getSettingsValue('mode'));
    
  useEffect(() => setMode(fileController.getSettingsValue('mode')), [fileController])
  useEffect(
    () => fileController.onStateChange(() => setMode(fileController.getSettingsValue('mode'))),
    [fileController]
  );
  
  return (
    <Select
      aria-label="Select Mode"
      selectionMode="single"
      selectedKey={mode}
      onSelectionChange={(s) => {
        fileController.setSettings('mode', s);
        setOpen(false);
      }}>
      <Button
        onPress={() => setOpen(true)}
        className="p-1 flex gap-2 outline-none whitespace-nowrap items-center text-pencil">
        <div className="text-pencil">
          {MODE_CAPTION_MAP[fileController.getSettingsValue('mode') || fileController.getSuggestedMode()]}
        </div>
        { !isOpen && (<ChevronDownIcon />) }
        { isOpen && (<ChevronUpIcon />) }
      </Button>

      <Popover isOpen={isOpen} onOpenChange={setOpen} offset={0}>
        <ListBox
          items={MODE_OPTIONS.concat([{ key: '', caption: 'Auto' }])}
          className="border border-rim block bg-paper min-w-40 max-w-60 outline-none max-h-[20rem] overflow-auto p-1">
          {({key, caption}) => (
            <ListBoxItem
              id={key}
              key={key}
              textValue={caption}
              className={({ isFocused, isDisabled }) => clsx(
                'px-2 py-1 text-sm cursor-pointer outline-none flex',
                isFocused && 'bg-marker text-white',
                isDisabled && 'opacity-50'
              )}>
              {({isSelected}) => (<>
                <div className="">{caption || '?????'}</div>
                {isSelected && <CheckIcon className="ml-1 mt-1 flex-shrink-0" />}
              </> )}
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </Select>
  );
}