import RemoteProvider from 'api/remote/Provider';
import LocalProvider from 'api/local/Provider';

const cloneArticle = async ({ sourcePath, targetPath, title, onUpdate }) => {
  const
    localProvider = new LocalProvider(),
    remoteProvider = new RemoteProvider();

  const
    s = targetPath.split('\\'),
    slug = s.pop(),
    parentPath = s.join('\\');

  const
    sourceProvider = sourcePath.startsWith('local') ? localProvider : remoteProvider,
    targetProvider = targetPath.startsWith('local') ? localProvider : remoteProvider;

  const { versionId, articleId, domainId, type } = (await sourceProvider.fetchPath(sourcePath)) || {};
  
  let article, elements;
  if (type === 'version') {
    const version = await sourceProvider.fetchVersion({ domainId, articleId, versionId });
    article = version.article;
    elements = await sourceProvider.fetchVersionElements({ domainId, articleId, versionId });
  }

  else if (type === 'article') {
    article = await sourceProvider.fetchArticle({ articleId, domainId });
    elements = await sourceProvider.fetchArticleElements({ articleId, domainId });
  }

  else {
    throw new Error('Invalid source path.');
  }

  const t = await targetProvider.fetchPath(parentPath);
  if (t.type !== 'folder')
    throw new Error('Invalid remote path');

  const newArticle = await targetProvider.createArticle({
    domainId: t.domainId,
    parentId: t.folderId,
    slug, title
  });

  const newArticleId = newArticle.data.articleId;

  // this algo assums there is no nested children
  const elementMap = {};
  const createElement = async (element) => {
    const data = Object.assign({}, element);
    delete data.id;
    if (data.parentId)
      data.parentId = elementMap[data.parentId];

    const e = await targetProvider.createElement({
      domainId: t.domainId,
      articleId: newArticleId,
      data
    });
    elementMap[element.id] = e.id;
  }


  // create parents
  for (let element of elements) { if (!element.parentId) { await createElement(element); } }
  // create children
  for (let element of elements) { if (element.parentId) { await createElement(element); } }


  const
    layout = article.layout,
    newOrder = layout.order.map(id => elementMap[id]),
    newElements = {};

  if (layout.type === 'grid') {
    for (var id in layout.grid.elements) {
      const
        newId = elementMap[id],
        e = layout.grid.elements[id];
      newElements[newId] = ({ id: newId, ...e });
    }
  }

  layout.order = newOrder;
  if (layout.type === 'grid')
    layout.grid.elements = newElements;
  console.log(layout);

  await targetProvider.updateArticle({
    articleId: newArticleId,
    domainId: t.domainId,
    data: { layout }
  });
}

export default cloneArticle;