import { useState, useEffect } from 'react';
import { Button } from 'react-aria-components';
import { EnterFullScreenIcon, ExitFullScreenIcon } from '@radix-ui/react-icons';


export function useFullScreen() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  
  useEffect(() => {
    const handleFullscreenChange = () => { setIsFullscreen(!!document.fullscreenElement); };
    document.addEventListener('fullscreenchange', handleFullscreenChange);    
    return () => { document.removeEventListener('fullscreenchange', handleFullscreenChange); };
  }, []);
  return isFullscreen;  
}

export function toggleFullScreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen()
      .catch(e => {
        alert(`Error attempting to enable full-screen mode.`);
      });
  } else {
    document.exitFullscreen();
  }
}

export function FullscreenButton() {
  const isFullscreen = useFullScreen();

  return (
    <Button onPress={toggleFullScreen}>
      {isFullscreen ? (
        <><ExitFullScreenIcon className="inline-block" /></>
      ) : (
        <><EnterFullScreenIcon className="inline-block" /></>
      )}
    </Button>
  );
};