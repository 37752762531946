import BaseController from 'lib/BaseController';

export default class Grid extends BaseController {
  constructor(layout) {
    super();
    this.layout = layout;
    this.state = this.state.merge({ adding: false, isHovered: false });
  }

  addElementAt = (insertAt) => {
    this.setState({ adding: false });
    this.layout.newElementPopup((id) => {}, () => {}, insertAt);
    this.setState({ 'cursor': 'default' });
  }
}