import {
  CommitIcon, CaretRightIcon, CaretDownIcon, LockClosedIcon,
  GlobeIcon, LockOpen2Icon
} from '@radix-ui/react-icons';
import clsx from 'clsx';


export default function VersionItem({ version, isSelected, isPublished, isExpanded, isExpandable=false }) {
  const
    label = version.get('label'),
    slug = version.get('slug');

  return (
    <div
      className={clsx(
        'border p-2 pr-4 rounded-sm bg-paper mb-2',
        isSelected ? 'border-marker' : 'border-rim'
      )}>
      <div className="flex gap-3 relative items-center">
        <div className={isSelected ? '' : ''}>
          <CommitIcon className="w-6 h-6" />
        </div>
        <div className="flex-grow">
          <div className="text-sm font-medium">{label}</div>
          <div className="font-mono text-xs">{slug}</div>
        </div>

        {!isExpanded && (<div>
          { version.get('isPublic') ? isPublished ? <GlobeIcon /> : <LockOpen2Icon /> : <LockClosedIcon /> }
        </div>)}

        {isExpandable && (
          <div className="outline-none">
            {isExpanded && <span className="i-down"><CaretDownIcon /></span>}
            {!isExpanded && <span className="i-right"><CaretRightIcon /></span>}
          </div>
        )}
      </div>

      {isExpanded && <VersionDetail version={version} isPublished={isPublished} /> }    
    </div>
  )

}


function VersionDetail({ version, isPublished }) {
  const files = version.get('filenames');

  return (
    <div className="detail text-sm p-1 pl-5 mt-4 select-none">
      <div className="border border-rim py-1 px-4">
        <div className="float-right text-pencil">
            { isPublished ?
              (<><GlobeIcon className="inline" /> Published</>) :
              version.get('isPublic') ?
                (<><LockOpen2Icon className="inline" /> Public</>) :
                (<><LockClosedIcon className="inline" /> Private</>)
            }
        </div>      
        <div className="text-pencil3 mt-2">Created At:</div>
        <div className="val">{new Date(version.get('createdAt').seconds * 1000).toLocaleString()}</div>
        <div className="text-pencil3 mt-2">Notes:</div>
        <div className="notes">
          { version.get('notes') || (<i>No notes</i>) }
        </div>
        <div className="text-pencil3 mt-2">Files:</div>
        <div className="break-all">
          {files.length === 0 ? (<i>No file</i>) : files.map((filename, i) => (
            <span key={i} className="font-mono text-xs inline inline-block">{filename} </span>
          ))}
        </div>
      </div>
    </div>
  );
}