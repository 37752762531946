import { Label as AriaLabel } from 'react-aria-components';
import clsx from 'clsx';

export function Label({ children }) {
  return (
    <AriaLabel className="pl-2 text-sm font-medium text-pencil3 w-full block select-none">
      {children}
    </AriaLabel>
  );
}

export function Block({ children, span }) {
  return (
    <div className={clsx('nb-input-block my-1 py-1 align-top', span === 'half' && 'w-3/6 inline-block')}>
      {children}
    </div>
  );
}

export function Break() {
  return (<div className="border-b border-rim mb-1 pb-1"></div>)
}