import Tabs from 'modules/elements/lib/sidebar/inputs/Tabs';
import { fields } from '../Policies/settings';
import { BuildInputs } from 'modules/elements/lib/sidebar/builder';
import Entries from './Entries';


function Inspector({ controller, isGrid }) {
  const tab = controller.state.get('tab');
  return (
    <div className="inspector gallery-inspector">
      <Tabs
        tabs={[
          {key: 'entries', caption: 'Entries', el: (<Entries controller={controller} isGrid={isGrid} />)},
          {key: 'settings', caption: 'Display', el: (<Settings controller={controller} isGrid={isGrid} />)}
        ]}
        selectedKey={tab}
        onSelectionChange={tab => controller.setState({ tab })} />
    </div>
  );
}

function Settings({ controller, isGrid }) {
  const {
    setSettings,
    getSettingsData,
    getSettingsValue,
    getSettingsValueOnNull
  } = controller;

  return (
    <BuildInputs
      fields={fields}
      layout={[
        [
          // ['galleryType'],
          ['fitting'],
          ['fillerColor'],
          (!isGrid ? ['maxWidth', 'maxHeight'] : []),
          // ['aspectRatio'],
          ['hasBorder'],
        ],
      ]}
      getData={getSettingsData}
      getValue={getSettingsValue}
      setData={setSettings}
      getValueOnNull={getSettingsValueOnNull} />    
  );
}

export default Inspector;