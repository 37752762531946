import { useControllerState } from 'lib/BaseController';
import { ErrorBlock } from 'lib/staticPages';
import Loader from 'lib/staticPages/Loader';
import ItemList from 'modules/folder/views/List';
import AddMenu from 'modules/folder/views/AddMenu';
import { PlusIcon } from '@radix-ui/react-icons';


export default function Folder({ folderController }) {
  const
    state = useControllerState(folderController),
    { isLoaded, isConnected } = folderController,
    error = state.get('error'),
    loading = (!isLoaded || !isConnected);
  
  if (error)
    return <ErrorBlock className="mt-12" title="Folder Load Error" error={error} />;

  if (loading)
    return  <div className="text-center p-5"><Loader /></div>;

  return (
    <div className="domain-folder flex flex-col h-full overflow-hidden p-6 gap-4">
      <div className="title-bar text-center flex-shrink-0">
        <div className="title font-medium text-lg">{state.getIn(['self', 'title'])}</div>
        <div className="slug font-mono text-sm">{state.getIn(['self', 'path'])}</div>
      </div>

      <div className="flex-grow overflow-auto border border-rim2 bg-paper2 rounded-sm px-2 pt-2 pb-6">
        <ItemList controller={folderController} />
      </div>

      <div className="buttons text-right flex-shrink-0">
        <AddMenu
          controller={folderController}
          className="flex items-center gap-2 ml-auto px-3 py-1 border border-rim3 rounded-sm">
          <PlusIcon /> Add
        </AddMenu>
      </div>
    </div>
  );
}
