import { useMatch } from 'react-router-dom';
import { Button, Link } from 'react-aria-components';
import { GearIcon, BellIcon, BookmarkIcon, ExitIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';

import { Frame } from '../Home/misc/components';
import Notifications from './Notifications';
import Settings from './Settings';
import Bookmarks from './Bookmarks';


export default function User({ app }) {
  const
    user = app.auth.user,
    match = useMatch('/user/:page'),
    page = match && match.params.page,
    hasSidebar = false;

  if (!user)
    return (<NoUser />);

  return (
    <Frame
      className={clsx(
        'user-page flex rounded-sm',
        hasSidebar ? 'max-w-[55rem]' : 'max-w-[40rem]'
      )}>
      { hasSidebar && (
        <Sidebar app={app} user={user} page={page} />
      ) }

      <div className="flex-grow">
        { page === 'settings' && <Settings app={app} /> }
        { page === 'notifications' && <Notifications app={app} /> }
        { page === 'bookmarks' && <Bookmarks app={app} /> }
      </div>
    </Frame>
  )
}

function Sidebar({ app, user, page }) {
  return (
    <div className="flex flex-col border-r w-[15rem]">
      <div className="py-8 border-b text-center text-medium">
        {/*<PersonIcon className="inline-block" />*/}
        {user.email}
      </div>

      <div className="flex-grow px-2 py-6">
        <LeftButton
          Icon={GearIcon}
          label="Settings"
          isActive={page === 'settings'}
          href="/user/settings" />

        <LeftButton
          label="Bookmarks"
          Icon={BookmarkIcon}
          isActive={page === 'bookmarks'}
          href="/user/bookmarks" />

        <LeftButton
          label="Notifications"
          Icon={BellIcon}
          isActive={page === 'notifications'}
          href="/user/notifications" />
      </div>

      <div className="p-2 text-center">
        <Button
          onPress={() => app.auth.signOut() }
          className="border rounded-sm px-6 py-2 flex items-center gap-4 m-auto"
          type="primary">
          <ExitIcon className="inline-block" /> Sign Out
        </Button>
      </div>
    </div>
  );
}

function LeftButton({ label, isActive, href, Icon }) {
  return (
    <Link
      href={href}
      className={clsx(
        'py-1 px-3 mb-1 text-left flex items-center gap-2 rounded-sm outline-none',
        // isActive && 'bg-gray-600 text-white'
        isActive && 'bg-wax2 font-medium'
      )}>
      <Icon />{label}
    </Link>
  )
}



function NoUser() {
  return (
    <div className="mt-12 mx-auto md:border border-rim text-center p-5 max-w-[30rem] bg-paper">
      <Link className="font-bold" href="/auth/signin">
        Login
      </Link>&nbsp;or&nbsp;
      <Link className="font-bold" href="/auth/signup">
        Sign Up
      </Link>&nbsp;to continue.
    </div>
  )
}