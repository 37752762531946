import { useState } from 'react';
import Icon from 'lib/Icon';
import { Button } from 'lib/inputs/buttons';
import { LinkBreak2Icon, FileTextIcon, LaptopIcon } from '@radix-ui/react-icons';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { useControllerState } from 'lib/BaseController';
import { BarButton, Break } from './res/components';
import { DialogTrigger, Popover } from 'react-aria-components';
import { Laptop2Icon } from './res/icons';



export default function MachineButton({ machine, editor, withBreak, verticle }) {
  const
    state = useControllerState(machine),
    [isOpen, setOpen] = useState(false);

  return state.get('status') === 'connected' && (
    <>
      { withBreak && (<Break verticle={verticle} />) }

      <DialogTrigger>
        <BarButton
          Icon={Laptop2Icon}
          tooltip="Remote Machine, Connected"
          fn={() => setOpen(true)} />

        <Popover isOpen={isOpen} onOpenChange={setOpen}>
          <MachineLeaf
            machine={machine}
            setOpen={setOpen}
            articleController={editor.currentArticleController} />
        </Popover>
      </DialogTrigger>
    </>
  );
}


function MachineLeaf({ machine, articleController, setOpen }) {
  const machineInfo = machine.state.get('machineInfo');

  return (
    <div className="bg-paper p-3 border border-rim text-center max-w-[10em]">
      <div className="text-center">
        <div className="text-xl"><Icon faIcon={faLaptopCode} /></div>
        {machineInfo && (<div className="text-sm text-marker3">{machineInfo.hostname}</div>)}
      </div>

      <div className="border-b border-rim pb-2 mb-2 w-full"></div>

      <div className="text-center">
        <Button
          onPress={() => {
            setOpen(false);
            machine.showMachinePopup();
          }}
          size="small"
          marginClass="m-1">
          <LaptopIcon className="inline" />&nbsp; Processes
        </Button><br />

        <Button
          onPress={() => {
            setOpen(false);
            machine.showDiskSyncPopup({
              docId: articleController.id,
              docPath: articleController.state.getIn(['article', 'path']).replaceAll( '\\', '/'),
              getLocalFiles: () => articleController.localFiles.proxy
            });
          }}
          size="small"
          marginClass="m-1">
          <FileTextIcon className="inline" />&nbsp; Files Sync
        </Button>

        <Button
          onPress={() => {
            setOpen(false);
            machine.close();
          }}
          size="small"
          marginClass="m-1">
          <LinkBreak2Icon className="inline" />&nbsp; Disconnect
        </Button><br />
      </div>
    </div>
  );
}