import Prompt from 'lib/popup/popups/Prompt/Controller';
import { addFeedback } from 'api/public';

export default function sendFeedbackFn({ popupManager, email, message }) {
  popupManager.open(Prompt, {
    title: 'Send Feedback',
    inputs: [
      { label: 'Your Email', type: 'text', defaultValue: email, autoFocus: true },
      { label: 'Message', type: 'textarea', defaultValue: message },
    ],
    onSubmit: async ([email, message]) => {
      await addFeedback({ email, message });
      
      popupManager.open(Prompt, {
        title: 'Feedback Sent',
        content: (<div className="text-center font-medium my-4">Your feedback has been sent!</div>),
        submitLabel: 'Ok',
        hasCancel: false
      });
    }
  });
}