import { CaretDownIcon } from '@radix-ui/react-icons';
import { name, slogan } from 'meta';
import { Page } from './common';


export default function HomePage() {
  return (
    <Page className="flex items-center">
      <div className="text-center flex-grow">
        <div className="text-8xl md:text-9xl leading-tight relative">
          <span className="relative">
            {name}

            <div className="absolute -right-10 top-2">
              <BetaStamp />
            </div>
          </span>
        </div>
        <div className="pt-4 pb-[5.5rem] w-90 m-auto text-lg">
          {slogan}
        </div>
      </div>

      <div className="text-center bottom-10 absolute w-full">
        <CaretDownIcon className="inline w-6 h-6" />
      </div>

    </Page>
  )
}

function BetaStamp() {
  return (
    <div
      className="border-2 w-12 h-12 flex items-center border-pencil">
      <div className="w-full text-center text-base font-medium text-pencil">beta</div>
    </div>  
  )
}