import { useControllerState } from 'lib/BaseController';
import ArticleInspector from './ArticleInspector';
import ElementInspector from './ElementInspector';
import './style.css';


function Inspector({ editor }) {
  const
    articleController = editor.currentArticleController,
    state = useControllerState(articleController);

  useControllerState(articleController.selection);

  if (state.get('error'))
    return ''

  const
    isGrid = articleController.layout.type === 'grid',
    { activeElementId } = articleController.selection,
    element = articleController.elements[activeElementId];
  
  return (
    <div className="h-full w-full overflow-hidden">
      {
        element ?
          <ElementInspector articleController={articleController} element={element} isGrid={isGrid} /> :
          <ArticleInspector articleController={articleController} />
      }
    </div>
  );
}

export default Inspector;