import { Map } from 'immutable';
import { debounce } from 'lodash';
import BasePopup from 'lib/popup/BasePopupController';
import View from './View';
import { urlRegex } from 'lib/regexps';


class Popup extends BasePopup {
  static View = View;
  position = new Map({ width: 380 });

  constructor(args, manager) {
    super(args, manager);
    const { location, getURL } = args;
    this.state = this.state.merge({ location });
    this.getURL = getURL;
    if (location) {
      this.onLocationChangeDebounced()
    }
  }

  updateLocation = (location) => {
    if (location === this.state.get('location'))
      return;

    this.setState({ location })
    this.onLocationChangeDebounced();
  }

  onLocationChange = async () => {
    const location = this.state.get('location');
    console.log('onchange')
    
    this.setState({ checking: true });
    let src;
    if (location.startsWith('./') || location.startsWith('/') || location.startsWith('../')) {
      src = await this.getURL(location);
      if (location !== this.state.get('location'))
        return;
    } else if (urlRegex.test(location)) {
      src = location;
    } else {
      this.setState({ checking: false, isValid: null });
    }

    this.setState({ src });
  }
  onLocationChangeDebounced = debounce(this.onLocationChange, 600);

  onImageLoad = () => {
    console.log('imageload')
    this.setState({ checking: false, loadedSrc: this.state.get('src'), isValid: true });
  }

  onImageError = () => {
    this.setState({ checking: false, isValid: false });
  }

  submit = () => {
    this.args.onSave(this.state.get('location'));
    this.close();
  }
}

export default Popup;