import { useEffect, useState } from 'react';
import { Button, Link } from 'react-aria-components';
import { fetchUserNotes, clearUserNotes, updateUserInfo } from 'api/remote/calls/user'
import Loader from 'lib/staticPages/Loader';


export default function Notifications({ app }) {
  const
    user = app.auth.user,
    userId = user && user.uid,
    [notes, setNotes] = useState(null),
    [errored, setErrored] = useState(false),
    [requested, setRequested] = useState(false),
    marked = app.auth.state.getIn(['userInfo', 'unreadNotes']);

  useEffect(() => {
    if (!userId)
      return;

    return fetchUserNotes(
      userId,
      (data) => setNotes(data),
      (error) => { console.error(error); setErrored(true) }
    )
  }, [userId]);

  useEffect(() =>{
    if (marked && !requested) {
      setRequested(true);
      updateUserInfo({ userId, data: { unreadNotes: false } })
        .catch(e => console.error(e));
    }
  }, [userId, marked, requested]);

  return (
      <div className="p-8">
        <div className="font-medium mb-4 text-center text-2xl">Notifications</div>
        <hr className="mb-4" />
        {
          errored ? <Errored /> :
            !userId ? <LoggedOut /> :
              notes === null ?
                <Loading /> :
                notes.length === 0 ?
                  <Empty /> :
                  <Index userId={userId} notes={notes} />
        }
      </div>
  )
}

function Errored() {
  return (
    <div className="text-center text-error-red">
      Error Loading Notifications!
    </div>
  );
}

function LoggedOut() {
  return (
    <div className="text-center border border-rim p-5">
      <h5>You're not logged in.</h5>
      <Link href="/user">Sign In</Link> or&nbsp;
      <Link href="/user/signup">Sign Up</Link> to see your notifications.
    </div>
  );
}

function Loading() {
  return (<div className="loading"><Loader /></div>);
}


function Index({ userId, notes }) {
  return (
    <>
      <div className="notifications my-4 p-1 px-3">
        { notes.map((n, i) => (
          <div key={i} className="notification text-sm my-2">
            <div className="float-right opacity-50">
              {new Date(n.createdAt.seconds * 1000).toLocaleDateString()}
            </div>
            <div className="message">{n.message}</div>
          </div>
        )) }
      </div>

      <div className="text-right">
        <Button
          className="clear-button link-react-aria-componentsn p-2"
          onClick={() => { clearUserNotes(userId, notes.map(n => n.id)) }}>
          Clear all
        </Button>
      </div>
    </>
  )
}

function Empty() {
  return (
    <div className="text-center">
      <h5 className="text-lg font-medium font-center mb-2">All clear!</h5>
      <p className="text-sm">
        You have no notification.
        Any activity worth knowing will be shown here.
      </p>
    </div>
  );
}