import { ui } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';
import FileIndex from './FileIndex';
import FileDetail from './FileDetail';

function View({ popup }) {
  useControllerState(popup);
  useControllerState(popup.articleController);
  
  return (
    <ui.Popup popup={popup} className="article-files-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            { popup.args.title || 'Files' }
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body className=" ">
          <div className="file-manager flex h-[26rem]">
            <div className="w-[14rem] flex-shrink-0">
              <FileIndex popup={popup} />
            </div>
            
            <div className="flex-grow overflow-hidden">
              <FileDetail popup={popup} />
            </div>
          </div>

          <ui.Alert />
          {/* <Buttons className="text-right">
            <Button isDisabled={popup.state.get('loading')} type="submit">Add</Button>
          </Buttons>*/}
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  );
}

export default View;