export function TextAreaInput({ value, controller, onChange, onFinish, textAlign }) {
  var next = false;

  return (
    <textarea
      value={value}
      onFocus={e => e.target.select()}
      onChange={e => { onChange(e.currentTarget.value) }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.stopPropagation();
          e.preventDefault();
          next = true;
          e.currentTarget.blur();
        }
      }}
      onBlur={e => { onFinish(next); }}
      autoFocus={true}
      style={{ textAlign: textAlign || 'left' }} />
  )
}