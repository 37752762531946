import { useNavigate } from 'react-router-dom';
import { GridList, DropIndicator } from 'react-aria-components';
import { useDragAndDrop } from 'react-aria-components';
import Item from './Item';


export default function List({ controller, compact, activeId, openIds, onPressClose, replace=false, onAfterSelect }) {
  const
    { state } = controller,
    self = state.get('self'),
    list = self.get('layout').get('list'),
    items = list.toJS().map(item => ({ key: item.id, ...item })),
    navigate = useNavigate();

  let { dragAndDropHooks } = useDragAndDrop({
    getItems: (keys) => [...keys].map(key => ({ 'text/plain': list.type + '-' + list.id })),
    onReorder(e) {
      let
        sourceIndex = items.findIndex(i => i.id === Array.from(e.keys)[0]),
        destIndex = items.findIndex(i => i.id === e.target.key);
      if (e.target.dropPosition === 'after') {
        // destIndex -= 1;
      }
      if (sourceIndex !== destIndex) {
        controller.layout.moveItem(sourceIndex, destIndex);
      }
    },
    renderDropIndicator(target) {
      return (
        <DropIndicator
          target={target}
          className={({ isDropTarget }) => `pt-1 bg-marker`}
        />
      );
    }
  });

  if (list.size === 0)
    return (
      <div className="p-3">
        <div className="text-sm text-center text-pencil3 py-5 bg-wax rounded">
          This folder is empty.<br />
          Start by adding an item.
        </div>
      </div>
    );

  return (
    <GridList
      aria-label="Folder Contents"
      dragAndDropHooks={dragAndDropHooks}
      items={items}
      onSelectionChange={({ currentKey }) => {
        const  
          item = items.find(i => i.id === currentKey),
          entry = controller.getItem(currentKey);
        if (!entry || !item)
          return;
        navigate(
          (item.type === 'folder' ? '/workspaces/' : '/editor/') + entry.get('path'),
          { replace }
        );
        if (onAfterSelect)
          onAfterSelect();
      }}
      selectedKeys={[activeId]}
      selectionMode="single">
      {item => (
        <Item
          item={item}
          controller={controller}
          compact={compact}
          isOpen={openIds && openIds.has(item.id)}
          isActive={activeId && activeId === item.id}
          onPressClose={onPressClose} />)}
    </GridList>
  );
}