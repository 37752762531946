import { Component } from 'react';
import { debounce } from 'lodash';
import { TextInput } from './basic';

// const checkFn = _ => {
//   return new Promise((resolve) => {
//     setTimeout(_ => resolve(Math.random() > .5), 1000);
//   })
// }

class SlugCheckInput extends Component {
  constructor(props) {
    super(props);
    this.checkFn = props.checkFn;
    this.state = {
      valid: false,
      checking: false,
      checkedValue: null,
    }
  }

  render = () => {
    const
      { autoFocus, label, value, onChange } = this.props,
      { valid, checking, checkedValue, invalidMessage } = this.state,
      status = (
        checking ? 'spinner' :
          checkedValue !== value ? null :
            valid === false ? 'cross' :
              valid === true ? 'check' :
                null
      ),
      warn = (
        (value && !valid && !checking && checkedValue === value) ?
          (invalidMessage) : ''
      );
  
      return (
        <TextInput
          autoFocus={autoFocus}
          label={label}
          value={value}
          status={status}
          warn={warn}
          spellCheck={false}
          onChange={onChange} />
      );
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.checkDebounced();
      if (this.props.onValidChange)
        this.props.onValidChange(false);
    }
  }

  componentDidMount() {
    this.checkDebounced();
  }

  check = async () => {
    const value = this.props.value;

    if (!value || (this.props.original === value)) {
      this.setState({ checking: false });
      return;
    }
    this.setState({ checking: true });

    var
      valid = true,
      invalidMessage = null;

    if (valid === true && !(/^[a-z0-9]+(?:[-.][a-z0-9]+)*$/.test(value))) {
      valid = false;
      invalidMessage = 'Invalid slug entered. Only lowercase letters, numbers or dash.';
    }
    if (valid === true && !(await this.checkFn(value))) {
      valid = false;
      invalidMessage = 'The slug is already in use.';
    }

    if (this.props.value !== value) {
      // console.log('ignoring', value);
      return;
    }
    // console.log('value', value, 'valid', valid);

    this.setState({
      valid: valid,
      checking: false,
      checkedValue: value,
      invalidMessage
    });
    
    if (this.props.onValidChange)
      this.props.onValidChange(valid);
  }
  checkDebounced = debounce(this.check, 400)
}

export default SlugCheckInput;