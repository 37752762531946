import { useState } from 'react';
import startDragging from 'lib/startDragging';
import clsx from 'clsx';


export function ResizeBar({ width, onChange }) {
  const
    [dragging, setDragging] = useState(false),
    [dx, setDx] = useState(0);
  return (
    <div
      className={clsx(
        'h-full absolute top-0 w-[6px] bg-black z-10',
        dragging ? 'opacity-30' : 'opacity-0 hover:opacity-20'
      )}
      style={{ left: width + dx - 3, cursor: 'ew-resize' }}
      onMouseDown={(e) => {
        setDragging(true);
        startDragging({
          event: e,
          onMove: (e, dx, dy) => { setDx(dx); },
          onEnd: (e, dx, dy) => { onChange(dx); setDx(0); setDragging(false); }
        });
      }} />)
}