import StorageImage from 'lib/StorageImage';
import FolderContent from './FolderContent';
import clsx from 'clsx';
import BookmarkButton from './BookmarkButton';


export default function Workspace2({ viewer, hidden }) {
  const { domain } = viewer.workspace;
  
  if (!domain)
    return '';

  return (
    <div className={clsx('nb-viewer-theme', hidden && 'hidden')}>
      <div className="mx-auto flex gap-8 flex-col max-w-[30rem] md:flex-row md:max-w-[64rem]">
        <Left domain={domain} />
        <Right viewer={viewer} />
      </div>
    </div>
  )
}

function Left({ domain }) {
  const hasCover = domain.cover640;

  return (
    <div className="flex-1 flex flex-col gap-4">
      {hasCover && (
        <div className="p-1 border w-[24rem] mx-auto">
          <StorageImage path={domain.cover640} className="w-full" />
        </div>
      ) }

      <div className={clsx('text-center text-5xl font-header', hasCover ? 'mt-4' : 'mt-8')}>
        {domain.title}
      </div>

      {domain.description && (
        <div className="mt-4 p-4 border-t border-rim">
          <div ref={el => { if (el) el.innerHTML = domain.description; }} />
        </div>
      )}
    </div>
  )
}


function Right({ viewer }) {
  const { folder } = viewer.workspace;
  return (
    <div className="flex-1 flex flex-col px-12 pt-4  bg-paper border border-rim relative">
      <div className="absolute right-4 top-3">
        <BookmarkButton viewer={viewer} />
      </div>

      <div className="pl-2 text-center font-medium py-4 uppercase text-pencil">
        {folder.title}
      </div>

      <div className="pt-4 border-t border-rim">
        <FolderContent viewer={viewer}  />
      </div>
    </div>
  );
}