import { useState, useEffect } from 'react';
import clsx from 'clsx';


export default function GridElement({ id, index, renderedElement, layout }) {
  const
    { grid, selection } = layout,
    isActive = (selection.activeElementId === id),
    isSelected = selection.isPageFocused && selection.selectedElements.has(id),

    { x, y, w, h } = grid.getGridElement(id).toJS(),
    { cellSize, cellGap, transformer } = grid,

    width = (w || 1) * (cellSize.width + cellGap.x) - cellGap.x,
    height = (h || 1) * (cellSize.height + cellGap.y) - cellGap.y,
    left = (x || 0) * (cellSize.width + cellGap.x),
    top = (y || 0) * (cellSize.height + cellGap.y),
    [diff, setDiff] = useState(null);

  useEffect(() => transformer.on('change', () => {
    const state = transformer.state;
    if (state.get('moving') && selection.selectedElements.has(id)) {
      setDiff({ left: state.get('dx'), top: state.get('dy'), width: 0, height: 0 });
    } else if (state.get('resizing') && state.get('id') === id) {
      setDiff({ width: state.get('dx'), height: state.get('dy'), left: 0, top: 0 });
    } else {
      setDiff(null);
    }
  }), [selection, transformer, id]);

  return (
    <div
      ref={ref => layout.setElementContainerRef(id, ref)}
      className={clsx(
        'nb-grid-element absolute group cursor-auto',
        isActive && 'active',
        isSelected && 'selected',
        (isActive || isSelected) && 'z-10'
        // diff && 'pointer-events-none'
      )}
      style={{
        width: width + (diff ? diff.width : 0),
        height: height + (diff ? diff.height : 0),
        left: left + (diff ? diff.left : 0),
        top: top  + (diff ? diff.top : 0)
      }}
      onMouseDown={() => selection.setActiveElementId(id)}>
      <div className="grid-outline p-1"
        onMouseDown={e => transformer.onMoverMouseDown(e, id)}>
        <div className={clsx(
            'element-border w-full h-full border-dashed',
            isActive ? 'border-marker' : 'border-pencil3',
            (isSelected || isActive) ? 'border' : 'group-hover:border'
          )}></div>
      </div>
      { renderedElement }
      <div
        className="resizer absolute p-1 -bottom-3 -right-3"
        onMouseDown={e => transformer.onResizerMouseDown(e, id)}>
        <div className={clsx('w-2 h-2', isActive ? 'bg-marker2' : isSelected ?  'bg-pencil3' : 'group-hover:bg-pencil3')}>
        </div>
      </div>
    </div>
  );
}