import MenuBuilder from 'lib/components/MenuBuilder';
import { GearIcon, BellIcon, BookmarkIcon, ExitIcon, PersonIcon } from '@radix-ui/react-icons';

export default function UserButton({ app }) {
  const user = app.auth.user;

  const options = user ? [
    ['Settings', GearIcon, () => {}, { href: '/user/settings' }],
    ['Bookmarks', BookmarkIcon, () => {}, { href: '/user/bookmarks' }],
    ['Notifications', BellIcon, () => {}, { href: '/user/notifications' }],
    [],
    ['Sign Out', ExitIcon, () => app.auth.signOut()],
  ] : [
    [ 'Login', null, null, { href: '/auth/login' } ],
    [],
    [ 'Sign Up', null, null, { href: '/auth/signup' } ]
  ];

  return (
    <MenuBuilder className="p-1 mx-2" options={options}>
      <div className="user-button rounded-full w-7 h-7 border border-pen">
        <PersonIcon className="w-5 h-5 inline" />
      </div>
    </MenuBuilder>
  )
}