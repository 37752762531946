
function Overview({ controller, isActive }) {
  const cmd = controller.getSettingsValue('command');
  return (
    <div>
      { cmd && (<div className="font-mono text-xs">{cmd}</div>) }
      { !cmd && (<div className="italic opacity-50">No Command</div>) }
    </div>
  )
}

export default Overview;