import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import clsx from 'clsx';


export default function Files({ controller, isGrid, isActive, type, isDark, fileControllers }) {
  const
    { isReadOnly } = controller,
    hideFilenames = fileControllers.size === 1 && fileControllers.get(0).getSettingsValue('filename') === '',
    onDragEnd = ({ source, destination }) => {
      if (destination && source.index !== destination.index) {
        controller.moveOrderIndex(source.index, destination.index);
      }
    };

  return !hideFilenames && (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="file-tabs"
        direction={type === 'vertical' ? 'vertical' : 'horizontal'}>
        { provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={clsx('file-tabs flex', type === 'vertical' && 'flex-col')}>
            <div className="flex-grow"></div>
            { fileControllers.map((fileController, index) => {
              return (
                <DraggableTab
                  key={fileController.elementId}
                  id={fileController.elementId}
                  fileController={fileController}
                  index={index}
                  isElementActive={isActive}
                  isActive={controller.currentIndex === index}
                  multi={fileControllers.size > 1}
                  isReadOnly={isReadOnly}
                  type={type}
                  isDark={isDark}
                  setAsCurrent={() => { controller.setCurrentFileIndex(index) }}
                  editFilename={() => { fileController.editFilenamePopup({ allowEmpty: true }); }} />);
            })}
            { provided.placeholder }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function DraggableTab({
  id, index, setAsCurrent, editFilename, isActive, isElementActive,
  multi, isReadOnly, type, isDark, fileController
}) {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isReadOnly}>
      {(provided, snapshot) => (
        <div {...provided.draggableProps} style={provided.draggableProps.style}>
          <div ref={provided.innerRef} {...provided.dragHandleProps}>
            <div
              className={clsx(
                'mx-1 px-2 break-words',
                (multi || !isReadOnly) ? 'cursor-pointer' : 'cursor-default',
                multi && (isActive ? `font-bold text-pencil` : 'text-pencil2'),
                type === 'floating' ? `py-1 ` : type === 'vertical' ? 'py-2' : 'py-2',
                type === 'floating' && 'bg-paper2 border border-rim2 text-pencil',
                type === 'normal' && isActive && `border-b border-pencil2`,
                type === 'vertical' && isActive && ''
                // (snapshot.isDragging ? ' dragging' : ''),
              )}
              onMouseDown={
                !isReadOnly ?
                  (isActive ?
                    () => {} : //editFilename :
                    setAsCurrent
                  ) :
                  () => {}
              }>
              <Filename fileController={fileController} />
            </div>
          </div>
        </div>
       )}
    </Draggable>
  )
}

function Filename({ fileController }) {
  const
    filename = fileController.getSettingsValue('filename'),
    [, setCurrentState] = useState(fileController.state);

  useEffect(() => fileController.onStateChange(() => setCurrentState(fileController.state)), [fileController]);
  return (<>{filename || 'untitled'}</>);
}