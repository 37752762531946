import { useControllerState } from 'lib/BaseController';

import { ui } from 'lib/popup';
import SlugCheckInput from 'lib/inputs/SlugCheckInput';
import { Group, TextInput, TextAreaInput, Checkbox } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';


function CreateVersionView({ popup }) {
  let
    state = useControllerState(popup),
    slugReady = state.get('isSlugValid'),
    loading = state.get('loading');

  return (
    <ui.Popup popup={popup} className="create-new-version-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Create a New Version
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <Group>
            <form onSubmit={e => { e.preventDefault(); popup.createVersion(); }}>
              <TextInput
                label="Version Label"
                value={state.get('label')}
                autoFocus={true}
                isDisabled={loading}
                onChange={v => popup.setState({ label: v })} />

              <SlugCheckInput
                label="Version Slug"
                value={state.get('slug')}
                onChange={v => popup.setState({ slug: v })}
                onValidChange={e => popup.setState({ isSlugValid: e })}
                checkFn={(val) => popup.provider.checkAvailable(state.get('parentPath') + '\\' + val)} />

              <Checkbox
                label="Public Access"
                isDisabled={loading}
                value={state.get('isPublic')}
                onChange={c => popup.setState({ isPublic: c })} />

              <TextAreaInput
                label="Notes"
                isDisabled={loading}
                value={popup.state.get('notes')}
                onChange={c => popup.setState({ notes: c })} />                

              <Files popup={popup} />

              <ui.Alert />

              <Buttons className="text-right">
                <Button isDisabled={loading || !slugReady} type="submit">
                  Create Version
                </Button>
                <Button onPress={e => { popup.close() }}>
                  Cancel
                </Button>
              </Buttons>
            </form>
          </Group>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}


function Files({ popup }) {
  const
    { localFiles } = popup.articleController,
    files = localFiles.getFilenames();

  return (
    <div className="">
      <div className="text-sm font-medium mb-1">Files in this version</div>
      <div className="p-1 border border-rim2 max-h-[8rem] overflow-auto">
        {Array.from(files).map((filename, i) => (
          <div className="font-mono text-xs inline-block p-1 mx-1 my-0.5 bg-wax" key={i}>{filename}</div>
        ))}
      </div>
    </div>
  )
}

export default CreateVersionView;