import { TopLeft, CloseButton, Frame } from '../misc/components';
import { companyName, feedbackEmail } from 'meta';

function Container({ children }) {
  return (
    <Frame className="max-w-[30rem] p-8" fullHeight={false}>
      <TopLeft>
        <CloseButton to="/" />
      </TopLeft>
      { children }
    </Frame>
  )
}

export function About() {
  return (
    <Container >
      <h1 className="text-3xl text-center mb-4 font-medium">About</h1>
      <hr className="my-4" />
      <p className="my-4">
        You are viewing the beta version of the website.
        The website is provided to you by {companyName}.
      </p>
      <p className="my-4">
        Please send your suggestions, feedbacks or queries
        to <strong>{feedbackEmail}</strong>.
      </p>
    </Container>
  );
}


export function Terms() {
  return (
    <Container>
      <h1 className="text-3xl text-center mb-4 font-medium">Terms of Use</h1>

      <hr className="my-4" />

      <h3 className="text-2xl mb-4 font-medium">Usage</h3>
      <p className="my-4">
        This current beta version of the app is <strong>for experminentation and demo</strong> purposes only.
        Please only this version of the app for personal, experimental or recreational purposes.
      </p>

      <h3 className="text-2xl mb-4 font-medium">Privacy Policy</h3>
      <p className="my-4">
        {companyName} takes <strong>NO RESPONSIBILITY</strong> whatsoever over the loss or 
        dissemination of your data during this experimental phase of this application.
      </p>
    </Container>
  )
}

export default About;