import BaseController from 'lib/BaseController';
import { toHuman } from 'lib/firebaseError';
import Prompt from 'lib/popup/popups/Prompt/Controller';


export default class Access extends BaseController {
  constructor(domain) {
    super();
    this.domain = domain;
    this.state = this.state.merge({ email: '', role: 'read', list: null });
  }

  get provider() {
    return this.domain.provider;
  }

  fetchList(soft) {
    if (!soft)
      this.setState({ list: null, loadingError: null, alert: null });

    return (
      this.provider.getDomainAccessList(this.domain.id)
        .then(result => this.setState({ list: result && result.data }))
        .catch(error => this.setState({ loadingError: true }))
    );
  }

  getUserId() {
    const user = this.domain.app.auth.user;
    return user && user.uid;
  }

  addDomainAccessPopup = () => {
    const roles = [
      ['admin', 'Admin'],
      ['write', 'Read / Write'],
      ['read', 'Read Only'],
    ];

    this.domain.popupManager.open(Prompt, {
      title: 'Add User',
      submitLabel: 'Save',
      inputs: [
        { label: 'User Email', type: 'text', defaultValue: '', autoFocus: true },
        { label: 'Role', type: 'select', defaultValue: 'admin', options: roles },
      ],
      onSubmit: async ([ email, role ]) => {
        const domainId = this.domain.id;
        await this.provider.addDomainAccess({ domainId, email, role });
        this.fetchList();
      }
    });
  }

  deleteUserAccess({ userId }) {
    this.setState({ processing: true })
    this.provider.deleteDomainUserAccess({ domainId: this.domain.id, userId })
      .then(() => this.fetchList(true))
      .catch(e => { console.error(e); this.setState({ alert: toHuman(e) }) })
      .then(() => this.setState({ processing: false }));
  }

  updateDomainUserRole({ userId, role }) {
    this.setState({ processing: true })
    this.provider.updateDomainUserRole({ domainId: this.domain.id, userId, role })
      .then(() => this.fetchList(true))
      .catch(e => { console.error(e); this.setState({ alert: toHuman(e) }) })
      .then(() => this.setState({ processing: false }));
  }
}