import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });

  constructor(args, manager) {
    super(args, manager);
    this.auth = args.auth;

    const
      { url, bio } = this.auth.state.get('userInfo') || {},
      name = this.auth.user.displayName;
    
    this.state = this.state.merge({
      name: name || '',
      url: url || '',
      bio: bio || ''
    });
  }

  save = () => {
    this.setState({ loading: true, alert: '' });
    const { name, url, bio } = this.state.toJS();
    return (
      this.auth.updateUserInfo({ url, bio })
        .then(_ => this.auth.updateProfile({ displayName: name }))
        .then(_ => this.auth.refreshUser())
        .then(_ => this.close())
        .catch(e => {
          console.error(e);
          this.setState({ alert: toHuman(e) });
        })
        .then(_ => this.setState({ loading: false }))
    );
  }
}

export default PopupController;