import { Map, fromJS } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';
import { toHuman } from 'lib/firebaseError';

class ArticleAccessController extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  constructor(args, manager) {
    super(args, manager);
    this.article = args.articleController.state.get('article');
    this.provider = args.articleController.provider;
    this.articleId = this.article.get('id');
    this.state = this.state.merge({
      showNotice: true,
      data: null,
      dataLoading: false,
      dataError: false,
      email: '',
      access: 'r'
    });
    this.unloadFns.push(
      this.provider.onArticleAccesses(this.articleId, this.handleData, this.handleError)
    ) 
  }

  handleData = (data) => {
    data.sort((b, a) => ((a.id > b.id) ? -1 : (a.id < b.id) ? 1 : 0));
    this.setState({ data: fromJS(data), dataLoading: false });
  }

  handleError = (e) => {
    console.error(e);
    this.setState({ dataError: true });
  }

  addAccess = async () => {
    const
      email = this.state.get('email'),
      access = this.state.get('access');

    await this.tryFn(() => this.provider.updateArticleAccess({
      articleId: this.articleId,
      email,
      accessData: { access }
    }));

    if (!this.state.get('alert'))
      this.setState({ email: '', access: 'r' });
  }

  updateAccess = ({ email, access }) => {
    console.log(email, access);
    this.tryFn(() => this.provider.updateArticleAccess({
      articleId: this.articleId,
      email,
      accessData: { access }
    }));
  }

  deleteAccess = ({ email }) => {
    this.tryFn(() => this.provider.deleteArticleAccess({
      articleId: this.articleId,
      email: email
    }));
  }

  tryFn = async (fn) => {
    this.setState({ loading: true });
    try {
      await fn();
    } catch (e) {
      console.error(e);
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }
}

export default ArticleAccessController;