function Overview({ controller }) {
  const state = controller.getState();
  
  return (
    <div>
      { state.get('order').map((id, i) => state.getIn(['filenames', id]) ? (
        <div key={id} className="font-mono text-xs">{ state.getIn(['filenames', id]) }</div>
      ) : (
        <div key={id} className="italic opacity-50">No filename</div>
      )) }
    </div>
  )
}

export default Overview;