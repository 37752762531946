import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import CodeMirror from '@uiw/react-codemirror';
import allThemes from 'lib/codemirror/themes';
import { loadLanguage } from '@uiw/codemirror-extensions-langs';
import clsx from 'clsx';

export function Page({ children, className }) {
  return (
    <div
      className="h-full overflow-hidden snap-start mb-10 max-w-[60rem] mx-auto relative px-2 pt-6">
      <div className={clsx("relative h-full w-full", className)}>
        {children}
      </div>
    </div>
  );
}


export function Pos({ x, y, width=1, height=1, className, children }) {
  return (
    <div
      className={clsx('absolute', className)}
      style={{
        top: y * 5 + 'rem',
        left: x * 5 + 'rem',
        width: (width * 5 - 1) + 'rem',
        height: (height * 5 - 1) + 'rem',
      }}>
      {children}
    </div>
  )
}


export function Quote({ x, y, width, height, children, arrow='right' }) {
  return (
    <Pos x={x} y={y} width={width} height={height} className="bg-paper">
      <div className="h-full w-full border border-blue-800 p-4 rounded text-blue-800 bg-blue-50 relative">
        <div className="float-left mr-2">
          <FontAwesomeIcon icon={faQuoteLeft} />
        </div>
        { children }

        <div className={clsx(
          arrow === 'right' && 'bottom-[2rem] right-0 translate-x-1/2 translate-y-1/2 -rotate-45 border-r border-b',
          arrow === 'left' && 'bottom-[2rem] left-0 -translate-x-1/2 translate-y-1/2 -rotate-45 border-l border-t',
          'absolute transform w-4 h-4 bg-blue-50 border-indigo-500'
        )}></div>
      </div>
    </Pos>
  )
}


export function Quotto({ x, y, className, }) {
  return (
    <Pos x={x} y={y} className={clsx('text-2xl pt-3 text-center text-blue-800', className)}>
      (⌐■_■)
    </Pos>
  )
}

export function Code({ x, y, width, height, value, lang, theme }) {
  return (
    <Pos x={x} y={y} width={width} height={height} className="border border-rim">
      <CodeMirror
        value={value}
        extensions={[loadLanguage(lang)]}
        theme={allThemes[theme || 'duotoneLight']}
        height="100%"
        basicSetup={{
          lineNumbers: true,
          highlightActiveLineGutter: false,
          highlightActiveLine: false,
          foldGutter: false,
        }}
        readOnly={true} />
    </Pos>
  )
}