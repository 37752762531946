import BaseController from 'lib/BaseController';
import startDragging from 'lib/startDragging';
// import { fromJS } from 'immutable';
// const CAMERA_MARGIN = 400;

export default class Camera extends BaseController {
  constructor(grid) {
    super();
    this.grid = grid;
    this.state = this.state.merge({
      width: 0, height: 0,
      dx: 0, dy: 0,
      ox: 0, oy: 0,
      dragging: false
    });
  }
  
  setStageEl = (el) => { this.stageEl = el;  }
  setPageEl = (el) => { this.pageEl = el;  }
  getX = () => this.stageEl ? this.stageEl.scrollLeft : 0;
  getY = () => this.stageEl ? this.stageEl.scrollTop : 0;

  startDrag = (e) => {
    if (!this.stageEl)
      return;
    const { scrollLeft, scrollTop } = this.stageEl;

    this.setState({ dragging: true });
    startDragging({
      event: e,
      onMove: (e, dx, dy) => {
        this.stageEl.scrollLeft = scrollLeft - dx;
        this.stageEl.scrollTop = scrollTop - dy;
      },
      onEnd: () => {
        this.setState({ dragging: false })
      }
    });
  }

  // setXY = ({ x, y }) => {
  //   const
  //     { state } = this,
  //     { width, height } = this.grid.pageDim;

  //   x = Math.min(CAMERA_MARGIN - state.get('ox'), x);
  //   y = Math.min(CAMERA_MARGIN - state.get('oy'), y);
  //   x = Math.max(- CAMERA_MARGIN - state.get('ox') - (width - state.get('width')), x);
  //   y = Math.max(- CAMERA_MARGIN - state.get('oy') - (height - state.get('height')), y);


  //   this.state = this.state.setIn(['positionMap', this.articleId], fromJS({ x, y }))
  // }
}