import { Button as AriaButton, TooltipTrigger, Tooltip } from 'react-aria-components';
import clsx from 'clsx';


export default function Button({ children, isDisabled, onPress, tooltip, className }) {
  return (
    <TooltipTrigger>
      <AriaButton
        type="button"
        className={({ isDisabled }) => clsx(
          'outline-none',
          typeof className === 'string' ? className : 'font-medium rounded-sm border border-rim bg-paper p-2',
          isDisabled && 'pointer-events-none text-pencil3 opacity-50'
        )}
        onPress={onPress}
        isDisabled={isDisabled}
        children={children} />
      {tooltip && (
        <Tooltip
          placement="bottom"
          className="text-sm bg-gray-800 text-gray-100 p-1 px-3">{tooltip}</Tooltip>
      )}
    </TooltipTrigger>
  );
}