class Emitter {
  constructor() {
    this._eventListeners = {};
  }

  on(event, fn) {
    if (!this._eventListeners[event])
      this._eventListeners[event] = new Set();
    const _eventListeners = this._eventListeners[event];
    _eventListeners.add(fn);
    return _ => _eventListeners.delete(fn);
  }

  emit(event, value) {
    if (!this._eventListeners[event])
      return;
    // cloning set so that any new set entries would be ignored
    const _eventListeners = new Set(this._eventListeners[event]);
    _eventListeners.forEach(fn => fn(value));
  }

  destroy() {
    for (var event in this._eventListeners) {
      const _eventListeners = this._eventListeners[event];
      _eventListeners.forEach(fn => _eventListeners.delete(fn));
    }
  }
}

export default Emitter;