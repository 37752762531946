import { Fragment } from 'react';
import { Block, Break } from './inputs/components';

import TextInput from './inputs/TextInput';
import Textarea from './inputs/Textarea';
import Selection from './inputs/Selection';
import Checkbox from './inputs/Checkbox';
import ColorInput from './inputs/ColorInput';



export function BuildInputs({ fields, layout, getData, setData, getValue, getValueOnNull }) {
  return (
    <>
      {layout.map((section, i1) => (
        <Fragment key={i1}>
          { section.map((line, i2) => (
            <Fragment key={i2}>
              { line.map((fieldKey, i3) => {
                const field = fieldKey && fields[fieldKey];
                if (!field)
                  return '';

                return (
                  <BuildInput
                    key={i3}
                    fieldKey={fieldKey}
                    field={fields[fieldKey]}
                    caption={field.captionFn ? field.captionFn(getValue) : field.caption}
                    value={typeof getData(fieldKey) === 'undefined' ? null : getData(fieldKey)}
                    setData={(v) => setData(fieldKey, v)}
                    valueOnNull={getValueOnNull(fieldKey)}
                    half={line.length === 2}
                    isDisabled={field.isDisabledFn && field.isDisabledFn(getValue)}
                    hidden={field.isHiddenFn && field.isHiddenFn(getValue)} />);
              }) }
            </Fragment>
          )) }
          { (i1 !== layout.length - 1) && <Break /> }
        </Fragment>
      )) }
    </>
  )
}

function BuildInput({
  fieldKey, field, caption, value, valueOnNull,
  setData, half, isDisabled, hidden
}) {
  if (hidden) {
    return '';
  }

  if (!field) {
    return ('Not found ' + fieldKey);
  }

  return (
    <Block span={half && 'half'}>
      { field.input === 'number' && !field.options && (
        <TextInput
          label={caption}
          type="number"
          value={value}
          valueOnNull={valueOnNull}
          nullable={field.nullable}
          unit={field.unit}
          range={field.range}
          validateFn={field.validateFn}
          onChange={v => setData(v)}
          isDisabled={isDisabled} />
      ) }
      { field.input === 'textarea' && (
        <Textarea
          label={caption}
          value={value}
          nullable={field.nullable}
          valueOnNull={valueOnNull}
          onChange={v => setData(v)}
          isDisabled={isDisabled} />
      ) }
      { field.input === 'text' && (
        <TextInput
          label={caption}
          value={value}
          valueOnNull={valueOnNull}
          validateFn={field.validateFn}
          nullable={field.nullable}
          onChange={v => setData(v)}
          isDisabled={isDisabled}/>
      ) }
      { field.input === 'options' && (
        <Selection
          label={caption}
          value={value}
          options={field.getOptions ? field.getOptions({ valueOnNull }) : field.options}
          onChange={v => setData(v)}
          isDisabled={isDisabled}/>
      ) }
      { field.input === 'checkbox' && (
        <Checkbox
          label={caption}
          value={value}
          nullable={field.nullable}
          valueOnNull={valueOnNull}
          onChange={v => setData(v)}
          isDisabled={isDisabled}/>
      ) }
      { field.input === 'color' && (
        <ColorInput
          label={caption}
          value={value}
          nullable={field.nullable}
          valueOnNull={valueOnNull}
          onChange={v => setData(v)}
          isDisabled={isDisabled}/>
      ) }
    </Block>
  )
}