import { Label } from './components';
import BaseSelection from 'lib/inputs/BaseSelection';

export function Selection({ label, options, value, onChange }) {
  return (
    <BaseSelection {...{
      label, options, value, onChange,
      LabelComponent: Label,
      buttonClassName: 'px-2 py-1 text-sm font-medium hover:bg-wax2 w-full',
      listClassName: 'min-w-40 max-w-60 text-sm' }} />);
}

export default Selection;