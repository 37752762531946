import Tabs from 'modules/elements/lib/sidebar/inputs/Tabs';
import { Button, Buttons } from 'modules/elements/lib/sidebar/inputs/buttons';
import { Break, Block, Label } from 'modules/elements/lib/sidebar/inputs/components';
import TextInput from 'modules/elements/lib/sidebar/inputs/TextInput';
import Textarea from 'modules/elements/lib/sidebar/inputs/Textarea';
import Selection from 'modules/elements/lib/sidebar/inputs/Selection';
import TableInspector from './TableInspector';
import {
  faAlignLeft, faAlignCenter, faAlignRight
} from '@fortawesome/free-solid-svg-icons';

import './style.css';


export default function Inspector({ controller }) {
  return (
    <div className="table-inspector">
      <Tabs
        selectedKey={controller.state.get('tabAt')}
        onSelectionChange={key => controller.setState({ tabAt: key })}
        tabs={[
          { key: 'cell', caption: 'Cell', el: (<DataCellInspector controller={controller} />) },
          { key: 'table', caption: 'Table', el: (<TableInspector controller={controller} />) },
        ]} />
    </div>
  )
}

function DataCellInspector({ controller }) {
  const
    {  selection, data } = controller,
    { si, sj } =   selection,
    cell = typeof si !== 'undefined' && data.getCell(si, sj),
    column = data.getCell(-1, sj),
    titleAlignment = controller.getSettingsValue('titleAlignment'),
    alignment = column.get('alignment');

  return (
    <>
      <Block>
        <Textarea
          label="Cell Value"
          key={selection ? (si + '.' + sj) : ''}
          value={cell.get('value') || ''}
          onChange={v => {
            if (cell)
              data.setCellValue(si, sj, v)
          }}
          isDisabled={!selection}
          alwaysFill={true} />
      </Block>

      <Break />

      <Block>
        <Selection
          label="Data Type"
          value={column.get('type')}
          options={[
            { key: 'text', caption: 'Text' },
            { key: 'number', caption: 'Number' }
          ]}
          onChange={v => data.setCell(-1, sj, 'type', v)}
          isDisabled={false}/>
      </Block>

      { si !== -1 && (
        <Block span="half">
          <Label>Data Alignment</Label>
          <Buttons>
            <Button
              faIcon={faAlignLeft}
              isActive={alignment === 'left'}
              onPress={() => data.setCell(-1, sj, 'alignment', 'left')} />
            <Button
              faIcon={faAlignCenter}
              isActive={alignment === 'center'}
              onPress={() => data.setCell(-1, sj, 'alignment', 'center')} />
            <Button
              faIcon={faAlignRight}
              isActive={alignment === 'right'}
              onPress={() => data.setCell(-1, sj, 'alignment', 'right')} />
          </Buttons>
        </Block>
      ) }

      { si === -1 && (
        <Block span="half">
          <Label>Title Alignment</Label>
          <Buttons>
            <Button
              faIcon={faAlignLeft}
              isActive={titleAlignment === 'left'}
              onPress={() => controller.setSettings('titleAlignment', 'left')} />
            <Button
              faIcon={faAlignCenter}
              isActive={titleAlignment === 'center'}
              onPress={() => controller.setSettings('titleAlignment', 'center')} />
            <Button
              faIcon={faAlignRight}
              isActive={titleAlignment === 'right'}
              onPress={() => controller.setSettings('titleAlignment', 'right')} />
          </Buttons>
        </Block>
      )}

      <br/>

      { column.get('type') === 'number' && (
        <>
          <Break />

          <Block span="half">
            <Label>Format</Label>
            <Buttons>
              <Selection
                value={column.get('numberFormatting')}
                options={[
                  { key: null, caption: 'Standard' },
                  { key: 'fr-FR', caption: 'French' },
                  { key: 'de-DE', caption: 'German' },
                  { key: 'en-IN', caption: 'Indian' },
                  { key: false, caption: 'No Formatting' }
                ]}
                onChange={v => data.setCell(-1, sj, 'numberFormatting', v)}
                isDisabled={false}/>
            </Buttons>
          </Block>

          <Block span="half">
            <Selection
              label="Decimal"
              value={column.get('decimalPlaces')}
              options={[
                { key: null, caption: 'Default' },
                { key: 1, caption: '0.0' },
                { key: 2, caption: '0.00' },
                { key: 3, caption: '0.000' },
                { key: 4, caption: '0.0000' },
                { key: 5, caption: '0.00000' },
                { key: 6, caption: '0.000000' }
              ]}
              onChange={v => data.setCell(-1, sj, 'decimalPlaces', v)}
              isDisabled={false}/>
          </Block>

          <Block span="half">
            <TextInput
              label="Prefix"
              key={selection ? (-1 + '.' + sj) : ''}
              value={column.get('prefix') || ''}
              onChange={v => {data.setCell(-1, sj, 'prefix', v)}}
              isDisabled={!selection}
              alwaysFill={true}  />
          </Block>

          <Block span="half">
            <TextInput
              label="Suffix"
              key={selection ? (-1 + '.' + sj) : ''}
              value={column.get('suffix') || ''}
              onChange={v => data.setCell(-1, sj, 'suffix', v)}
              isDisabled={!selection}
              alwaysFill={true} />
          </Block>
        </>
      ) }
    </>
  )
}