import { useState, useEffect } from 'react';
import { getSizeString } from 'lib/tools.ts';
import { Button } from 'react-aria-components';
import { TrashIcon, OpenInNewWindowIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons';

import './FileDetail.css';

export default function FileDetail({ popup }) {
  const
    state = popup.state,
    selectedId = state.get('selectedId'),
    uploadFiles = popup.articleController.state.get('uploads'),
    uploadFile = uploadFiles.find(d => d.get('id') === selectedId);

  return (
    <div className="file-detail h-full">
      { uploadFile && (<UploadFileInfo popup={popup} upload={uploadFile} />) }
      { !uploadFile && <div className="pt-16 text-center uppercase text-pencil3">Select a file</div> }
    </div>
  );
}


function UploadFileInfo({ popup, upload }) {
  const
    { filename, name, createdAt, contentType, size} = upload.toJS(),
    { getDownloadURL } = popup.articleController.provider;

  return (
    <div className="file-info p-4 flex flex-col h-full">
      <div className="flex-grow overflow-auto">
        <div className="big-file-icon">
          <div className="type">{contentType}</div>
        </div>
        {upload.get('isDeleted') && (
          <div className="text-sm p-1 text-center text-error">
            <ExclamationTriangleIcon className="inline mr-1" /> Deleted file, exists in other versions
          </div>
        )}

        <div className="font-mono text-center py-2 my-3 border-b border-t border-rim text-sm break-words">
          {filename}
        </div>


        <div className="my-3 text-sm">
          <div className="text-pencil3 mb-1">Uploaded at</div>
          <div>{new Date(createdAt.seconds * 1000).toLocaleString()}</div>
        </div>

        <div className="my-3 text-sm">
          <div className="text-pencil3 mb-1">Size</div>
          <div>{getSizeString(size)}</div>
        </div>
      </div>

      <div className="flex gap-2 py-1 justify-center">
        <DownloadURL
          path={name}
          getDownloadURL={getDownloadURL} />

        {!upload.get('isDeleted') && (
          <Button
            className="w-20 py-1 px-2 border border-rim3 inline-block"
            onPress={() => popup.articleController.popups.deleteUploadPopup({ upload })}>
            <TrashIcon className="inline" /> Delete
          </Button>
        ) }
        {upload.get('isDeleted') && (
          <Button
            className="py-1 px-2 border border-rim3 inline-block"
            onPress={() => popup.articleController.popups.deleteUploadPopup({ upload, hardDelete: true })}>
            <TrashIcon className="inline" /> Hard Delete
          </Button>
        ) }        
      </div>      
    </div>
  )
}

function DownloadURL({ path, getDownloadURL }) {
  const [url, setUrl] = useState();
  useEffect(_ => {
    if (!path)
      return;
    getDownloadURL(path).then(u => setUrl(u)).catch(e => setUrl(''));
  }, [path, getDownloadURL]);

  return url && (
    <a
      target="_blank"
      href={url}
      rel="noopener noreferrer"
      className="w-20 py-1 px-2 border border-rim3 inline-block">
      <OpenInNewWindowIcon className="inline" /> Open
    </a>
  )
}