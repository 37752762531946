import './ImageInput.css';

export default function ImageInput({
  imageData, imageUrl, placeholderUrl, placeholderText,
  imageWidth, imageHeight, autoWidth, borderRadius=0,
  children
}) {
  const displayUrl = imageUrl || placeholderUrl;

  return (
    <div className="nb-image-input">
      <div className="py-1 px-0.5 relative border border-rim2" style={{ borderRadius: borderRadius }}>
        { imageData ?
          (<div className="image" style={{
            width: imageWidth,
            height: imageHeight,
            borderRadius: borderRadius || 0,
            backgroundImage: `url(data:${imageData})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: '#eee',
            }} />
          ) :
          displayUrl ? (
            <img
              src={displayUrl}
              className="block"
              style={{
                height: imageHeight,
                borderRadius,
                width: (autoWidth ? 'auto' : imageWidth),
              }}
              alt="user" />
          ) : (
            <div
              className="flex flex-row  justify-center items-center text-sm font-medium bg-paper2"
              style={{ borderRadius, width: imageWidth, height: imageHeight }} >
              <div className="text-pencil3">{placeholderText}</div>
            </div>
        )}
        { children }
      </div>
    </div>
  );
}