import { useEffect } from 'react';
import { useControllerState } from 'lib/BaseController';
import UsageView from 'modules/domain/views/Usage';
import Loader from 'lib/staticPages/Loader';
import { Button } from 'lib/inputs/buttons';


export default function Usage({ controller }) {
  const
    state = useControllerState(controller.usage),
    tally = state.get('tally'),
    subscription = state.get('subscription'),
    plans = state.get('plans');

  useEffect(() => { controller.usage.fetch() }, [controller]);

  return (
    <div className="px-8 py-6">
      <div className="font-medium mb-4 text-center text-2xl">Usage and Limits</div>
      <hr className="mb-4" />

      {tally !== null && (<UsageView tally={tally} subscription={subscription} plans={plans} />)}
      {tally === null && (<div className="text-center"><Loader /></div>)}

      <div className="text-right mt-10">
        <Button
          href={'/upgrade/' + controller.state.getIn(['domain', 'slug'])}>
          Upgrade Workspace
        </Button>
      </div>
    </div>
  )
}