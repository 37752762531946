import { history } from 'prosemirror-history'
import { keymap } from 'prosemirror-keymap'
import { dropCursor } from 'prosemirror-dropcursor'
import { gapCursor } from 'prosemirror-gapcursor'
import { baseKeymap } from 'prosemirror-commands'
import { schema } from '../schema.ts'
import { buildKeymap } from './keymap.ts'
import { buildInputRules } from './inputRules.ts'
import { getLinkTooltipPlugin } from './linkTooltip'
// import { getMathPlugin } from './math';

export function getPlugins() {
  return [
    // keymap({
    //   'Mod-Space': insertMathCmd(schema.nodes.math_inline),
    //   'Backspace': chainCommands(deleteSelection, mathBackspaceCmd, joinBackward, selectNodeBackward),
    // }),
    buildInputRules(schema),
    keymap(buildKeymap(schema)),
    keymap(baseKeymap),
    dropCursor(),
    gapCursor(),
    history(),
    getLinkTooltipPlugin(),
    // getMathPlugin()
  ]
}