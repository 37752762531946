import Editor from './Editor';
import Controller from './Controller';
import Policies from './Policies';
import Inspector from './Inspector';
import Viewer from './Viewer';
import Overview from './Overview';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';

const index = {
  type: 'excalidraw',
  caption: 'Excalidraw',
  faIcon: faPencilRuler,
  description: 'Draw diagram, flowcharts, infographics, etcetra',

  Editor: Editor,
  Controller: Controller,
  Inspector: Inspector,
  Policies: Policies,
  Viewer: Viewer,
  Overview: Overview
}

export default index;