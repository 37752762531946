import BaseElementController from 'modules/elements/lib/BaseElementController';
import EditorController from 'lib/prosemirror/Controller';
import settingsFields from '../Policies/settingsFields';


class Controller extends BaseElementController {
  settingsFields = settingsFields;

  constructor(args) {
    super(args);
    this.editorController = new EditorController({
      openPromptPopup: a => this.openPopup('prompt', a),
      openKatexPopup: a => this.openPopup('katex-editor', a),
      openLinkPopup: a => this.openPopup('link-select', a),
      openImagePopup: a => this.openPopup('image-select', a),
      getURL: async location => {
        const file = (
          location.startsWith('./') ?
            this.getLocalFiles().getFile(location.substr(2)) :
            location.startsWith('/') ?
              await this.getRemoteFiles().getFile(location).catch(e => {}) :
              null
        );
        return file ? await file.getURL() : location;
      },
      viewState: this.state.get('editorViewState'),
      isReadOnly: args.isReadOnly
    });
    this.unloadFns = [
      this.editorController.on('change', this.transferState),
      this.onStateChange(this.handleStateChange)
    ];
  }

  focus = () => {
    if (this.editorController.view)
      this.editorController.view.focus();
  }

  transferState = () => {
    this.setState({ editorViewState: this.editorController.view.state });
  }
  
  handleStateChange = () => {
    if (this.state.get('editorViewState') !== this.editorController.view.state) {
      this.editorController.setViewState(this.state.get('editorViewState'));
    }
  }
}

export default Controller;