import { useRef } from 'react';
import { list } from 'modules/elements/all';
import { useControllerState } from 'lib/BaseController';
import ui from 'lib/popup/components/ui';
import Icon from 'lib/Icon';
import clsx from 'clsx';


function Popup({ popup }) {
  const inputRef = useRef();
  useControllerState(popup);

  return (
    <ui.Popup popup={popup} className="add-new-element-popup">
      <ui.Dialog onMouseDown={_ => inputRef.current.focus()}>
        <ui.TitleBar>
          <ui.Title>Add Element</ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <input
            ref={inputRef}
            autoFocus={true}
            className="w-0 h-0 opacity-0 absolute"
            onKeyDown={popup.handleInputKeyDown} />

          <ElementButtons popup={popup} />
          <ui.Alert />
          {/* <Description popup={popup} /> */}
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

function ElementButtons({ popup }) {
  const
    selected = popup.state.get('selected'),
    loading = popup.state.get('loading');

  return (
    <div className="">
      { list.map((element, i) => {
        return (
          <div
            key={i}
            className={clsx(
              'inline-flex w-24 h-24 text-center m-2 flex-col justify-center cursor-pointer rounded-sm border ',
              selected === i ? 'border-marker text-marker3 ' : 'text-pencil border-rim',
              loading && 'disabled',
              element.unavailable && 'disabled'
            )}
            onMouseEnter={_ => popup.select(i)}
            onMouseLeave={_ => popup.select(null)}
            onClick={_ => popup.addElement(element.type)}>
            <div className="text-xl mb-1">
              <Icon faIcon={element.faIcon} />
            </div>
            <div className="text-sm">
              {element.caption}
            </div>
          </div>
        );
      }) }
    </div>
  )
}

// function Description({ popup }) {
//   const selected = popup.state.get('selected');
//   if (!selected) return '';
//   return (
//     <div className="description">
//       {elements[selected].description}
//     </div>
//   )
// }

export default Popup;