import { ui } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';
import Loader from 'lib/staticPages/Loader';
import { UserAccessTable, AddAccessForm } from './components';
import { Buttons, Button } from 'lib/inputs/buttons';


function ArticleAccess({ popup }) {  
  return (
    <ui.Popup popup={popup} className="article-access-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Article User Access
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body className="p-3">
          <Body popup={popup} />
          <ui.Alert />
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  );
}


function Body({ popup }) {
  let state = useControllerState(popup);

  if (state.get('dataError'))
    return (
      <div className="text-red-6 p-4">
        The user access list could not be loaded.<br />
        Make sure you have the required permission and try again.
      </div>
    );

  if (state.get('showNotice'))
    return (<Notice onDone={() => popup.setState({ showNotice: false})} />)

  if (state.get('dataLoading'))
    return (<div className="p-3 text-center"><Loader /></div>);

  return (
    <div className="">
      <UserAccessTable popup={popup} />
      <AddAccessForm popup={popup} />
    </div>
  )
}


function Notice({ onDone }) {
  return (
    <div className="py-4 px-4">
      <div className="p-5 border border-rim">
        <div className="text-center text-xl mb-2">Note</div>
        <div className="text-sm">
          Adding user's <strong>email</strong> here will give them access 
          only to this particular article.
          To add access for all articles in workspace, add
          them to the workplace's access list.
        </div>
        <Buttons className="text-right">
          <Button onPress={onDone}>Continue</Button>
        </Buttons>
      </div>
    </div>    
  );
}

export default ArticleAccess;