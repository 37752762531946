import elementLibs from 'modules/elements/all';
import { useControllerState } from 'lib/BaseController';


export default function Scroll({ article }) {
  const
    { layout } = article,
    { pointSize, themeData } = layout,
    pageMargin = themeData.getValue('pageMargin'),
    pagePaddingStyle = {
      paddingLeft: pageMargin.left * pointSize,
      paddingRight: pageMargin.right * pointSize,
      paddingTop: pageMargin.top * pointSize,
      paddingBottom: pageMargin.bottom * pointSize
    }

  return (
    <div className="scroll-container px-12 pt-24 pb-24">
      <div className="scroll-page border-rim border page-max-width mx-auto" style={pagePaddingStyle}>
        <div className="my-6 article-title font-header">
          {article.article.title}
        </div>
        <div>
          { layout.order.map((id, index) => article.elementControllers[id] && (
            <Element key={id} element={article.elementControllers[id]} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Element({ element }) {
  useControllerState(element);
  const lib = elementLibs[element.type];
  return (
    <div className="my-8">
      <lib.Viewer controller={element.controller} />
    </div>
  )
}