class DiskSync {
  constructor(machine) {
    this.machine = machine;
  }

  fetchRemoteIndex = async ({ docId }) => {
    const response = await fetch(`${this.machine.url}/disk/doc/${docId}/index`);
    
    if (response.status === 404) {
      return {};
    } else if (response.ok) {
      try { return await response.json(); }
      catch (e) { throw new Error('Error parsing remote index.'); }
    } else {
      throw new Error('Failed to fetch remote index.');
    }
  }

  initialize = async ({ docId, docPath }) => {
    const response = await fetch(`${this.machine.url}/disk/doc/${docId}/initialize`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ docPath }),
    })
    if (!response.ok)
      throw new Error('Failed to initialize remote folder.');
  }

  addFile = async ({ docId, file }) => {
    const res = await fetch(`${this.machine.url}/disk/doc/${docId}/files`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(file),
    });
    if (!res.ok)
      throw new Error(`Failed sending file ${file.filename} ${res.status ? `(${res.status})` : ''}`);
  }

  deleteFile = async ({ docId, filename }) => {
    const res = await fetch(`${this.machine.url}/disk/doc/${docId}/files/${filename}`, {
      method: 'DELETE',
    });
    if (!res.ok)
      throw new Error('Failed deleting file ' + filename);
  }

  fetchFile = async ({ docId, filename }) => {
    const url = `${this.machine.url}/disk/doc/${docId}/files/${filename}`;
    console.log('[ Fetching file from machine', url, ' ]');
    return fetch(url);
  }
}

export default DiskSync;