import clsx from 'clsx';
import { PlayIcon, UpdateIcon } from '@radix-ui/react-icons';
import Button from 'modules/elements/lib/components/Button';
import { Button as AriaButton } from 'react-aria-components';
import MenuBuilder from 'lib/components/MenuBuilder';
import { useControllerState } from 'lib/BaseController';
import { getOptions } from './options';


export function FloatingMenu({ controller }) {
  const
    menuBg = controller.getSettingsValue('menuBg'),
    invertedColor = controller.getSettingsValue('invertedColor');

  return (
    <div
      className={clsx(
        'absolute top-2 right-2 z-10 flex gap-4 items-center px-3 py-1 rounded-sm',
        menuBg && (invertedColor ? 'bg-pencil border-pencil border' : 'bg-paper border-rim border'),
        invertedColor ? 'text-white' : 'text-pencil',
      )}>
      <Target controller={controller} />
      <MenuButtons controller={controller} />
    </div>
  )
}

export function BarMenu({ controller }) {
  const { getSettingsValue } = controller;

  return (
    <div
      style={{ borderBottom: 'none' }}
      className={clsx(
        'relative z-10 flex gap-4 items-center rounded-t-sm py-2',
        getSettingsValue('hasBorder') && 'px-4 border border-rim'
      )}>
      { getSettingsValue('title') && (
        <Button
          onPress={controller.editTarget}
          className="font-medium text-pencil">
          {getSettingsValue('title')}
        </Button>
      ) }
      <div className="flex-grow"></div>
      <Target controller={controller} />
      <MenuButtons controller={controller} />
    </div>
  )  
}

function MenuButtons({ controller }) {
  const
    { getSettingsValue, state } = controller,
    loading = state.get('loading'),
    showRun = getSettingsValue('showRun');
  useControllerState(controller.webFrame);

  return (
    <div className="flex gap-4">
      {showRun && (
        <Button
          className=""
          onPress={() => { controller.run(); controller.activate(); }}
          isDisabled={!getSettingsValue('target') || loading}
          tooltip="Run">
          { loading && (
            <UpdateIcon className="inline-block animate-spin opacity-40" />
          ) }
          { !loading && (
            <PlayIcon className="inline-block" />
          ) }
        </Button>
      )}

      <MenuBuilder
        tight={true}
        options={getOptions(controller)} />
    </div>
  )
}

function Target({ controller }) {
  const
    { getSettingsValue, isReadOnly } = controller,  
    target = getSettingsValue('target'),
    classToRender = getSettingsValue('classToRender'),
    showTarget = getSettingsValue('showTarget');

  return showTarget && target && (
    <AriaButton className="text-sm" onPress={controller.editTarget} isDisabled={isReadOnly}>
      { target && showTarget && (
        <div className="font-mono text-pencil">
          <span className="">{target}</span>
          {classToRender && (<span>&nbsp;/&nbsp;{classToRender}</span>)}
        </div>
      ) }
    </AriaButton>
  )
}