import { Button, ListBox, ListBoxItem, Popover, Select } from 'react-aria-components';
import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';


export default function BaseSelection({
  label, options, value, onChange,
  LabelComponent, buttonClassName, listClassName
}) {
  let
    [isOpen, setOpen] = useState(false),
    entry = options.find(v => v.key === value),
    caption = (entry && entry.caption) || value,
    disabledKeys = options.reduce((r, o, i) => {
      if (o.type === 'heading' || o.type === 'break' || o.isDisabled)
        r.add(i);
      return r;
    }, new Set());

  return (
    <Select
      className=""
      selectionMode="single"
      disabledKeys={disabledKeys}
      selectedKey={options.findIndex(o => o.key === value)}
      onSelectionChange={(s) => {
        if (typeof options[s].key === 'undefined')
          return;
        onChange(options[s].key);
        setOpen(false);
      }}
      aria-label={label}>
      {LabelComponent && (<LabelComponent>{label}</LabelComponent>)}
      <Button
        onPress={() => setOpen(true)}
        className={clsx(
          'group text-left relative outline-none flex items-center',
          buttonClassName,
          isOpen && 'bg-wax2'
        )}>
        <div className="flex-grow">{caption}</div>
        <div aria-hidden={true} className="flex-shrink-0">
          { !isOpen && (<ChevronDownIcon className="chevron-icon h-4 w-4 hidden group-hover:block" />) }
          { isOpen && (<ChevronUpIcon />) }
        </div>
      </Button>

      <Popover isOpen={isOpen} onOpenChange={setOpen} offset={0}>
        <ListBox
          items={options.map((o, i) => ({o, i}))}
          className={clsx(
            'border border-rim block bg-paper  outline-none max-h-[20rem] overflow-auto p-1',
            listClassName
          )}>
          {({o, i}) => (
             o.type === 'heading' ? (
              <ListBoxItem
                id={i}
                textValue={o.caption}
                className={({ isFocused }) => clsx(
                  'pointer-events-none px-2 py-1 font-medium text-pencil3 outline-none'
                )}>
                {o.caption}
              </ListBoxItem>
            ) :
              o.type === 'break' ? (
                <ListBoxItem
                  id={i}
                  textValue="break"
                  className={({ isFocused }) => clsx(
                    'border-b border-rim mb-1 pb-1 pointer-events-none outline-none'
                  )} />
              ) : (
                <ListBoxItem
                  id={i}
                  key={i}
                  textValue={o.key || 'none'}
                  className={({ isFocused, isDisabled }) => clsx(
                    'px-2 py-1 cursor-pointer outline-none flex',
                    isFocused && 'bg-marker text-white',
                    isDisabled && 'opacity-50'
                  )}>
                  {({isSelected}) => (<>
                    <div className="flex-grow truncate">{o.caption}</div>
                    {isSelected && <CheckIcon className="ml-1 mt-1 flex-shrink-0" />}
                  </> )}
                </ListBoxItem>)
          )}
        </ListBox>
      </Popover>
    </Select>
  );
}