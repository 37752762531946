import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { fetchDemos } from 'api/public';
import { editDemoPopup, editTextPopup } from '../adminFns';
import { Frame } from '../misc/components';
import Icon from 'lib/Icon';
import StorageImage from 'lib/StorageImage';
import placeholder from './placeholder.webp';
import {
  faPen, faMessage, faEdit, faPlus
} from '@fortawesome/free-solid-svg-icons';

import clsx from 'clsx';

export default function Collection({ isAdmin, app }) {
  const
    [demos, setDemos] = useState([]),
    [version, setVersion] = useState(0),
    updateVersion = () => setVersion(version + 1);

  useEffect(() => { fetchDemos(10).then(r => setDemos(r)) }, [version]);

  return (
    <Frame className="demo-index-page max-w-[60rem] p-8 flex flex-col gap-4">
      <h2 className="text-2xl font-medium">Quick Demos</h2>
      <div className="flex-grow overflow-auto">
        <div className="">Demos made here. Curated every week. Click and run.</div>
        <div className="cards my-4 grid gap-x-6 gap-y-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {demos.map((demo, i) => (
            <Link to={'./' + demo.id} className="card group" key={i}>
              <StorageImage path={demo.thumbnailPath} className="image relative" placeholder={placeholder}>
                <div
                  className={clsx(
                    'description absolute p-3 top-0 w-full h-full text-sm',
                    ' bg-paper bg-opacity-50 overflow-auto hidden group-hover:block'
                  )}
                  ref={el => { if (el) el.innerHTML = demo.shortDesc; }} />
              </StorageImage>

              <div className="title py-1 px-2 text-center">
                {demo.title}
                {isAdmin && (
                  <span className="hidden group-hover:block text-xs">
                    <br/>
                    &nbsp;&nbsp;
                    <AdminButton
                      onClick={() => editDemoPopup(demo, app, updateVersion)}
                      faIcon={faPen} />
                    &nbsp;
                    <AdminButton
                      onClick={() => editTextPopup(demo, app, 'shortDesc', updateVersion)}
                      faIcon={faMessage} />
                    &nbsp;
                    <AdminButton
                      onClick={() => editTextPopup(demo, app, 'longDesc', updateVersion)}
                      faIcon={faEdit} />
                  </span>
                ) } 
              </div>
            </Link>
          ))}
        </div>

        {isAdmin && (
          <div className="text-right">
            <AdminButton
              faIcon={faPlus}
              onClick={() => editDemoPopup({}, app, updateVersion)} />          
          </div>
        ) }
      </div>
    </Frame>
  )
}

function AdminButton({ faIcon, onClick }) {
  return (
    <div
      className="inline-block mx-1 cursor-pointer"
      onClick={e => { e.preventDefault(); onClick(); } }>
      <Icon faIcon={faIcon} />
    </div>
  )
}