import { Map } from 'immutable';
import BasePopup from 'lib/popup/BasePopupController';
import View from './View';


class Popup extends BasePopup {
  static View = View;
  position = new Map({ width: 500 });

  constructor(args, manager) {
    super(args, manager);
    this.articleController = args.articleController;
    this.unloadFns.push(_ => {
      if (args.onUnload)
        args.onUnload();
    });
  }

  selectCurrentUploadFile() {
    const uploadFile = this.args.articleController.state.get('uploads').find(
      d => d.get('id') === this.state.get('selectedId')
    );
    if (uploadFile) 
      this.args.onSelect(uploadFile.get('filename'));
    this.close();
  }
}

export default Popup;