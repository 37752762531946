import {
  collection, query, doc, getDocs, getDoc, setDoc,
  updateDoc, addDoc, orderBy, limit, serverTimestamp } from 'firebase/firestore';

import { db } from './remote/firebase';
import RemoteProvider from './remote/Provider';

import { httpsCallable } from 'firebase/functions';
import { functions } from 'api/remote/firebase';
import './migrations';

export { getDownloadURL } from 'api/remote/calls/file';


export async function fetchExamples(cursor) {
  const
    q = query(collection(db, 'examples')),
    qss = await getDocs(q),
    docs = qss.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  docs.sort((a, b) => b.order - a.order)
  return docs;
}

export async function fetchDemos(count, cursor) {
  const
    q = query(collection(db, 'demos'), orderBy('createdAt', 'desc'), limit(count)),
    qss = await getDocs(q),
    docs = qss.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return docs;
}

export async function fetchDemo(id) {
  const
    snap = await getDoc(doc(db, 'demos', id)),
    data = snap.data();
  data.id = id;
  return data;
}

export async function updateDemo(id, data) {
  return await updateDoc(doc(db, 'demos', id), data);
}

export async function setDemo(id, data) {
  return await setDoc(doc(db, 'demos', id), {
    createdAt: serverTimestamp(), ...data
  });
}

export async function updateExample(id, data) {
  return await updateDoc(doc(db, 'examples', id), data);
}

export async function addExample(data) {
  return await addDoc(collection(db, 'examples'), data);
}

export async function getPathInfo(path) {
  path = path.replaceAll('/', '\\');
  const pathData = path && (await getDoc(doc(db, 'paths', path))).data();

  if (!pathData)
    return null;

  const { type, domainId, folderId, articleId } = pathData;
  let ref;
  if (type === 'article')
    ref = doc(db, 'articles', articleId)
  if (type === 'folder') {
    if (path.indexOf('\\') === -1) {
      ref = doc(db, 'domains', domainId);
    } else {
      ref = doc(db, 'folders', folderId);
    }
  }
  
  if (!ref)
    return null;

  const data = (await getDoc(ref)).data();
  return data && { title: data.title, type: type };
}


export async function fetchTemplate(path) {
  const
    provider = new RemoteProvider(),
    { publishedVersionId, type, articleId, domainId } = await provider.fetchPath(path.replaceAll('/', '\\')),
    versionId = publishedVersionId;

  if (type !== 'article')
    throw new Error('Invalid path: ', path)

  if (!versionId)
    throw new Error('Article isn\'t published.');

  const props = { domainId, articleId, versionId };

  return {
    layout: (await provider.fetchVersion(props)).article.layout,
    elements: await provider.fetchVersionElements(props)
  };
}

export async function addFeedback({ email, message }) {
  const createdAt = serverTimestamp();
  return await addDoc(collection(db, 'feedbacks'), { email, message, createdAt });
}


window.setUserRole = (email, role) => {
  httpsCallable(functions, 'setUserRoleCall')({ email, role })
    .then(status => console.log(status));
}