import { fromJS } from 'immutable';
import { initialSettings } from './settingsFields';

class Policies {
  static autoSave = true;

  static getInitialData() {
    return {
      order: [],
      settings: initialSettings
    }
  }

  static dataToState(data, currentState) {
    if (!currentState)
      currentState = fromJS({
        inspectorTab: 'files',
        currentIndex: 0,
        order: [],
      });
    
    return (
      currentState.merge(fromJS({
        order: data.order,
        settings: data.settings,
      }))
    );
  }

  static stateToData(state) {
    const { order, settings } = state.toJS()
    return { order, settings };
  }

  static contentHasChanged(newState, oldState) {
    return (
      newState.get('order') !== oldState.get('order') ||
      newState.get('settings') !== oldState.get('settings')
    )
  }

  static async afterCreate({ data, createChildFile }) {
    await createChildFile({ parentId: data.id, filename: '', isPulled: false });
  }
}

export default Policies;