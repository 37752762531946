const map = {
  'auth/user-not-found' : 'There is no user corresponding to the email address.',
  'auth/missing-email' : 'The email field is missing',
  'auth/invalid-email' : 'The email address is not valid.',
  'auth/email-already-in-use' : 'There already exists an account with the given email address.',
  'auth/cancelled-popup-request' : 'The login popup request was cancelled.',
  'auth/popup-blocked' : 'The login popup was blocked by the browser. Please enable popup for this website.',
  'auth/popup-closed-by-user' : 'the popup window is closed by the user without completing the sign in ',
  'auth/invalid-credential' : 'The credential is malformed or has expired.',
  'auth/wrong-password' : 'The password is incorrect. Please check and try again.',
  'auth/invalid-verification-code' : 'The verification code of the credential is not valid.',
  'auth/invalid-verification-id' : 'The verification ID of the credential is not valid.',
  'auth/account-exists-with-different-credential' : 'There already exists an account with the email address.',
  'auth/argument-error' : 'Please check your form and try again.',
  'auth/network-request-failed' : 'Please connect to the internet and try again.',
  'auth/requires-recent-login' : 'Please login again.',
  'auth/user-disabled' : 'The user account has been disabled by an administrator.',
  'auth/user-token-expired' : 'Please login again.',
  'auth/weak-password': 'Password should be at least 6 characters.',
  'auth/internal-error': 'Could not reach authentication server.',

  'permission-denied': 'You don\'t have the required permission.',
  'storage/unauthorized': 'You don\'t have the required permissions.',
  'not-found': 'We couldn\'t find what your\'re looking for.',
  'not-published': 'The article you are looking for is not published.',
  'invalid-argument': 'We received invalid inputs.',
  'failed-precondition': 'Failed precondition error.',  
  'functions/internal': 'Something went wrong on our end and we\'ve been notified. Please wait for us to fix it.'
}


const messaged = new Set(['functions/not-found'])


export const toHuman = (error) => {
  if (!error) 
    return 'Unknown error.';

  const { code, message } = error;
  if (map[code])
    return map[code];
  if (messaged.has(code))
    return message;
  if (message)
    return message;

  if (!code)
     return 'Unknown Error';

  return (
    'There was an error performing your request. ' +
    'Code: ' + code
  )
}
