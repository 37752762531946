import { Link, Button, Dialog, DialogTrigger, Popover, OverlayArrow } from 'react-aria-components';
import { DotFilledIcon, DotIcon, Cross2Icon } from '@radix-ui/react-icons';
import Arrow from 'lib/components/Arrow';
import clsx from 'clsx';


export default function Leaf({ viewer, children }) {
  return (
    <DialogTrigger key={viewer.state.get('path')} >
      {children}

      <Popover className="border bg-paper p-4 py-4" >
        <OverlayArrow>
          <Arrow />
        </OverlayArrow>

        <Dialog className="outline-none">
          <Title viewer={viewer} />
          <Content viewer={viewer} />
        </Dialog>
      </Popover>
    </DialogTrigger>
  );
}


function Title({ viewer }) {
  const { domain, folder } = viewer.workspace;
  return (
    <div className="mb-2 pb-2 border-b text-lg text-center relative">
      <Link
        className="outline-none hover:bg-wax mx-4"
        href={'/' + folder.path}>
        {domain.title} - <span className="">{folder.title}</span>
      </Link>
    </div>
  );
}

function Content({ viewer }) {
  const { sections } = viewer.workspace;

  return (
    <div className={clsx(sections.length > 1 && 'columns-2 gap-2')}>
      { sections.map((section, i) => section.items.length > 0 && (
        <div className="mb-3 avoid-column-break" key={i}>
          { section.title && (
            <div className="font-medium text-sm pointer-events-none">
              {section.title}
            </div>
          ) }

          <div className="">
            {section.items.map(item => (
              <FolderItem key={item.id} item={item} section={section} viewer={viewer} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}


export function FolderItem({ item, viewer, className }) {
  const
    { publishedVersionTitle, path, id, type } = item,
    isFolder = type === 'folder',
    isOpen = !!viewer.articles[id],
    isActive = id === viewer.state.get('articleId') || id === viewer.state.get('folderId');

  return (
    <div className={clsx('flex items-top', className || 'text-sm')}>
      <div className="pt-2">
        { isOpen ? (
          <Button
            className={({ isDisabled }) => clsx(
              isDisabled && 'disabled',
              'group opacity-60',
              'close-button outline-none'
            )}
            isDisabled={isActive}
            onPress={(e) => viewer.closeArticle(id)}>
            <div className={clsx(!isActive && 'group-hover:hidden')}><DotFilledIcon /></div>
            <div className={clsx('hidden ', !isActive && 'group-hover:block')}><Cross2Icon /></div>
          </Button>
        ) : (
          <DotIcon />
        ) }
      </div>

      <Link
        className={clsx(
          'flex-grow py-1 px-2 outline-none',
          isActive ? 'bg-wax' : 'hover:bg-wax2',
          isActive ? 'font-medium' : isOpen ? 'text-pencil2' : 'text-pencil',
          isFolder && 'text-marker2'
        )}
        href={'/' + path.replace('\\', '/')}>
        <div>
          { publishedVersionTitle }
        </div>
      </Link>
    </div>
  );
}