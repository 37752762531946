import { useControllerState } from '../BaseController';

export default function ManagerView({ manager }) {
  useControllerState(manager);

  return (
    <div className="popups-container">
      { manager.popups.map((popup, i) => {
        const PopupView = popup.constructor.View;
        return <PopupView key={popup.id} popup={popup} />
      }) }
    </div>
  );
}