import BaseController from 'lib/BaseController';
import Emitter from 'lib/Emitter';
import { fromJS } from 'immutable';

import Element from './Element';
import Selection from './Selection';
import Settings from './Settings';
import KeyManager from './KeyManager';
import Popups from './Popups';
import LocalFiles from './files/LocalFiles';
import RemoteFiles from './files/RemoteFiles';
import Layout from 'modules/layout/Controller';
import elementLibs from 'modules/elements/all';

import { toHuman } from 'lib/firebaseError';


class Article extends BaseController {
  constructor({ app, domain }) {
    super();
    this.state = this.initialState;
    this.app = app;
    this.domain = domain;
    this.id = null;

    this.unloadFns = [];
    this.elements = {};
    this.popups = new Popups(this);
    this.settings = new Settings(this);
    this.selection = new Selection(this);
    this.layout = new Layout(this);
    this.keyManager = new KeyManager(this);
    this.localFiles = new LocalFiles({
      getUploads: () => this.state.get('uploads').filter(u => !u.get('isDeleted')),
      getElements: () => this.elements,
      getProvider: () => this.provider,
    });
    this.remoteFiles = new RemoteFiles({
      getProvider: () => this.provider,
      getLocalPath: () => this.path
    });
    this.titleElement = new TitleElement(this);
    this.plugins = this.app.plugins;
    this.commonEmitter = new Emitter();
  }
  
  initialState = fromJS({ article: null, elementIdSet: new Set(), uploads: [] });

  load = ({ domainId, articleId, provider, path }) => {
    if (this.id)
      this.unload();
    
    this.id = articleId;
    this.domainId = domainId;
    this.provider = provider;
    this.path = path;

    console.log('[', 'loading article doc', domainId, articleId, ']');
    this.unloadFns.push(
      provider.onArticle(domainId, articleId, this.handleArticle, this.handleError),
      provider.onArticleElementChange(domainId, articleId, this.handleElementChange, this.handleError),
      provider.onArticleUploads(domainId, articleId, this.handleUploads, this.handleError),
      this.keyManager.subscribe()
    );
    return this.unload;
  }

  unload = () => {
    console.log('[', 'unloading article doc', this.id, ']');
    this.popups.closeSession();
    this.unloadFns.forEach(fn => fn());
    for (var k in this.elements)
      this.elements[k].destroy();
    this.unloadFns = [];
    this.elements = {};
    this.state = this.initialState;
    this.emit('change');
  }

  handleError = e => {
    console.error(e);
    this.setState({ error: toHuman(e) })
  }

  handleArticle = docs => {
    const article = fromJS(docs);
    this.setState({ article });
  }

  handleUploads = docs => {
    const uploads = fromJS(docs);
    this.setState({ uploads });
    this.commonEmitter.emit('files update');
  }

  handleElementChange = (doc, type) => {
    const elements = this.elements;
    var elementIdSet = this.state.get('elementIdSet');

    if (type === 'added') {
      let element = elements[doc.id] = new Element(doc, this);
      elementIdSet = elementIdSet.add(doc.id);
      element.on('control-change', _ => this.handleElementControlChange(element));
      // // todo: trigger change on parent
      this.registerParent(element);
    } else if (type === 'modified') {
      elements[doc.id].handleData(doc);
    } else if (type === 'removed') {
      this.deregisterParent(elements[doc.id]);
      elements[doc.id].destroy();
      delete elements[doc.id];
      elementIdSet = elementIdSet.remove(doc.id);
    }
    
    this.setState({ elementIdSet });
    this.emit('elements update');
  }

  _unsavedElements = new Set();
  handleElementControlChange = (element) => {
    const
      modified = element.controlState.get('modified'),
      has = this._unsavedElements.has(element);
    if (modified && !has) {
      this._unsavedElements.add(element);
      this.emit('element-modified');
    } else if (!modified && has) {
      this._unsavedElements.delete(element);
      this.emit('element-modified');
    }
  }

  bringToFront = () => {
    this.keyManager.bringToFront();
  }
  
  canSave = () => {
    return this._unsavedElements.size === 0;
  }

  saveAll = () => {
    this._unsavedElements.forEach(element => element.saveFromState());
  }

  getInState = (arr) => {
    return this.state.getIn(arr);
  }

  setInState = (arr, val) => {
    this.state = this.state.setIn(arr, val);
    this.emit('change');
  }

  saveArticleStateKey = (key) => {
    console.log('saving key', key);
    var value = this.state.get('article').get(key);
    if (value.toJS)
      value = value.toJS();
    return this.provider.updateArticle({
      articleId: this.id,
      domainId: this.domainId,
      data: { [key]: value }
    });
  }

  // todo: rethink this function
  createElement = async ({ type, insertAt }) => {
    if (this.state.get('elementIdSet').size >= 128) {
      throw new Error('You have reached the maximum number of elements for this article.');
    }
    
    const
      lib = elementLibs[type],
      initialData = lib.Policies.getInitialData(),
      data = await this.provider.createElement({
        articleId: this.id,
        domainId: this.domainId,
        data: { ...initialData, type, version: 0 }
      });

    // let element them child file
    if (lib.Policies.afterCreate)
      await lib.Policies.afterCreate({
        data: data,
        createChildFile: this.createChildFile,
        update: (d) => this.provider.updateElement({
          articleId: this.id,
          domainId: this.domainId,
          elementId: data.id,
          data: d
        })
      });

    // await this.files.initialize
    await this.layout.addElementId(data.id, insertAt);
    return data.id;
  }

  deleteElement = async (elementId) => {
    await this.provider.deleteElement({ elementId, articleId: this.id, domainId: this.domainId });
    // Delete children
    for (let child of this.getElementChildren(elementId)) {
      await this.provider.deleteElement({ elementId: child.id, articleId: this.id, domainId: this.domainId });
    };
    await this.layout.deleteElementId(elementId);
  }

  // Child file element
  createChildFile = async ({ parentId, filename, isPulled }) => {
    const
      lib = elementLibs['file'],
      initialData = lib.Policies.getInitialData();

    return this.provider.createElement({
      articleId: this.id,
      domainId: this.domainId,
      data: {
        ...initialData,
        settings: {...initialData.settings, filename, isPulled },
        type: 'file',
        parentId: parentId,
        version: 0
      }
    });
  }

  elementChildren = {};

  registerParent = (element) => {
    const { parentId } = element;
    if (!parentId)
      return;
    this.elementChildren[parentId] = this.elementChildren[parentId] || new Map();
    this.elementChildren[parentId].set(element.id, element);
    // notify parent
    if (this.elements[parentId]) {
      this.elements[parentId].emit('children');
    }
  }

  deregisterParent = (element) => {
    const { parentId } = element;
    if (this.elementChildren[parentId])
      this.elementChildren[parentId].delete(element.id)
    if (this.elements[parentId])
      this.elements[parentId].emit('children');
  }

  getElementChildren = (parentId) => {
    return this.elementChildren[parentId] ? Array.from(this.elementChildren[parentId].values()) : [];
  }
}

class TitleElement {
  id = 'titlebar';
  constructor(article) { this.article = article; }
  setElement = (el) => { this.el = el; }
  focus = () => this.el && this.el.focus();
  scrollIntoView = () => this.el && this.el.scrollIntoView(false);
  setActive = () => { this.article.selection.setActiveElementId('titlebar'); }
}

export default Article;