import { fromJS } from 'immutable';

class Selection {
  constructor(editor) {
    this.editor = editor;
  }

  get state() {
    return this.editor.state.get('selection');
  }

  setState = (diff) => {
    const newState = this.state.merge(fromJS(diff));
    this.editor.setState({ selection: newState });
  }

  moveSelection = (di, dj) => {
    if (this.state.get('startCell') === null)
      return;

    const
      i = Math.min(Math.max(this.si + di, -1), this.editor.data.rows.size -1),
      j = Math.min(Math.max(this.sj + dj, 0), this.editor.data.columns.size - 1);

    this.setState({ startCell: { i, j }, endCell: null });
  }

  moveSelectionEnd = (di, dj) => {
    const
      { ei, ej } = this.state.get('endCell') ? this : { ei: this.si, ej: this.sj },
      i = Math.min(Math.max(ei + di, -1), this.editor.data.rows.size -1),
      j = Math.min(Math.max(ej + dj, 0), this.editor.data.columns.size - 1);
    this.setState({ endCell: { i, j } })
  }

  get startCell() { return this.state.get('startCell'); }
  get endCell() { return this.state.get('endCell'); }
  get si() { return this.state.getIn(['startCell', 'i']); }
  get sj() { return this.state.getIn(['startCell', 'j']); }
  get ei() { return this.state.getIn(['endCell', 'i']); }
  get ej() { return this.state.getIn(['endCell', 'j']); }

  isSelected = (i, j) => {    
    const { si, sj, ei, ej } = this;

    if (this.state.get('endCell') === null)
      return (si === i && sj === j);

    return (
      (Math.min(si, ei) <= i && Math.max(si, ei) >= i) &&
      (Math.min(sj, ej) <= j && Math.max(sj, ej) >= j)
    )
  }

  isActive = (i, j) => {
    const { si, sj } = this;    
    return (si === i && sj === j);
  }

  selecting = false;

  startSelection = (i, j) => {
    this.selecting = true;
    this.setState({ startCell: { i, j }, endCell: null });
  }

  updateSelection = (i, j) => {
    if (
      this.selecting && 
      (this.state.getIn(['endCell', 'i']) !== i || this.state.getIn(['endCell', 'j']) !== j)
    ) {
      this.setState({ endCell: { i, j } });
    }
  }

  endSelection = (i, j) => {
    this.selecting = false;
  }

  getSelected = () => {
    return this.state.get('selected')
  }
}


export default Selection;