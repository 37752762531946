import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
// import { toHuman } from 'lib/firebaseError';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });

  constructor(args, manager) {
    super(args, manager);
    this.domain = args.domain;
    this.provider = args.provider;
    this.domainId = this.domain.get('id');
    this.fetch();
  }

  fetch = async () => {
    this.setState({ loading: true });
    const plans = await this.provider.fetchDomainPlans();
    this.setState({ plans, loading: false });
  }

  upgrade = () => {
  }
}

export default PopupController;