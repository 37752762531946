export function sectionize({ folder, childrenFolders, childrenArticles }) {
  var
    current = { items: [] },
    sections = [current];
  
  folder.layout.list.forEach(item => {
    if (item.type === 'section') {
      current = { ...item, items: [] }
      if (!current.isHidden)
        sections.push(current);
    }
    else if (item.type === 'folder') {
      const data = childrenFolders.find(f => f.id === item.id)
      if (data && data.isPublic)
        current.items.push({ ...data, type: 'folder' });
    }
    else if (item.type === 'article') {
      const data = childrenArticles.find(f => f.id === item.id);
      if (data && data.publishedVersionId)
        current.items.push({ ...data, type: 'article' });
    }
  });
  
  return sections;
}