import { Map } from 'immutable';
import { debounce } from 'lodash';
import BasePopup from 'lib/popup/BasePopupController';
import View from './View';
import { urlRegex } from 'lib/regexps';
import { getPathInfo } from 'api/public';

class Popup extends BasePopup {
  static View = View;
  position = new Map({ width: 380 });

  constructor(args, manager) {
    super(args, manager);
    this.state = this.state.merge({ link: '', type: null, checking: false })
  }

  openUploadFilesPopup = () => {
    this.args.openPopup('upload-files', {
      title: 'Select file',
      onSelect: filename => {
        this.setState({ link: 'file:' + filename });
        this.submit();
      }
    })
  }

  onLinkChange = async () => {
    const link = this.state.get('link');
    var isValid = false, info = '';
    
    this.setState({ checking: true, type: null });

    if (urlRegex.test(link)) {
      isValid = true;
    }

    if (!isValid && link.startsWith('/')) {
      info = await getPathInfo(link.substr(1));
      if (this.state.get('link') !== link)
        return;

      if (info)
        isValid = true;
    }

    this.setState({ isValid, checking: false, checkedLink: link, info });
  }
  onLinkChangeDebounced = debounce(this.onLinkChange, 300);

  submit = () => {
    this.args.onSave(this.state.get('link'));
    this.close();
  }
}

export default Popup;