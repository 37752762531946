import ui from 'lib/popup/components/ui';
import { useControllerState } from 'lib/BaseController';
import { Button, Buttons } from 'lib/inputs/buttons';
import Loader from 'lib/staticPages/Loader';
import { LinkBreak2Icon, ExclamationTriangleIcon } from '@radix-ui/react-icons';
import List from './List';


export default function Popup({ popup }) {  
  return (
    <ui.Window popup={popup} title="Syncing Files">
      <Body popup={popup} />
    </ui.Window>
  )
}

function Body({ popup }) {
  const
    { machine } = popup,
    machineState = useControllerState(machine),
    state = useControllerState(popup),
    status = state.get('status');

  return (
    machineState.get('status') !== 'connected' ?
      (<MachineNotConnected popup={popup} />) :
      status === 'start' ?
        <Start popup={popup} /> :
        status === 'uninitialized' ?
          <RemoteNotReady popup={popup} /> :
          status === 'initializing' ?
            <Loading message="Initializing Remote" /> :
            status === 'errored' ?
              <Error popup={popup} /> :
              status === 'loading' ?
              <Loading message="Loading" /> :
                status === 'loaded' ?
                <List popup={popup} /> :
                ''
    );
}

function Start({ popup }) {
  return (
    <div className="text-center py-24">
      <Button onClick={popup.tryLoad}>Load Remote Index</Button>
    </div>
  )
}

function Loading({ message }) {
  return (
    <div className="text-center py-24">
      <div>{message}</div>
      <Loader />
    </div>
  )
}

function MachineNotConnected({ popup }) {
  return (
    <div className="py-14">
      <div className="text-center">
        <LinkBreak2Icon className="w-14 h-14 inline" />
      </div>
      <div className="text-center p-4 mt-2">
        Remote machine is not connected.
      </div>
      <Buttons className="text-center">
        <Button onPress={_ => popup.machine.showMachinePopup()}>
          Connect
        </Button>
      </Buttons>
    </div>
  );
}

function RemoteNotReady({ popup }) {
  return (
    <div className="py-20">
      <div className="text-center">
        Remote folder is not initialized.
      </div>

      <Buttons className="text-center">
        <Button
          onPress={() => popup.tryInitializeRemote()}
          isDisabled={popup.state.get('initializing')}>
          Initialize
        </Button>
      </Buttons>
    </div>
  )
}

function Error({ popup }) {
  return (
    <div className="p-8">
      <div className="text-center p-4">
        <ExclamationTriangleIcon className="w-14 h-14 inline mb-4 text-error-red" />
        <div>{popup.state.get('error') || 'Error!'}</div> 
      </div>
      <Buttons className="text-center">
        <Button onPress={() => popup.tryLoad()}>
          Reload
        </Button>
      </Buttons>
    </div>
  );  
}