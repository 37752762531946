import { wrapInList } from 'prosemirror-schema-list';
import Icon from 'lib/Icon';
import { schema } from '../schema.ts';
import { setBlockType, toggleMark, joinUp, lift } from 'prosemirror-commands';
import { isNodeActive, canInsert } from '../helpers';
import clsx from 'clsx';
import { Button , TooltipTrigger, Tooltip } from 'react-aria-components';

import 'katex/dist/katex.min.css';

import {
  faLink, faRulerHorizontal, faLeftLong, faPlus
} from '@fortawesome/free-solid-svg-icons';


export function IconButton({ faIcon, isActive, isDisabled, onPress, children, tooltip }) {
  return (
    <TooltipTrigger>
      <Button
        className={clsx(
          'icon-button mark-button px-2.5 py-1.5 text-xs cursor-pointer ',
          isActive && 'bg-wax2',
          isDisabled ? 'text-pencil3' : 'text-pencil2',
        )}
        preventFocusOnPress={true}
        onPress={onPress}>
        { faIcon && (<Icon faIcon={faIcon} />) }
        { children }
      </Button>

      { tooltip && (
        <Tooltip
          placement="bottom"
          className="text-sm bg-pencil2 text-white p-1 px-3">{tooltip}</Tooltip>
      )}
    </TooltipTrigger>
  )
}


export function MarkButton({ controller, faIcon, markType }) {
  const { view, viewState } = controller;
  return (
    <IconButton
      faIcon={faIcon}
      isActive={controller.isMarkActive(markType)}
      onPress={_ => toggleMark(markType)(viewState, view.dispatch)} />
  );
}

export function LinkButton({ controller }) {
  const
    { viewState } = controller,
    markType = schema.marks.link;

  return (
    <IconButton
      faIcon={faLink}
      tooltip="Insert Hyperlink"
      isActive={controller.isMarkActive(markType)}
      isDiabled={viewState.selection.empty}
      onPress={e => {
        e.preventDefault();
        if (controller.isMarkActive(markType)) {
          controller.toggleMark(markType);
          return;
        }
        controller.addLinkPrompt();
      }} />
  )
}

export function RuleButton({ controller, onPress }) {
  const
    { viewState } = controller,
    hr = schema.nodes.horizontal_rule,
    isDisabled = !canInsert(viewState, hr);

  return (
    <IconButton
      faIcon={faRulerHorizontal}
      tooltip="Insert Divider"
      isDisabled={isDisabled}
      onPress={e => {
        controller.addRule();
        if (onPress) onPress();
      }} />);
}

export function FormulaButton({ controller, onPress }) {
  const
    { viewState } = controller,
    formula = schema.nodes.formula,
    isDisabled = !canInsert(viewState, formula);

  return (
    <IconButton
      tooltip="Insert Formula"
      isDisabled={isDisabled}
      onPress={e => {
        if (onPress) onPress();
        controller.addFormula();
      }}>
      <span style={{ fontWeight: 'bold' }}>∑</span>
    </IconButton>
  );  
}

export function BlockButton({ nodeType, command, attrs, controller, faIcon, children, onPress, tooltip }) {
  command = command || setBlockType(nodeType, attrs);
  const
    { view, viewState } = controller,
    isDisabled = !command(viewState),
    isActive = nodeType && isNodeActive(nodeType, viewState, attrs);

  return (
    <IconButton
      isDisabled={!isActive && isDisabled}
      isActive={isActive}
      onPress={e => {
        if (view) {
          if (isActive) {
            setBlockType(schema.nodes.paragraph, attrs)(viewState, view.dispatch);
          } else {
            command(viewState, view.dispatch);
          }
        }
        if (onPress)
          onPress(e);
      }}
      faIcon={faIcon}
      tooltip={tooltip}
      children={children} />
  );
}

export function HeadingButton({ controller, level, onPress }) {
  return (
    <BlockButton
      attrs={{ level }}
      controller={controller}
      nodeType={schema.nodes.heading}
      onPress={onPress} >
      <span className="text-sm">H{level}</span>
    </BlockButton>);
}

export function ListWrapButton({ nodeType, controller, faIcon, children, tooltip }) {
  return (
    <BlockButton
      nodeType={nodeType}
      command={wrapInList(nodeType, null)}
      faIcon={faIcon}
      controller={controller}
      tooltip={tooltip} />
  );
}

export function LiftItemButton({ controller }) {
  return (
    <BlockButton
      command={lift}
      faIcon={faLeftLong} controller={controller}
      tooltip="Shift Back" />
  );
}

export function JoinUpButton({ controller }) {
  return (
    <BlockButton command={joinUp} faIcon={faPlus} controller={controller} />
  );
}
