import BaseController from 'lib/BaseController';
import elementLibs from 'modules/elements/all';


export default class Element extends BaseController {
  constructor(data, article) {
    super();
    this.data = data;
    this.article = article;
    let
      { app } = article,
      { type, id } = data,
      noop = () => {};
    this.type = type;
    this.lib = elementLibs[type];
    this.parentId = data.parentId;

    if (!this.lib)
      throw new Error('Type not  found');

    let docPath = article.article.path.replaceAll('\\', '/');
        
    this.state = this.lib.Policies.dataToState(data, null);
    this.controller = new this.lib.Controller({
      elementId: id,
      getState: () => this.state,
      setState: this.setState,
      onStateChange: cb => this.on('change', cb),
      getVersion: () => data.version,
      docId: article.article.id,
      docPath: docPath,
      themeData: article.themeData,
      getLocalFiles: () => article.localFiles.proxy,
      getRemoteFiles: () => this.article.remoteFiles.proxy,
      getMachineInterface: () => app.machine.interface.getProxy({
        elementId: id,
        docId: article.article.id,
        docPath: docPath,
        getLocalFiles: () => article.localFiles.proxy
      }),

      isReadOnly: true,
      save: noop,
      activate: noop,
      openPopup: noop,
      popOff: noop,
      vani: app.vani,

      deleteSelf: noop,
      addChildFilePopup: noop,
      getChildren: () => (
        Object.values(article.elementControllers)
          .filter((e) => e.parentId === id)
          .map(element => element.controller)
      ),
      onChildren: noop,
      commonEmitter: article.article.commonEmitter,
      getColorScheme: () => app.colorScheme.getSchemeValue()     
    });
  }

  get version() { return this.data.version; }
  get elementFilenames() { return this.controller.elementFilenames; }
  getElementFile(filename) { return this.controller.getElementFile(filename); }  
  focus = () => {}
}