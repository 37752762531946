import { fields } from '../Policies/settings';
import { BuildInputs } from 'modules/elements/lib/sidebar/builder';
import { Break, Block } from 'modules/elements/lib/sidebar/inputs/components';
import Textarea from 'modules/elements/lib/sidebar/inputs/Textarea';

import './style.css';

function Inspector({ controller, state, isGrid }) {
  const
    { setSettings, getSettingsData, getSettingsValue, getSettingsValueOnNull } = controller;
  
  return (
    <>
      <Block>
        <Textarea
          label="Embed Source URL"
          value={getSettingsData('embedUrl') || ''}
          onChange={v => {
            const
              regex = /src="([^"]+)"/,
              match = v.match(regex);
            if (match[1])
              v = match[1];
            
            setSettings('embedUrl', v);
          }} />
      </Block>

      <Break />

      <BuildInputs
        fields={fields}
        layout={[
          !isGrid ? [
            ['maxWidth'],
            ['heightScale'],
            ['aspectRatio'],
            ['height', null]
          ] : [],
        ]}
        getData={getSettingsData}
        getValue={getSettingsValue}
        setData={setSettings}
        getValueOnNull={getSettingsValueOnNull} />
    </>
  );
}

export default Inspector;