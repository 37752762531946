import Icon from 'lib/Icon';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';

import './SaveIndicators.css'


export default function SaveIndicators({ element }) {
  const
    [controlState, setControlState] = useState(element.controlState),
    saving = controlState.get('saving'),
    saveError = controlState.get('saveError'),
    savePending = controlState.get('savePending'),
    modified = controlState.get('modified');
  
  useEffect(() => element.on('control-change', () => setControlState(element.controlState)), [element]);

  // usePrompt('You have unsaved changes, are you sure you want to leave?', modified);
  return (
    <div className="nb-element-indicators">
      { saveError && (
        <div className="save-error color-error-red font-mono" >
          <Icon faIcon={faSave} /> Error
        </div>
      ) }

      <div className="saving">
        <Dots color={modified ? 'black' : saving ? 'green' : ''} />
      </div>


      { modified && !savePending && (
        <div className="save-button" onClick={_ => element.saveFromState()}>
          <Icon faIcon={faSave} />
        </div>
      ) }
    </div>
  )
}


function Dots({ color }) {
  return (
    <div className="dots">
      <div className={'dot dot1 ' + color}></div>
      <div className={'dot dot2 ' + color}></div>
      <div className={'dot dot3 ' + color}></div>
    </div>
  )
}