import { Button, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import { FileIcon, CardStackIcon, HeadingIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';


export default function AddMenu({ controller, className, children }) {
  const
    { popups } = controller,
    menuItems = [
      [<><FileIcon className="inline-block mr-2" /> Add Article</>, () => popups.createArticle()],
      [<><CardStackIcon className="inline-block mr-2" /> Add Folder</>, () => popups.createFolder()],
      [<><HeadingIcon className="inline-block mr-2" /> Add Section</>, () => popups.createSection()],
    ];

  return (
    <MenuTrigger>
      <Button className={clsx('outline-none', className)}>
        { children }
      </Button>
      
      <Popover placement="top">
        <Menu onAction={(i) => menuItems[i][1]()} className="outline-none">
          {
            menuItems.map(([caption], i) => (
              <MenuItem
                key={i}
                id={i}
                className="bg-paper border border-rim hover:bg-wax cursor-pointer px-3 py-1 m-1 text-sm">
                { caption }
              </MenuItem>
            )
          )}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}