import { Map } from 'immutable';
import katex from 'katex';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  constructor(args, manager) {
    super(args, manager);
    this.updateValue(args.value);
    
    if (args.onClose) {
      this.unloadFns.push(args.onClose);
    }
  }

  valueIsInvalid(value) {
    try { katex.__parse(value); }
    catch (e) { return true; }
    return false;
  }

  updateValue(value) {
    let invalid = this.valueIsInvalid(value);
    if (invalid && !this.state.get('invalid')) {
      this.setState({ lastValid: value });
    }
    this.setState({ invalid, value });
  }
  
  async handleSubmit() {
    this.setState({ loading: true });
    try {
      await this.args.onSave(this.state.get('value'));
      this.setState({ loading: false });
      this.close();
    } catch (e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }

  handleValueFn(fn) {
    this.valueFn = fn;
  }
}

export default Controller;