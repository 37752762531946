import { useState } from 'react';
import { schema } from '../schema.ts';
import Icon from 'lib/Icon';
import { useControllerState } from 'lib/BaseController';
import {
  MarkButton, LinkButton, RuleButton,
  BlockButton, HeadingButton, ListWrapButton , LiftItemButton
} from './buttons';

import {
  faBold, faItalic, faUnderline, faCode, // faHighlighter, faStrikethrough,
  faListUl, faListOl, faAlignLeft, faAlignCenter, faAlignRight, faCaretDown
} from '@fortawesome/free-solid-svg-icons';

import { DialogTrigger, Button, Popover, Dialog } from 'react-aria-components';

import './style.css';


function MenuBar({ controller, isMarkdown }) {
  useControllerState(controller);

  return (
    <div className="flex center items-center">
      <MarkButton faIcon={faBold} markType={schema.marks.strong} controller={controller} />
      <MarkButton faIcon={faItalic} markType={schema.marks.em} controller={controller} />
      <MarkButton faIcon={faUnderline} markType={schema.marks.underline} controller={controller} />
      {/*<MarkButton faIcon={faStrikethrough} markType={schema.marks.strikethrough} controller={controller} />*/}
      <MarkButton faIcon={faCode} markType={schema.marks.code} controller={controller} />
      {/*<MarkButton faIcon={faHighlighter} markType={schema.marks.highlight} controller={controller} />*/}
      <LinkButton controller={controller} />
      <ButtonBreak />
      {!isMarkdown && (<>
        <Alignments controller={controller} />
        <ButtonBreak />
      </>) }
      <BlockTypeDD controller={controller} />


      <ButtonBreak />
      <ListWrapButton
        faIcon={faListUl}
        nodeType={schema.nodes.bullet_list}
        controller={controller}
        tooltip="Numbered List" />

      <ListWrapButton
        faIcon={faListOl}
        nodeType={schema.nodes.ordered_list}
        controller={controller}
        tooltip="Bullet List" />

      <LiftItemButton controller={controller} />

      <ButtonBreak />
      <Insert controller={controller} />
    </div>
  )
}

function ButtonBreak() {
  return (<div className="mr-2 pr-2 h-5 border-r border-rim3" />);
}


function getActiveBlockCaption(state) {
  let { $from, to, node } = state.selection;
  node = node || (to <= $from.end() && $from.parent);
  if (!node)
    return null;
  const { type, attrs } = node;
  if (type === schema.nodes.paragraph)
    return 'Normal';
  if (type === schema.nodes.heading)
    return 'Heading ' + attrs.level;
  if (type === schema.nodes.code_block)
    return 'Code';

  return null;
}


function BlockTypeDD({ controller }) {
  const
    blockCaption = getActiveBlockCaption(controller.viewState),
    [show, setShow] = useState(),
    props = { onMouseDown: _ => setShow(false), controller };

  return (
    <DropdownInput
      show={show}
      setShow={setShow}
      caret={true}
      caption={blockCaption || '-'}>
      <BlockButton nodeType={schema.nodes.paragraph} {...props}>
        <span className="text-sm">Normal</span>
      </BlockButton>
      <HeadingButton level={1} {...props} />
      <HeadingButton level={2} {...props} />
      <HeadingButton level={3} {...props} />
      <HeadingButton level={4} {...props} />
      <HeadingButton level={5} {...props} />
      <BlockButton faIcon={faCode} nodeType={schema.nodes.code_block} {...props} />
    </DropdownInput>
  )
}

function Alignments({ controller }) {
  const
    props = { controller },
    { viewState } = controller;

  let { $from, to, node } = viewState.selection;
  node = node || (to <= $from.end() && $from.parent);

  return (
    <>
      <BlockButton
        faIcon={faAlignLeft}
        nodeType={node.type}
        attrs={{ align: 'left' }} {...props}>
      </BlockButton>
      <BlockButton
        faIcon={faAlignCenter}
        nodeType={node.type}
        attrs={{ align: 'center' }} {...props}>
      </BlockButton>
      <BlockButton
        faIcon={faAlignRight}
        nodeType={node.type}
        attrs={{ align: 'right' }} {...props}>
      </BlockButton>
    </>
  )
}

function Insert({ controller }) {
  return (
    <>
      <RuleButton controller={controller} />
      {/*<FormulaButton controller={controller} />*/}
    </>
  )
}

function DropdownInput({ caption, show, setShow, children, caret }) {
  return (
    <DialogTrigger>
      <Button
        className="cursor-pointer text-sm"
        preventFocusOnPress={true}
        onPress={() => setShow(true)}>
        { caption }
        { caret && (
          <span className="ml-2">
            <Icon faIcon={faCaretDown} />   
          </span>
        ) }
      </Button>

      <Popover offset={8} show={show} onOpenChange={setShow}>
        <Dialog className="outline-none border border-rim bg-paper">
          {children}
        </Dialog>
      </Popover>
    </DialogTrigger>
  );
}

export default MenuBar;