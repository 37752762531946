import BaseElementController from 'modules/elements/lib/BaseElementController';
import { fields } from '../Policies/settings';
import { isEmpty } from 'lodash';

export default class Controller extends BaseElementController {
  settingsFields = fields;
  setRemoteWindow(w) {
    this.remoteWindow = w;
  }

  sendMessage(message) {
    this.remoteWindow.postMessage(message, '*');
  }

  handleMessage(e) {
    if (e.source !== this.remoteWindow)
      return;
    
    console.log('incoming..', e.data);
    const message = e.data;
    if (message.topic === 'req loaddata') {
      this.sendMessage({
        topic: 'res loaddata',
        data: this.getState().get('content')
      });
    }
    else if (message.topic === 'res savedata') {
      if (!isEmpty(message.data.files)) {
        console.log(message.data.files);
        this.showErrorMessage(
          'Saving external files is currently not supported. They will be removed.'
        );
        message.data.files = {};
      }

      try {
        this.setState({ content: message.data });
        this.save();
        this.showSavedMessage();
      } catch (e) {
        this.showErrorMessage('There was an error saving your image.')
      }
    }
    else if (message.topic === 'req cancel') {
      this.openPopup('prompt', {
        question: 'Save your changes?',
        title: 'Close',
        inputs: [],
        submitLabel: 'Save and Close',
        cancelLabel: 'Close',
        onSubmit: () => {
          this.handleClickSave();
        },
        onUnload: (popup) => {
          if (!popup.closeButtonClicked)
            setTimeout(() => this.handleClose(), 100);
        }
      });
    }
    else if (message.topic === 'staged') {
      this.setState({ ready: true });
    }
  }

  showErrorMessage(errorMessage) {
    clearTimeout(this._errorMessageTimeout);
    this.setState({ showErrorMessage: true, errorMessage });
    this._errorMessageTimeout = setTimeout(_ => this.setState({
      showErrorMessage: false,
      errorMessage: ''
    }), 2000);
  }

  showSavedMessage() {
    this.setState({
      saveCount: (this.state.get('saveCount') || 0) + 1,
      showSavedMessage: true
    });
    clearTimeout(this._savedMessageTimeout);
    this._savedMessageTimeout = setTimeout(_ => this.setState({
      showSavedMessage: false
    }), 2000);
  }
  
  handleClickSave() {
    this.sendMessage({ topic: 'req savedata' });
  }

  handleClose() {
    window.location.hash = '';
    this.setState({ ready: false });
  }

  startDrawing() {
    const { elementId } = this;
    window.location.hash = elementId;
  }

  editCaption = (i) => {
    this.openPopup('rich-text-editor', {
      title: `Edit Caption`,
      format: 'html',
      text: this.getSettingsValue('caption') || '',
      onSave: text => this.setSettings('caption', text)
    });
  }
}