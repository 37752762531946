import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';


export async function fetchPath(path) {
  return (await getDoc(doc(db, 'paths', path))).data();
}

export async function checkAvailable(path) {
  return !(await fetchPath(path));
}