import { getKeyDispatcher } from 'lib/KeyDispatcher';


class KeyManager {
  constructor(articleController) {
    this.id = `article-${Math.random()}`;
    this.articleController = articleController;
    this.resetControlKeys();
  }

  resetControlKeys = () => {
    this.isAltPressed = false;
    this.isMetaPressed = false;
    this.isControlPressed = false;
  }

  // Keys from global capture
  subscribe() {
    return getKeyDispatcher().subscribe(this.id, this.onKey);
  }

  bringToFront() {
    getKeyDispatcher().bringToFront(this.id);
  }

  onKey = (e) => {
    if (e.type === 'keydown' && e.key === 'Escape') {
      this.onEscape();
    }
  }

  onEscape = () => {
    if (this.articleController.layout.handleEscapeKey()) {
      return;
    }    
    if (this.articleController.selection.state.get('isPageFocused')) {
      this.articleController.selection.blurPage();
    } else {
      this.articleController.selection.focusPage();
    }
  }
  
  handleInputKeyDown = (event) => {
    const key = event.key;

    if (key === 'Alt') {
      this.isAltPressed = true;
    } else if (key === 'Meta') {
      this.isMetaPressed = true;
    } else if (key === 'Control') {
      this.isControlPressed = true;
    } else {
      const command = this.keyCommands[(
        (this.isAltPressed ? 'Alt-' : '') +
        (this.isMetaPressed ? 'Meta-' : '') +
        (this.isControlPressed ? 'Control-' : '') +
        key
      )];
      if (command) {
        command();
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  get selection() { return this.articleController.selection; }
  get layout() { return this.articleController.layout; }

  moveElementUp = () => this.selection.moveSelectedElement(-1);
  moveElementDown = () => this.selection.moveSelectedElement(1);
  selectUp = () => this.selection.moveSelection(-1);
  selectDown = () => this.selection.moveSelection(1);
  runElement =  () =>this.selection.runElement();
  activateCurrentElement =  () => this.selection.activateCurrentElement();
  newElementPopup = () => this.articleController.popups.newElementPopup();
  deleteSelectedElementPopup = () => this.selection.deleteSelectedElementPopup();
  // toggleInspector = () => this.article.toggleInspector();
  popOffSelectedElement = () => this.selection.popOffSelectedElement();
  selectAll = () => this.selection.selectAll();

  undo = () => this.layout.undo();
  redo = () => this.layout.redo();
  goBack = () => window.history.back();

  keyCommands = {
    'Meta-A': this.selectAll,
    'Meta-a': this.selectAll,
    'Meta-ArrowLeft': this.goBack,
    '[-ArrowLeft': this.goBack,

    'Meta-Z': this.undo,
    'Meta-z': this.undo,
    'Meta-Y': this.redo,
    'Meta-y': this.redo,

    'Alt-k': this.moveElementUp,
    'Alt-K': this.moveElementUp,
    'Alt-ArrowUp': this.moveElementUp,

    'Alt-j': this.moveElementDown,
    'Alt-J': this.moveElementDown,
    'Alt-ArrowDown': this.moveElementDown,

    'k': this.selectUp,
    'K': this.selectUp,
    'ArrowUp': this.selectUp,

    'j': this.selectDown,
    'J': this.selectDown,
    'ArrowDown': this.selectDown,

    'Alt-Enter': this.runElement,

    'Enter': this.activateCurrentElement,
    'Tab': this.activateCurrentElement,

    'N': this.newElementPopup,
    'n': this.newElementPopup,

    'Backspace': this.deleteSelectedElementPopup,
    
    // 't': this.toggleInspector,

    'p': this.popOffSelectedElement,
  }


  handleInputKeyUp = (event) => {
    const key = event.key;
    if (key === 'Alt') {
      this.isAltPressed = false;
    } else if (key === 'Meta') {
      this.isMetaPressed = false;
    } else if (key === 'Control') {
      this.isControlPressed = false;
    }
  }
}

export default KeyManager;