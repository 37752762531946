import { useControllerState } from 'lib/BaseController';
import Loader from 'lib/staticPages/Loader'
import List from './List';
import { ui } from 'lib/popup';
import VersionButtons from './Buttons.js';


function Popup({ popup }) {
  const
    state = useControllerState(popup),
    articleState = useControllerState(popup.articleController),
    versions = state.get('versions'),
    publishedId = articleState.getIn(['article', 'publishedVersionId']);

  return (
    <ui.Popup popup={popup} className="article-versions-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>{popup.state.get('title')}</ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body className="p-2">
          <div className="h-[22rem] bg-paper2 rounded-sm p-2 pr-4 overflow-auto">
            {
              !versions ?
                (<div className="p-4 text-center"><Loader /></div>) :
                  (<List
                    popup={popup}
                    versions={versions}
                    publishedId={publishedId}
                    selectedId={state.get('selectedId')}
                    setSelectedId={(id) => popup.setState({ selectedId: id })} />)
            }
          </div>
          <VersionButtons popup={popup} publishedId={publishedId} />
        </ui.Body>          
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;