import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { RouterProvider } from 'react-aria-components';
import { useControllerState } from 'lib/BaseController';
import PopupManagerView from 'lib/popup/ManagerView';
import Context from '../Context'

import Home from './Home';
import Landing from './Home/Landing';
import Demos from './Home/Demos';
import Explore from './Home/Explore';
import { About, Terms } from './Home/misc/static';

import User from './User';
import Auth from './Auth';

import Workspace from 'modules/workspace/views/Page';
import Settings from 'modules/workspace/views/Settings';
import Editor from 'modules/editor/views/Page';
import Viewer from 'modules/viewer/views/Page';
import Theme from 'modules/theme/views/Page';
import AuthFlash from './AuthFlash';

import 'normalize.css';
import './variables.css';
import './colors.css';
import './base.css';


function App({ app }) {
  const
    { auth } = app,
    authState = useControllerState(auth),
    loaded = authState.get('loaded'),
    userId = auth.user && auth.user.uid;
  
  useEffect(() => auth.listenToUserInfo(userId), [userId, auth]);

  if (!loaded)
    return (<></>);

  return (
    <Context.Provider value={app}>
      <Router>
        <AppRoutes app={app} />
        <PopupManagerView manager={app.popupManager} />
        <AuthFlash app={app} />
      </Router>
    </Context.Provider>
  )
}


function AppRoutes({ app }) {
  return (
    <RouterProvider navigate={useNavigate()}>
      <Routes>
        <Route path="/" element={<Home app={app} />}>
          <Route index element={<Landing />} />
          <Route path="user/*" element={<User app={app} />} />
          <Route path="auth/*" element={<Auth app={app} />} />

          <Route path="demos/*" element={<Demos app={app} />} />
          <Route path="explore/*" element={<Explore app={app} />} />
          <Route path="about" element={<About />} />
          <Route path="terms" element={<Terms />} />

          <Route path="workspaces/*" element={<Workspace app={app} />} />          
          <Route path="preferences/*" element={<Settings app={app} />} />
          <Route path="publication/*" element={<Settings app={app} />} />
          <Route path="users/*" element={<Settings app={app} />} />
          <Route path="usage/*" element={<Settings app={app} />} />
          <Route path="upgrade/*" element={<Settings app={app} />} />

          <Route path="theme/*" element={<Theme app={app} />} />
        </Route>

        <Route path="editor/*" element={<Editor app={app} />} />
        <Route path="*" element={<Viewer app={app} />} />
      </Routes>
    </RouterProvider>
  );
}

export default App;