import {
  collection, onSnapshot, query, orderBy,
  doc, addDoc, getDoc, getDocs, setDoc, updateDoc, deleteDoc,
  serverTimestamp
} from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../firebase';
import { getDownloadURL } from './file';


const objectify = (qss) => qss.docs.map(doc => ({ id: doc.id, ...doc.data() }));

export function onArticle(domainId, articleId, cb, ecb) {
  const ref = doc(db, 'domains', domainId, 'articles', articleId);
  return onSnapshot(ref, {
    next: snap => cb({ id: snap.id, ...snap.data() }),
    error: ecb
  });
}

export async function updateArticle({ domainId, articleId, data }) {
  return await updateDoc(doc(db, 'domains', domainId, 'articles', articleId), data);
}

/* Elements */
export function onArticleElementChange(domainId, articleId, cb, ecb) {
  const q = query(collection(db, 'domains', domainId, 'articles', articleId, 'elements'));
  return onSnapshot(q, qss => {
    qss.docChanges().forEach(change => {
      const { doc, type } = change;
      cb({ id: doc.id, ...doc.data() }, type);
    });
  }, ecb);
}

export async function fetchArticle({ domainId, articleId }) {
  const snap = await getDoc(doc(db, 'domains', domainId, 'articles', articleId));
  return (snap.exists() ? ({ id: snap.id, ...snap.data() }) : null);
}

export function fetchArticleElements({ domainId, articleId }) {
  return getDocs(
    query(collection(db, 'domains', domainId, 'articles', articleId, 'elements'))
  ).then(qss => qss.docs.map(doc => ({ id: doc.id, ...doc.data() })));
}

export async function createElement({ domainId, articleId, data }) {
  const ref = await addDoc(collection(db, 'domains', domainId, 'articles', articleId, 'elements'), data);
  return ({ ...data, id: ref.id });
}

export async function updateElement({ domainId, articleId, elementId, data }) {
  return await updateDoc(doc(db, 'domains', domainId, 'articles', articleId, 'elements', elementId), data);
}

export async function deleteElement({ domainId, articleId, elementId }) {
  return await deleteDoc(doc(db, 'domains', domainId, 'articles', articleId, 'elements', elementId));
}


/* Uploads */
export function onArticleUploads(domainId, articleId, cb, ecb) {
  const q = query(
    collection(db, 'domains', domainId, 'articles', articleId, 'uploads'),
    orderBy('createdAt')
  );
  return onSnapshot(q, qss => cb(objectify(qss)), ecb);
}


/* Versions */
export function onArticleVersions(domainId, articleId, cb, ecb) {
  const q = query(collection(db, 'domains', domainId, 'articles', articleId, 'versions'), orderBy('createdAt', 'desc'));
  return onSnapshot(q, qss => cb(objectify(qss)), ecb);
}

export async function createVersion({ domainId, articleId, label, slug, notes, isPublic, dvMap }) {
  return await httpsCallable(functions, 'createVersionCall')({
    domainId, articleId, label, slug, notes, isPublic, dvMap
  });
}

export async function updateVersion({ domainId, articleId, versionId, data }) {
  return await updateDoc(doc(db, 'domains', domainId, 'articles', articleId, 'versions', versionId), data);
}

export async function deleteVersion({ domainId, articleId, versionId }) {
  return await httpsCallable(functions, 'deleteVersionCall')({ domainId, articleId, versionId });
}

export async function revertToVersion({ domainId, articleId, versionId }) {
  return await httpsCallable(functions, 'revertToVersionCall')({ domainId, articleId, versionId });
}

export async function publishVersion({ domainId, articleId, versionId }) {
  return await httpsCallable(functions, 'publishVersionCall')({ domainId, articleId, versionId });
}

export async function unpublishArticle({ domainId, articleId }) {
  return await httpsCallable(functions, 'unpublishArticleCall')({ domainId, articleId });
}

export async function fetchVersion({ domainId, articleId, versionId }) {
  const snap = await getDoc(doc(db, 'domains', domainId, 'articles', articleId, 'versions', versionId));
  return (snap.exists() ? ({ id: snap.id, ...snap.data() }) : null);
}

export async function fetchVersionElements({ domainId, articleId, versionId }) {
  const url = await getDownloadURL(`domain/${domainId}/article/${articleId}/version/${versionId}/elements.json`);
  return await fetch(url).then(res => res.json());
}

/* User Access */
export function onArticleAccesses(domainId, articleId, cb, ecb) {
  const q = query(collection(db, 'domains', domainId, 'articles', articleId, 'access'), orderBy('createdAt'));
  return onSnapshot(q, qss => cb(objectify(qss)), ecb);
}

export function updateArticleAccess({ domainId, articleId, email, accessData }) {
  return setDoc(doc(db, 'domains', domainId, 'articles', articleId, 'access', email), {
    createdAt: serverTimestamp(),
    ...accessData
  });
}

export function deleteArticleAccess({ domainId, articleId, email }) {
  return deleteDoc(doc(db, 'domains', domainId, 'articles', articleId, 'access', email));
}