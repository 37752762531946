export function grabProperty(obj, key) {
  const result = {};
  for (let k in obj) {
    result[k] = obj[k][key];
  }
  return result;
}

export const getGetOptionsFnWithDefaults = (originalOptions) => {
  return (({ valueOnNull }) => {
    const d = originalOptions.find(opt => opt.key === valueOnNull);
    if (!d) {
      console.error('Key found ', valueOnNull);
      return originalOptions;
    }
    return ([
      { caption: <>{d.caption} <span className="font-normal opacity-50 ml-1 text-xs">default</span></>, key: null },
      { type: 'break' },
      ...originalOptions,
    ])
  })
}


export const getBoolSelectOptions = getGetOptionsFnWithDefaults([
  { caption: 'Yes', key: true },
  { caption: 'No', key: false },
]);