import { useState } from 'react';
import startDragging from 'lib/startDragging';
import { Tabs, TabList, Tab, TabPanel } from 'react-aria-components';
import clsx from 'clsx';
import panelLib from './panelLib';
import { OpenInNewWindowIcon, PinLeftIcon, PinRightIcon } from '@radix-ui/react-icons'


export function TabbedBoxPanel({ editor, group, className, outerClassName }) {
  const
    { state } = editor.panels,
    ids = state.getIn(['groups', group]),
    index = Math.min(state.getIn(['selection', group]), ids.size - 1),
    id = state.getIn(['groups', group, index]),
    width = state.getIn(['widths', group]),
    ac = editor.currentArticleController;

  return state.getIn(['expand', group]) && ids.size > 0 && (
    <div className={clsx('flex-grow-0 flex-shrink-0 overflow-hidden', outerClassName)}>
      <div
        className={clsx(className, 'h-full')}
        style={{ width: width + 'em' }}>
        <Tabs
          className="h-full flex flex-col"
          selectedKey={id}
          onSelectionChange={id => editor.panels.setInState(['selection', group], ids.indexOf(id))}>
          <TabList aria-label={group} className="flex gap-3 px-5 py-3 border-b border-rim">
            { ids.map(id => (
              <Tab
                id={id}
                key={id}
                className={({ isSelected, isDisabled }) => clsx(
                  'text-sm outline-none cursor-pointer font-medium',
                  isSelected ? 'text-marker2' : 'text-pencil3 hover:text-pencil2'
                )}>
                {panelLib[id].title}
              </Tab>
            ))}
          </TabList>

          { ids.map(id => {
            const panel = panelLib[id];
            return (
              <TabPanel id={id} key={id} className="relative flex flex-grow overflow-hidden">
                {/*{ false && <PanelLayoutMenu editor={editor} group={group} id={id} /> }*/}
                { ac && <panel.Render editor={editor} /> }
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}

export function FloatingPanel({ id, editor }) {
  const
    [diff, setDiff] = useState({ dx: 0, dy: 0 }),
    panel = panelLib[id],
    position = editor.panels.getPosition(id);

  return (
    <div
      className="bg-paper border border-rim overflow-auto"
      style={{
        left: position.get('x') + diff.dx,
        top: position.get('y') + diff.dy,
        width: position.get('width'),
        height: position.get('height'),
        position: 'absolute'
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        startDragging({
          event: event,
          onMove: (e, dx, dy) => setDiff({ dx, dy }),
          onEnd: (e, dx, dy) => {
            let position = editor.panels.getPosition(id);
            position = position.set('x', position.get('x') + dx);
            position = position.set('y', position.get('y') + dy);
            editor.panels.setPosition(id, position);
            setDiff({ dx: 0, dy: 0 });
          }
        });
      }}>
      <PanelLayoutMenu editor={editor} group="floating" id={id} />
      <panel.Render editor={editor} />
    </div>
  )
}


function PanelLayoutMenu({ editor, group, id }) {
  return (
    <div className="absolute right-1 top-1 flex gap-2">
      {group !== 'floating' ? (
        <div onClick={() => editor.panels.move(id, group, 'floating')}>
          <OpenInNewWindowIcon />
        </div>
      ) : (
        <>
          <div onClick={() => editor.panels.move(id, group, 'left')}>
            <PinLeftIcon />
          </div>
          <div onClick={() => editor.panels.move(id, group, 'right')}>
            <PinRightIcon />
          </div>                
        </>
      )}
    </div>
  )
}