import { Outlet } from 'react-router-dom';
import Container from './Container';
import UserButton from '../User/UserButton';
import ColorSchemeMenu from './misc/ColorSchemeMenu';

import './misc/stars.css';

function Home({ app }) {
  return (
    <Container app={app}>
      <Outlet />

      <Stars />

      <div className="absolute top-4 right-6 max-md:top-2 max-md:right-2 flex items-center gap-2">
        <ColorSchemeMenu app={app} />
        <UserButton app={app} />
      </div>
    </Container>
  );  
}

function Stars() {
  return (
    <div className="stars-box">
      <div className="stars"></div>
      <div className="stars2"></div>
      <div className="stars3"></div>
    </div>
  )
}

export default Home;