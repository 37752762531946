import { useEffect, useRef } from 'react';
import clsx from 'clsx';


export default function TitleElement({ articleController, onInputEl }) {
  const
    { state, selection } = articleController,
    article = state.get('article'),
    value = article.get('title'),
    inputRef = useRef();
  
  function resize(el) {
    el.style.height = '6px';
    el.style.height = (el.scrollHeight - 4) + 'px';
  }
  useEffect(_ => resize(inputRef.current), [value]);
  useEffect(_ => { window.document.title = 'Notebook X | ' + value; }, [value]);

  
  return (
    <textarea
      className={clsx(
        'article-title block',
        'outline-none resize-none overflow-hidden w-full',
        'text-3xl m-0 mb-0 hover:bg-wax focus:bg-wax bg-paper2'
      )}
      value={value}
      ref={el => {
        inputRef.current = el;
        onInputEl(el);
      }}
      onInput={e => {
        const el = e.currentTarget;
        resize(el);
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          e.stopPropagation();
          e.preventDefault();
          selection.focusPage();
        }
      }}
      onFocus={_ => articleController.titleElement.setActive() }
      onChange={e => {
        const title = e.currentTarget.value.replace(/\n/g, '');
        articleController.setInState(['article', 'title'], title);
      }}
      onBlur={_ => {
        articleController.saveArticleStateKey('title');
      }}
      spellCheck="false" />
  )
}