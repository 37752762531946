import { Button, Menu, MenuItem, MenuTrigger, Popover, Separator, OverlayArrow } from 'react-aria-components';
import { HamburgerMenuIcon, DotFilledIcon } from '@radix-ui/react-icons';
import Icon from 'lib/Icon';
import clsx from 'clsx';
import Arrow from 'lib/components/Arrow';


export default function MenuBuilder({
  options, children, className, isOpen, onOpenChange,
  tight, preventFocusOnPress, popoverClassName
}) {
  return (
    <MenuTrigger isOpen={isOpen} onOpenChange={onOpenChange}>
      <Button
        className={clsx(className, 'outline-none')}
        preventFocusOnPress={preventFocusOnPress}>
        { children || <HamburgerMenuIcon className="inline-block" /> }
      </Button>
      <MenuBuilderPopOver {...{popoverClassName, options, tight}} />
    </MenuTrigger>
  );
}

export function MenuBuilderPopOver({ popoverClassName, options, tight }) {
  return (
    <Popover className={popoverClassName || "min-w-36"}>
      <OverlayArrow>
        <Arrow />
      </OverlayArrow>
            
      <MenuBuilderMenu {...{ options, tight }} />
    </Popover>
  )
}

export function MenuBuilderMenu({ options, tight }) {
  return (
    <Menu
      onAction={(i) => options[i][2] && options[i][2]()}
      className="bg-paper font-normal bg-paper shadow outline-none border border-rim rounded-sm"
      disabledKeys={options.map((o, i) => (o[3] && o[3].isDisabled && ({ i }))).filter(o => o).map(o => o.i)}>
      {
        options.map(([caption, IconC, fn, props], i) => (
          caption ? (
            <MenuItem
              id={i}
              key={i}
              {...(props || {})}
              className={({ isDisabled, isFocused }) => clsx(
                'block cursor-pointer text-sm outline-none relative',
                isDisabled && 'pointer-events-none text-pencil3',
                isFocused ? 'bg-wax2' : 'bg-paper',
                tight ? 'px-2 py-1' : 'px-3 py-2'
              )}>
              { typeof IconC === 'string' &&
                (<div className="inline-block text-sm w-6">{IconC && <Icon type={IconC} />}</div>)}
              { (typeof IconC === 'object' || typeof IconC === 'function') && IconC &&
                (<div className="inline-block w-6"><IconC className="inline-block mr-2" /></div>)}
              {caption}
              { props && props.notification && (
                <>
                  <div
                    className={clsx('absolute top-1 right-1', props.notification === 'alert' && 'text-red-800')}>
                    <DotFilledIcon />
                  </div>
                </>
              ) }
            </MenuItem>
          ) : (<Separator key={i} className="border-t border-rim mt-1 pt-1" />)
        )
      )}
    </Menu>
  )
}