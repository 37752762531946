import { useControllerState } from 'lib/BaseController';
import ui from 'lib/popup/components/ui';
import { TextInput, Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';


function Popup({ popup }) {
  const
    state = useControllerState(popup),
    status = (
      state.get('checking') ? 'spinner' :
        (state.get('checkedLink') !== state.get('link') || state.get('link') === '') ? null :
          state.get('isValid') === false ? 'cross' :
            state.get('isValid') === true ? 'check' :
              null
    );

  return (
    <ui.Popup popup={popup} className="link-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>Add Link</ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.submit(); }}>
            <Group>
              { state.get('checkedLink') === state.get('link') && state.get('info') && (
                <div className="item-title">
                  { state.get('info').title }
                </div>
              ) }

              <TextInput
                label="Link URL"
                value={state.get('link')}
                onChange={link => {
                  popup.setState({ link })
                  popup.onLinkChangeDebounced();
                }}
                placeholder="A relative link (eg. /demo/chess) or an absolute URL"
                autoFocus={true} 
                status={status} />

              { popup.args.allowUploads && (
                <div className="pull-right">
                  <label className="mr-2">
                    Or
                  </label>
                  <Button onClick={() => popup.openUploadFilesPopup()}>
                    A File Download Link
                  </Button>
                </div>
              ) }

            </Group>
            
            <ui.Alert />

            <Buttons className="text-right">
              <Button
                type="submit"
                isDisabled={state.get('loading') || state.get('checking') || !state.get('isValid')}>
                Add
              </Button>

              <Button onPress={_ => popup.close()}>
                Cancel
              </Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;