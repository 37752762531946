import { getSizeString } from 'lib/tools.ts';
import clsx from 'clsx';

export default function Usage({ tally, subscription, plans }) {
  const
    factor = 1024 * 1024,
    plan = plans[tally.currentPlan] || plans['default'],
    storage = plan.maxStorage * factor,
    storageUse = tally.storageUse || 0,
    articleCount = tally.articleCount || 0;

  return (
    <div className="stats">
      <div className="stats-row my-4">
        <div className="key font-medium text-sm">Your Current Plan</div>
        <div className="value">{plan.caption}</div>
      </div>

      <div className="stats-row my-4">
        <div className="key font-medium text-sm ">Max File Upload Size</div>
        <div className="value">{getSizeString(plan.maxUploadSize * factor)}</div>
      </div>

      <Bar
        label="Storage use"
        ratio={storageUse / storage}
        value={getSizeString(storageUse)}
        max={getSizeString(storage)} />

      <Bar
        label="Total Articles"
        ratio={articleCount / plan.maxArticles}
        value={ articleCount }
        max={plan.maxArticles} />
    </div>
  )
}

function Bar({ label, value, max, ratio }) {
  const percent = ratio * 100;

  return (
     <div className="bar text-sm my-4">
       <div className="flex">
         <div className="font-medium flex-grow">{label}</div>         
         <div className="">{value} / {max}</div>
       </div>
       <div className="bg-wax3 h-3 w-full relative rounded mt-1 overflow-hidden">
         <div className={clsx('absolute top-0 left-0 h-3', ratio < .8 ? 'bg-blue-600' : 'bg-red-600')}
           style={{ width: Math.min(percent, 100) + '%' }}></div>
       </div>
    </div>
  )
}