import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';
import clone from 'api/clone';

class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 480 });

  constructor(args, manager) {
    super(args, manager);
    this.state = this.state.merge({
      folderPath: [],
      done: false,
      title: args.article.get('title'),
      slug: args.article.get('slug'),
      slugValid: false
    });
    this.loadDomains();
  }

  loadDomains() {
    const { auth, remoteProvider } = this.args.app;
    auth.fetchUserDomains()
      .then((userDomains) => Promise.all(
        userDomains.map(u => remoteProvider.fetchDomain({ domainId: u.id }))
      ))
      .then(domains => this.setState({ domains }))
  }

  get currentProvider() {
    return (
      this.state.get('folderPath')[0] === 'local' ?
        this.args.app.localProvider: 
        this.args.app.remoteProvider
    );
  }

  checkAvailable = async (path) => !(await this.args.provider.fetchPath(path));


  clone = async () => {
    this.setState({ loading: true });
    try {
      await clone({
        sourcePath: this.args.article.get('path'),
        targetPath: [...this.state.get('folderPath'), this.state.get('slug')].join('\\'),
        title: this.state.get('title')
      });
      this.setState({ loading: false, done: true });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, alert: toHuman(e) });
    }
  }
}

export default PopupController;