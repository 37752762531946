import BaseController from 'lib/BaseController';
import Prompt from 'lib/popup/popups/Prompt/Controller';


export default class Comments extends BaseController {
  constructor(viewer) {
    super();
    this.viewer = viewer;
    this.state = this.state.merge({
      list: null,
      sortBy: 'createdAt',
      countDiff: 0,
      isAdmin: null,
      commentLikes: [],
    });
  }

  load = ({ domainId, articleId, provider }) => {
    this.articleId = articleId;
    this.domainId = domainId;
    this.provider = provider;

    this.fetchComments();
    this.fetchCommentLikes();
  }

  fetchComments = (startCursor) => {
    const { provider, articleId, domainId } = this;
    this.setState(startCursor ?  { loadingMore: true } : { loading: true });
    return (
      provider
        .fetchComments({ articleId, domainId, sortBy: this.state.get('sortBy'), startCursor })
        .then(({ data, nextCursor }) => {
          var prevList = startCursor ? this.state.get('list') : [];
          this.setState({ list: prevList.concat(data), nextCursor, loading: false, loadingMore: false })
        })
        .catch(e => {
          console.error(e);
          this.setState({ error: 'error', loading: false, loadingMore: false });
      })
    );
  }
  fetchNext =() => { this.fetchComments(this.state.get('nextCursor')); }

  fetchCommentLikes = () => {
    const { provider, articleId, domainId } = this;
    provider
      .fetchCommentLikes({ articleId, domainId })
      .then(commentLikes => this.setState({ commentLikes }))
      .catch(e => console.error(e))
  }


  checkIsAdmin = async () => {
    const
      { provider, domainId } = this,
      userId = provider.getUserId(),
      roleIsAdmin = async () => {
        const
          snap = await provider.getDomainUserAccess({ domainId, userId }),
          role = snap.exists && snap.data().role;
        return role === 'admin' || role === 'write';
      };
    if (this.checkedDomainId === domainId) {
      return;
    }
    this.checkedDomainId = domainId;
    this.setState({ isAdmin: userId === domainId || (await roleIsAdmin())});
  }

  getUserId = () => this.provider.getUserId();
  getUserName = () => this.provider.getUserName();

  postComment = ({ comment, commentId }) => {
    const { articleId, domainId, provider } = this;
    return provider.postComment({ articleId, domainId, comment, commentId });
  }

  likeComment = (commentId, like) => {
    const { articleId, domainId, provider } = this;
    return provider.likeComment({ domainId, articleId, commentId, like });
  }

  prompt = (args) => {
    return this.viewer.app.popupManager.open(Prompt, args)
  }

  openDeletePopup = (commentId) => {
    const { domainId, provider } = this;
    this.prompt({
      title: 'Delete Comment',
      question: 'Are you sure you want to delete this comment?',
      submitLabel: 'Yes',
      onSubmit: async () => {
        await provider.deleteComment({ domainId, commentId });
        this.fetchComments();
        this.setState({ countDiff: this.state.get('countDiff') - 1 });
      }
    });
  }

  reportComment = (commentId) => {
    const { domainId, provider } = this;
    this.prompt({
      title: 'Report Comment',
      inputs: [{
        label: 'You are about to report this comment. Please state your reason.',
        type: 'text',
        defaultValue: '',
        autoFocus: true
      }],
      submitLabel: 'Submit',
      onSubmit: async ([ cause ]) => {
        await provider.reportComment({ domainId, commentId, cause });
        this.prompt({
          title: 'Reported',
          content: <div className="font-sm text-center py-4">The comment has been reported.</div>,
          submitLabel: 'Ok',
          hasCancel: false
        });
      }
    });
  }
}
