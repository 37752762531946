import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 420 });

  constructor(args, manager) {
    super(args, manager);
    // expects args:
    // imageUrl, placeholderUrl, placeholderText,
    // uploadImageFn, onUpload,
    // width, height, autoWidth, maxWidth

    this.args = args;
    this.state = this.state.merge({
      at: 'select',
      dragCount: 0,
      imageUrl: args.imageUrl,
      imageData: args.imageData
    });

    this.position = this.position.merge({
      width: args.imageWidth + 40,
    });

    if (args.getImage)
      args.getImage().then(imageUrl => this.setState({ imageUrl }))
  }

  setFile = (file) => {
    const fileReader = new FileReader();
    if (!file || file['type'].split('/')[0] !== 'image') {
      return;
    }
    fileReader.onload = e => {
      this.setState({ uploadData: e.target.result });
      if (this.args.autoWidth) {
        this.setAutoWidth(e.target.result);
      }
    }
    fileReader.readAsDataURL(file);
    this.setState({ at: 'confirm', file });
  }

  setAutoWidth = (result) => {
    const img = new Image();
    img.onload = () => this.setOverrideWidth(
      Math.min(
        this.args.height * img.width / img.height,
        this.args.maxWidth || Infinity
      )
    );
    img.src = result;
  }

  setOverrideWidth = (overrideWidth) => {
    const
      windowWidth = Math.max(overrideWidth + 40, 300),
      leftOffset = (windowWidth - this.position.get('width')) / 2,
      windowLeft = this.position.get('left') - leftOffset;

    this.position = this.position.merge({
      width: windowWidth,
      left: windowLeft
    });
    this.setState({ overrideWidth });
  }

  discard = () => {
    this.setState({
      at: 'select',
      file: null,
      uploadData: null
    });
    this.setOverrideWidth(this.args.width);
  }

  beginUpload = () => {
    this.setState({
      loading: true, uploading: true, uploaded: false,
      uploadPercent: 0, alert: null });

    const
      file = this.state.get('file'),
      filename = file.name,
      done = () => {
        this._cancelUpload = null;
        this.setState({ uploadPercent: 100, uploaded: true, uploading: false, loading: false });
        if (this.args.onUpload) {
          this.args.onUpload(this.state.get('uploadData'));
        }
      };
    
    this._cancelUpload = this.args.uploadImageFn(
      { file, filename },
      uploadPercent => this.setState({ uploadPercent }),
      error => {
        this._cancelUpload = null;
        console.error(error);
        if (error.code === 'storage/unknown')
           return done();
        this.setState({ alert: toHuman(error), uploading: false, loading: false });
      },
      done
    );
  }

  cancelUpload = () => {
    if (this._cancelUpload)
      this._cancelUpload();
  }
}

export default PopupController;