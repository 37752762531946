import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  constructor(args, manager) {
    super(args, manager);
    const
      { folder, article } = this.args,
      item = folder || article,
      newPath = item.get('path').split('\\').slice(0, -1),
      oldPath = newPath.slice();

    this.item = item;
    this.folder = folder;
    this.article = article;
    this.provider = args.provider;

    this.itemType = item === folder ? 'folder' : 'article';
    this.state = this.state.merge({ newPath, oldPath });
  }

  async move() {
    const
      itemType = this.itemType,
      itemId = this.item.get('id'),
      domainId = this.item.get('domainId'),
      path = this.state.get('newPath').join('\\'),
      newParentId = (await this.provider.fetchPath(path)).folderId;

    this.setState({ loading: true, alert: '' });
    try {
      await this.provider.moveToFolder({ domainId, itemId, itemType, newParentId });
      this.close();
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }
}

export default PopupController;