import Editor from './Editor';
import Policies from './Policies';
import Inspector from './Inspector';
import Viewer from './Viewer';
import Controller from './Controller'
import Overview from './Overview'
import { faIcons } from '@fortawesome/free-solid-svg-icons';

const index = {
  type: 'iframe',
  caption: 'Iframe',
  faIcon: faIcons,
  description: 'Add an IFrame to external resources',
  
  Editor,
  Inspector,
  Policies,
  Viewer,
  Controller,
  Overview, 
}

export default index;