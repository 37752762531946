import { ui } from 'lib/popup';
import { slugify } from 'lib/slugify';
import { useControllerState } from 'lib/BaseController';

import { Group, TextInput } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import SlugCheckInput from 'lib/inputs/SlugCheckInput';

import './style.css';


function AddNewFolderPopupView({ popup }) {
  let
    state = useControllerState(popup),
    slug = state.get('slug'),
    valid = state.get('valid'),
    loading = state.get('loading');
  

  return (
    <ui.Popup popup={popup} className="create-folder-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Add a New Folder
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); if (valid) popup.addFolder(); }}>
            <Group>                
              <TextInput
                label="Folder Title"
                value={state.get('title')}
                autoFocus={true}
                isDisabled={loading}
                onChange={v => {
                  popup.setState({ title: v })
                  if (state.get('autoSlug')) {
                    popup.setState({ slug: slugify(v) });
                  }
                }} />
              
              <SlugCheckInput
                label="Slug"
                value={state.get('slug')}
                onChange={v => popup.setState({ slug: v })}
                onValidChange={e => popup.setState({ valid: e })}
                checkFn={(val) => popup.checkAvailable(state.get('parentPath') + '\\' + val)} />
            </Group>

            <ui.Alert />

            <Buttons className="text-right">
              <Button isDisabled={loading || !slug || !valid} type="submit">
                Create Folder
              </Button>
              <Button onPress={e => { popup.close() }}>
                Cancel
              </Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default AddNewFolderPopupView;