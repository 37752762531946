import clsx from 'clsx';

export default function ArrowSVG({ className }) {
  return (
    <div className="h-[15px]">
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className={clsx(className, 'fill-paper stroke-rim')}>
        <path d="M0 17 L8 8 L16 17 Z" strokeWidth="1" />
      </svg>
    </div>
  )
}