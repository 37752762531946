import { TrashIcon } from '@radix-ui/react-icons';
import { Button } from 'react-aria-components';
import clsx from 'clsx';


export function UserAccessTable({ popup }) {
  const
    { state } = popup,
    data = state.get('data'),
    loading = state.get('loading');

  return (
    <div className="p-2 border border-rim mb-2 h-[20rem] overflow-auto">
      { data && data.size === 0 && (
        <div className="text-center p-4 text-sm text-pencil3 select-none">
          No user has been added yet
        </div>
      ) }
      { data.map(entry => (
        <UserRow
          key={entry.get('id')}
          entry={entry}
          popup={popup}
          loading={loading} />
      )) }
    </div>
  )
}

function UserRow({ entry, popup, loading }) {
  const email = entry.get('id');
  return (
    <div className="border border-rim2 m-2 py-2 px-3 rounded-sm">
      <div className="flex overflow-hidden w-full break-words">
        <div className="flex-grow overflow-hidden">
          <div className="w-full">
            <div className="font-medium text-sm mb-2">{email}</div>
          </div>          
          <AccessOptions
            value={entry.get('access')}
            onChange={ access => popup.updateAccess({ email, access }) }
            isDisabled={loading} />
        </div>

        <div className="flex-shrink-0">
          <Button
            className={({ isDisabled }) => clsx(isDisabled && 'opacity-50')}
            isDisabled={loading}
            onClick={_ => popup.deleteAccess({ email: entry.get('id') })}>
            <TrashIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}

export function AccessOptions({ value, onChange, isDisabled, big }) {
  return (
    <select
      value={value}
      disabled={isDisabled}
      className={clsx('border border-rim2 cursor-pointer text-sm', big && 'p-1')}
      onChange={e => onChange(e.currentTarget.value)}>
      <option value="r">Read Only</option>
      <option value="rw">Read &amp; Write</option>
    </select>    
  )
}


export function AddAccessForm({ popup }) {
  const
    { state } = popup,
    loading = state.get('loading');

  return (
    <form
      className="my-4 px-2 flex gap-3"
      onSubmit={e => { e.preventDefault(); popup.addAccess();}}>
      <input
        type="email"
        placeholder="Email"
        value={state.get('email')}
        className="text-sm border border-rim2 py-1 px-2 mb-2 w-full"
        onChange={e => popup.setState({ email: e.currentTarget.value })}
        disabled={loading} />

      <div className="access mb-2">
        <AccessOptions
          value={state.get('access')}
          onChange={access => popup.setState({ access }) }
          isDisabled={loading}
          big={true} />
      </div>

      <div className="action">
        <Button
          className={({isDisabled}) => clsx(isDisabled && 'opacity-50', 'text-sm border border-rim2 py-1 px-4')}
          type="submit"
          isDisabled={loading}>
          Add
        </Button>
      </div>
    </form>
  )
}