import GlobalStyle from 'lib/GlobalStyle';
import { FONTS, CURSIVE_FONTS, MONOSPACE_FONTS } from '../defaults/fonts';
import { memo } from 'react';
import { useControllerState } from 'lib/BaseController';

export function Styler({ themeData, baseClass }) {
  const { getValue } = themeData;
  useControllerState(themeData);

  // todo : move cm-line to inside of codemirror
  const
    styles = `ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600`,
    importFont = val => `@import url('https://fonts.googleapis.com/css2?family=${val}:${styles}&display=swap');`,
    pageWidth = getValue('pageWidth'),
    pageMaxWidth = (pageWidth === 'wide' ? '64rem' : pageWidth === 'narrow' ? '40rem' : '52rem');

  const css = `
    ${importFont(getValue('font'))}
    ${importFont(getValue('headerFont'))}
    ${importFont(getValue('cursiveFont'))}
    ${getValue('codeFont') === 'System' ? '' : importFont(getValue('codeFont'))}

    .${baseClass} {
      color: ${getValue('color')};
      font-family: ${FONTS[getValue('font')]};
      font-size: ${getValue('fontSize')}px;
      line-height: ${getValue('lineSpacing')};
    }

    .${baseClass} textarea {
      line-height: ${getValue('lineSpacing')}em;
    }

    .${baseClass} .font-mono {
      font-family: ${MONOSPACE_FONTS[getValue('codeFont')]};
    }
    .${baseClass} .cm-line,
    .${baseClass} .cm-theme,
    .${baseClass} .font-code,
    .${baseClass} pre,
    .${baseClass} p code {
      font-family: ${MONOSPACE_FONTS[getValue('codeFont')]};
      font-size: ${getValue('codeFontSize')}px;
      line-height: ${getValue('codeLineHeight')};
    }

    .${baseClass} .font-cursive {
      font-family: ${CURSIVE_FONTS[getValue('cursiveFont')]};
    }

    .${baseClass} .page-max-width {
      max-width: ${pageMaxWidth};
    }

    .${baseClass} header {
      color: ${getValue('navColor')};
      background-color: ${getValue('navBackgroundColor')}
    }

    .${baseClass} header a {
      color: ${getValue('navColor')};
    }

    .${baseClass} .article-title,
    .${baseClass} h1,
    .${baseClass} h2,
    .${baseClass} h3,
    .${baseClass} h4,
    .${baseClass} h5 {
      font-family: ${FONTS[getValue('headerFont')]};
      font-weight: ${getValue('headerFontWeight')};
    }

    .${baseClass} .article-title {
      font-size: 2.8rem;
      line-height: 1;
      text-align: ${getValue('titleAlignment')};
    }


    .${baseClass} .font-header {
      font-family: ${FONTS[getValue('headerFont')]};
      font-weight: ${getValue('headerFontWeight')};
    }

    .${baseClass} a {
      color: ${getValue('linkColor')};
      font-weight: 500;
    }

    .${baseClass} pre,
    .${baseClass} code {
    }

    @media (max-width: 550px) {
      .${baseClass} .page {
        padding: 0px;
      }
    }
  `;

  return (<GlobalStyle css={css} />);
}

export default memo(Styler);