import { breakPath, assemblePath, combinePath, isSameDomain } from './fns';


export const gatherDependenciesPlugin = (babel) => {
  return {
    visitor: {
      ImportDeclaration(path, state) {
        let src = path.node.source.value;
        if (!isSameDomain(src))
          return;
        src = combinePath(state.opts.docPath, src);
        state.opts.dependencies.add(breakPath(src).docPath);
      },
    },
  };
};


export const modifyImportsPlugin = function ({ types: t }) {
  return {
    visitor: {
      ImportDeclaration(path, state) {
        let src = path.node.source.value;
        if (!isSameDomain(src))
          return;

        src = combinePath(state.opts.docPath, src);
        let { articlePath, versionSlug, filename } = breakPath(src);
        // console.log({ articlePath, versionSlug, dvMap: state.opts.dvMap })

        versionSlug = versionSlug || state.opts.dvMap[articlePath];
        src = assemblePath({ articlePath, versionSlug, filename });

        if (!/\.(js|jsx|ts|tsx|css)$/.test(src)) {
          console.log('Returning ', src);
          path.replaceWith(
            t.variableDeclaration('const', [
              t.variableDeclarator(
                t.identifier(path.node.specifiers[0].local.name),
                t.stringLiteral(src)
              )
            ])
          );
        } else {
          console.log('replacing source', path.node.source.value, 'with', src);
          path.node.source = t.stringLiteral(src);
        }
      }
    }
  };
};