import {
  collection, getDocs, query,
  doc, getDoc, updateDoc, onSnapshot, orderBy, deleteDoc
} from 'firebase/firestore';
import { db, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';


export async function fetchUserDomains({ userId }) {
  const qss = await getDocs(query(collection(db, 'userInfos', userId, 'domains'), orderBy('createdAt')));
  return qss.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function createDomain({ slug, title, isPrimary }) {
  return await httpsCallable(functions, 'createDomainCall')({ slug, title, isPrimary });
}

export async function fetchDomain({ domainId }) {
  const snap = await getDoc(doc(db, 'domains', domainId));
  return (snap.exists() ? ({ id: snap.id, ...snap.data() }) : null);
}

export function onDomain(domainId, cb, ecb) {
  const ref = doc(db, 'domains', domainId);
  return onSnapshot(ref, snap => cb({ id: snap.id, ...snap.data() }));
}

export async function updateDomain({ domainId, data }) {
  return await updateDoc(doc(db, 'domains', domainId), data);
}

export async function removeThumbnail({ domainId, articleId, folderId, userId, key }) {
  return await httpsCallable(functions, 'removeThumbnailCall')({ domainId, articleId, folderId, userId, key });
}

/* User Access */
export async function getDomainAccessList(domainId) {
  return await httpsCallable(functions, 'getDomainAccessListCall')({ domainId });
}

export async function addDomainAccess({ domainId, email, role }) {
  return await httpsCallable(functions, 'addDomainAccessCall')({ domainId, email, role });
}

export async function getDomainUserAccess({ domainId, userId }) {
  return await getDoc(doc(db, 'domains', domainId, 'access', userId));
}

export async function deleteDomainUserAccess({ domainId, userId }) {
  await deleteDoc(doc(db, 'domains', domainId, 'access', userId));
}

export async function updateDomainUserRole({ domainId, userId, role }) {
  const ref = doc(db, 'domains', domainId, 'access', userId);
  return await updateDoc(ref, { role });
}

/* Tally */
export async function fetchTally({ domainId }) {
  const snap = await getDoc(doc(db, 'tallies', domainId));
  return (snap.exists() ? ({ id: snap.id, ...snap.data() }) : null);
}

export async function fetchDomainPlans() {
  return (await httpsCallable(functions, 'fetchDomainPlans')()).data;
}