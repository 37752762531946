import BaseController from 'lib/BaseController';
import { throttle } from 'lodash';
import startDragging from 'lib/startDragging';


export default class PageResizer extends BaseController {
  constructor(grid) {
    super();
    this.grid = grid;
    this.state = this.state.merge({ dx: 0, dy: 0, dax: 0, day: 0, dragging: false });
  }

  startDrag = (e) => {
    e.preventDefault();
    this.grid.layout.selection.focusPage();
    this.setState({ dragging: true, dx: 0, dy: 0 });
 
    startDragging({
      event: e,
      camera: this.grid.camera,
      onMove: (e, dx, dy) => this.handleUpdate_(dx, dy),
      onEnd: () => this.handleEnd()
    });
  }

  handleUpdate = (dx, dy) => {
    var { cellSize, cellGap } = this.grid;
    this.setState({
      dx: dx,
      dy: dy,
      dax: Math.floor(0.5 + dx / (cellSize.width + cellGap.x)),
      day: Math.floor(0.5 + dy / (cellSize.height + cellGap.y))
    });
  }
  handleUpdate_ = throttle(this.handleUpdate, 10);
  handleEnd = () => {
    const { cols, rows } = this.grid.dim;
    this.grid.saveIn(['config', 'dim'],{
      cols: cols + this.state.get('dax'),
      rows: rows + this.state.get('day')
    })
    this.setState({ dx: 0, dy: 0, dax: 0, day: 0, dragging: false });
  }
}
