import { memo } from 'react';
import { useControllerState } from 'lib/BaseController';


function Margins({ grid, }) {
  const
    { width, height } = grid.pageRect,
    resizer = grid.pageResizer,
    state = useControllerState(resizer),
    dragging = state.get('dragging'),
    { themeData, pointSize } = grid.layout,
    pageMargin = themeData.getValue('pageMargin'),
    pagePadding = themeData.getValue('pagePadding'),
    dx = state.get('dx'),
    dy = state.get('dy');

  return (
    <>
      <MemoMargins {...{width, height, pageMargin, pagePadding, pointSize, dx, dy, resizer, dragging }} />
    </>);
}


const MemoMargins = memo(function MemoMargins({
  width, height, pageMargin, pagePadding, pointSize, dx, dy, resizer, dragging
}) {
  // const
  //   lineClass = clsx(
  //     'absolute pointer-events-none',
  //     dragging ? 'border-marker border-dashed' : 'border-wax2 dark:border-wax'
  //   ),
  //   pLeft = -pageMargin.left * pointSize,
  //   pWidth = (pageMargin.right + pageMargin.left) * pointSize + width + dx,
  //   pTop = -pageMargin.top * pointSize,
  //   pHeight = (pageMargin.bottom + pageMargin.top) * pointSize + height + dy,
  //   extra = 800; //pagePadding * pointSize;

  return (
    <>
      {/*<div
        className={clsx(lineClass, 'border-l border-r')}
        style={{ top: pTop - extra, left: pLeft, width: pWidth, height: pHeight + extra + extra }} />

      <div
        className={clsx(lineClass, 'border-t border-b')}
        style={{ top: pTop, left: pLeft -extra, width: pWidth + extra + extra, height: pHeight }} />*/}
    </>
  );
});

export default Margins;