import React, { useEffect, useState } from 'react';
import { isArray } from 'lodash';

import { ui as p } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';
import { Button } from 'react-aria-components';
import { TrashIcon } from '@radix-ui/react-icons';

import './style.css';


function Popup({ popup }) {
  let
    state = useControllerState(popup),
    loading = state.get('loading');

  useEffect(_ => { popup.loadAccessList(); }, [popup]);

  return (
    <p.Popup popup={popup} className="domain-access-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>
            Workspace Collaborators
          </p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          <div className="w-full">
            <div className="flex font-medium border-b border-rim2 gap-2">
              <div className="w-48">Email</div>
              <div className="w-20">Access</div>
              <div className="flex-grow"></div>
            </div>

            <div className="flex h-64 overflow-auto">
              {
              state.get('loadingList') ?
                (<div className="body-info">...</div>) :
                  !isArray(state.get('list')) ? '' :
                    state.get('list').length === 0 ?
                      (<div className="p-3 text-sm text-pencil3">No one has been added yet.</div>) :
                      state.get('list').map(a =>(
                        <UserAccessRow
                          key={a.id}
                          a={a}
                          popup={popup}
                          loading={loading} />
                      ))
              }
            </div>
            
            <form
              className="flex font-medium border-t border-rim2 py-2 gap-2 items-center"
              onSubmit={e => { e.preventDefault(); popup.addDomainAccess(); }}>
              <input
                className="text-sm p-1.5 border border-rim3 w-48"
                placeholder="User Email"
                onChange={e => popup.setState({ email: e.currentTarget.value })} 
                value={state.get('email')} />

              <select
                className="text-sm p-1 border border-rim3 w-24 rounded"
                value={state.get('role')}
                onChange={e => popup.setState({ role: e.currentTarget.value })}>
                <option value="admin">Admin</option>
                <option value="write">Read / Write</option>
                <option value="read">Read Only</option>
              </select>

              <div className="action">
                <Button
                  className="text-sm py-1 px-4 border border-rim3"
                  isDisabled={loading}
                  type="submit">
                  Add
                </Button>
              </div>
            </form>
          </div>
          <p.Alert />
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

function UserAccessRow({ a, popup, loading }) {
  const
    [role, setRole] = useState(a.role),
    userId = popup.provider.getUserId(),
    self = a.id === userId;

  return (
    <div className="flex py-2 gap-2" key={a.id}>
      <div className="font-medium text-sm px-1 w-48 py-1">{a.email}</div>
      <div className="access">
        <select
          className="text-sm p-1 border border-rim3 w-28 rounded font-medium"
          value={role}
          onChange={e => {
            setRole(e.currentTarget.value);
            popup.updateDomainUserRole({ userId: a.id, role: e.currentTarget.value });
          } }
          disabled={loading || self}>
          <option value="admin">Admin</option>
          <option value="write">Read / Write</option>
          <option value="read">Read Only</option>
        </select>
      </div>

      <div className="action">
        <Button
          className="p-2 rounded data-[disabled]:text-pencil3 "
          isDisabled={loading || self}
          onPress={_ => popup.deleteUserAccess({ userId: a.id })}>
          <TrashIcon className="w-4 h-4" />
        </Button>
      </div>
    </div>
  )
}

export default Popup;