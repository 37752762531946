import { Map, fromJS } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import RenameSlug from 'modules/folder/popups/RenameSlug/Controller';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });

  constructor(args, manager) {
    super(args, manager);
    this.domain = args.domain;
    this.domainId = args.domain.get('id');
    this.provider = args.provider;
    this.state = this.state.merge({ loaded: false });
    this.unloadFns.push(this.provider.onDomain(
      this.domainId,
      (domain) => this.setState({ domain: fromJS(domain) }),
      (e) => { console.error(e); this.setState({ alert: toHuman(e) }); }
    ));
  }

  save = async () => {
    const
      state = this.state,
      description = state.get('description'),
      title = state.get('title');
    
    this.setState({ loading: true, alert: '' });
    try {
      await this.provider.updateDomain({
        domainId: this.domainId,
        data: { title, description }
      });
    } catch (e) {
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }

  openRenameSlugPopup() {
     this.manager.open(RenameSlug, {
       domain: this.domain,
       provider: this.provider
     });
  }
}

export default PopupController;