import { useControllerState } from 'lib/BaseController';
import { BarButton } from './res/components';
import { SidebarIcon } from './res/icons';


export default function PanelButtons({ panels }) {
  const state = useControllerState(panels);
  return (
    <>
{/*      <BarButton
        iconType="SidebarLeft"
        tooltip="Left Sidebar"
        label="left-bar"
        isActive={state.getIn(['expand', 'left'])}
        fn={() => panels.saveInState(['expand', 'left'], !state.getIn(['expand', 'left']))} />*/}

      <BarButton
        Icon={SidebarIcon}
        tooltip="Right Sidebar"
        label="right-bar"
        isActive={state.getIn(['expand', 'right'])}
        fn={() => panels.saveInState(['expand', 'right'], !state.getIn(['expand', 'right']))} />
    </>
  );
}