import { ASPECT_RATIOS } from 'modules/theme/defaults/image';

export const fields = {
  embedUrl: {
    caption: 'Embed Source URL',
    input: 'textarea', init: ''
  },

  percentWidth: {
    caption: 'Percent Width',
    input: 'number',
    init: 100,
    unit: '%',
    range: [20, 100],
  },

  heightScale: {
    caption: 'Height Scale',
    input: 'options',
    init: 'aspect',
    options: [
      { key: 'fixed', caption: 'Use Fixed Height' },
      { key: 'proportional', caption: 'Use Proportional Height' },
      { key: 'aspect', caption: 'Use Aspect Ratio' }
    ]
  },

  maxWidth: {
    caption: 'Max Width',
    input: 'number',
    init: 800,
    unit: 'px',
    range: [100, 2000],
  },

  aspectRatio: {
    caption: 'Aspect Ratio',
    input: 'options',
    init: '1.6000',
    options: ASPECT_RATIOS.map(([key, caption]) => ({ key, caption })),
    isHiddenFn: getValue => getValue('heightScale') !== 'aspect'
  },

  height: {
    caption: 'Height',
    input: 'number',
    init: 400,
    unit: 'px',
    range: [100, 900],
    isHiddenFn: getValue => getValue('heightScale') !== 'fixed' && getValue('heightScale') !== 'proportional'
  }
}

export const initialSettings = {};
for (let k in fields) {
  initialSettings[k] = fields[k].init;
}