import { Button } from 'react-aria-components';
import { CaretDownIcon, CaretRightIcon } from '@radix-ui/react-icons';
// import { TextAreaInput } from 'lib/inputs/basic';


export default function VersionDetail({ popup }) {
  const expanded = popup.state.get('expanded');

  return (
    <div>
      <div className="">
        <Button
          className="text-sm outline-none font-medium text-pencil2"
          onPress={() => popup.setState({ expanded: !expanded })}>
          more
          { expanded && <CaretDownIcon className="inline" /> }
          { !expanded && <CaretRightIcon className="inline" /> }
        </Button>
      </div>

      { expanded && (
        <div className="p-1">
          <Files popup={popup} />
          <DVMap popup={popup} />
          {/*<Note popup={popup} />*/}
        </div>
      ) }
    </div>
  )
}

function Files({ popup }) {
  const
    { localFiles } = popup.articleController,
    files = localFiles.getFilenames();

  return (
    <div className="">
      <div className="text-sm font-medium">Files in this version</div>
      <div className="">
        {Array.from(files).map((filename, i) => (
          <div  key={i} className="font-mono text-xs inline-block p-1 mr-1 bg-wax">{filename}</div>
        ))}
      </div>
    </div>
  )
}

function DVMap({ popup }) {
  const
    dvMap = popup.state.get('dvMap'),
    keys = dvMap && Object.keys(dvMap);

  return keys && keys.length > 0 && (
    <div>
      <div className="text-sm font-medium mb-1">
        Dependencies
      </div>

      { keys.map(k => (
        <div key={k} className="flex gap-2 font-mono text-xs">
          <div>{k}</div>
          <div className="bg-wax2 px-1">{dvMap[k]}</div>
        </div>
      ))}
    </div>
  );
}


// function Note({ popup }) {
//   return (
//     <TextAreaInput
//       label="Notes"
//       value={popup.state.get('notes')}
//       onChange={c => popup.setState({ notes: c })} />             
//   )
// }