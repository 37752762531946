import { filenameRegex } from 'lib/regexps';

export function getSizeString(size: number): string {
  var units = ['bytes', 'kB', 'MB', 'GB', 'TB'];
  for (var i = 0; size > 1024; i++) {
    size = size / 1024;
  }
  return Number(size).toFixed(2) + ' ' + units[i];
}


export const validateFilename = (val: any) => {
  if (typeof val !== 'string')
    return 'Not a string';
  if (val !== '' && !filenameRegex.test(val))
    return 'Control characters are not allowed';
  if (val.length > 30)
    return 'Filename should be less than 30 chars';
}