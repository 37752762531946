import { debounce } from 'lodash';

class Settings {
  constructor(article) {
    this.article = article;
  }

  get state() {
    return this.article.state.getIn(['article', 'settings']);
  }

  setStateAndSave(obj) {
    this.article.setInState(['article', 'settings'], this.state.merge(obj));
    this.saveDebounced();
  }

  save = () => this.article.saveArticleStateKey('settings');
  saveDebounced = debounce(this.save, 500);

  getProperty = (key) => {
    const val =  this.state.get(key);
    return val !== undefined ? val : this.defaultSettings[key];
  }

  defaultSettings = {
    maxWidth: 900,
    alignment: 'center',
    interElementMargin: 21,
    displayLines: true,
    color: '#000000',
    backgroundColor: '#ffffff'
  }
}

export default Settings;