import _ from 'lodash';

class KeyDispatcher {
  constructor() {
    window.document.addEventListener('keydown', this.handleKeyEvent);
    window.document.addEventListener('keyup', this.handleKeyEvent);
    window.document.addEventListener('keypress', this.handleKeyEvent);
  }
  
  fnMaps = {};
  idStack = [];

  subscribe(id, keyHandleFn) {
    this.fnMaps[id] = keyHandleFn;
    this.idStack.push(id);

    return _ => {
      delete this.fnMaps[id];
      const index = this.idStack.indexOf(id);
      if (index !== -1)
        this.idStack.splice(index, 1);
    }
  }

  handleKeyEvent = (e) => {
    const
      id = this.idStack[this.idStack.length - 1],
      fn = id && this.fnMaps[id];

    if (fn) {
      const handled = fn(e);
      if (handled) {
        console.log(e);
        e.stopPropagation();
      }
    }
  }

  bringToFront = (id) => {
    this.idStack = _.sortBy(this.idStack, id_ => id_ === id);
  }
}


const keyDispatcher = new KeyDispatcher();
export function getKeyDispatcher() {
  return keyDispatcher;
}

export default KeyDispatcher;