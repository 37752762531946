import { name } from 'meta';
import { Page } from './common';


export default function PageAbout() {
  return (
    <Page className="md:flex overflow-auto">
      <div className="flex-1 p-4">
        <div className="text-4xl mb-8">
          1. It helps you think
        </div>

        <ul>
          <li className="mb-4">
            {name} comes with a simple document editor, a code editor,
            components to run your code, and several other editors, all
            droppable into your drocument.
          </li>

          <li className="mb-4">
            No boilerplate. No github repos. No deployments.
            No package manager. No environment setup. You can dive right into 
            the heart of the problem. And it's very straightforward.
          </li>

          <li className="mb-4">
            Think, code and create with a sense of clarity, create
            interactive documents and bring your work to life.
          </li>

          <li className="mb-4">
            Colloborate, versionize, and publish. It's hosted.
          </li>


          <li className="mb-4">
            Build big things things in a modular way.
            Create a library in one document, import it to another.
            Create an app in one document, import it to another.
          </li>
        </ul>
      </div>

      <div className="flex-1 p-4">
        <div className="text-4xl mb-8">
          2. It's versatile
        </div>

        <div className="mb-4">
          You can write documents. But it's not Google Doc.
          You can code. But it's not VSCode or GitHub.
          You can run your code, but it's not Replit.
          You can export libraries. But it's not NPM.
          You can publish documents. But it's not Medium.
        </div>
        <div className="mb-8">
          It's a little bit of everything. But it's not a mere sum of parts.
        </div>

      
        <div className="text-4xl mb-8">
          3. It's made for creators
        </div>

        <div className="mb-4">
          {name} supports multiple languages.
          You can run JavaScript or TypeScript directly on the browser, or you can
          connect to any computer using <strong>Baklava</strong>, and run your program. And then easily
          get them to communicate via <strong>Vani</strong>.
        </div>
      </div>
      


{/*      <Quote x={0} y={6} width={10} height={2} className="max-md:hidden">
        <div className="mb-2">
          We are accustomed to certain way of doing things. A certain repetition or a routine
          that we don't question and don't want to change, even when they that might be
          limiting us.
        </div>

        <div>
          When we clear our mind of overheads and free up our precious mental resources,
          we have more cognitive capacity to tackle the problem and push us even further.
        </div>
      </Quote>

      <Quotto x={10} y={7}>
        (⌐■_■)
      </Quotto>*/}
    </Page>
  )
}
