import MenuBuilder from 'lib/components/MenuBuilder';
import { SunIcon, MoonIcon, DesktopIcon } from '@radix-ui/react-icons';
import { useControllerState } from 'lib/BaseController';

export default function ThemeMenu({ app }) {
  const { colorScheme } = app;
  useControllerState(colorScheme);
  
  return (
    <MenuBuilder
      className="p-1 mx-2"
      options={[
        [ 'Light', SunIcon, () => colorScheme.setSchemeData('light') ],
        [ 'Dark', MoonIcon, () => colorScheme.setSchemeData('dark') ],
        [ 'System', DesktopIcon, () => colorScheme.setSchemeData(null) ],
      ]} popoverClassName="min-w-28">

      { colorScheme.getSchemeValue() === 'dark' ? <MoonIcon /> : <SunIcon /> }
    </MenuBuilder>
  );
}