import { Map, List } from 'immutable';
import { initialSettings } from './settingsFields';


export default class Policies {
  // EXAMPLE_DATA = {
  //   settings: {}
  // }
  // EXAMPLE_STATE = {
  //   settings: {}
  // }
  static autoSave = true;

  static getInitialData() {
    return {
      version: 0,
      type: 'web-runner',
      settings: initialSettings
    }
  }
  
  static dataToState(data, currentState) {
    if (!currentState)
      currentState = Map({ logs: List([]) });

    return (
      currentState
        .set('settings', Map(data.settings))
        .set('editorData', data.editorData || {})
    );
  }

  static stateToData(state) {
    return {
      settings: state.get('settings').toObject(),
      editorData: state.get('editorData')
    }
  }

  static contentHasChanged(newState, oldState) {
    return (
      newState.get('settings') !== oldState.get('settings') ||
      newState.get('editorData') !== oldState.get('editorData')
    )
  }
}