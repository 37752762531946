import { ui as p } from 'lib/popup';
import { Buttons, Button } from 'lib/inputs/buttons'
import { useControllerState } from 'lib/BaseController';
import Loader from 'lib/staticPages/Loader';
import Usage from '../../views/Usage.js'
import { useNavigate } from 'react-router-dom';
import './style.css';


function Popup({ popup }) {
  let
    state = useControllerState(popup),
    tally = state.get('tally'),
    subscription = state.get('subscription'),
    plans = state.get('plans'),
    navigate = useNavigate();
  
  return (
    <p.Popup popup={popup} className="domain-usage-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>Workspace Usage</p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          { state.get('loading') && <div className="text-center"><Loader /></div> }
          { tally && <Usage {...{ subscription, tally, plans }} />}
          <p.Alert />
          <Buttons className="text-right">
            <Button onPress={() => {
              navigate('/upgrade/' + popup.domain.getIn(['slug']));
              popup.close();
            }}>Upgrade</Button>
          </Buttons>
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

export default Popup;