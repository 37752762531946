import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });

  constructor(args, manager) {
    super(args, manager);
    this.auth = args.auth;

    this.state = this.state.merge({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
  }

  changePassword() {    
    return (
      this.auth.changePassword({
        oldPassword: this.state.get('oldPassword'),
        newPassword: this.state.get('newPassword')
      })
        .then(_ => this.setState({ done: true }))
        .catch(e => {
          console.error(e);
          this.setState({ alert: toHuman(e) });
        })
        .then(_ => this.setState({ loading: false }))
    );
  }
}

export default PopupController;