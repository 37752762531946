import Editor from './Editor';
import Policies from './Policies';
import Inspector from './Inspector';
import Viewer from './Viewer';
import Overview from './Overview';
import Controller from './Controller';
import { faJs } from '@fortawesome/free-brands-svg-icons';

const index = {
  type: 'web-runner',
  caption: 'Web Runner',
  faIcon: faJs,
  description: 'Run JavaScript & CSS code',

  Editor: Editor,
  Inspector: Inspector,
  Policies: Policies,
  Viewer: Viewer,
  Controller: Controller,
  Overview: Overview
}

export default index;