import File from './File';

export default class LocalFiles {
  constructor({ getUploads, getElements, getProvider }) {
    this.getUploads = getUploads;
    this.getElements = getElements;
    this.getProvider = getProvider;
  }

  get proxy() {
    const { getFilenames, getFile, getAllFiles } = this;
    return ({ getFilenames, getFile, getAllFiles });
  }

  getFilenames = (filters={}) => {
    let
      result = new Set(),
      elements = this.getElements(),
      uploads = this.getUploads();

    for (let id in elements) {
      if (id === filters.ignoreId)
        continue;

      const filenames = elements[id] && elements[id].elementFilenames;
      if (filenames)
        filenames.forEach(filename => { if (filename) result.add(filename); });
    }

    for (let upload of uploads) {
      result.add(upload.get('filename'));
    }
    return result;
  }

  getFile = (filename) => {
    let
      elements = this.getElements(),
      uploads = this.getUploads();

    for (let id in elements) {
      let file = elements[id].getElementFile(filename);
      if (file) {
        return this.getDocFile(file);
      }
    }
    for (let upload of uploads) {
      if (upload.get('filename') === filename) {
        return this.getUploadFile(upload);
      }
    }
  }

  getAllFiles = () => {
    let
      elements = this.getElements(),
      uploads = this.getUploads(),
      result = {};

    for (let id in elements) {
      const filenames = elements[id] && elements[id].elementFilenames;
      if (!filenames)
        continue;
      for (let filename of filenames) {
        let file = elements[id].getElementFile(filename);
        if (file) {
          result[filename] = this.getDocFile(file);
        }
      }
    }

    for (let upload of uploads) {
      result[upload.get('filename')] = this.getUploadFile(upload);
    }
    
    return result;
  }

  getDocFile = ({ filename, contentType, getContent, isBase64, isPulled, version }) => (
    new File({ filename, contentType, getContent, isBase64, isPulled, version })
  );

  getUploadFile = (upload) => new File({
    filename: upload.get('filename'),
    contentType: upload.get('contentType'),
    version: 1,
    location: upload.get('name'),
    getDownloadURL: this.getProvider().getDownloadURL
  })
}