import { useControllerState } from 'lib/BaseController';


export default function Selector({ grid }) {
  const
    { selector } = grid,
    state = useControllerState(selector);

  if (!state.get('dragging'))
    return '';

  const
    dx = state.get('dx'),
    dy = state.get('dy'),
    x = state.get('x'),
    y = state.get('y');

  return (
    <div
      className="absolute pointer-events-none opacity-10 bg-marker3 z-20"
      style={{
        left: Math.min(x, x + dx),
        top: Math.min(y, y + dy),
        width: Math.abs(dx),
        height: Math.abs(dy)
      }}></div>
  );
}

