import { useState } from 'react';
import clsx from 'clsx';

export default function ColDraggers({ widths, onChange }) {
  var a = 0;
  return widths.map((width, i) => {
    a = a + width;
    return (<ColDragger key={i} left={a} onChange={dx => onChange(i, dx)} />)
  });
}

function ColDragger({ index, left, onChange }) {
  const
    [offset, setOffset] = useState(0),
    [dragging, setDragging] = useState(false);

  return (
    <div
      className={clsx(
        ' column-dragger',
        'absolute cursor-col-resize h-full top-0 w-2 bg-pencil',
        dragging ? 'opacity-30' : 'opacity-0 hover:opacity-30',
      )}
      style={{
        left: `calc(${left}px)`,
        transform: `translateX(${offset - 4}px)`
      }}
      onMouseDown={e => {
        e.preventDefault();
        setDragging(true);
        startDrag(
          e,
          (dx, dy) => {
            setOffset(dx);
          },
          (dx, dy) => {
            setDragging(false);
            setOffset(0);
            onChange(dx);
          }
        )
      }}
      ></div>)
}

function startDrag(e, onMove, onEnd) {
  e.preventDefault();
  // debugger;
  const
    startX = e.screenX,
    startY = e.screenY;

  const handleMouseEnd = (e) => {
    clear();
    onEnd(e.screenX - startX, e.screenY - startY);
  }
  const handleMouseMove = (e) => {
    onMove(e.screenX - startX, e.screenY - startY);
  }        
  window.document.addEventListener('mousemove', handleMouseMove);
  window.document.addEventListener('mouseup', handleMouseEnd);
  const clear = () => {
    window.document.removeEventListener('mousemove', handleMouseMove);
    window.document.removeEventListener('mouseup', handleMouseEnd);
  }
}

