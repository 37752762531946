import { Checkbox as AriaCheckbox } from 'react-aria-components';
import { CheckboxIcon, BoxIcon } from '@radix-ui/react-icons';

import clsx from 'clsx';


export function Checkbox({ label, value, onChange, isDisabled, defaultValue, display, isYesNo }) {
  value = (value === null || value === undefined) ? defaultValue : value;
  
  return (
    <div className="my-2">
      <AriaCheckbox
        isSelected={value}
        onChange={onChange}
        isDisabled={isDisabled}
        className={({ isDisabled }) => clsx(
          'text-sm font-medium flex gap-2 cursor-pointer items-center',
          isDisabled && 'opacity-30'
        )}>
        {label}

        <span className="font-medium text-sm underline">
          { isYesNo && (value ? 'Yes' : 'No')}
          { !isYesNo && (value ? <CheckboxIcon /> : <BoxIcon />) }
        </span>
      </AriaCheckbox>
    </div>
  );
}