import { useState, useRef } from 'react';
import { TextField, Input, Popover, Button } from 'react-aria-components';
import { Label } from './components'
import { Cross2Icon } from '@radix-ui/react-icons';
import clsx from 'clsx';


function TextInput({
  label, value, unit, isDisabled, validateFn,
  nullable, valueOnNull,
  step, type, spellCheck, autoFocus,
  onFocus, onChange, onBlur, range, className,
  placeholder
}) {
  const validateValue = (value) => {
    if (validateFn)
      return validateFn(value)
    if (range) {
      if (value < range[0])
        return `This value must be more than ${range[0]}`;
      if (value > range[1])
        return `This value must be less than ${range[1]}`;
    }
    return false;
  };

  const
    ref = useRef(),
    isNull = value === null,
    [inputValue, setInputValue] = useState(value),
    [focused, setFocused] = useState(false),
    isInvalid = !isNull && validateValue(inputValue),
    displayValue = (isInvalid ? inputValue : isNull ? valueOnNull : value) || '',
    isEmpty = !displayValue;

  return (
    <TextField>
      <Label>{label}</Label>

      <div className={clsx(
       'relative text-sm font-medium hover:bg-wax2',
        isEmpty && 'bg-wax2',
        focused && 'bg-wax2',
        isInvalid && 'border border-red-200',
        isDisabled && 'text-pencil3 pointer-events-none',
        className
      )}>
        {/* Suffix */}
        {unit && (
          <span className="absolute left-2 top-1 pointer-events-none"> 
            <span className="opacity-0">{displayValue || (<>&nbsp;</>)}</span>
            &nbsp;<span className="">{unit}</span>
          </span>
        )}

        <Input
          className="px-2 py-1 w-full bg-transparent outline-none"
          onFocus={e => {
            setFocused(true);
            if (onFocus)
              onFocus(e);
          }}
          onBlur={e => {
            setFocused(false);
            if (!isInvalid && (inputValue !== displayValue))
              onChange(inputValue);
            if (onBlur)
              onBlur(e);
          }}
          onChange={e => {
            const val = type === 'number' ? e.currentTarget.valueAsNumber : e.currentTarget.value;
            setInputValue(val)
            if (!validateValue(val))
              onChange(val);
          }}
          onKeyPress={e => {
            if (e.key === 'Enter')
              e.currentTarget.blur()
          }}
          value={displayValue}
          ref={ref}
          step={step}
          type={type}
          spellCheck={spellCheck === true}
          autoFocus={autoFocus}
          placeholder={placeholder} />

        { nullable && inputValue !== null && (
          <span
            className="remove-value"
            onClick={_ => { setInputValue(null); onChange(); }}>
            <Cross2Icon />
          </span>
        )}
      </div>

      {isInvalid && (
        <Popover
          className="text-xs py-1 px-2 border-rim border bg-paper shadow-sm flex gap-2"
          isOpen={true}
          triggerRef={ref}
          isNonModal={true}
          placement="bottom">
            <div className="text-red-500">{isInvalid}</div>
            <Button className="cancel" onPress={_ => setInputValue(value)}>
              <Cross2Icon className="inline" />
            </Button>
        </Popover>
      )}
    </TextField>
  )
}


export default TextInput;