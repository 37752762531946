import BaseController from 'lib/BaseController';
import { throttle } from 'lodash';
import startDragging from 'lib/startDragging';


export default class Selector extends BaseController {
  constructor(grid) {
    super();
    this.grid = grid;
    this.state = this.state.merge({
      x0: 0, y0: 0,
      dx: 0, dy: 0,
      dragging: false,
      type: null
    });
  }

  startDrag(e, x, y) {
    const
      { camera, layout } = this.grid;
      // rect = e.currentTarget.getBoundingClientRect(),
      // x = e.clientX - rect.left - camera.x,
      // y = e.clientY - rect.top - camera.y;

    this.setState({ dragging: true, x: x, y: y, dx: 0, dy: 0, });

    // e.stopPropagation();
    e.preventDefault();
    layout.selection.setActiveElementId(null);
    layout.selection.focusPage();
//    this.lockSelected = layout.selection.state.get('selectedElements');

    startDragging({
      event: e,
      camera: camera,
      onMove: (e, dx, dy) => {
        this.setState({ dx: dx, dy: dy });
        this.updateDrag_();
      },
      onEnd: () => this.setState({ dragging: false, type: null })
    });
  }

  updateDrag = () => {
    const
      { state } = this,
      x = state.get('x'), y = state.get('y'),
      dx = state.get('dx'), dy = state.get('dy'),
      x0 = Math.min(x, x + dx),
      y0 = Math.min(y, y + dy),
      x1 = Math.max(x, x + dx),
      y1 = Math.max(y, y + dy);

    this.selectElements(x0, y0, x1, y1);
  }
  updateDrag_ = throttle(this.updateDrag, 20);


  selectElements = (x0, y0, x1, y1) => {
    const
      { grid } = this,
      { selection } = grid.layout,
      elements = grid.grid.get('elements'),
      { cellSize, cellGap } = grid;

    var { selectedElements } = selection;

    elements.forEach((element) => {
      const
        { id, x, y, w, h } = element.toJS(),
        bx0 = x * (cellSize.width + cellGap.x),
        by0 = y * (cellSize.height + cellGap.y),
        bx1 = (x + w) * (cellSize.width + cellGap.x) - cellGap.x,
        by1 = (y + h) * (cellSize.height + cellGap.y) - cellGap.y;

      if (
        (x1 > bx0 && bx1 > x0) &&
        (y1 > by0 && by1 > y0)
      ) {
        selectedElements = selectedElements.add(id);
      } else {
        selectedElements = selectedElements.delete(id);
      }
    });

    if (selection.selectedElements !== selectedElements) {
      selection.setSelectedElements(selectedElements);
    }
  }
}
