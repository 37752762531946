import Code from './Code';
import Text from './Text';
import WebRunner from './WebRunner';
import BoxRunner from './BoxRunner';
import Excalidraw from './Excalidraw';
import Images from './Images';
import Iframe from './Iframe';
import Table from './Table';
import File from './File';

export const list = [
  Text,
  Code,
  Excalidraw,

  WebRunner,
  BoxRunner,
  Images,

  Iframe,
  Table,
];

const index = {
  'code': Code,
  'text': Text,
  'text-v2': Text,
  'web-runner': WebRunner,
  'box-runner': BoxRunner,
  'excalidraw': Excalidraw,
  'iframe': Iframe, 
  'gallery': Images,
  'images': Images,
  'table': Table,
  'file': File,
}

list.forEach(item => {
  if (index[item.type] !== item)
    throw (new Error('type mismatch. ' + item.type));
});

export default index;