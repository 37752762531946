import BaseController from 'lib/BaseController';
import {
  signIn, signOut, createUser, signInWithGoogle,
  updateProfile, sendForgotPasswordEmail, sendEmailVerification,
  changePassword, changeEmail, findProvider, onAuthStateChanged,
  getCurrentUser
} from 'api/remote/calls/auth';
import { onUserInfo, updateUserInfo } from 'api/remote/calls/user';
import { fetchUserDomains, createDomain } from 'api/remote/calls/domain';
import { checkAvailable } from 'api/remote/calls/path';

class Auth extends BaseController {
  constructor() {
    super();
    onAuthStateChanged(this.updateUser);
  }

  get user() { return this.state.get('user'); }
  signIn = signIn;
  signOut = signOut;
  signInWithGoogle = signInWithGoogle;
  createUser = async (name, email, password) => {
    if (!name || !email)
      throw new Error('The name and email field shouldn\'t be empty');
    const response = await createUser(email, password);
    if (response.operationType === 'signIn') {
      await this.updateProfile({ displayName: name });
      await this.refreshUser();
    }
  };
  sendForgotPasswordEmail = sendForgotPasswordEmail;
  changePassword = changePassword;
  findProvider = findProvider;
  sendEmailVerification = sendEmailVerification;
  updateProfile = updateProfile;

  changeEmail = (args) => changeEmail(args).then(this.refreshUser);
  refreshUser = () => this.updateUser(getCurrentUser());

  updateUser = (user) => {
    this.setState({
      user: user && ({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        emailVerified: user.emailVerified
      }),
      loaded: true
    });
  }

  loadUserDomains = async () => {
    this.setState({ domainsLoading: true })
    try {
      const domains = await this.fetchUserDomains();
      this.setState({
        domains,
        domainsLoadingError: false,
        domainsLoading: false
      });
      return domains;
    } catch(e) {
      console.error(e);
      this.setState({
        domainsLoadingError: true,
        domainsLoading: false
      });
    }
  }

  fetchUserDomains = () => fetchUserDomains({ userId: this.user && this.user.uid });

  listenToUserInfo = (userId, cb, ecb) => {
    if (!userId)
      return;
    
    return onUserInfo(
      userId,
      (data) => {
        this.setState({ userInfo: data, userInfoLoadingError: false });
      },
      (e) => {
        console.error(e);
        this.setState({ userInfoLoadingError: true });
      });
  }

  updateUserInfo = async (data) => {
    return updateUserInfo({ userId: this.user.uid, data: data });
  }

  createDomain = ({ slug, title, isPrimary }) => createDomain({ slug, title, isPrimary });
  checkPathAvailable = checkAvailable;

  getDomainOrder = () => {
    const
      userInfo = this.state.get('userInfo'),
      domains = this.state.get('domains');

    if (!userInfo || !domains)
      return null;

    let
      domainIds = domains.map(d => d.id),
      domainOrder = userInfo.domainOrder || [];

    // remove that's not there
    domainOrder = domainOrder.filter(d => domainIds.indexOf(d) !== -1);

    // add that's not there
    domainIds.forEach(d => {
      if (domainOrder.indexOf(d) === -1)
        domainOrder.push(d);
    })
    return domainOrder;
  }

  saveDomainOrder = (domainOrder) => {
    this.updateUserInfo({ domainOrder });
  }

  // fetchThumbnailIcon(t40) {
  //   if (!t40)
  //     this.setState({ thumbnailUrl: null });
  //   else 
  //     storage.ref().child(t40).getDownloadURL().then(l => this.setState({
  //       thumbnailUrl : l
  //     }));
  //   this.thumbnailIconPath = t40;
  // }
}

export default Auth;