import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';

import View from './View';


class CreateVersion extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  constructor(args,manager) {
    super(args, manager);

    this.articleController = args.articleController;
    this.provider = args.articleController.provider;
    this.state = this.state.merge({
      label: 'Updates - ' + (new Date()).toLocaleDateString(),
      slug: (new Date()).toLocaleDateString().replaceAll('/', '-'),
      isPublic: true,
      notes: '',
      parentPath: args.articleController.state.get('article').get('path')
    });
  }

  async createVersion() {
    const
      articleId = this.args.articleController.id,
      domainId = this.args.articleController.state.getIn(['article', 'domainId']),
      { label, slug, notes, isPublic } = this.state.toJS();

    this.setState({ loading: true, alert: '' });
    try {
      await this.provider.createVersion({ articleId, domainId, label, slug, notes, isPublic });
      this.close();
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }
}

export default CreateVersion;