import BaseController from 'lib/BaseController';
import { toHuman } from 'lib/firebaseError';
import FolderController from 'modules/folder/controllers/Folder';
import DomainController from 'modules/domain/controllers/Domain';


export default class Page extends BaseController {
  constructor({ app }) {
    super();
    this.app = app;
    this.popupManager = app.popupManager;
    this.folderController = new FolderController({ app });
    this.domainController = new DomainController({ app });
    this.state = this.state.merge({ error: null, path: null, pathData: null });
  }

  loadPath = (path) => {
    console.log('loading workspace path', path);
    this.setState({ error: null });
    this.tryLoadPath(path).catch(e => this.setState({ error: toHuman(e) }));
  }

  tryLoadPath = async (path) => {
    if (this.state.get('path') === path) {
      console.log('already loaded.');
      return;
    }
    
    const
      folderPath = path.replaceAll('/', '\\'),
      domainSlug = path.split('/')[0],
      provider = (domainSlug === 'local' ? this.app.localProvider : this.app.remoteProvider),
      pathData = await provider.fetchPath(folderPath);

    if (!pathData || pathData.type !== 'folder')
      throw new Error('Resource not found.');

    const { domainId, folderId } = pathData;
    this.setState({ path, pathData });

    this.domainController.load({ provider, domainId, slug: domainSlug });
    this.folderController.load({ provider, domainId, folderId });
  }

  unload = () => {
    console.log('unloading workspace')
    this.domainController.unload();
    this.folderController.unload();
    this.setState({ path: null });
  }
}