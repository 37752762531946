import { Button as AriaButton } from 'react-aria-components';


export function Group({ children, title }) {
  return (
    <div className="my-4">
      { children }
    </div>
  )
}

export function Label({ children }) {
  return (<label className="text-sm mb-4 font-medium">{children}</label>);
}

export function LinkButton({ children, onPress }) {
  return (
    <AriaButton className="float-right text-sm font-medium underline outline-none" onPress={onPress}>
      {children}
    </AriaButton>
  );
}