import { BarButton } from './res/components';
import { FileIcon } from '@radix-ui/react-icons';
import { Disk2Icon } from './res/icons';


export default function ArticleButtons({ editor }) {
  const { articleController } = editor;

  return (
    <>
      <BarButton
        Icon={FileIcon}
        tooltip="Upload Files"
        label="upload"
        isDisabled={!articleController}
        fn={articleController && articleController.popups.uploadFilesPopup} />

      <BarButton
        Icon={Disk2Icon}
        tooltip="Save"
        label="Save"
        isDisabled={!articleController}
        fn={articleController && articleController.popups.expressVersionPopup} />
    </>
  );
}