import { useRef } from 'react';
import Single from './Single';
import Double from './Double';
import clsx from 'clsx';


export default function Workspace({ viewer }) {
  const
    { domain, themeData } = viewer.workspace,
    Component = themeData.getValue('coverPageLayout') === 'double' ? Double : Single,
    prevScrollTop = useRef(0),
    hidden = viewer.state.get('type') !== 'folder';


  return (domain && (
    <div
      className={clsx('pt-20 pb-20 px-4 h-full overflow-auto nb-viewer-theme', hidden && 'hidden')}
      onScroll={e => {
        const scrollTop = e.currentTarget.scrollTop;
        viewer.onArticleScroll(scrollTop, scrollTop - prevScrollTop.current);
        prevScrollTop.current = e.currentTarget.scrollTop;
      }}>
      <Component viewer={viewer} />
    </div>
  ));
}