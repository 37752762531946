import clsx from 'clsx';
import CommandInput from './CommandInput';
import Output from './Output';

import './style.css';


export function BoxRunner({ controller, isGrid }) {
  return (
    <div
      className={clsx(
        'nb-box-runner font-mono text-sm relative border border-rim bg-paper flex flex-col',
        isGrid && 'flex-grow overflow-hidden'
      )}
      onClick={controller.activate}
      style={{
        // backgroundImage: `url("${bg}")`,
        // backgroundRepeat: 'repeat',
      }}>
      <CommandInput controller={controller} />
      <Output controller={controller} isGrid={isGrid} />
    </div>
  )
}

export default BoxRunner;