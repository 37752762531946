import ui from 'lib/popup/components/ui';
import { useControllerState } from 'lib/BaseController';

import Auth from './Auth';
import Machine from './Machine';

import './style.css';


function Popup({ popup }) {
  useControllerState(popup);
  useControllerState(popup.machine);

  return (
    <ui.Popup popup={popup} className="machine-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>Machine</ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          {
            popup.machine.state.get('status') === 'connected' ?
              <Machine popup={popup} /> :
              <Auth popup={popup} />
          }
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;