import { useState } from 'react';
import { ui } from 'lib/popup';
import { Buttons, Button } from 'lib/inputs/buttons';
import { Button as AriaButton } from 'react-aria-components';
import { useControllerState } from 'lib/BaseController';
import { FileTextIcon, TriangleRightIcon, TriangleDownIcon } from '@radix-ui/react-icons';

import './style.css';


function View({ popup }) {
  const
    state = useControllerState(popup),
    loading = state.get('loading'),
    upload = popup.args.upload,
    usedBy = state.get('usedBy'),
    hardDelete = popup.args.hardDelete || (usedBy && usedBy.length === 0);

  return (
    <ui.Popup popup={popup} className="delete-file-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Delete File
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.deleteUpload(hardDelete); }}>
            <ui.Warning title="Delete this file?">
              {
                !usedBy ? '...' :
                popup.args.hardDelete ? (
                  <>
                    You are about to delete this file completely. <br /><br />
                    <span className="font-bold">Warning:</span> The
                    file will be removed permanently from all versions that use it.
                    This could be unsafe.
                  </>
                ):
                usedBy.length === 0 ? ( <>
                  You are about to delete this file completely. <br />
                  Are you sure you want to proceed?
                </> ) : ( <>
                  This file won't be deleted from the other versions
                  that use it. To remove this file completely you must:
                  <ul>
                    <li>Delete all of those versions, or</li>
                    <li>Hard delete this file next</li>
                  </ul>
                </> )
              }
            </ui.Warning>

            { usedBy && usedBy.length > 0 && (
              <UsedBy versions={usedBy} />
            ) }

            <div className="filename font-mono py-2 px-5 text-sm bg-wax2">
              <FileTextIcon className="inline-block" /> { upload.get('filename') }
            </div>

            <ui.Alert />

            <Buttons className="text-right">
              <Button isDisabled={loading || !usedBy} type="submit">{popup.args.hardDelete ? 'Hard ' : ''}Delete</Button>
              <Button onPress={e => { popup.close() }}>Cancel</Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  );
}

function UsedBy({ versions }) {
  const
    [expanded, setExpanded] = useState(false),
    Icon = expanded ? TriangleDownIcon : TriangleRightIcon;

  return (
    <AriaButton
      className="font-mono text-xs border px-2 py-1 my-4 outline-none  w-full text-left"
      onPress={() => setExpanded(!expanded)}>
      <div className="flex">
        <div className="flex-grow">This file is used by versions</div>
        <Icon className="" />
      </div>
      { expanded && (
        <div className="mt-1">
          {versions.map(v => (
            <div className="bg-wax2 inline-block mr-2 my-1 px-1" key={v.versionSlug}>
              { v.slug }
            </div>
          ))}
        </div>
      )}
    </AriaButton>
  )
}

export default View;