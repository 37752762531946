import {
  FONT_OPTIONS, CURSIVE_FONT_OPTIONS,
  LINE_SPACING_OPTIONS,
  FONT_SIZE_OPTIONS,
  FONT_WEIGHT_OPTIONS } from '../../defaults/fonts';
import { TITLE_ALIGNMENT_OPTIONS, PAGE_WIDTH_OPTIONS } from '../../defaults/page';

import { getGetOptionsFnWithDefaults } from 'modules/elements/lib/helpers';
import { Block, Break } from 'modules/elements/lib/sidebar/inputs/components';
import Selection from 'modules/elements/lib/sidebar/inputs/Selection';
import ColorInput from 'modules/elements/lib/sidebar/inputs/ColorInput';
// import TextInput from 'modules/elements/lib/sidebar/inputs/TextInput';


export default function Text({ themeController }) {
  const
    { themeData } = themeController,
    { getDefault, getData, setData, setDataDebounced } = themeData;

  return (
    <>
      <Block>
        <Selection
          label="Header Font"
          value={getData('headerFont')}
          options={getGetOptionsFnWithDefaults(FONT_OPTIONS)({ valueOnNull: getDefault('headerFont') })}
          onChange={v => setData({ headerFont: v })} />
      </Block>

      <Block>
        <Selection
          label="Primary Font"
          value={getData('font')}
          options={getGetOptionsFnWithDefaults(FONT_OPTIONS)({ valueOnNull: getDefault('font') })}
          onChange={v => setData({ font: v })} />
      </Block>

      <Block>
        <Selection
          label="Header Font Weight"
          value={getData('headerFontWeight')}
          options={getGetOptionsFnWithDefaults(FONT_WEIGHT_OPTIONS)({ valueOnNull: getDefault('headerFontWeight') })}
          onChange={v => setData({ headerFontWeight: v })} />
      </Block>      

      <Block>
        <Selection
          label="Cursive Font"
          value={getData('cursiveFont')}
          options={getGetOptionsFnWithDefaults(CURSIVE_FONT_OPTIONS)({ valueOnNull: getDefault('cursiveFont') })}
          onChange={v => setData({ cursiveFont: v })} />
      </Block>
      <Break />

      <Block span="half">
        <Selection
          label="Font Size"
          value={getData('fontSize')}
          options={getGetOptionsFnWithDefaults(FONT_SIZE_OPTIONS)({ valueOnNull: getDefault('fontSize') })}
          onChange={v => setData({ fontSize: v })} />
      </Block>

      <Block span="half">
        <Selection
          label="Line Spacing"
          value={getData('lineSpacing')}
          options={getGetOptionsFnWithDefaults(LINE_SPACING_OPTIONS)({ valueOnNull: getDefault('lineSpacing') })}
          onChange={v => setData({ lineSpacing: v })} />
      </Block>

      <Break />
      
      <Block span="half">
        <Selection
          label="Page Width"
          value={getData('pageWidth')}
          options={getGetOptionsFnWithDefaults(PAGE_WIDTH_OPTIONS)({ valueOnNull: getDefault('pageWidth') })}
          onChange={v => setData({ pageWidth: v })} />
      </Block>      

      <Block span="half">
        <Selection
          label="Title Alignment"
          value={getData('titleAlignment')}
          options={getGetOptionsFnWithDefaults(TITLE_ALIGNMENT_OPTIONS)({ valueOnNull: getDefault('titleAlignment') })}
          onChange={v => setData({ titleAlignment: v })} />
      </Block>


{/*
      <Block span="half">
        <TextInput
          label="Page Max Width"
          type="number"
          unit="px"
          nullable={true}
          value={getData('pageMaxWidth')}
          valueOnNull={getDefault('pageMaxWidth')}
          onChange={v => setData({ pageMaxWidth: v })}
          validateFn={
            v => (
              isNaN(v) ?
                'Enter a valid number' :
                  v < 400 ?
                    'Max width should be greater than 400' :
                    v > 1200 ? 'Max width should be less than 1200' :
                false
          )} />
      </Block>*/}

      <Break />

{/*      <Block span="half">
        <ColorInput
          label="Font Color"
          nullable={true}
          valueOnNull={getDefault('color')}
          value={getData('color')}
          onChange={v => setDataDebounced({ color: v })} />
      </Block>

      <Block span="half">
        <ColorInput
          label="Background Color"
          nullable={true}
          valueOnNull={getDefault('backgroundColor')}
          value={getData('backgroundColor')}
          onChange={v => setDataDebounced({ backgroundColor: v })} />
      </Block>*/}

      <Block span="half">
        <ColorInput
          label="Link Color"
          nullable={true}
          valueOnNull={getDefault('linkColor')}
          value={getData('linkColor')}
          onChange={v => setDataDebounced({ linkColor: v })} />
      </Block>
{/*
      <Block span="half">
        <ColorInput
          label="Border Color"
          nullable={true}
          valueOnNull={getDefault('borderColor')}
          value={getData('borderColor')}
          onChange={v => setDataDebounced({ borderColor: v })} />
      </Block>*/}
    </>
  )
}