import { updateExample, addExample, updateDemo, setDemo } from 'api/public';

import Prompt from 'lib/popup/popups/Prompt/Controller';
import RichText from 'lib/popup/popups/RichText/Controller';


function examplePopup(app, f, cb) {
  app.popupManager.open(
    Prompt,
    {
      title: 'Examples',
      inputs: [
        { label: 'Title', type: 'text', defaultValue: f.title || '', autoFocus: true },
        { label: 'Link', type: 'text', defaultValue: f.link || '' },
        { label: 'Description', type: 'textarea', defaultValue: f.description || '' },
        { label: 'Order', type: 'number', defaultValue: f.order || '' },
      ],
      onSubmit: cb
  });
}

export async function addExamplePopup(app, cb) {
  examplePopup(app, {}, ([title, link, description, order]) => {
    addExample({ title, link, description, order });
    cb();
  });
}

export async function editExamplePopup(example, app, cb) {
  examplePopup(app, example, ([title, link, description, order]) => {
    updateExample(example.id, { title, link, description, order });
    cb();
  });
}

function getDateId() {
  var d = new Date();
  return ((d.getYear() - 100) * 10000 + d.getMonth() + 100 * d.getDay());
}


export async function editDemoPopup(demo, app, updateCb) {
  const isNew = !demo.id;
  app.popupManager.open(
    Prompt,
    {
      title: 'Edit Demo',
      inputs: [
        { label: 'Id (Date)', type: 'text', defaultValue: demo.id || getDateId(), disabled: !isNew },
        { label: 'Title', type: 'text', defaultValue: demo.title || '', autoFocus: true },
        { label: 'Link', type: 'text', defaultValue: demo.link || '', },
        { label: 'Target', type: 'text', defaultValue: demo.target || '' },
        { label: 'Thumbnail Path', type: 'text', defaultValue: demo.thumbnailPath || '' },
      ],
      onSubmit: ([id, title, link, target, thumbnailPath]) => {
        (isNew ? setDemo : updateDemo)(`${id}`, { title, link, target, thumbnailPath });
        updateCb();
      }
    });
}

export async function editTextPopup(demo, app, key, updateCb) {
  app.popupManager.open(RichText, {
      title: 'Edit ' + key,
      text: demo[key],
      onSave: (text) => {
        updateDemo(demo.id, { [key]: text });
        updateCb()
      },
      format: 'html'
    }
  );
}