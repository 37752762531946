import FAIcon from 'lib/Icon';
import clsx from 'clsx';
import { Button, TooltipTrigger, Tooltip } from 'react-aria-components';


export function BarButton({ iconType, faIcon, Icon, fn, tooltip, label, isActive, isDisabled }) {
  return (
    <TooltipTrigger placement="bottom" delay={100}>
      <Button
        className={({ isDisabled }) => clsx(
          'px-1 py-1.5 text-sm w-8 text-center outline-none rounded',
          isActive && 'bg-wax2 dark:bg-gray-800',
          isDisabled && 'opacity-50'
        )}
        aria-label={label}
        onPress={fn}
        isDisabled={isDisabled}>
        { (iconType || faIcon) && <FAIcon type={iconType} faIcon={faIcon} /> }
        { Icon && <Icon className="inline-block w-4 h-4" /> }
      </Button>
      <Tooltip className="text-sm bg-paper p-1 px-3 border border-rim" >{tooltip}</Tooltip>
    </TooltipTrigger>
  )
}

export function Break({ verticle }) {
  return (
    verticle ?
      (<div className="border-t border-rim pt-1 mt-1 w-4"></div>) :
      (<div className="border-r border-rim pr-1 mr-1 h-4"></div>)
  );
}