import clsx from 'clsx';
import './style.css';

function Editor({ controller, isActive, isGrid }) {
  return (
    <div className={clsx('nb-iframe-editor relative', isGrid && ' is-grid')}>
      <Iframe controller={controller} isGrid={isGrid} />
      {!isActive && !controller.isReadOnly && (
        <div className="w-full h-full top-0 left-0 absolute" onClick={controller.activate} />
      )}
    </div>
  );
}

export function Iframe({ controller, isGrid }) {
  const
    { getSettingsValue } = controller,
    heightScale = getSettingsValue('heightScale'),
    aspectRatio =getSettingsValue('aspectRatio'),
    width = getSettingsValue('maxWidth'),
    height = getSettingsValue('height'),

    paddingTop = (
      heightScale === 'aspect' ? 
        (100 / aspectRatio) + '%':
        heightScale === 'proportional' ? 
          (100 * height/ width) + '%' :
            height + 'px'
    );

  return (
    <div className="nb-iframe">
      <div
        className="width-container w-auto"
        style={isGrid ? { height: '100%' } :{
          maxWidth: width + 'px',
          width: getSettingsValue('percentWidth') + '%'
        }}>
        <div
          className="height-container relative"
          style={isGrid ? { height: '100%' } : { paddingTop: paddingTop }}>
          <div className="iframe-container w-full h-full absolute top-0 left-0 bg-striped">
            { getSettingsValue('embedUrl') && (
              <iframe
                title={'iframe' + Math.random()}
                src={getSettingsValue('embedUrl')}
                frameBorder="0"
                allowtransparency="true"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );  
}

export default Editor;