class KeyManager {
  constructor(editor) {
    this.editor = editor;
  }

  handleInputKeyDown = (e) => {
    const
      key = e.key,
      { selection } = this.editor;

    var handled = true;
    if (key === 'Meta') {
      this.isMetaPressed = true;
    } else if (key === 'Control') {
      this.isControlPressed = true;
    } else if (key === 'Shift') {
      this.isShiftPressed = true;
    }

    const
      shift = this.isShiftPressed,
      ctrl = this.isMetaPressed || this.isControlPressed,
      factor = ctrl ? Infinity : 1;

    const arrowMove = shift ? selection.moveSelectionEnd : selection.moveSelection;

    if (key === 'ArrowUp') {
      arrowMove(-1 * factor, 0);
    } else if (key === 'ArrowDown') {
      arrowMove(1 * factor, 0);
    } else if (key === 'ArrowLeft') {
      arrowMove(0, -1 * factor);
    } else if (key === 'ArrowRight') {
      arrowMove(0, 1 * factor);
    } else if (key === 'ArrowRight') {
      arrowMove(0, 1 * factor);
    } else if (key === 'Enter') {
      this.editor.startEditingCell();
    }  else if (ctrl && (key === 'Z' || key === 'z')) {
      if (shift)
        this.editor.data.redo();
      else
        this.editor.data.undo();
    }  else if (ctrl && (key === 'C' || key === 'c')) {
      this.editor.data.copyToClipboard(e)
    }  else if (ctrl && (key === 'X' || key === 'x')) {
      this.editor.data.cutToClipboard(e);
    }  else if (ctrl && (key === 'V' || key === 'v')) {
      this.editor.data.pasteFromClipboard(e);
    } else if (key === 'Backspace') {
      this.editor.data.deleteSelected();
    } else {
      handled = false;
    }

    if (handled) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  handleInputKeyUp = (event) => {
    const key = event.key;
    if (key === 'Meta') {
      this.isMetaPressed = false;
    } else if (key === 'Control') {
      this.isControlPressed = false;
    } else if (key === 'Shift') {
      this.isShiftPressed = false;
    }
  }

  handleBlur = () => {
    this.isMetaPressed = false;
    this.isControlPressed = false;
    this.isShiftPressed = false;
  }
}

export default KeyManager;