import Policies from './Policies';
import Controller from './Controller';
import { faFile } from '@fortawesome/free-solid-svg-icons';

const index = {
  type: 'file',
  caption: 'File',
  faIcon: faFile,
  description: 'File',
  Policies: Policies,
  Controller: Controller,
}

export default index;