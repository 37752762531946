import { Group, Label, LinkButton } from './common';


export default function Preferences({ controller }) {
  const domain = controller.state.get('domain');

  return domain && (
    <div className="px-8 py-6">
      <div className="font-medium mb-4 text-center text-2xl">Preferences</div>
      <hr className="mb-4" />

{/*      <Group>
        <div className="max-w-[10rem] m-auto">
          <ImageInput controller={controller} />
        </div>
      </Group>*/}

      <Group>
        <Label>Title</Label> <br/>
        <LinkButton onPress={controller.popups.editTitlePopup}>
          Change
        </LinkButton>

        {domain.get('title')}
      </Group>

      <Group>
        <Label>Slug</Label> <br/>
        <LinkButton onPress={controller.popups.renameSlugPopup}>
          Change
        </LinkButton>

        <div className="font-mono text-sm">{domain.get('slug')}</div>
      </Group>
    </div>
  )
}