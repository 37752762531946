import { uniqueId } from 'lodash';
import { Map } from 'immutable';
import BaseController from '../BaseController';


export default class BasePopupController extends BaseController {
  position = new Map({ width: 320, height: 420 });
  placement = 'top';
  autoHeight = false;
  closeButtonClicked = false;

  constructor(args, manager) {
    super();
    this.id = uniqueId('popup');
    this.args = args;
    this.manager = manager;
    this.unloadFns = [ this.manager.subscribeKey(this.id, this.handleKey) ];
  }

  initializePosition = () => {
    let
      width = this.position.get('width'),
      height = this.position.get('height'),
      centerH = width ? (window.innerWidth - width) / 2 : 0,
      centerV = height ? (window.innerHeight - height) / 2 : 0,
      right = width ? (window.innerWidth - width - 10) : 0,
      bottom = height ? (window.innerHeight - height - 10) : 0;

    let top, left;

    if (this.placement === 'top') {
      left = centerH;
      top = 80;
    } else if (this.placement === 'top-right') {
      left = right;
      top = 80;
    } else if (this.placement === 'bottom-right') {
      left = right;
      top = bottom;
    } else {
      left = centerH;
      top = centerV;
    }
    let offset = this.manager.popups.size * 15;

    left += offset;
    top += offset;
    this.setPosition({ left, top });
  }


  handleKey = (e) => {
    if (e.type === 'keydown' && e.code === 'Escape') {
      this.manager.closeById(this.id);
    }
  }

  close = () => {
    this.manager.closeById(this.id);
  }
  
  setPosition = (s) => {
    this.position = this.position.merge(Map(s));
    this.emitter.emit('change');
  }

  bringToFront = () => {
    this.manager.bringToFront(this);
  }

  setObservedRect = (rect) => {
    this.observedRect = rect;
    if (!this.position.get('width') && !this.position.get('height')) {
      this.position = this.position.merge({ width: rect.width, height: rect.height });
      this.initializePosition();
    }
  }


  startDrag = e1 => {
    const onMouseMove = e => {
      if (!this.observedRect)
        return;
      const
        { position } = this,
        width = this.observedRect.width,
        height = this.observedRect.height,
        dx = position.get('left') + e.pageX - position.get('startX'),
        dy = position.get('top') + e.pageY - position.get('startY'),
        dragX = Math.min(Math.max(dx, 0), window.innerWidth - width),
        dragY = Math.min(Math.max(dy, 0), window.innerHeight - height);

      this.setPosition({ dragX, dragY });
    }

    const stopDrag = _ => {
      this.setPosition({
        dragging: false,
        left: this.position.get('dragX'),
        top: this.position.get('dragY')
      });
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', stopDrag);
    }

    this.setPosition({
      dragging: true,
      dragX: this.position.get('left'),
      dragY: this.position.get('top'),
      startX: e1.pageX,
      startY: e1.pageY
    });
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', stopDrag);
  }

  handleWindowResize = () => {
    if (!this.observedRect)
        return;

    const
      p = this.position,
      width = this.observedRect.width,
      height = this.observedRect.height,
      left = Math.max(0, Math.min(p.get('left'), window.innerWidth - width - 5)),
      top = Math.max(0, Math.min(p.get('top'), window.innerHeight - height - 5));

    if (left !== p.get('left') || top !== p.get('top'))
      this.setPosition({ left, top });
  }

  unload = () => {
    this.unloadFns.forEach(fn => fn());
    this.unloadFns = [];
    this.unloaded = true;
  }
}