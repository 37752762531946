import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { useControllerState } from 'lib/BaseController';
import { ErrorBlock } from 'lib/staticPages';
import Article from './Article';
import Cover from './Cover';
import Container from 'modules/app/views/Home/Container';
import Styler from 'modules/theme/views/Styler';
import Wrapper from './wrappers/Wrapper';


export default function Page({ app }) {
  const
    { viewer } = app,
    match = useMatch({ path: '*' }),
    path = match.params['*'],
    state = useControllerState(viewer),
    type = state.get('type');

  useEffect(() => viewer.loadPath(path), [path, viewer]);
  useEffect(() => viewer.unload, [viewer]);

  if (state.get('error')) {
    return (
      <Container app={app}>
        <ErrorBlock title="Loading Error" error={state.get('error')} className="pt-10" />
      </Container>
    );
  }

  return type && (
    <Wrapper viewer={viewer}>
      <Styler themeData={viewer.workspace.themeData} baseClass="nb-viewer-theme" />
      <Article viewer={viewer} />
      <Cover viewer={viewer} />
    </Wrapper>
  )
}