const templates = [
  { path: 'templates/tools', caption: 'Python Module' },
  { path: 'templates/js-app', caption: 'React App' },
  { path: 'templates/react', caption: 'JavaScript Module' },
  { path: 'templates/travel', caption: 'Travel Blog' },
  { path: 'templates/news', caption: 'News' },
  { path: 'templates/research', caption: 'Research Paper' },
  { path: 'templates/challenge', caption: 'Programming Challenge' },
];

export default templates;