import { Pencil1Icon } from '@radix-ui/react-icons'
import { Button } from 'react-aria-components';

export default function EditButton({ onPress }) {
  return (
    <Button
      className="edit-button absolute top-2 right-2 text-lg bg-paper border border-rim3 p-1 hidden group-hover:block outline-none"
      onPress={onPress}>
      <Pencil1Icon />
    </Button>
  );
}