import { CheckIcon } from '@radix-ui/react-icons';
import { Label } from './components';
import clsx from 'clsx';


export default function Checkbox({ onChange, value, defaultValue, isDisabled, label }) {
  value = (value === null || value === undefined) ? defaultValue : value;

  return (
    <div
      onMouseDown={e => {
        e.stopPropagation();
        e.preventDefault();
        if (onChange)
          onChange(!value)
      }}>
      <Label>{label}</Label>
      <div
        className={clsx(
          'w-4 h-4 bg-wax2 mt-1 ml-2 cursor-pointer',
          isDisabled && 'pointer-events-none'
        )}>
        { value && <CheckIcon />}
      </div>
    </div>
  )
}