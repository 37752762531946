import { DOMSerializer } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';

import { useRef, useEffect } from 'react';
import { schema } from '../schema.ts';
import { defaultMarkdownParser } from './from.ts';
import { defaultMarkdownSerializer } from './to.ts';
import { getPlugins } from '../plugins/index.ts'



export function markdownToState(text) {
  return EditorState.create({
    doc: defaultMarkdownParser.parse(text),
    plugins: getPlugins()
  });
}

export function stateToMarkdown(state) {
  return defaultMarkdownSerializer.serialize(state.doc);
}


export function markdownToHtml(text) {
  const
    doc = defaultMarkdownParser.parse(text).content,
    dom = DOMSerializer.fromSchema(schema).serializeFragment(doc);
  return dom;
}

export function Markdown({ text }) {
  const ref = useRef();

  useEffect(_ => {
    const div = ref.current;
    if (div) {
      div.innerHTML = '';
      if (text) {
        div.appendChild(markdownToHtml(text));
      }
    }
  }, [text]);

  return (<div ref={ref}></div>);
}