import { GridListItem, Button } from 'react-aria-components';
import {
  BoxIcon, GridIcon,
  FileIcon, CardStackIcon,
  DragHandleDots2Icon,
  DotFilledIcon, Cross2Icon,
  GlobeIcon, EyeNoneIcon
} from '@radix-ui/react-icons';

import Tooltipped from 'lib/inputs/Tooltipped';
import { getSectionOptions } from './options/section';
import { getFolderOptions } from './options/folder';
import { getArticleOptions } from './options/article';
import MenuBuilder from 'lib/components/MenuBuilder';
import clsx from 'clsx';
import './Item.css';


export default function Item({ item, controller, compact, isActive, isOpen, onPressClose }) {
  let
    { id, type } = item,
    { state } = controller,
    { isOffline } = controller.provider,
    entry = (
      type === 'folder' ?
        state.get('childrenFolders').find(d => d.get('id') === id) :
        type === 'article' ?
          state.get('childrenArticles').find(d => d.get('id') === id) :
          null
    ),
    icon = (
      type === 'folder' ? <CardStackIcon className="inline-block text-marker" /> :
        type ==='article' ? <FileIcon className="inline-block" /> : null
    ),

    menuButtonCls = 'px-2 hover:bg-wax2';

  return (
    <GridListItem
      id={item.id}
      className={({ isSelected, isFocused }) => clsx(
        'nb-folder-list-item flex cursor-pointer p-1 outline-none group text-pencil items-start',
        compact && 'text-sm',
        type === 'section' && 'pt-2',
        isSelected && 'font-medium',
        isFocused ? 'bg-wax' : isSelected ? 'bg-wax' : '',
        // type === 'section' && 'mt-2 pt-2 border-t border-rim first: border-pt-0 first:border-t-0 first:mt-0'
        // type === 'section' && 'pt-3 border-b border-rim mb-2'
        type === 'section' && '[&:not(:first-child)]:mt-2'
      )}
      // href={entry && ((type === 'folder' ? '/workspaces/' : '/editor/') + entry.get('path')) }
      textValue={entry ? entry.get('title') : item.title}>
      <Button slot="drag" className={clsx('inline opacity-20 pt-1.5', compact && 'hidden')}>
        <DragHandleDots2Icon />
      </Button>

      { type === 'folder' || type === 'article' ? (entry && (
        <>
          <div className="inline-block w-6 text-center px-1">
            { icon }
          </div>
          <div className="flex-grow px-1 ">
            <div className={clsx('inline-block mr-3 align-middle')}>
              {entry.get('title')}
            </div>
            <div className={clsx('inline-block font-mono text-xs whitespace-nowrap', compact && 'hidden')}>
              {entry.get('slug')}
            </div>
          </div>
          { type === 'folder' && !entry.get('isPublic') && !isOffline && !compact && (
            <Tag>Private</Tag>
          )}
          { type === 'article' && !isOffline && !compact && (
            <Tag tooltip={entry.get('publishedVersionId') ? 'Published' : 'Not Published'}>
                <GlobeIcon className={clsx(entry.get('publishedVersionId') ? 'opacity-80' : 'opacity-20' )} />
            </Tag>
          )}
        </>
      )) : (
        <>
          <div className="inline-block w-4 text-center"></div>
          <div className="inline-block font-medium flex-grow text-pencil2">
            {item.title || <span className="inline-block text-pencil3 text-sm">No Title</span>}
            {!compact && (
              item.displayMode === 'list' ? (
                // <ListBulletIcon className="inline-block ml-4" />
                  item.isHidden ? <EyeNoneIcon className="inline-block ml-4" /> : ''
                ) :
                item.displayMode === 'tile' ? (<GridIcon className="inline-block ml-4" />) :
                  item.displayMode === 'featured' ? (<BoxIcon className="inline-block ml-4" />) : '' )}
          </div>
        </>
      )}
      
      <div className={clsx('menu', compact && 'hidden')}>
        {
          type === 'folder' ? <MenuBuilder options={getFolderOptions({ controller, folder: entry })} className={menuButtonCls} /> :
            type ==='article' ? <MenuBuilder options={getArticleOptions({ controller, article: entry })}  className={menuButtonCls}/> :
            type ==='section' ? <MenuBuilder options={getSectionOptions({ controller, item })}  className={menuButtonCls}/> : null
        }
      </div>
      { isOpen && (
        <Button
          className={({ isDisabled }) => clsx(isDisabled && 'disabled', 'close-button outline-none')}
          isDisabled={isActive}
          onPress={(e) => onPressClose(id)}>
          <div className="indicator"><DotFilledIcon /></div>
          <div className="close"><Cross2Icon /></div>
        </Button>
      ) }
    </GridListItem>
  );
}

function Tag({ children, tooltip }) {
  return (
    <Tooltipped tooltip={tooltip} isInverted={false}>
      <Button className="text-xs text-pencil3 p-1 mr-3 whitespace-nowrap">
        {children}
      </Button>
    </Tooltipped>
  )
}