import React from 'react';
import clsx from 'clsx';


export default function Tiles({ grid, type }) {
  const
    extra = 4,
    { cellSize, cellGap, dim } = grid,
    { width, height } = cellSize,
    { x, y } = cellGap,
    { rows, cols } = dim;

  if (type === 'grid')
    return (<MemoGrid {...{width, height, x, y, rows, cols}} />);
  
  return (<MemoTiles {...{width, height, x, y, rows, cols, extra, type}} />);
}

const MemoGrid = React.memo(function MemoGrid({ width, height, x, y, rows, cols }) {
  const
    extra = 0,
    top = -extra * (width +  x) - x / 2,
    left = -extra * (height +  y) - y / 2 , 
    fullWidth = (extra + cols) * (width + x),
    fullHeight = (extra + rows) * (height +  y);

  return (
    <div>
      { Array(cols + 1).fill(0).map((r, i) => (
        <div
          style={{ top, height: fullHeight, width: 1, left: i * (width + x) - (x / 2) }}
          className="w-[1px] h-full bg-wax2 dark:bg-wax absolute" key={i}></div>
      ))}

      { Array(rows + 1).fill(0).map((r, i) => (
        <div
          style={{ left, width: fullWidth, top: i * (height + y) - (y / 2) }}
          className="h-[1px] w-full bg-wax2 dark:bg-wax absolute" key={i}></div>
      ))}

    </div>
  )
})




const MemoTiles = React.memo(function MemoTiles({ width, height, x, y, rows, cols, extra, type }) {
  return (
    Array(cols + extra + extra).fill(0).map((r, i0) => (
      <div key={i0}>
        { Array(rows + extra + extra).fill(0).map((c, j0) => {
          const
            i = i0 - extra,
            j = j0 - extra,
            mod = (i + j) % 3,
            isExtra = (i >= cols || i < 0 || j >= rows || j < 0),
            top = j * (height + y),
            left = i * (width + x),
            Comp = type === 'dot' ? Dot : type === 'cell' ? Cell : Freckles;

          return (<Comp key={j0} {...{top, left, width, height, isExtra, mod}} />)
        } ) }
      </div>
    ))
  );
});

function Freckles({ top, left, width, height, isExtra }) {
  const className = clsx(
    'pointer-events-none absolute bg-black w-0.5 h-0.5 rounded-full',
    isExtra ? 'opacity-20' : 'opacity-20'
  );
  return (
    <>
      <div className={className} style={{ top: top, left: left }} />
      <div className={className} style={{ top: top + height, left: left }} />
      <div className={className} style={{ top: top, left: left + width }} />
      <div className={className} style={{ top: top + height, left: left + width }} />
    </>    
  )
}

function Cell({ top, left, width, height, isExtra, mod }) {
  return (
    <div
      className={clsx(
        'pointer-events-none absolute block border border-rim',
        (isExtra ? 'opacity-20' : mod === 2 ? 'opacity-30' : mod === 1 ? 'opacity-50' : 'opacity-70')
      )}
      style={{ top, left, width, height }} />    
  )
}

function Dot({ top, left, width, height, isExtra, mod }) {
  return (
    <div
      className="pointer-events-none absolute bg-black w-[2px] h-[2px] rounded-full opacity-20"
      style={{ top: top, left: left }} />
  )
}