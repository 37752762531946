const getTime = () => (new Date()).getTime();

class History {
  constructor(data) { this.data = data; }
  get editor() { return this.data.editor }
  get history() { return this.editor.state.get('history'); }
  get index() { return this.editor.state.get('historyIndex'); }

  setHistory = (history, historyIndex) => {
    this.editor.setState({ history, historyIndex }, true);
  }

  prevTime = getTime() - 2000;
  push = (dataState) => {
    var { index, history } = this;
    if (index < history.size - 1) {
      history = history.slice(0, this.index + 1);
    }
    if (history.size >= 1000) {
      history = history.unshift();
      index = index - 1;
    }
    if ((getTime() - this.prevTime) < 2000) {
      history = history.pop();
      index = index - 1;
    } else {
      this.prevTime = getTime();
    }
    history = history.push(dataState);
    index += 1;
    this.setHistory(history, index);
  }


  undo() {
    var { history, index } = this;
    if (index > 0) {
      index = index - 1;
      this.setHistory(history, index);
      return history.get(index);
    }
    return null;
  }

  redo() {
    var { history, index } = this;
    if (index < history.size - 1) {
      index += 1;
      this.setHistory(history, index);
      return history.get(index);
    }
    return null;
  }
}

export default History;