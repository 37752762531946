import { fromJS } from 'immutable';
import { initialSettings } from './settingsFields';

class Policies {
  static autoSave = true;

  static getInitialData() {
    return {
      content: null,
      settings: initialSettings
    }
  }

  static dataToState(data, currentState) {
    if (!currentState)
      currentState = fromJS({});
    const { settings, content } = data;
    return currentState.merge(fromJS({ settings, content }));
  }

  static stateToData(state) {
    const { settings, content } = state.toJS();
    return { settings, content };
  }

  static contentHasChanged(newState, oldState) {
    return (
      newState.get('settings') !== oldState.get('settings') ||
      newState.get('content') !== oldState.get('content')
    );
  }

  static getDataUpdates(newState, oldState) {
    var r = {};
    if (newState.get('settings') !== oldState.get('settings'))
      r['settings'] = newState.get('settings').toJS();
    if (newState.get('content') !== oldState.get('content'))
      r['content'] = newState.get('content');
    return r;
  }
}

export default Policies;