class VaniInterface {
  constructor(machine) {
    const { vani } = machine.app;

    // listen to socket <-> vani
    vani.on('for machine', payload => {
      if (machine.state.get('status') === 'connected') {
        machine.socket.emit('vani', payload);
      }
    });

    machine.emitter.on('vani', payload => {
      vani.handleMachineMessage(payload);
    });
  }
}

export default VaniInterface;