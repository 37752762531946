import { fromJS } from 'immutable';
import { debounce } from 'lodash';
import BaseController from 'lib/BaseController';
import DEFAULT_THEME from '../defaults/theme';

class ThemeData extends BaseController {
  load = (data) => {
    const themeData = fromJS(data || {});
    this.setState({ data: themeData, original: themeData });
  };
  hasChanged = () => this.state.get('data') !== this.state.get('original');
  revert = () => this.setState({ data: this.state.get('original') });

  getData = (k) => {
    const r = this.state.get('data').get(k);
    return (typeof r !== 'undefined' ? r : null);
  }
  setData = (o) =>  this.setState({ data: this.state.get('data').merge(o) });
  toJS = () => this.state.get('data').toJS();

  getValue = (k) => {
    const d = this.getData(k);
    if (typeof d === 'undefined' || d === null)
      return this.getDefault(k)
    return this.getData(k)
  };
  getDefault = (k) => DEFAULT_THEME[k];
  setDataDebounced = debounce(this.setData, 50);
}

export default ThemeData;