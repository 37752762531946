import { useControllerState } from 'lib/BaseController';
import { Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import EditorView from 'lib/prosemirror/View';
import ui from 'lib/popup/components/ui';

import './style.css';


function Popup({ popup }) {
  const state = useControllerState(popup);

  return (
    <ui.Popup popup={popup}>
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>{popup.args.title}</ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.handleSubmit(); }}>
            <Group>
              <EditorView
                controller={popup.editorController}
                isMarkdown={popup.args.format !== 'html'} />
            </Group>
            <ui.Alert />
            <Buttons className="text-right">
              <Button type="submit" isDisabled={state.get('loading')}>
                Save
              </Button>
              <Button onPress={e => { popup.close() }}>
                Cancel
              </Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;