import {Schema} from 'prosemirror-model'


function getNodeAttrsFromEl(el) {
  const
    className = (el as HTMLElement).getAttribute('class') || '',
    result: { [key: string] : string } = {};

  for (let n of className.split(' ')) {
    if (n.startsWith('text-')) {
      result.align = n.substr(5);
    }
  }
  return result;
}

function getDOMAttrsFromNode(node) {
  var classNames = [];
  if (node.attrs.align) {
    classNames.push('text-' + node.attrs.align);
  }
  return { class: classNames.join(' ') };
}


/// Document schema for the data model used by CommonMark.
export const schema = new Schema({
  nodes: {
    doc: {
      content: 'block+'
    },

    paragraph: {
      content: 'inline*',
      group: 'block',
      attrs: { align: {default: 'left'} },
      parseDOM: [{
        tag: 'p',
        getAttrs: el => getNodeAttrsFromEl(el)
      }],
      toDOM(node) { return ['p', getDOMAttrsFromNode(node), 0] }
    },

    blockquote: {
      content: 'block+',
      group: 'block',
      parseDOM: [{tag: 'blockquote'}],
      toDOM() { return ['blockquote', 0] }
    },

    horizontal_rule: {
      group: 'block',
      parseDOM: [{tag: 'hr'}],
      toDOM() { return ['div', ['hr']] }
    },

    heading: {
      attrs: {level: {default: 1}, align: {default: 'left'} },
      content: 'inline*',
      group: 'block',
      defining: true,
      parseDOM: [
        { tag: 'h1', getAttrs: el => Object.assign({ level: 1}, getNodeAttrsFromEl(el)) },
        { tag: 'h2', getAttrs: el => Object.assign({ level: 2}, getNodeAttrsFromEl(el)) },
        { tag: 'h3', getAttrs: el => Object.assign({ level: 3}, getNodeAttrsFromEl(el)) },
        { tag: 'h4', getAttrs: el => Object.assign({ level: 4}, getNodeAttrsFromEl(el)) },
        { tag: 'h5', getAttrs: el => Object.assign({ level: 5}, getNodeAttrsFromEl(el)) },
        { tag: 'h6', getAttrs: el => Object.assign({ level: 6}, getNodeAttrsFromEl(el)) }
       ],
      toDOM(node) {
        return ['h' + node.attrs.level, getDOMAttrsFromNode(node), 0]
      }
    },

    code_block: {
      content: 'text*',
      group: 'block',
      code: true,
      defining: true,
      marks: 'sup sub',
      attrs: {params: {default: ''}},
      parseDOM: [{
        tag: 'pre',
        preserveWhitespace: 'full',
        getAttrs: node => ({ params: (node as HTMLElement).getAttribute('data-params') || '' })
      }],
      toDOM(node) {
        let p = { spellcheck: false }
        if (node.attrs.params)
          p['data-params'] = node.attrs.params;
        return ['pre', p, ['code', 0]];
      }
    },

    ordered_list: {
      content: 'list_item+',
      group: 'block',
      attrs: {order: {default: 1}, tight: {default: false}},
      parseDOM: [{tag: 'ol', getAttrs(dom) {
        return {order: (dom as HTMLElement).hasAttribute('start') ? +(dom as HTMLElement).getAttribute('start')! : 1,
                tight: (dom as HTMLElement).hasAttribute('data-tight')}
      }}],
      toDOM(node) {
        return ['ol', {start: node.attrs.order === 1 ? null : node.attrs.order,
                       'data-tight': node.attrs.tight ? 'true' : null}, 0]
      }
    },

    bullet_list: {
      content: 'list_item+',
      group: 'block',
      attrs: {tight: {default: false}},
      parseDOM: [{tag: 'ul', getAttrs: dom => ({tight: (dom as HTMLElement).hasAttribute('data-tight')})}],
      toDOM(node) { return ['ul', {'data-tight': node.attrs.tight ? 'true' : null}, 0] }
    },

    list_item: {
      content: 'paragraph block*',
      defining: true,
      parseDOM: [{tag: 'li'}],
      toDOM() { return ['li', 0] }
    },

    text: {
      group: 'inline'
    },

    image: {
      group: 'block',
      selectable: true,
      attrs: {
        location: {},
        alt: { default: null },
        title: { default: null },
        maxWidth: { default: null },
        hasBorder: { default: 'yes' },
        alignment: { default: 'left' },
        description: { default: '' }
      },
      parseDOM: [{
        tag: `figure[data-type='imageBlock']`,
        getAttrs(dom) {
          return {
            hasBorder: dom.getAttribute('data-hasborder'),
            description: dom.getAttribute('data-description'),
            alignment: dom.getAttribute('data-alignment'),
            location: dom.getAttribute('data-location'),
            maxWidth: dom.style.maxWidth,
          }
        }
      }],
      toDOM(node) {
        const { location, alt, title, maxWidth, alignment, description, hasBorder } = node.attrs;
        return ['figure', { 
          'data-type': 'imageBlock',
          'data-alignment': alignment,
          'data-description': description,
          'data-hasborder': hasBorder,
          'data-location': location,
          'data-alt': alt,
          'data-title': title,
          style: maxWidth ? `max-width: ${maxWidth}` : null
        },]
      }
    },

    hard_break: {
      inline: true,
      group: 'inline',
      selectable: false,
      parseDOM: [{tag: 'br'}],
      toDOM() { return ['br'] }
    },

    math: {
      inline: true,
      group: 'inline',
      content: 'text*',
      draggable: true,
      atom: true,
      toDOM: () => ['math', { class: 'math-node' }, 0],
      parseDOM: [{ tag: 'math', getAttrs: (node) => { return {};} }],
    },
  },

  marks: {
    em: {
      parseDOM: [
        {tag: 'i' },
        {tag: 'em' },
        { style: 'font-style', getAttrs: value => value === 'italic' && null }
      ],
      toDOM() { return ['em'] }
    },

    strong: {
      parseDOM: [
        {tag: 'b'},
        {tag: 'strong'},
        { style: 'font-weight',
          getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value as string) && null}],
      toDOM() { return ['strong'] }
    },

    link: {
      attrs: {
        href: {},
        title: {default: null}
      },
      inclusive: false,
      parseDOM: [{tag: 'a[href]', getAttrs(dom) {
        return {href: (dom as HTMLElement).getAttribute('href'), title: (dom as HTMLElement).getAttribute('title')}
      }}],
      toDOM(node) { return ['a', node.attrs] }
    },

    code: {
      parseDOM: [{tag: 'code'}],
      attrs: { spellcheck: { default: false } },
      toDOM(node) { return ['code', node.attrs] }
    },

    sub: {
      parseDOM: [{tag: 'sub'}],
      toDOM(node) { return ['sub', node.attrs] },
      excludes: 'sup',
    },

    sup: {
      parseDOM: [{tag: 'sup'}],
      toDOM(node) { return ['sup', node.attrs] },
      excludes: 'sub',
    },

    underline: {
      parseDOM: [
        { style: 'text-decoration', getAttrs: value => value === 'underline' && null }],
      toDOM() { return ['span', { style: 'text-decoration: underline' }] }
    },

    strikethrough: {
      parseDOM: [
        { style: 'text-decoration', getAttrs: value => value === 'line-through' && null }],
      toDOM() { return ['span', { style: 'text-decoration: line-through' }] }
    },

    highlight: {
      parseDOM: [{ tag: 'mark' }],
      toDOM() { return ['mark'] }
    },

    cursive: {
      attrs: { class: { default: 'font-cursive' } },
      toDOM: (mark, inline) => ['span', { class: mark.attrs.class }, 0],
      parseDOM: [{ tag: 'span.font-cursive', }]
    },

    // color: {
    //   parseDOM: [{ style: 'color', getAttrs: value => value === 'line-through' && null }],
    //   toDOM() { return ['span', val => {style : 'color: color'}] }
    // },
  }
})
