import Editor from './Editor';
import Policies from './Policies';
import Inspector from './Inspector';
import Viewer from './Viewer';
import Controller from './Controller';
import Overview from './Overview';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';

const index = {
  type: 'box-runner',
  Editor: Editor,
  Inspector: Inspector,
  Policies: Policies,
  Viewer: Viewer,
  Controller: Controller,
  Overview: Overview,
  caption: 'Box Runner',
  faIcon: faLaptopCode,
  description: 'Run any code a connected machine',
}

export default index;