export function SingleColumn({ className }) {
  return (
    <svg className={className} width="120" height="98" viewBox="0 0 120 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="118.5" height="96.5" stroke="black" strokeWidth="1" strokeLinejoin="round"/>
      <rect x="39.5" y="20.5" width="36" height="20" stroke="black"/>
      <path d="M39 57H55" stroke="black"/>
      <path d="M60 57H76" stroke="black"/>
      <path d="M39 67H55" stroke="black"/>
      <path d="M60 67H76" stroke="black"/>
      <path d="M39 62H55" stroke="black"/>
      <path d="M60 62H76" stroke="black"/>
      <path d="M39 72H55" stroke="black"/>
      <path d="M60 72H76" stroke="black"/>
      <path d="M39 49H76" stroke="black" strokeWidth="2"/>
    </svg>
  )
}

export function DoubleColumn({ className }) {
  return (
    <svg className={className} width="120" height="98" viewBox="0 0 120 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="15.5" y="27.5" width="36" height="20" stroke="black"/>
      <rect x="0.75" y="0.75" width="118.5" height="96.5" stroke="black" strokeWidth="1" strokeLinejoin="round"/>
      <path d="M67 27H103" stroke="black"/>
      <path d="M67 47H103" stroke="black"/>
      <path d="M67 37H103" stroke="black"/>
      <path d="M67 57H103" stroke="black"/>
      <path d="M67 32H103" stroke="black"/>
      <path d="M67 52H103" stroke="black"/>
      <path d="M67 42H103" stroke="black"/>
      <path d="M67 62H103" stroke="black"/>
      <path d="M15 56H52" stroke="black" strokeWidth="2"/>
    </svg>
  )
}
