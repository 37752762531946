import './Loader.css';
import clsx from 'clsx';

export default function Loader({ className }) {
  return (
    <div className={clsx('nb-dots-loader', className)}>
      <div className="dot dot1 fade-in">.</div>
      <div className="dot dot2 fade-in">.</div>
      <div className="dot dot3 fade-in">.</div>
    </div>
  )
}
