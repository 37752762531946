import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import { getLocalDVMapForArticle } from 'lib/transpiler/dvMap'

import View from './View';


class CreateVersion extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  constructor(args, manager) {
    super(args, manager);

    this.articleController = args.articleController;
    this.provider = args.articleController.provider;
    const initialSlug = this.getInitialVersion();
    this.state = this.state.merge({
      label: 'Updates - ' + (new Date()).toLocaleDateString(),
      slug: initialSlug,
      initialSlug: initialSlug,
      isPublic: true,
      notes: '',
      parentPath: args.articleController.state.get('article').get('path'),
      publishVersion: true,
      created: false,
    });
    this.loadDVMap();
  }

  async createAndPublish() {
    const
      articleId = this.args.articleController.id,
      domainId = this.args.articleController.state.getIn(['article', 'domainId']),
      { label, slug, notes, isPublic, dvMap } = this.state.toJS();

    this.setState({ loading: true, alert: '' });
    try {
      const
        res = await this.provider.createVersion({ articleId, domainId, label, slug, notes, isPublic, dvMap }),
        { versionId } = res.data;

      if (isPublic && this.state.get('publishVersion'))
        await this.provider.publishVersion({ domainId, versionId, articleId });
      
      this.setState({ created: true });
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }


  versionRegex = /^(\d+)\.(\d+)\.(\d+)(?:-([a-z]+)\.(\d+))?$/;

  extractVersion = (version) => {
    let
      m = version.match(this.versionRegex),
      parse = i => parseInt(i, 10);
    if (!m)
      return null;
    let m5 = parse(m[5]);
    return [parse(m[1]), parse(m[2]), parse(m[3]), m[4], isNaN(m5) ? -1 : m5];
  }

  getInitialVersion = () => {
    const
      latestVersion = this.articleController.state.getIn(['article', 'latestVersion']) || '',
      extract = this.extractVersion(latestVersion);

    if (!extract)
      return '0.0.0-d.0';

    let [m1, m2, p, dType, d] = extract;
    return `${m1}.${m2}.${p}-${dType || 'd'}.${d + 1}`
  }

  loadDVMap = async () => {
    this.setState({ dvMap: null });
    try {
      let dvMap = await getLocalDVMapForArticle({ articleController: this.articleController });
      this.setState({ dvMap });
    } catch (e) {
      this.setState({ dvMapError: e.message });
      this.setState({ dvMap: {} });
    }
  }
}

export default CreateVersion;