import LogoButton from './LogoButton';
import { RightGroup } from './BarHorizontal';

import Logo from 'lib/Logo';

export default function BarVerticle({ editor }) {
  return (
    <>
      <div className="absolute top-4 left-4">
        <LogoButton editor={editor} showChevron={false} showTitle={true} placement="end" wide={true}>
          <Logo className="w-8 h-8 inline-block" />
        </LogoButton>
      </div>

      <div className="absolute bottom-2 left-2 flex  flex-col items-center bg-paper py-1 px-2 gap-1 border border-rim rounded-sm">
        <RightGroup editor={editor} verticle={true} />
      </div>
    </>
  )
}