import CodeMirror from '@uiw/react-codemirror';
import allThemes from 'lib/codemirror/themes';
import { loadLanguage } from '@uiw/codemirror-extensions-langs';
import Styler from './Styler';


export default function Article({ themeController }) {
  return (
    <div className="px-8 py-6 h-full w-full overflow-auto nb-document">
      <Styler themeData={themeController.themeData} baseClass="nb-document" />

      <article className="max-width">
        <div className="my-4">
          <h2 className="article-title font-header">
            This is a Sample Article
          </h2>
        </div>

        <div className="my-4">
          This is a short example article to give you an idea of what
          a scrollable page would look like based on your selected preferences.
          On the right hand side you'll find a panel where you could modify and 
          fine tune the page to your personalized preferences.
        </div>
        <div>
          <strong>Note:</strong> Be careful as you're saving your preference.
          The design will take into effect for your entire workspace
          as soon as you confirm save.
        </div>

        <div className="my-4">
          <h4>Text Size and Spacings</h4>
        </div>
        <div className="my-4">
          By default, each paragraph comes with a standard <i>1.6 em</i>  of
          line height, and <i>2 em</i> of spacing 
          between them. These number are to give the readers 
          a highest amount of legibility.
          Here are some examples: <u>Underline</u>, <i>Italic</i>, <strong>Bold</strong>,
          and a <a href="#link" onClick={e => e.preventDefault()}>Link</a>.
        </div>

        <div className="my-4">
          <h4>Code Editor</h4>
        </div>

        <div className="my-4">
          Here is an example of a code element. On the <code>Code</code> section of the
          inspector you could select various preferences, such as a dark or a light theme.
        </div>

        <div className="my-4">
          <CodeBlock themeController={themeController} themeKeyKey="codeThemeLight" />
        </div>

        <div className="my-4">
          <CodeBlock themeController={themeController} themeKeyKey="codeThemeDark" />
        </div>        

        <div className="my-4">
          <h4>Nav Bar</h4>
        </div>

        <div className="my-4">
          The <code>navigation</code> tab is where you can add
          navigation links to your pages.
          They could be both external links and internal links.
          Be sure to test them well since they will show up
          on every page in your workspace.
        </div>

        <div className="my-4">
          <h4>Font Families</h4>
        </div>

        <div className="my-4">
          You could pick upto four different fonts for your page.

          The right pairing of fonts make a document much more effective
          at communicating ideas with the right audiences.

          The pairing could be fun but if they're overwhelming try one of
          our pre-paired fonts from the <code>Presets</code> section.
        </div>

        <div className="my-4 font-cursive">
          And here's the cursive font you've selected.
        </div>
      </article>
    </div>
  )
}

function CodeBlock({ themeController, themeKeyKey }) {
  const
    { getValue } = themeController.themeData,
    themeKey = getValue(themeKeyKey),
    lineNumbers = getValue('codeHasLineNumbers');

  // let style = {};
  // if (codeBorderColor)
  //   style.borderColor = codeBorderColor;
  //     codeBorder = getValue('codeBorder'),
  //   codeBorderColor = getValue('codeBorderColor'),

  return (
    <div
      className={'border-rim border'}>
      <CodeMirror
        value={`#include <stdio.h>\n\nint main() {\n    printf("Hello World");\n}`}
        extensions={[loadLanguage('cpp')]}
        theme={allThemes[themeKey]}
        height={null}
        basicSetup={{
          lineNumbers: lineNumbers,
          highlightActiveLineGutter: false,
          highlightActiveLine: false,
          foldGutter: false,
        }}
        readOnly={false} />
    </div>);
}