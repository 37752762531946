import settingsFields from '../Policies/settingsFields';
import { BuildInputs } from 'modules/elements/lib/sidebar/builder';
import { Block, Label, Break } from 'modules/elements/lib/sidebar/inputs/components';
import Selection from 'modules/elements/lib/sidebar/inputs/Selection';
import { Buttons, Button } from 'modules/elements/lib/sidebar/inputs/buttons';
import { schema } from 'lib/prosemirror/schema.ts';
import Toolbar from './Toolbar';


export default function Inspector({ state, controller }) {
  const
    { getSettingsData, getSettingsValue, getSettingsValueOnNull, setSettings } = controller,
    opts = {
      fields: settingsFields,
      getData: getSettingsData,
      getValue: getSettingsValue,
      setData: setSettings,
      getValueOnNull: getSettingsValueOnNull
    }
  
  return (
    <>
      <Toolbar controller={controller} />

      <Break />

      <BuildInputs
        layout={[
          [['fontSize']]
        ]}
        {...opts} />
      <Break />

      <InsertSection controller={controller} />
      
      <Break />      
      <BuildInputs
        layout={[
          [['spellCheck', 'columns']],
        ]}
        {...opts} />

      <CSSSection controller={controller} />
    </>
  )
}


function InsertSection({ controller }) {
  const
    { editorController } = controller,
    isLink = editorController.isMarkActive(schema.marks.link);

  return (
    <Block>
      <Label>Insert</Label>
      <Buttons>
        <Button
          active={isLink}
          onPress={e => {
            if (isLink) {
              editorController.toggleMark(schema.marks.link);
              return;
            }
            editorController.addLinkPrompt();
          }}>
          Link
        </Button>

        <Button
          onPress={e => editorController.addMath()}
          isDisabled={editorController.canReplaceSelectionWith(schema.marks.math)}>
          Formula
        </Button>

        <Button onPress={e => editorController.addRule()}>
          Rule
        </Button>

        <Button onPress={e => editorController.addImage({})}>
          Image
        </Button>
      </Buttons>
    </Block>
  )
}


function CSSSection({ controller }) {
  const
    options = controller.settingsFields.css.options,
    value = controller.getSettingsValue('css'),
    isCustom = !options.find(o => o.key === value);

  return (
    <Block>
      <Selection
        label="CSS Style"
        value={isCustom ? 'custom' : value}
        options={[...options, { caption: 'Custom CSS', key: 'custom' }]}
        onChange={v => {
          if (v === 'custom') {
            controller.openPopup('code-editor', {
              title: 'Custom CSS',
              language: 'css',
              value: value,
              onSave: async (value) => {
                controller.setSettings('css', value);
              }
            });
          } else {
            controller.setSettings('css', v);
          }
        }} />
    </Block>
  );
}