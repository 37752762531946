function Overview({ controller, isActive, onMouseDown }) {
  const editorViewState = controller.getState().get('editorViewState');
  if (!editorViewState)
    return ''

  const texts = [];
  editorViewState.doc.forEach((child, i) => {
    if (child.type.name === 'heading') {
      texts.push(
        <div key={i} className="">
          {child.textContent}
        </div>
      );
    } else {
      texts.push(
        <div key={i} className="break-words text-[0.5rem] leading-none w-full">
          { Array(Math.floor(child.content.size / 3)).fill('.-').join('') }
        </div>)
    }
  })
  return texts;
}

export default Overview;