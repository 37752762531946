import p from 'lib/popup/components/ui';
import { Element } from 'modules/article/views/Elements';

function Popup({ popup }) {
  let element = popup.element;
  
  return (
    <p.Popup popup={popup} className="change-email-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>
            Element
          </p.Title>
          <p.CloseButton />
        </p.TitleBar>

        <p.Body>
          <Element element={element} isActive={true} />
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

export default Popup;