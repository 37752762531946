import Loader from './Loader';
import { toHuman } from 'lib/firebaseError';
import { supportEmail } from 'meta';
import clsx from 'clsx';

import './style.css';


export function LoadingBlock() {
  return (
    <div className="nb-loading-block">
      <Loader />
    </div>
  );
}


export function ErrorBlock({ title, error, style, className }) {
  return (
    <div className={clsx('text-center max-w-[30rem] mx-auto pt-16 pb-4', className)} style={style}>
      <h2 className="text-3xl font-medium text-red-800">{title || 'Error'}</h2>
      <p className="my-4 break-words font-medium text-lg">Reason: {typeof error === 'string' ? error : toHuman(error)}</p>
      <p className="">
        Contact our support at <span className="font-mono text-blue-600 text-sm">{supportEmail}</span> <br />
        if this isn't an expected behavior.</p>
    </div>
  );
}