import { Button } from 'react-aria-components';
import clsx from 'clsx';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'


function Controls({ controller }) {
  const
    { state, setState } = controller,
    entries = state.get('entries'),
    at = state.get('at'),
    setAt= at => setState({ at });

  if (entries.size <= 1)
    return '';

  return (
    <>
      <CircleButton
        Icon={ChevronLeftIcon}
        onPress={() => setAt((at - 1) % entries.size)}
        isDisabled={at === 0}
        alignClass="left-1" />

      <CircleButton
        Icon={ChevronRightIcon}
        onPress={() => setAt((at + 1) % entries.size)}
        isDisabled={at === (entries.size - 1)}
        alignClass="right-1" />

      <div className="absolute left-0 bottom-0 w-full p-1 text-center">
        {entries.map((e, i) => (
          <Button
            key={i}
            className={clsx(
              'inline-block w-2 h-2 rounded-full bg-paper opacity-60 dot outline-none m-1 shadow',
              i === at ? 'drop-shadow opacity-80' : ''
            )}
            onClick={() => setAt(i)} />
        ))}
      </div>
    </>
  );
}

function CircleButton({ onPress, Icon, alignClass, isDisabled }) {
  return (
    <Button
      onPress={onPress}
      className={clsx(
        'rounded-full bg bg-paper bg-opacity-80 p-0.5 outline-none text-pencil absolute top-1/2 -translate-y-1/2',
        alignClass,
        isDisabled && 'opacity-40'
      )}
      isDisabled={isDisabled}>
      <Icon className="h-4 w-4" />
    </Button>
  )
}

export default Controls;