import { useState } from 'react';
import p from 'lib/popup/components/ui';
import { Group, TextInput } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import { Button as AriaButton } from 'react-aria-components';
import SlugCheckInput from 'lib/inputs/SlugCheckInput';

import { slugify } from 'lib/slugify';
import { useControllerState } from 'lib/BaseController';
import { checkIsAdmin } from 'api/remote/calls/auth';

import './style.css';


function Popup({ popup }) {
  let
    state = useControllerState(popup),
    slug = state.get('slug'),
    valid = state.get('valid'),
    loading = state.get('loading'),
    disabled = loading || !slug || !valid,
    userInfo = popup.args.auth.state.get('userInfo'),
    { domainCount, domainLimit } = userInfo,
    left = (domainLimit || 0) - (domainCount || 0),
    atLimit = left <= 0 && !checkIsAdmin();

  return (
    <p.Popup popup={popup} className="create-article-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>
            Create a New Workspace
          </p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          { !state.get('emailVerified') ? (
            <VerifyEmail popup={popup} />
          ) : state.get('created') ? (
            <div>
              <div className="created-message text-center">
                <h3 className="pt-8 pb-4 font-medium">
                  New Workspace has been created!
                </h3>
              </div>

              <Buttons className="text-center">
                <Button onClick={_ => popup.close()} autoFocus={true}>
                  Done
                </Button>
              </Buttons>
            </div>
          ) : (
          <form
            onSubmit={e => {
              e.preventDefault();
              if (!disabled)
                popup.createDomain();
            }}>
            <Group>
              { popup.isPrimary && (
                <p.Info title="Creating First Workspace">
                  This will be your primary personal workspace
                  tied to your account.
                </p.Info>
              ) }

              <TextInput
                label="Title"
                value={state.get('title')}
                autoFocus={true}
                isDisabled={loading}
                onChange={v => {
                  popup.setState({ title: v });
                  if (state.get('autoSlug')) {
                    popup.setState({ slug: slugify(v) });
                  }
                }} />

              <SlugCheckInput
                // ref={slugInputRef}
                label="Slug"
                value={state.get('slug')}
                onChange={v => popup.setState({ slug: v })}
                onValidChange={e => popup.setState({ valid: e })}
                checkFn={(val) => popup.checkAvailable(val)} />
            </Group>

            <p.Alert />

            {/*{ left > 0 && <div className="text-right font-medium mt-4 text-sm">{left} Left</div>}*/}
            { atLimit && (
              <p.Info>
                You've reached you domain creation limit.
                <AriaButton
                  onPress={popup.requestMore}
                  className="font-medium underline">Request More</AriaButton>
              </p.Info>
            )}


            <Buttons className="text-right">
              <Button isDisabled={disabled || atLimit} type="submit">
                Create
              </Button>
            </Buttons>
          </form>
          ) }
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

function VerifyEmail({ popup }) {
  const [at, setAt] = useState('init');

  function sendVerificationEmail() {
    setAt('sending');
    popup.auth
      .sendEmailVerification()
      .then(() => setAt('sent'))
  }  

  return (
    <div className="verify-email text-center p-4">
      <p>
        Please verify your email before you could create a new workspace.
      </p>
      <hr className="my-4" />
      <p>
        { at === 'init' && (
          <span className="link-button" onClick={sendVerificationEmail}>
            Resend Verification Email
          </span>
        ) }
        { at === 'sending' && '...' }
        { at === 'sent' && (
          <span>
            Verification e-mail sent to <br />
            <strong>{ popup.auth.user.email }</strong>
          </span>
        ) }
      </p>
    </div>
  )
}

export default Popup;