import { useRef, useEffect } from 'react';
import { Button } from 'react-aria-components';


export default function CommandInput({ controller }) {
  const
    { state, getSettingsValue, setSettings } = controller,
    inputRef = useRef(),
    processIsActive = state.get('processIsActive'),
    command = getSettingsValue('command');

  useEffect(() => controller.setFocusFn(
    () => inputRef.current.focus()), [controller]
  );
    
  return (
    <form
      className="flex gap-2 px-4 border-b border-rim"
      onSubmit={e => { e.preventDefault(); controller.run(); }}>
      <div className="py-2 leading-normal font-code">$</div>
      <div className="command flex-grow font-code">
        <TextArea
          value={command}
          inputRef={inputRef}
          onFocus={controller.activate}
          onChange={e => setSettings('command', e.currentTarget.value)} />
      </div>

      <div className="actions flex">
        {!processIsActive ?
          (<ProcButton type="submit" isDisabled={!command}>Run</ProcButton>) :
          (<ProcButton type="button" onPress={_ => controller.kill()}>Kill</ProcButton>)
        }
      </div>
    </form>
  );
}

function ProcButton(props) {
  return (<Button {...props} className="uppercase p-2 leading-normal font-code" />);
}

function TextArea({ value, onChange, onFocus, inputRef, spellCheck }) {
  const ref = useRef();

  function resizeTextbox() {
    if (!ref.current)
      return;
    ref.current.style.height = 'auto';
    ref.current.style.height = ref.current.scrollHeight + 1 + 'px';
  }
  useEffect(resizeTextbox, [value]);

  return (
    <textarea
      className="outline-none resize-none bg-transparent w-full p-2 block"
      rows={1}
      ref={el => {
        ref.current = el;
        if (inputRef)
          inputRef.current = el;
      }}
      {...{ value, onFocus, onChange }}
      spellCheck={spellCheck || 'false'} />
  )
}