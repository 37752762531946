import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';
import { fromJS } from 'immutable';
import Prompt from 'lib/popup/popups/Prompt/Controller';


class Controller extends BasePopupController {
  position = fromJS({ width: 420 });
  static View = View;

  constructor(args, manager) {
    super(args, manager);
    this.docId = args.docId;
    this.docPath = args.docPath;
    this.machine = args.machine;

    if (args.onClose)
      this.unloadFns.push(args.onClose);

    this.state = this.state.merge({ root: fromJS({}) })
    this.expandFolder([]);
  }

  expandFolder = async (path) => {
    const
      // root = this.state.get('root'),
      objectPath = ['root', ...path.map(p => ['children', p])].flat();

    this.setState({ loading: true });
    this.setInState([...objectPath, 'loading'], true);
    try {
      let
        children = {},
        response = await this.machine.diskSync.fetchFile({ docId: this.docId, filename: path.join('/') }),
        list = await response.json();
      for (let file of list) { children[file.name] = file; }
      this.setInState([...objectPath, 'expanded'], true);
      this.setInState([...objectPath, 'children'], fromJS(children));
    } catch (e) {
      this.setState({ errored: true });
      this.setInState([...objectPath, 'errored'], false);
    }
    this.setInState([...objectPath, 'loading'], false);
    this.setState({ loading: false });
  }

  collapseFolder = (path) => {
    this.setInState(['root', ...path.map(p => ['children', p]), 'expanded'].flat(), false);
  }

  promptDeleteRemoteFile = async (path) => {
    const filename = path.join('/');

    this.manager.open(Prompt, {
      title: 'Delete this file?',
      question: (
        <>Delete <span className="font-medium">"{filename}"</span> on the connected machine?</>
      ),
      onSubmit: async () => {
        await this.machine.diskSync.deleteFile({ filename: filename, docId: this.docId });
        this.expandFolder(path.slice(0, -1))
      },
      submitLabel: 'Yes'
    }, this.sessionId);
  }
}

export default Controller;