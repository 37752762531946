import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  async revert() {
    const { version, provider } = this.args;
    this.setState({ loading: true, alert: '' });

    try {
      await provider.revertToVersion({
        domainId: version.get('domainId'),
        articleId: version.get('articleId'),
        versionId: version.get('id')        
      });
      this.close();
    } catch (e) {
      this.setState({ alert: 'Error! ' + e.message });
    }
    this.setState({ loading: false });
  }
}

export default Controller;