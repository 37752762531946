import React from 'react';

function Viewer({ element }) {
  return (
    <div className="re">
      Viewer
    </div>
  );
}

export default Viewer;