import Loader from 'lib/staticPages/Loader';
import EditButton from './EditButton';
import StorageImage from 'lib/StorageImage';


export default function DomainImageInput({ controller }) {
  const
    { state } = controller,
    domain = state.get('domain'),
    uploadedImageData = state.get('uploadedThumbnailImageData'),
    imagePath = domain.getIn(['thumbnail', '640x640']),
    uploading = imagePath && state.get('oldThumbnailImageUrl') === imagePath,
    getDownloadURL = controller.provider.getDownloadURL;

  return (
    <>
      <div className="image border border-rim2 bg-paper2 relative p-1 group">
        { uploading && (<StorageImage placeholder={`data:${uploadedImageData}`} />)}
        { !uploading && (<StorageImage path={imagePath} getDownloadURL={getDownloadURL} />)}
        { !uploading && (<EditButton onPress={() => controller.popups.editThumbnailPopup()} />)}
      </div>
      { uploading && (<div className="loader-position"><Loader /></div>) }
    </>
  );
}