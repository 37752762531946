import clsx from 'clsx';
import { useControllerState } from 'lib/BaseController';

export default function Resizer({ grid, isResizable }) {
  const
    resizer = grid.pageResizer,
    state = useControllerState(resizer),
    dragging = state.get('dragging'),
    { width, height } = grid.pageRect,
    { cellSize, cellGap, dim } = grid,
    dx = state.get('dx'),
    dy = state.get('dy'),
    dcol = Math.round(state.get('dx') / (cellSize.width + cellGap.x)),
    drow = Math.round(state.get('dy') / (cellSize.height + cellGap.y)),
    qx = dcol * (cellSize.width + cellGap.x),
    qy = drow * (cellSize.height + cellGap.y),
    left = width + cellGap.x + dx - 16,
    top = height + cellGap.y + dy - 16,
    barClassName = clsx('absolute', dragging ? 'bg-marker' : 'bg-wax3 group-hover:bg-rim3');

  return (
    <>
      <div
        className={
          clsx(
            'drag-end absolute absolute cursor-se-resize pointer-events-auto w-4 h-4 group',
            // dragging ? 'bg-marker2' : 'bg-rim2 hover:opacity-100'
          )
        }
        style={{ left, top }}
        onMouseDown={resizer.startDrag}>
        <div className={clsx('w-4 h-1 top-3', barClassName)} />
        <div className={clsx('w-1 h-4 left-3', barClassName)} />
      </div>

      { dragging && (
        <div
          className="absolute border border-blue-200 dark:border-blue-900"
          style={{
            left: -cellGap.x/2,
            top: -cellGap.y/2,
            width: width + cellGap.x + qx,
            height: height + cellGap.y + qy
          }}></div>
      ) }

      { dragging && (
        <div className="absolute no-brak bg-wax whitespace-nowrap p-1" style={{ left: left + 16, top: top + 16 }}>
          { dim.cols + dcol } &times; { dim.rows + drow }
        </div>
      ) }
    </>
 );
}
