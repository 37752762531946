import { useState, useEffect } from 'react';
import { Map } from 'immutable';
import Emitter from './Emitter';

class BaseController {
  constructor() {
    this.emitter = new Emitter();
    this.state = new Map({});
  }

  setState = (s) => {
    this.state = this.state.merge(Map(s));
    this.emit('change');
  }

  setInState = (keyPath, value) => {
    this.state = this.state.setIn(keyPath, value);
    this.emit('change');
  }

  getState = (s) => {
    return this.state;
  }

  emit() {
    this.emitter.emit.apply(this.emitter, arguments);
  }

  on() {
    return this.emitter.on.apply(this.emitter, arguments);
  }

  destroy() {
    this.emitter.destroy();
  }
}

export function useControllerState(controller) {
  const [state, setState] = useState(controller && controller.state);
  if (typeof controller === 'undefined') {
    throw new Error('Controller not provided. Send null instead.');
  }

  useEffect(() => {
    if (controller)
      return controller.on('change', () => setState(controller.state));
    else
      return () => {};
  }, [controller]);
  return state;
}

export default BaseController;