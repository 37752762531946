import { BarButton } from './res/components';
import { PlusIcon, CursorArrowIcon, HandIcon } from '@radix-ui/react-icons';
import { useControllerState } from 'lib/BaseController';


export default function PageButtons({ editor }) {
  const { articleController } = editor;

  return articleController && (
    articleController.layout.type === 'grid' ?
      <GridPageButtons articleController={articleController} /> :
      <ScrollPageButtons articleController={articleController} />
  );
}

function GridPageButtons({ articleController }) {
  const
    { grid } = articleController.layout,
    state = useControllerState(grid),
    cursor = state.get('cursor');

  return (
    <>
      <BarButton
        Icon={CursorArrowIcon}
        tooltip="Select Elements"
        fn={() => grid.setState({ cursor: 'default' }) }
        isActive={cursor === 'default'}/>

      <BarButton
        Icon={HandIcon}
        tooltip="Move Screen"
        fn={() => grid.setState({ cursor: 'move' }) }
        isActive={cursor === 'move'}/>

      <BarButton
        Icon={PlusIcon}
        tooltip="Add Element"
        fn={() => grid.setState({ cursor: 'add' }) }
        isActive={cursor === 'add'}/>
    </>
  )
}

function ScrollPageButtons({ articleController }) {
  const
    { scroll } = articleController.layout,
    state = useControllerState(scroll);

  return (
    <BarButton
      Icon={PlusIcon}
      tooltip="Add a new Element"
      label="Add a new Element"
      fn={() => scroll.setState({ adding: !state.get('adding') })} />
  )
}