import { useControllerState } from 'lib/BaseController';
import { TabbedBoxPanel } from './panelContainers';
import BarHorizontal from '../bars/BarHorizontal';
import BarVertical from '../bars/BarVerticle';
import BarFloating from '../bars/BarFloating';

export default function Switch({ editor, children }) {
  const
    { articleController, panels } = editor,
    articleState = useControllerState(articleController || null),
    panelState = useControllerState(panels);
  useControllerState(articleController || null);
  useControllerState((articleController && articleController.selection) || null);

  const
    hudLayout = panelState.get('hudLayout'),
    error = articleState && articleState.get('error');

  return (
    error ?
      (<ErrorMode error={error}>{children}</ErrorMode>) :
      hudLayout === '2' ?
        <SideBarMode editor={editor} children={children} /> :
        hudLayout === '1' ?
          <FloatMode editor={editor} children={children} /> :
          <RegularMode editor={editor} children={children} />
  );
}

function RegularMode({ editor, children }) {
  return (
    <div className="h-full w-full overflow-hidden flex flex-col">
      <BarHorizontal editor={editor} />
      <div className="flex-grow flex h-full overflow-hidden">
        <div className="flex-grow h-full overflow-hidden">
          {children}
        </div>
        <TabbedBoxPanel
          editor={editor}
          group="right" className="border-l border-rim bg-paper" />
      </div>
    </div>
  );
}

function SideBarMode({ editor, children }) {
  return (
    <div className="h-full w-full overflow-hidden relative flex">
      <div className="flex-grow h-full overflow-hidden">{children}</div>
      <TabbedBoxPanel editor={editor} group="right" className="border-l border-rim bg-paper" />
      <BarVertical editor={editor} />
    </div>
  );
}

function FloatMode({ editor, children }) {
  return (
    <div className="h-full w-full relative flex casing-floating">
      <div className="flex-grow overflow-hidden">{children}</div>
      <TabbedBoxPanel
        editor={editor} group="right"
        outerClassName="pt-14 px-4 pb-4"
        className="border border-rim bg-paper"
        />
      {/*<FloatingPanel editor={editor} id="inspector" />*/}
      <BarFloating editor={editor} />
    </div>
  );
}

function ErrorMode({ children }) {
    return (
      <div>
        {children}
      </div>
    );
}