import { useState, useRef, useEffect } from 'react';
import { Label } from './components';
import clsx from 'clsx';

function Textarea({
  label, value, isDisabled, nullable, valueOnNull,
  spellCheck, onChange, onFocus, onBlur
}) {
  const
    ref = useRef(),
    [focused, setFocused] = useState(false),
    displayValue = (nullable && value === null) ? valueOnNull : value;

  function resize() {
    var text = ref.current;
    text.style.height = 'auto';
    text.style.height = text.scrollHeight + 'px';
  }
  useEffect(() => resize());

  return (
    <div>
      <Label>{label}</Label>
      <textarea
        className={clsx(
          'p-1 px-2 w-full px-1 text-sm hover:bg-wax2',
          (!displayValue || focused)  ? 'bg-wax2' : 'bg-wax',
          isDisabled && ' text-pencil3 pointer-events-none',
        )}
        onFocus={e => {
          setFocused(true);
          if (onFocus) onFocus(e);
        }}
        onBlur={e => {
          setFocused(false);
          if (onBlur) onBlur(e);
        }}
        value={displayValue}
        onChange={e => { onChange(e.currentTarget.value); }}
        ref={ref}
        spellCheck={spellCheck === true} />
    </div>
  )
}


export default Textarea;