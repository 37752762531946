import { useState } from 'react';
import clsx from 'clsx';
import { TriangleRightIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import { Button } from 'react-aria-components';


export default function Collapsible({ children, title }) {
  const [a, setA] = useState(false);
  return (
    <div className="mt-2 md-1 py-2">
      <Button
        className="flex items-center cursor-pointer select-none text-pencil2 gap-1 outline-none"
        onClick={() => setA(!a)}>
        <div>
          {a ? <TriangleDownIcon /> : <TriangleRightIcon />}          
        </div>
        <div className="flex-grow text-sm font-medium">
          {title}
        </div>
      </Button>
      <div className={clsx(!a && 'hidden', 'pl-4 py-1')}>
        {children}
      </div>
    </div>
  );
}