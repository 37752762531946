import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 360 });

  constructor(args, manager) {
    super(args, manager);
    this.auth = args.auth;

    this.state = this.state.merge({
      oldEmail: this.auth.user.email,
      email: '',
      password: '',
    });
  }

  changeEmail() {
    const
      password = this.state.get('password'),
      newEmail = this.state.get('email');

    this.setState({ loading: true, alert: '' });
    return (
      this.auth.changeEmail({ newEmail, password })
        .then(_ => this.setState({ done: true }))
        .catch(e => {
          console.error(e);
          this.setState({ alert: toHuman(e) });
        })
        .then(_ => this.setState({ loading: false }))
    );
  }
}

export default PopupController;