import BaseController from 'lib/BaseController';
import { fromJS } from 'immutable';

import Transformer from './draggers/Transformer';
import Selector from './draggers/Selector';
import Adder from './draggers/Adder';
import PageResizer from './draggers/PageResizer';

import Camera from './Camera';

export const GRID_DEFAULTS = {
  cellSize: { width: 16, height: 14 },
  cellGap: { x: 4, y: 4 },
  dim: { rows: 6, cols: 12 },
}

export default class Grid extends BaseController {
  constructor(layout) {
    super();
    this.layout = layout;
    this.transformer = new Transformer(this);
    this.camera = new Camera(this);
    this.selector = new Selector(this);
    this.adder = new Adder(this);
    this.pageResizer = new PageResizer(this);
    this.state = this.state.merge({ cursor: 'default' });
  }

  load() { this.camera.load(); }

  get grid() { return this.layout.state.get('grid'); }
  get dim() { return this.getConfig('dim'); }
  // Convert points to pixels before sending.
  get pointSize() { return this.layout.pointSize }
  get cellSize() {
    const { width, height } = this.getConfig('cellSize');
    return { width: width * this.pointSize, height: height * this.pointSize };
  }
  get cellGap() {
    const { x, y } = this.getConfig('cellGap');
    return { x: x * this.pointSize, y: y * this.pointSize };
  }

  get pageRect() {
    const { cellSize, cellGap, dim } = this;
    return {
      width: dim.cols * (cellSize.width + cellGap.x) - cellGap.x,
      height: dim.rows * (cellSize.height + cellGap.y) - cellGap.y
    }
  }

  getConfig(key) {
    var s = this.grid.getIn(['config', key]);
    return s ? s.toJS() : GRID_DEFAULTS[key];
  }

  getGridElement(id) {
    return this.grid.getIn(['elements', id]) || fromJS({ id, x: 0, y: 0, w: 2, h: 2 });
  }

  saveGridElement({ id, x, y, w, h }) {
    this.saveIn(['elements', id], { id, x, y, w, h });
  }

  saveIn(keyList, object) {
    this.setIn(keyList, object);
    this.save();
  }

  setIn(keyList, object) {
    const grid = this.grid.setIn(keyList, fromJS(object));
    this.layout.setState({ grid });
  }

  save() {
    this.layout.save();
  }

  handleMouseDown = (e, id) => {
    // this.selection.setActiveElementId(id);
  }
}